import React from 'react';
import styles from './RoundSpinner.module.scss';

const RoundSpinner = ({ position = 'position-fixed', theme = 'dark' }) => (
  <div
    className={`
      ${styles.loader}
      ${position}
      ${theme === 'light' ? styles.light : ''}
      w-100 h-100
      start-0 top-0`}
  >
    <div className="h-100 d-flex align-items-center justify-content-center">
      <div className={styles.loading_spinner} />
    </div>
  </div>
);

export default RoundSpinner;
