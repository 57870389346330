import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCurrPage,
  selectFieldSettings,
  setFieldSettings,
} from '../../../../../../store/reducers/forms';
import styles from './HeadlineSettings.module.scss';
import settingsStyles from '../../../Components/ModalSettings/ModalSettings.module.scss';
import ModalSettings from '../../../Components/ModalSettings';
import Input from '../../../Components/ModalSettings/Input';
import { tagOptions, styleOptions } from './options';
import SelectionButtons from '../../../../../../components/ui/SelectionButtons';

const HeadlineSettings = ({ activeSettings }) => {
  const dispatch = useDispatch();
  const initialized = useRef(false);
  const currPage = useSelector(selectCurrPage);
  const fieldSettings = useSelector(selectFieldSettings);
  const [label, setLabel] = useState('Headline');
  const [styleTag, setStyleTag] = useState(tagOptions[0].value);
  const [textAlignment, setTextAlignment] = useState(styleOptions[0].value);
  const [change, setChange] = useState(false);
  const [errors, setErrors] = useState({});

  const handleChange = useCallback(async () => {
    setChange(false);
    const obj = {
      name: 'headline',
      label,
      type: 'headline',
      styleTag,
      textAlignment,
    };
    dispatch(setFieldSettings({ ...obj, field_id: activeSettings.field_id }));
  }, [
    label,
    styleTag,
    textAlignment,
    dispatch,
    activeSettings,
  ]);

  const setOptions = (config) => {
    setLabel(config.label);
    setStyleTag(config.styleTag);
    setTextAlignment(config.textAlignment);
  };

  useEffect(() => {
    if (label.length === 200) {
      setErrors({ ...errors, label: 'Character limit reached' });
    } else {
      setErrors({ ...errors, label: '' });
    }
  }, [label]);

  useEffect(() => {
    if (initialized.current) return;
    const currPageSettings = fieldSettings.find((o) => o.pageName === currPage);
    if (currPageSettings) {
      const config = currPageSettings.fields?.find((x) => x.field_id === activeSettings.field_id);
      if (config) {
        setOptions(config);
      } else {
        handleChange();
      }
    }
    initialized.current = true;
  }, [activeSettings, currPage, fieldSettings]);

  useEffect(() => {
    if (change) {
      handleChange();
    }
  }, [label, styleTag, textAlignment]);

  useEffect(() => {
    // Reset state when activeSettings changes
    const currPageSettings = fieldSettings.find((o) => o.pageName === currPage);
    if (currPageSettings) {
      const config = currPageSettings.fields?.find((x) => x.field_id === activeSettings.field_id);
      if (config) {
        setLabel(config.label || 'Headline');
        setStyleTag(config.styleTag || tagOptions[0].value);
        setTextAlignment(config.textAlignment || styleOptions[0].value);
      } else {
        setLabel('Headline');
        setStyleTag(tagOptions[0].value);
        setTextAlignment(styleOptions[0].value);
      }
    }
  }, [activeSettings]);

  return (
    <ModalSettings title="Headline Properties">
      <div className={styles.wrapper}>
        <div className={settingsStyles.title}>Style</div>
        <div className={styles.button_wrapper}>
          <SelectionButtons
            buttons={tagOptions}
            selectedValue={styleTag}
            setSelectedValue={(value) => {
              setStyleTag(value);
              setChange(true);
            }}
          />
          <SelectionButtons
            buttons={styleOptions}
            selectedValue={textAlignment}
            setSelectedValue={(value) => {
              setTextAlignment(value);
              setChange(true);
            }}
          />
        </div>
      </div>

      <Input
        title="Headline"
        value={label}
        placeholder="Type here"
        onChange={(e) => {
          setLabel(e.currentTarget.value);
          setChange(true);
        }}
        maxLength={200}
        error={errors.label}
      />
    </ModalSettings>
  );
};

export default HeadlineSettings;
