import React from 'react';

import styles from '../inputs/Input.module.scss';

const FormLabel = ({ val }) => (
  <div className="d-flex cursor-pointer justify-content-between align-items-center">
    <span className={`${styles.label} me-2`}>{val?.label}</span>
  </div>
);

export default FormLabel;
