import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectFilterOption } from '../../../store/reducers/coin';
import { coinApi } from '../../../api/coin';
import ExchangesTable from '../../../components/ui/ExchangesTable';

const Exchanges = ({
  holders,
}) => {
  const { name, network } = useParams();
  const [page, setPage] = useState(1);
  const [desc, setDesc] = useState(true);
  const [orderBy, setOrder] = useState('number_of_users_last_30d');
  const [limit, setLimit] = useState(25);
  const filterOption = useSelector(selectFilterOption);

  const {
    data: exchanges,
    isLoading: isExchnagesLoading,
    isFetching: isExchnagesFetching,
  } = coinApi.useGetNFTExchangeDataQuery({
    address: name,
    chain: network,
    limit,
    order: desc ? 'desc' : 'asc',
    orderBy,
    offset: (page - 1) * limit,
    audience: filterOption,
  });

  return (
    <ExchangesTable
      data={exchanges}
      totalUsers={holders}
      isLoading={isExchnagesLoading || isExchnagesFetching}
      setPage={setPage}
      setDesc={setDesc}
      setOrder={setOrder}
      setLimit={setLimit}
      page={page}
      limit={limit}
      desc={desc}
      orderBy={orderBy}
    />
  );
};

export default Exchanges;
