import React from 'react';
import styles from './RoundSpinner.module.scss';

const steps = [
  { number: 1, text: 'Switching to blockchain' },
  { number: 2, text: 'Uploading Assets' },
  { number: 3, text: 'Deploying Contract' },
  { number: 4, text: 'Waiting for transaction' },
];

const RoundSpinner = ({ position = 'position-fixed', theme = 'dark', statusInfo }) => (
  <div
    className={`modal modal-dialog-centered
      ${styles.loader}
      ${position}
      ${theme === 'light' ? styles.light : ''}`}
  >
    <div className="modal-dialog modal-md">
      <div className={`${styles.window} d-flex modal-content align-items-center justify-content-center gap-3`}>
        <div className={`${styles.wrapper} gap-4`}>
          <div className={`${styles.stepsContainer}`}>
            {steps.map((step) => (
              <div
                key={step.number}
                className={`${styles.stepItem} ${
                  statusInfo?.status === step.number
                    ? styles.activeStep
                    : statusInfo?.status > step.number
                      ? styles.completedStep
                      : ''
                }`}
              >
                <div className={`${styles.stepNumber}`}>
                  {step.number}
                </div>
                <div className={`${styles.stepText}`}>
                  {step.text}
                </div>
              </div>
            ))}
          </div>
          <div className={`${styles.loading_spinner}`} />
          <div className={`${styles.textContainer} d-flex flex-column align-items-center justify-content-center`}>
            <div className={`${styles.text}`}>
              {statusInfo?.statusContent?.title}
            </div>
            <div className={`${styles.description}`}>
              {statusInfo?.statusContent?.extraText}
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
);

export default RoundSpinner;
