import React from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.scss';

const Sidebar = ({
  setSideBAr, sidebar, name, picture, isAuthenticated, logout, userAdmin,
}) => (
  <div className={`sidebar p-4 ${sidebar ? 'show' : ''}`}>
    <div className="sidebar-id d-flex justify-content-between align-items-center mb-4">
      <div className="d-flex align-items-center gap-1">
        <div className="img-wrapper d-flex justify-content-center align-items-center">
          {isAuthenticated ? (
            <img src={picture} alt="avatar" width="28" height="28" />
          ) : (
            <div className="img-layout" />
          )}
        </div>
        <span className="sidebar-name">{name}</span>
      </div>
      <button
        onClick={() => setSideBAr(false)}
        type="button"
        className="btn-close shadow-none"
        aria-label="Close"
      />
    </div>
    <div className="sidebar-wrapper d-flex flex-column">
      {userAdmin && (
        <>
          <Link
            to="/my-team"
            onClick={() => setSideBAr(false)}
          >
            <p>My team</p>
          </Link>
          <Link
            to="/settings"
            onClick={() => setSideBAr(false)}
          >
            <p>Settings</p>
          </Link>
        </>
      )}
      <a
        href="https://docs.absolutelabs.io/"
        target="_blank"
        rel="noreferrer"
        onClick={() => setSideBAr(false)}
      >
        <p>Documentation</p>
      </a>
      <Link
        to="/"
        onClick={(e) => {
          e.preventDefault();
          // window.intercomSettings.email = null;
          // window.intercomSettings.name = null;
          logout({ returnTo: window.location.origin });
        }}
      >
        <p>Log out</p>
      </Link>
    </div>
  </div>
);

export default Sidebar;
