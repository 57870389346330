import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { localeStorageCache } from '../../utils/localeStorage';

const BASE_API = 'api/profiles';

export const profilesApi = createApi({
  reducerPath: 'profilesAPI',
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers) => {
      const authKey = localeStorageCache.getAuth0Key();
      const authData = localeStorageCache.get(authKey);

      if (authData && authData.body) {
        const { id_token: idToken } = authData.body;
        if (idToken) {
          headers.set('Authorization', `Bearer ${idToken}`);
        }
      }
      return headers;
    },
    baseUrl: import.meta.env.VITE_APP_SERVER_DOMAIN,
  }),
  tagTypes: ['profiles'],
  endpoints: (build) => ({
    getWalletData: build.query({
      query: (arg) => ({
        url: `${BASE_API}/${arg}`,
        method: 'GET',
      }),
    }),
    getWalletInterests: build.query({
      query: (address) => ({
        url: `${BASE_API}/interest/${address}`,
        method: 'GET',
      }),
    }),
    getWalletNotableInvestments: build.query({
      query: (address) => ({
        url: `${BASE_API}/notable-investments/${address}&limit=6`,
        method: 'GET',
      }),
    }),
    getWalletInvestorPortfolioData: build.query({
      query: (arg) => ({
        url: `${BASE_API}/investor-portfolio/${arg.address}&order=${arg.order}${arg.limit
          ? `&limit=${arg.limit}` : ''}${arg.asset_type
          ? `&asset_type=${arg.asset_type}` : ''}${arg.page
          ? `&page=${arg.page}` : ''}${arg.orderBy
          ? `&order_by=${arg.orderBy}` : ''}`,
      }),
    }),
    getWalletNftData: build.query({
      query: (arg) => ({
        url: `${BASE_API}/nfts/?address=${arg.address}&chain=${arg.chain}${arg.disableTotal
          ? `&disable_total=${arg.disableTotal}` : ''}${arg.format
          ? `&format=${arg.format}` : ''}${arg.limit
          ? `&limit=${arg.limit}` : ''}${arg.mediaItems
          ? `&media_items=${arg.mediaItems}` : ''}${arg.cursor
          ? `&cursor=${arg.cursor}` : ''}${arg.normalizeMetadata
          ? `&normalizeMetadata=${arg.normalizeMetadata}` : ''}${arg.tokenAddresses
          ? `&token_addresses=${arg.tokenAddresses}` : ''}`,
        method: 'GET',
      }),
    }),
    getWalletExchangeData: build.query({
      query: (arg) => ({
        url: `${BASE_API}/exchange/${arg.address}&order=${arg.order}${arg.limit
          ? `&limit=${arg.limit}` : ''}${arg.page
          ? `&page=${arg.page}` : ''}${arg.orderBy
          ? `&order_by=${arg.orderBy}` : ''}`,
      }),
    }),
    getWalletCustomData: build.query({
      query: (arg) => ({
        url: `${BASE_API}/team-dataset/${arg}`,
        method: 'GET',
      }),
      providesTags: () => ['profiles'],
    }),
    postWalletCustomData: build.mutation({
      query: ({ address, body }) => ({
        url: `${BASE_API}/team-dataset/${address}`,
        method: 'POST',
        body,
      }),
    }),
    searchWallets: build.query({
      query: (query) => ({
        url: `${BASE_API}/search/?query=${query}`,
        method: 'GET',
      }),
      providesTags: () => ['profiles'],
    }),
    getWalletSegments: build.query({
      query: (address) => ({
        url: `${BASE_API}/target-audience/${address}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 10,
      providesTags: () => ['profiles'],
    }),
    getWalletDappUsageData: build.query({
      query: (arg) => ({
        url: `${BASE_API}/dapp-data/${arg.address}&limit=${arg.limit}${arg.offset
          ? `&offset=${arg.offset}` : ''}${arg.order
          ? `&order=${arg.order}` : ''}${arg.orderBy
          ? `&order_by=${arg.orderBy}` : ''}`,
        method: 'GET',
      }),
    }),
    walletIsSanctioned: build.query({
      query: (address) => ({
        url: `${BASE_API}/sanctions/${address}`,
        method: 'GET',
      }),
    }),
    getWalletCustomLists: build.query({
      query: (address) => ({
        url: `${BASE_API}/custom-list/${address}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 10,
      providesTags: () => ['profiles'],
    }),
    getWalletALIDLookup: build.query({
      query: (arg) => ({
        url: `${BASE_API}/lookup/?address=${arg.address}${arg.alid ? `&alid=${arg.alid}` : ''}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 180,
    }),
  }),
});
