import React, { useEffect, useState } from 'react';
import SearchIcon from '../../../assets/icons/search.svg';
import DeleteImg from '../../../assets/images/delete.png';
import styles from './searchBar.module.scss';

const SearchBar = ({ value, handleSearch, placeholder }) => {
  const [showPlaceholder, setShowPlaceholder] = useState(true);

  useEffect(() => {
    if (!value) {
      setShowPlaceholder(true);
    }
  }, [value]);

  return (
    <div className={`rounded border ${styles.wrapper}`}>
      <SearchIcon height={18} width={18} />

      <input
        type="text"
        className={`${styles.input} rounded border-0`}
        placeholder={showPlaceholder ? placeholder : ''}
        aria-label="Search"
        aria-describedby="search-addon"
        value={value}
        onChange={(e) => handleSearch(e.target.value)}
        onClick={() => setShowPlaceholder(false)}
        onBlur={() => {
          if (value === '') {
            setShowPlaceholder(true);
          }
        }}
      />
      {value && (
        <img
          role="presentation"
          src={DeleteImg}
          alt="search"
          height="20"
          width="20"
          className="cursor-pointer"
          onClick={() => {
            handleSearch('');
          }}
        />
      )}
    </div>
  );
};

export default SearchBar;
