import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styles from './AirdropToWallet.module.scss';
import CloseModalButton from '../../modals/components/CloseModalButton';
import InfoSmall from '../../../../assets/icons/info_small.svg';
import Close from '../../../../assets/icons/close.svg';
import Tooltip from '../../Tooltip';
import { showErrorMessage } from '../../../base/Notifications';
import { contractsApi } from '../../../../api/contracts';
import Success from '../../../../assets/icons/botton_check.svg';

const isValidAddress = (address) => /^(0x)[0-9A-Fa-f]{40}$/.test(address);

export const AirdropToWalletsModal = ({ onCancel, id }) => {
  const [addresses, setAddresses] = useState([]);
  const [success, setSuccess] = useState(false);
  const [etherURL, setEtherURL] = useState('https://etherscan.io/');
  const { network } = useParams();
  const [sendAirdrop, resultAirdrop] = contractsApi.useSendAirdropMutation();
  const isSending = resultAirdrop.isLoading;

  const addAddress = (address) => {
    if (address && isValidAddress(address) && !addresses.includes(address) && addresses.length < 100) {
      setAddresses((prev) => [...prev, address]);
    }
  };

  const addAddresses = (newAddresses) => {
    const validNewAddresses = newAddresses.filter(isValidAddress);
    const uniqueNewAddresses = [...new Set(validNewAddresses)].filter((newAddress) => !addresses.includes(newAddress));

    // Calculate how many more addresses can be added to avoid exceeding 100.
    const availableSpace = 100 - addresses.length;
    const addressesToAdd = uniqueNewAddresses.slice(0, availableSpace);

    setAddresses((prev) => [...new Set([...prev, ...addressesToAdd])]);
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const pasteContent = event.clipboardData.getData('text').replace(/['"]+/g, '');
    const addressesToBeAdded = pasteContent.split(/[\s,]+/)
      .filter(isValidAddress);
    addAddresses(addressesToBeAdded);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      addAddress(event.target.value.trim());
      event.target.value = '';
    }
  };

  const removeAddress = (index) => {
    setAddresses(addresses.filter((_, i) => i !== index));
  };

  const handleSendNow = async () => {
    if (addresses.length > 0) {
      const dataToUpload = { addresses, id, mint_amount: 1 };
      await sendAirdrop(dataToUpload);
    } else {
      showErrorMessage('Please provide at least one address');
    }
  };

  useEffect(
    () => {
      if (resultAirdrop.isSuccess) {
        const etherURLDict = {
          ethereum: 'https://etherscan.io/tx/',
          'eth-sepolia': 'https://sepolia.etherscan.io/tx/',
          polygon: 'https://polygonscan.com/tx/',
          amoy: 'https://amoy.polygonscan.com/tx/',
          base: 'https://mainnet.base.org/tx/',
          'base-sepolia': 'https://sepolia.basescan.org/tx/',
          linea: 'https://lineascan.build/tx/',
          'linea-sepolia': 'https://sepolia.lineascan.build/tx/',
          chiliz: 'https://chiliscan.com/tx/',
          'chiliz-spicy': 'https://testnet.chiliscan.com/tx/',
        };
        if (resultAirdrop?.data?.transaction_hash && resultAirdrop.data.status === 'Success') {
          const url = etherURLDict[network] + resultAirdrop.data.transaction_hash;
          setEtherURL(url);
        }
        setSuccess(true);
      } else if (resultAirdrop.isError) {
        showErrorMessage('Failed to send airdrop');
        onCancel();
      }
    },
    [resultAirdrop],
  );

  return (
    <div
      className={`modal modal-dialog-centered ${styles.modal_wrapper}`}
      id="confirmModal"
      tabIndex="-1"
      aria-labelledby="confirmModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-md modal-phone w-100 position-relative">
        <div className={`${styles.body} modal-content position-relative border-0`}>
          <CloseModalButton onCancel={onCancel} />
          {!success ? (
            <>
              <div>
                <div className={`${styles.title} d-flex justify-content-start`}>
                  Distribute through Airdrop
                </div>
              </div>
              <div className={`${styles.description} mb-3 d-flex justify-content-between`}>
                <div>
                  <span className="mb-3">
                    Provide up to 100 addresses to send this NFT to.
                  </span>
                  <InfoSmall data-for="AirdropToWalletsInfo" data-tip />
                </div>
                <span>
                  {addresses?.length > 0 ? `${addresses.length} Address${addresses.length > 1 ? 'es' : ''}` : ''}
                </span>
              </div>
              <Tooltip
                id="AirdropToWalletsInfo"
                info="To airdrop this NFT to more addresses or to a saved Audience, use the Flow feature"
              />
              <div>
                <div className={styles.addressesInput}>
                  <div className={styles.addressesList}>
                    {addresses.map((address, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <div key={index} className={`${styles.addressTag} d-flex`}>
                        {address}
                        <button
                          type="button"
                          className={styles.removeButton}
                          onClick={() => removeAddress(index)}
                        >
                          <Close />
                        </button>
                      </div>
                    ))}
                  </div>
                  <input
                    type="text"
                    onKeyDown={handleKeyDown}
                    onPaste={handlePaste}
                    placeholder="Type or paste address and press Enter"
                    className={styles.addressInputField}
                  />
                </div>
              </div>
              <div className={`${styles.footer} d-flex justify-content-end w-100`}>
                <button type="button" className="regular-button w-100" onClick={handleSendNow} disabled={isSending}>
                  {isSending ? 'Sending...' : 'Send Now'}
                </button>
              </div>
            </>

          ) : (
            <div>
              <Success className={`${styles.SuccessIcon} d-flex justify-content-between`} />
              <div className={`${styles.SuccessTitle} d-flex justify-content-center mb-3 mt-3`}>
                Transaction Posted!
              </div>
              <div className={`${styles.SuccessDescription} mb-3 d-flex justify-content-center`}>
                <div>
                  <span className="mb-3">
                    Your airdrop transaction was posted to the blockchain.
                    <br />
                    You can check its status on the
                    {' '}
                    <a
                      href={etherURL}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      block explorer.
                    </a>
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
