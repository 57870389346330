import { createSlice } from '@reduxjs/toolkit';
import {
  convertCEXValues,
  convertCustomDataValues,
  convertNftCoinsSearchToNewStructure,
  convertTxCountValues,
  convertCustomDataCreationDateValues,
  convertInvestmentsValue,
  convertCustomDataIsSet,
  convertCustomDataString,
  convertCustomDataTimestampValues,
  convertCustomDataDate,
  convertDappValues,
  convertWebActivityValues,
} from '../../../utils/segments';

const arrayValueTypes = [
  'search-cex',
  'search',
  'search-list',
  'search-segment',
  'search-coin',
  'search-nft',
  'search-dapp',
];

const mulltipleSelectFilters = [
  'owns-nft',
  'owns-token',
  'has-ever-owned-nfts-of-collection',
  'has-ever-owned-token',
  'value-in-dollars-of-nfts',
  'value-in-dollars-of-token',
  'number-of-nfts-in-collection',
  'number-of-nfts-minted-in-collection',
  'wallet_type',
  'country',
  'is-interested',
  'is-interested-at-least',
  'is-interested-at-most',
  'does-not-own-token-anymore',
  'does-not-own-nft-anymore',
];

const initialState = {
  segmentId: null,
  userSegment: { name: '', segment: { categories: [], team_dataset: null } },
  name: '',
  userConditions: [],
  offset: 0,
  limit: 25,
  editMode: true,
  request: false,
  createdSegmentId: null,
  createdCustomListId: null,
  baseStructure: null,
};

export const segmentSlice = createSlice({
  name: 'segment',
  initialState,
  reducers: {
    setFormIsReady: (state, action) => {
      state.formIsReady = action.payload;
    },
    setSegmentName: (state, action) => {
      state.name = action.payload;
    },
    setCreatedSegment: (state, action) => {
      const {
        name, segment, id, isInRunning,
      } = action.payload;
      state.userSegment = {
        name, segment, isInRunning,
      };
      state.name = name;
      state.segmentId = id;
    },
    setUserConditions: (state, action) => {
      state.userConditions = action.payload;
    },
    mapCreatedSegmentToConditions: (state, action) => {
      const { segment, data } = action.payload;
      const result = [];
      segment.categories.forEach((category) => {
        category.filters.forEach((filter) => {
          const ownCondition = ['owns-token', 'owns-nft', 'does-not-own-token-anymore', 'does-not-own-nft-anymore'];
          if (ownCondition.includes(filter.name)) {
            const preResult = convertInvestmentsValue(filter, category);
            if (mulltipleSelectFilters.includes(filter.name)) {
              result.push(convertNftCoinsSearchToNewStructure(preResult, preResult.category));
              return;
            }

            result.push(preResult);
            return;
          }

          if (data && (category.name === 'Custom data' || category.name === 'Profile attributes')) {
            if (filter.options.some((option) => ['is-set', "isn't-set"].includes(option.value[0].value))) {
              result.push(convertCustomDataIsSet(filter, category, data));
              return;
            }

            if (filter.type === 'string') {
              result.push(convertCustomDataString(filter, category, data));
              return;
            }

            if (filter.type === 'date') {
              result.push(convertCustomDataDate(filter, category, data));
              return;
            }
          }

          if (filter.type === 'timestamp') {
            result.push(convertCustomDataTimestampValues(filter, category, data));
            return;
          }

          if (filter.name === 'creation_date') {
            result.push(convertCustomDataCreationDateValues(filter, category));
            return;
          }

          if (filter.name === 'wallet_type' || filter.name === 'country') {
            result.push(convertCustomDataValues(filter, category));
            return;
          }

          if (filter.name === 'Uses CEX') {
            result.push(convertCEXValues(filter, category, data));
            return;
          }
          if (filter.name === 'Uses Dapp') {
            result.push(convertDappValues(filter, category, data));
            return;
          }

          if (filter.name === 'transaction-count') {
            result.push(convertTxCountValues(filter, category));
            return;
          }

          if (category.name === 'Web activity') {
            result.push(convertWebActivityValues(filter, category.name));
            return;
          }

          result.push({ category: category.name, ...filter });
        });
      });
      state.baseStructure = result;
      state.userConditions = result;
    },
    // create an object at the initial stage and push it into an array
    createCondition: (state, action) => {
      state.userConditions.push(action.payload);
    },
    // add fields such as filterName and options to the existing object
    addFilterNameAndOptionsToCondition: (state, action) => {
      const {
        id, name, options, label, type,
      } = action.payload;

      const conditionsToSet = [];
      state.userConditions.forEach((condition) => {
        if (condition.id === id) {
          const newCond = {
            ...condition,
            name,
            options,
            label,
            type,
          };
          conditionsToSet.push(newCond);
        } else {
          conditionsToSet.push(condition);
        }
      });

      state.userConditions = conditionsToSet;
    },
    // We are looking for the desired option in the desired condition and add value
    addValueToOptions: (state, action) => {
      const { options } = state.userConditions.filter(
        (condition) => condition.id === action.payload.id,
      )[0];
      const selectedOption = options.filter(
        (option) => option.name === action.payload.name,
      )[0];
      const selectedOptionIndex = options.indexOf(selectedOption);
      selectedOption.value = (arrayValueTypes.includes(action.payload.type) || Array.isArray(action.payload.value))
        ? action.payload.value : [{ value: action.payload.value }];
      const newOptionsToPush = [...options];
      newOptionsToPush[selectedOptionIndex] = selectedOption;
      const conditionsToSet = [];
      state.userConditions.forEach((condition) => {
        if (condition.id === action.payload.id) {
          const newCond = {
            ...condition,
            options: newOptionsToPush,
          };
          if (newCond.name === 'Uses Dapp' && action.payload.type === 'select' && !Number(action.payload.value)
              && action.payload.name === 'select-option') {
            newCond.options.splice(6, 1, {
              type: 'input',
              name: 'dapp-amount',
              label: 'Amount',
              value: null,
            });
          }
          conditionsToSet.push(newCond);
        } else {
          conditionsToSet.push(condition);
        }
      });
      state.userConditions = conditionsToSet;
    },
    // same for tx Count
    addTxCountValueToOption: (state, action) => {
      const { options } = state.userConditions.filter(
        (condition) => condition.id === action.payload.id,
      )[0];
      const selectedOption = options[0].options.filter(
        (option) => option.name === action.payload.name,
      )[0];
      const selectedOptionIndex = options[0].options.indexOf(selectedOption);
      selectedOption.value = [{ value: action.payload.value }];
      const newOptionsToPush = [...options];
      newOptionsToPush[0].options[selectedOptionIndex] = selectedOption;
      const conditionsToSet = [];
      state.userConditions.forEach((condition) => {
        if (condition.id === action.payload.id) {
          const newCond = {
            ...condition,
            options: newOptionsToPush,
          };
          conditionsToSet.push(newCond);
        } else {
          conditionsToSet.push(condition);
        }
      });
      state.userConditions = conditionsToSet;
    },
    // The same, the current for tx Count, where there is an additional nesting
    addTxCountValueToOption2: (state, action) => {
      const { options } = state.userConditions.filter(
        (condition) => condition.id === action.payload.id,
      )[0];
      let selectedOption;
      let otherOption;
      let selectedOptionIndex;
      let otherOptionIndex;
      let newOptionsToPush = [];
      if (options[0].options[1].name === 'is-than') {
        const data = [
          {
            name: 'period',
            options: [
              { label: 'Last 24 hours', value: 'last-24-hours' },
              { label: 'Last 7 days', value: 'last-7-days' },
              { label: 'Last 30 days', value: 'last-30-days' },
            ],
            type: 'select',
          },
          {
            label: 'Since',
            name: 'since',
            type: 'date-picker',
          },
        ];
        otherOption = data.filter((option) => option.name !== action.payload.name)[0];
        selectedOption = data.filter((option) => option.name === action.payload.name)[0];
        selectedOptionIndex = data.indexOf(selectedOption);
        otherOptionIndex = data.indexOf(otherOption);
        const opt = [];
        options[0].options.forEach((option, index) => {
          if (index === 1) {
            opt.push({
              name: 'date',
              type: 'select',
              options: [
                {
                  name: 'period',
                  options: [
                    { label: 'Last 24 hours', value: 'last-24-hours' },
                    { label: 'Last 7 days', value: 'last-7-days' },
                    { label: 'Last 30 days', value: 'last-30-days' },
                  ],
                  type: 'select',
                },
                {
                  label: 'Since',
                  name: 'since',
                  type: 'date-picker',
                },
              ],
            });
            opt.push(option);
            return;
          }
          opt.push(option);
        });
        newOptionsToPush = [{
          ...options,
          options: opt,
        }];
      } else {
        const opts = [
          {
            name: 'period',
            options: [
              { label: 'Last 24 hours', value: 'last-24-hours' },
              { label: 'Last 7 days', value: 'last-7-days' },
              { label: 'Last 30 days', value: 'last-30-days' },
            ],
            type: 'select',
          },
          {
            label: 'Since',
            name: 'since',
            type: 'date-picker',
          },
        ];
        selectedOption = opts.filter(
          (option) => option.name === action.payload.name,
        )[0];
        otherOption = opts.filter(
          (option) => option.name !== action.payload.name,
        )[0];
        selectedOptionIndex = opts.indexOf(selectedOption);
        otherOptionIndex = opts.indexOf(otherOption);
        newOptionsToPush = [...options];
      }
      selectedOption.value = action.payload.value;
      otherOption.value = null;
      newOptionsToPush[0].options[1].options[selectedOptionIndex] = selectedOption;
      newOptionsToPush[0].options[1].options[otherOptionIndex] = otherOption;
      const conditionsToSet = [];
      state.userConditions.forEach((condition) => {
        if (condition.id === action.payload.id) {
          const newCond = {
            ...condition,
            options: newOptionsToPush,
          };
          conditionsToSet.push(newCond);
        } else {
          conditionsToSet.push(condition);
        }
      });
      state.userConditions = conditionsToSet;
    },
    deleteConditionById: (state, action) => {
      state.userConditions = state.userConditions.filter(
        (condition) => condition.id !== action.payload,
      );
    },

    pushConditionIntoTree: (state, action) => {
      const {
        category,
        name,
        options,
        id,
        label,
        type,
      } = action.payload;
      const segmentCategories = state.userSegment.segment.categories;
      const isSegmentAlreadyHasCategory = segmentCategories.some(
        (segmentCategory) => segmentCategory?.name === category,
      );

      if (isSegmentAlreadyHasCategory) {
        const categoryToInsert = segmentCategories.filter(
          (segmentCategory) => segmentCategory.name === category,
        )[0];
        const isAlreadyHasThisFilter = categoryToInsert.filters.some(
          (filter) => filter.name === name,
        );

        if (isAlreadyHasThisFilter) {
          const filterToInsertInto = categoryToInsert.filters.filter(
            (filter) => filter.id === id,
          )[0];

          if (filterToInsertInto?.id === id) {
            filterToInsertInto.options = options;
          } else {
            const dataToPush = {
              name,
              id,
              label,
              options,
              type,
            };
            categoryToInsert.filters.push(dataToPush);
          }
        } else {
          const dataToPush = {
            name,
            id,
            label,
            options,
            type,
          };
          categoryToInsert.filters.push(dataToPush);
        }
      } else {
        const dataToPush = {
          name: category,
          filters: [
            {
              name,
              id,
              label,
              options,
              type,
            },
          ],
        };
        segmentCategories.push(dataToPush);
      }
    },
    // remove condition from json tree
    deleteConditionFromTree: (state, action) => {
      const { id, name } = action.payload;
      const categoryWhereToRemove = state.userSegment.segment.categories.filter(
        (category) => category.name === name,
      )[0];
      if (categoryWhereToRemove) {
        const otherCategories = state.userSegment.segment.categories.filter(
          (category) => category.name !== name,
        );
        const filtersWithoutOneToRemove = categoryWhereToRemove.filters.filter(
          (filter) => filter.id !== id,
        );
        categoryWhereToRemove.filters = filtersWithoutOneToRemove;
        if (
          Object.keys(otherCategories).length === 0
          && Object.keys(filtersWithoutOneToRemove).length === 0
        ) {
          state.userSegment.segment.categories = [];
        } else if (
          Object.keys(otherCategories).length === 0
          && Object.keys(filtersWithoutOneToRemove).length !== 0
        ) {
          state.userSegment.segment.categories = [categoryWhereToRemove];
        } else if (
          Object.keys(otherCategories).length !== 0
          && Object.keys(filtersWithoutOneToRemove).length === 0
        ) {
          state.userSegment.segment.categories = [...otherCategories];
        } else {
          state.userSegment.segment.categories = [
            ...otherCategories,
            categoryWhereToRemove,
          ];
        }
      }
    },
    changeOffset: (state, action) => {
      state.offset = action.payload;
    },
    changeLimit: (state, action) => {
      state.limit = action.payload;
    },
    changeEditMode: (state, action) => {
      state.editMode = action.payload;
    },
    createdSegment: (state, action) => {
      state.createdSegmentId = action.payload;
    },
    createdCustomList: (state, action) => {
      state.createdCustomListId = action.payload;
    },
    setTeamDataset: (state, action) => {
      state.userSegment.segment.team_dataset = action.payload;
    },
    clearState: (state) => {
      state.userSegment = { name: '', segment: { categories: [], team_dataset: null } };
      state.name = '';
      state.userConditions = [];
      state.offset = 0;
      state.limit = 25;
      state.editMode = true;
      state.segmentId = null;
      state.baseStructure = null;
    },
    onRequest: (state, action) => {
      state.request = action.payload;
    },
    setTabPages: (state, action) => {
      state.tabPages = action.payload;
    },
  },
});

export const {
  createCondition,
  addFilterNameAndOptionsToCondition,
  addValueToCondition,
  deleteConditionById,
  addValueToOptions,
  addTxCountValueToOption,
  changeEditMode,
  setSegmentName,
  pushConditionIntoTree,
  deleteConditionFromTree,
  clearState,
  changeOffset,
  mapCreatedSegmentToConditions,
  setCreatedSegment,
  addTxCountValueToOption2,
  onRequest,
  setUserConditions,
  createdSegment,
  createdCustomList,
  setFormIsReady,
  setTeamDataset,
  setTabPages,
} = segmentSlice.actions;

export const getCreatedCustomList = (state) => state.segmentReducer.createdCustomListId;
export const getCreatedSegmentId = (state) => state.segmentReducer.createdSegmentId;
export const getUserSegmentName = (state) => state.segmentReducer.name;
export const getEditMode = (state) => state.segmentReducer.editMode;
export const getUserSegment = (state) => state.segmentReducer.userSegment;
export const getSegmentId = (state) => state.segmentReducer.segmentId;
export const getOffset = (state) => state.segmentReducer.offset;
export const getLimit = (state) => state.segmentReducer.limit;
export const getCreatedConditions = (state) => state.segmentReducer.userConditions;
export const getBaseStructure = (state) => state.segmentReducer.baseStructure;
export const selectSegmentRequestStatus = (state) => state.segmentReducer.request;
export const getFormIsReady = (state) => state.segmentReducer.formIsReady;
export const getSelectedOptions = (id) => (state) => {
  const { segmentReducer } = state;
  if (segmentReducer.userConditions[id]) {
    return segmentReducer.userConditions[id]?.options;
  }
  return null;
};
export const getFilterByNameAndId = (name, id) => (state) => {
  if (!name || id === undefined) {
    return undefined;
  }
  const categoryToReturn = state.segmentReducer.userSegment?.segment?.categories?.filter(
    (category) => category.name === name,
  )[0];

  const filterToReturn = categoryToReturn?.filters?.filter(
    (filter) => filter.id === id,
  );

  return {
    name: '',
    segment: {
      categories: [{ ...categoryToReturn, filters: filterToReturn }],
      team_dataset: state.segmentReducer.userSegment.segment?.team_dataset,
    },
  };
};

export const selectTabPages = (state) => state.segmentReducer.tabPages;

export default segmentSlice.reducer;
