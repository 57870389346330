import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  clearActiveSettings,
  onSettingsChange,
  removeErrors,
  selectConfigsList,
  selectFlowStatus,
} from '../../../../../store/reducers/flows';
import styles from './WaitForNodeSettings.module.scss';
import EditableTitle from '../../../../../components/base/EditableTitle';

const WaitForNodeSettings = ({ activeSettings }) => {
  const [titleValue, setTitleValue] = useState('');

  const dispatch = useDispatch();

  const {
    register, handleSubmit, setValue, watch, reset,
  } = useForm();

  const configList = useSelector(selectConfigsList);
  const status = useSelector(selectFlowStatus);

  const disableChangeStatus = useMemo(() => status === 'running'
    || status === 'scheduled' || status === 'stopped', [status]);

  const onSubmit = (submitData) => {
    const waitInfo = { ...submitData };
    if (titleValue) {
      waitInfo.name = titleValue;
    } else {
      waitInfo.name = 'Wait for';
    }
    dispatch(removeErrors(activeSettings.node_id));
    dispatch(onSettingsChange({ ...waitInfo, node_id: activeSettings.node_id }));
    dispatch(clearActiveSettings());
  };

  const onPaste = (e) => {
    const paste = e.clipboardData.getData('text/plain');
    if (paste.match(/[,.eE+-]/)) {
      e.preventDefault();
    }
  };

  const preventInvalidSymbols = (e) => {
    const arr = watch('value') ? ['e', 'E', '+', '-', '.', ','] : ['e', 'E', '+', '-', '.', ',', '0'];
    if (arr.includes(e.key)) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (configList.length) {
      const index = configList.map((object) => object.node_id).indexOf(activeSettings.node_id);
      if (index >= 0) {
        setTitleValue(configList[index].name);
        setValue('time', configList[index].time);
        setValue('value', configList[index].value);
      } else {
        reset({
          value: '',
          time: '',
        });
        setTitleValue('');
      }
    }
  }, [activeSettings.node_id, configList, reset, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="d-flex flex-column justify-content-between h-100">
      <div>
        <div className={`${styles.title} d-flex align-items-center gap-2`}>
          <EditableTitle
            defaultTitle="Wait for"
            titleValue={titleValue}
            setTitleValue={setTitleValue}
            maxLength={25}
            size={16}
            edit={!disableChangeStatus}
          />
        </div>
        <div className="d-flex flex-column w-100 gap-1">
          <div className={styles.title_input}>
            Enter Time
          </div>
          <div className="d-flex gap-3">
            <div className={`${styles.number_area} d-flex gap-4 w-25`}>
              <input
                {...register('value')}
                onChange={(e) => setValue('value', Number(e.target.value))}
                type="number"
                maxLength={3}
                className="p-2 w-100"
                placeholder="00"
                onKeyPress={preventInvalidSymbols}
                onPaste={onPaste}
                min={1}
                max="999"
                disabled={disableChangeStatus}
              />
            </div>
            <div className={`${styles.select} w-25`}>
              <select
                {...register('time')}
                disabled={disableChangeStatus}
              >
                <option hidden value="">Select</option>
                <option value="Minutes">Minutes</option>
                <option value="Hours">Hours</option>
                <option value="Days">Days</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex gap-4 mt-4">
        <button
          type="button"
          className={`${styles.btn_cancel}`}
          onClick={() => dispatch(clearActiveSettings())}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="regular-button w-100"
          disabled={!watch('value') || !watch('time') || disableChangeStatus}
        >
          Save
        </button>
      </div>
    </form>
  );
};

export default WaitForNodeSettings;
