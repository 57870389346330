import { useEffect } from 'react';
import { foldersApi } from '../api/folders';

const useAddToFolder = ({ onSuccess, onError }) => {
  const [addItemsToFolders, addItemsToFoldersResult] = foldersApi.useAddItemsToFoldersMutation();

  const addItemToFolder = async ({
    itemId, itemType, folderId, folderType,
  }) => {
    await addItemsToFolders(
      {
        items: [{ object_id: itemId, object_type: itemType }],
        folders: [folderId],
        type: folderType,
      },
    );
  };

  useEffect(() => {
    if (addItemsToFoldersResult.isSuccess) {
      if (onSuccess) {
        onSuccess();
      }
    } else if (addItemsToFoldersResult.isError) {
      if (onError) {
        onError();
      }
    }
  }, [addItemsToFoldersResult]);

  return addItemToFolder;
};

export default useAddToFolder;
