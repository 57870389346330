import React from 'react';

import styles from './Input.module.scss';

const Input = ({
  label, validation, register, name, error, ...props
}) => (
  <div className="form-floating mb-3 ms-2 p-1">
    <input
      className={`form-control shadow-none ${styles.input} ${
        error ? styles.error : ''
      }`}
      id="floatingInput"
      {...register(name, validation)}
      {...props}
    />
    <label htmlFor="floatingInput" className={styles.label}>
      {label}
    </label>
  </div>
);

export default Input;
