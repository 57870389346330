import React from 'react';
import styles from './CircleChart.module.scss';
import GrowthIndicator from '../GrowthIndicator';

const Highlight = ({ text, value, growth }) => {
  if (!text || !value) {
    return null;
  }

  return (
    <div className={styles.highlight_wrapper}>
      <span className={styles.text}>{text}</span>
      <div className={styles.value_wrapper}>
        <span className={styles.value}>{value}</span>
        {growth && (!growth.hideZero || growth?.percent !== '0')
          ? <GrowthIndicator percent={growth.percent} oldValue={growth.previousValue} />
          : null}
      </div>
    </div>
  );
};

export default Highlight;
