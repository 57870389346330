import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import Loader from '../../../../components/base/Loader';
import NoData from '../../../../components/base/NoData';
import { ethFormat } from '../../../../utils/singleAssetPage/parseData';
import CircleChart from '../../../../components/ui/CircleChart';
import LogoDisplay from '../../../../components/ui/modals/SearchModal/LogoDisplay';
import HoriontalPercentBarChart from '../../../../components/ui/HorizontalBarChart/PercentageBased';
import ChainLogo from '../../../../components/base/ChainLogo';
import { convertNumber } from '../../../../tools/NumberConverterTool';
import WalletIcon from '../../../../components/base/WalletIcon';
import { isWalletSupported } from '../../../../components/base/WalletIcon/wallets'

const UserTabContainer = ({
  title, isLoading, data, total, info, walletFilterOption = 'any_wallet_status', activeMultipleEcosystems
}) => {
  const languageNamesInEnglish = new Intl.DisplayNames(['en'], { type: 'language' });
  const regionNamesInEnglish = new Intl.DisplayNames(['en'], { type: 'region' });
  const landscape = title === 'Languages' || title === 'New Visitors';

  const validRegion = (value) => {
    try {
      return regionNamesInEnglish.of(value) !== undefined;
    } catch (e) {
      return false;
    }
  };

  const validLanguage = (value) => {
    try {
      return languageNamesInEnglish.of(value) !== undefined;
    } catch (e) {
      return false;
    }
  };

  const handleContent = (text) => {
    switch (text) {
      case 'Languages':
        return 'Most Frequent Language';
      case 'Wallet Types':
        return 'Most Frequent Wallet';
      case 'EVM Chains':
        return 'Most Frequent Blockchain';
      case 'New Visitors':
        return 'Most Frequent Visitors';
      case 'Web3 Adoption':
        return 'Visitors with wallets';
      default:
        return 'Most Frequent';
    }
  };

  const seriesDataCount = useCallback(() => {
    if (data && data.length && total) {
      let result = [];
      
      if (title === 'Web3 Adoption') {
        const filteredData = walletFilterOption === 'no_wallet_detected'
          ? data.filter((category) => category.value === 'no_wallet_detected')
          : data.filter((category) => category.value !== 'no_wallet_detected');
        const sumOfFilteredData = Object.values(filteredData).reduce((acc, value) => acc + value.visitor_count, 0);
        result = filteredData.map((elem) => ({
          label: elem.value === 'wallets_connected' ? 'Connected' : 'Not Connected',
          percent: sumOfFilteredData !== 0 ? ethFormat((elem.visitor_count / sumOfFilteredData) * 100) : 0,
          count: elem.visitor_count,
        }));
      } else if (title === 'Languages') {
        result = data.map((elem) => {
          if (!elem.value || !validLanguage(elem.value)) {
            return {
              value: 'Unknown',
              label: 'Unknown',
              percent: total !== 0 ? ethFormat((elem.visitor_count / total) * 100) : 0,
              count: elem.visitor_count,
            };
          }
          return {
            value: elem.value,
            label: languageNamesInEnglish.of(elem.value),
            percent: total !== 0 ? ethFormat((elem.visitor_count / total) * 100) : 0,
            count: elem.visitor_count,
          };
        });
  
      } else if (title === 'Countries') {
        result = data.map((elem) => {
          if (!elem.value || !validRegion(elem.value)) {
            return {
              value: 'Unknown',
              label: 'Unknown',
              percent: total !== 0 ? ethFormat((elem.visitor_count / total) * 100) : 0,
              count: elem.visitor_count,
            };
          }
          return {
            value: elem.value,
            label: (
              <span className="d-flex align-items-center gap-2 w-50">
                <LogoDisplay
                  logo={`/trackedWebsite/countryFlags/${elem.value.toLowerCase()}.svg`}
                  size="15px"
                  asset="address"
                  position="static"
                />
                <span className="text-capitalize">
                  {regionNamesInEnglish.of(elem.value)}
                </span>
              </span>
            ),
            percent: total !== 0 ? ethFormat((elem.visitor_count / total) * 100) : 0,
            count: elem.visitor_count,
          }
        })
      } else if (title === 'New Visitors') {
        result = data.filter((elem) => elem.value).map((elem) => ({
          value: elem.value === 'new_users' ? 'New visitors' : 'Returning visitors',
          label: elem.value === 'new_users' ? 'New visitors' : 'Returning visitors',
          percent: total !== 0 ? ethFormat((elem.visitor_count / total) * 100) : 0,
          count: elem.visitor_count,
          color: elem.value === 'new_users' ? 'yellow' : 'blue',
        }));
      } else {
        result = data.filter((elem) => elem.value).map((elem) => ({
          value: elem.value,
          label: (title === 'Wallet Types'
            ? (
              <span className="d-flex align-items-center gap-2 w-50">
                <LogoDisplay
                  logo={`/trackedWebsite/walletIcons/wallet_${elem.value.toLowerCase()}.png`}
                  size="15px"
                  asset="address"
                  position="static"
                />
                <span className="text-capitalize">
                  {elem.value}
                </span>
              </span>
            ) : elem.value),
          percent: total !== 0 ? ethFormat((elem.visitor_count / total) * 100) : 0,
          count: elem.visitor_count,
        }));
      }

      if (title !== 'Web3 Adoption' && title !== 'New Visitors') {
        const sumValues = data.filter((elem) => elem.value).reduce((acc, elem) => acc + elem.visitor_count, 0);
        const safeCount = total - sumValues >= 0 ? total - sumValues : 0;
        result.push({
          label: 'Other',
          fullName: 'Other',
          percent: total !== 0 ? ethFormat(((safeCount) / total) * 100) : 0,
          count: safeCount,
        });
      }
      return result;
    }
    return [];
  }, [data, title, total, walletFilterOption]);
  

  const highlightValue = useCallback(() => {
    if (data && data.length) {
      let result;
      if (title === 'Web3 Adoption') {
        const walletDetected = data.find((category) => category.value === 'wallets_connected').visitor_count
            + data.find((category) => category.value === 'wallets_not_connected').visitor_count;
        result = `${walletDetected.toLocaleString()} (${ethFormat(total !== 0 ? (walletDetected / total) * 100 : 0)}%)`;
      } else if (title === 'Languages') {
        const elem = data[0];
        result = `${validLanguage(elem.value) ? languageNamesInEnglish.of(elem.value) : 'Unknown'} (${ethFormat(total !== 0
          ? (elem.visitor_count / total) * 100 : 0)}%)`;
      } else if (title === 'New Visitors') {
        const elem = data[0];
        result = `${elem.value === 'new_users'
          ? 'New visitors' : 'Returning visitors'} (${ethFormat(total !== 0 ? (elem.visitor_count / total) * 100 : 0)}%)`;
      } else {
        const elem = data[0];
        result = `${elem.value} (${ethFormat(total !== 0 ? (elem.visitor_count / total) * 100 : 0)}%)`;
      }
      return result;
    }
    return null;
  }, [data, title, total, languageNamesInEnglish]);

  const getChainName = (chain) => {
    switch (chain) {
      case 'EVM':
        return 'Ethereum'
      case 'Solana':
        return 'Solana'
      case 'Bitcoin':
        return 'Ordinals'
      case 'Near':
        return 'Near'
      case 'Cosmos':
        return 'Cosmos'
      case 'Tezos':
        return 'Tezos'
      default:
        return null;
    }
  }

  const getWalletHighlight = () => {
    if (!data || !total || total === 0) return '';

    const wallet = data.reduce((max, obj) => {    
      return obj.visitor_count > max.visitor_count ? obj : max;
    }, data[0]);

    const percent = ethFormat((wallet.visitor_count / total) * 100)
    return `${wallet.value} (${percent}%)`
  } 

  const containerContent = useMemo(() => {
    if (isLoading) {
      return (
        <div className="p-5">
          <Loader />
        </div>
      );
    }
    if (title === 'Chain Environments' || title === 'Wallet Types') {
      let parsedData;
      if (title === 'Chain Environments') {
        parsedData = data.filter((chain) => chain.visitor_count > 0).map((chain) => ({
          name: chain.value,
          value: chain.visitor_count,
          logo: <ChainLogo chain={getChainName(chain.value)} square large />,
          message: `${convertNumber(chain.visitor_count.toFixed(0))}
            wallet${chain.visitor_count > 1 ? 's' : ''} of this audience have had activity on ${chain.value}.`,
        }))
      } else {
        parsedData = data.filter((wallet) => wallet.visitor_count > 0).map((wallet) => ({
          name: wallet.value,
          value: wallet.visitor_count,
          logo: isWalletSupported(wallet.value) ? <WalletIcon wallet={wallet.value} large rounded_s /> : null,
          message: `${convertNumber(wallet.visitor_count.toFixed(0))}
            wallet${wallet.visitor_count > 1 ? 's' : ''} of this audience are ${wallet.value} wallet${wallet.visitor_count > 1 ? 's' : ''}.`,
        }))
      }

      return data && data.length && total > 0
        ? (
          <HoriontalPercentBarChart
            title={title}
            highlightText={title === 'Chain Environments' ? "Active in Multiple Ecosystems" : "Most Frequent Wallet"}
            highlightValue={title === 'Chain Environments' 
              ? `${activeMultipleEcosystems.toLocaleString()} (${ethFormat(total !== 0 ? (activeMultipleEcosystems / total) * 100 : 0)}%)` 
              : getWalletHighlight()
            }
            data={parsedData}
            total={total}
            info={info}
          />
        )
        : (
          <div className="d-flex justify-content-center align-items-center">
            <NoData />
          </div>
        )
    }

    return (
      data && data.length && total
        ? (
          <CircleChart
            title={title}
            highlightText={handleContent(title)}
            highlightValue={highlightValue()}
            data={seriesDataCount().map((elem) => elem)}
            isLoading={isLoading}
            className="h-100"
            landscape={landscape}
            info={info}
          />
        )
        : (
          <div className="d-flex justify-content-center align-items-center">
            <NoData />
          </div>
        )
    );
  }, [data, isLoading, seriesDataCount, title, total]);

  return (
    <div className={landscape ? 'col-6' : 'col-sm-12 col-md-6 col-lg-3'}>
      {containerContent}
    </div>

  );
};

export default UserTabContainer;
