import { useSearchParams } from 'react-router-dom';

const useSetSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setParams = (params) => {
    const updatedParams = Object.entries(params).reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, { ...Object.fromEntries([...searchParams]) });

    setSearchParams(updatedParams, { replace: true });
  };

  return setParams;
};

export default useSetSearchParams;
