import React from 'react';
import styles from './ConfirmModal.module.scss';
import RoundSpinner from '../../../base/RoundSpinner';
import useKeydown from '../../../hooks/app/useKeydown';
import CloseModalButton from '../components/CloseModalButton';

const ConfirmModal = ({
  title = 'Confirm ?',
  description = '',
  buttonName = 'Confirm',
  onSubmit,
  onCancel,
  loading,
  link,
  deleteMode = false,
}) => {
  useKeydown('Escape', onCancel);
  return (
    <div
      className={`modal modal-dialog-centered ${styles.modal_wrapper}`}
      id="confirmModal"
      tabIndex="-1"
      aria-labelledby="confirmModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-md modal-phone w-100 position-relative">
        <div className={`${styles.body} modal-content position-relative border-0`}>
          {loading
            ? <RoundSpinner />
            : null}
          <CloseModalButton onCancel={onCancel || onSubmit} />
          <div>
            <div className={`${styles.title} d-flex justify-content-start`}>
              {title}
            </div>
          </div>
          <div className={`${styles.description}`}>
            <span>
              {description}
            </span>
            {link
              ? (
                <div>
                  {link}
                </div>
              )
              : null}
          </div>
          <div className={`${styles.footer} d-flex justify-content-end`}>
            {onCancel && (
              <button
                type="button"
                className="outline-button border border-1"
                data-bs-dismiss="modal"
                onClick={() => onCancel()}
              >
                Cancel
              </button>
            )}
            <button
              type="button"
              className={`regular-button ${deleteMode ? 'delete_button' : ''}`}
              data-bs-dismiss="modal"
              onClick={() => onSubmit()}
            >
              {buttonName}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
