import React from 'react';
import styles from '../inputs/Input.module.scss';
import ChainList from '../../../../components/base/ChainLogo/ChainList';
import { supportedBlockchains } from '../../../../utils/supportedBlockchains';

const categoryColorMap = {
  DeFi: styles.blue,
  Marketplace: styles.yellow,
  Games: styles.green,
  Gambling: styles.red,
  Misc: styles.defaultColor,
  Social: styles.purple,
};

const DappLabel = ({ val }) => (
  <div className="d-flex justify-content-between align-items-center">
    <span className={`${styles.label} me-2`}>{val?.label}</span>
    <div className="d-flex align-items-center gap-1">
      <div
        className={`${styles.pills} ${
          categoryColorMap[val?.category] || styles.defaultColor
        } px-3 d-flex justify-content-center text-truncate align-items-center`}
      >
        {val?.category}
      </div>
      {val?.blockchains?.length > 0 && (
        <div>
          <div className="d-flex gap-1 align-items-center">
            <div className={`${styles.separator_icon} mx-1`} />
            <div className={`${styles.svg} d-flex align-items-center`}>
              <ChainList chains={supportedBlockchains(val.blockchains)} square />
            </div>
          </div>
        </div>
      )}
    </div>
  </div>
);

export default DappLabel;
