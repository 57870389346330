import React, { useEffect, useRef } from 'react';
import Searching from '../../assets/icons/search.svg?url';
import { preventPaste, preventSearch } from '../../utils/search/preventSearch';
import './Search.scss';

const Search = ({ handleChange, searchParams }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <div className="search-wrapper d-flex flex-column justify-content-around align-items-center">
      <h3>
        {searchParams.get('from') === 'dashboard'
          ? 'To add assets to the dashboard, use search to find them:'
          : 'To get started, let’s look up your assets'}
      </h3>
      <div className="search-wrapper-button d-flex justify-content-around align-items-center">
        <img src={Searching} alt="search" height="16" width="16" />
        <input
          onPaste={(e) => { preventPaste(e); }}
          onKeyPress={(e) => { preventSearch(e); }}
          placeholder="Search by NFT collections, Tokens..."
          className="search-input"
          onChange={(event) => {
            handleChange(event);
          }}
          ref={inputRef}
        />
      </div>
    </div>
  );
};
export default Search;
