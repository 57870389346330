import React, { useEffect, useRef } from 'react';
import styles from './slider.module.scss';

const SliderPercentage = ({ value, setValue, setChange }) => {
  const sliderRef = useRef();

  useEffect(() => {
    const newBG = `linear-gradient(90deg, #109cf1 ${value}%, #D7EDFF ${value}%)`;
    if (sliderRef.current) {
      sliderRef.current.style.background = newBG;
    }
  }, [value]);

  return (
    <div className={styles.wrapper}>
      <input
        ref={sliderRef}
        type="range"
        min="1"
        max="100"
        value={value}
        onChange={(e) => {
          setChange(true);
          setValue(Number(e.target.value));
        }}
        className={styles.slider}
      />
      <span className={styles.text}>
        {value}
        %
      </span>
    </div>
  );
};
export default SliderPercentage;
