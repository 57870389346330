import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../../../../components/ui/modals/ConfirmModal/ConfirmModal.module.scss';
import CloseModalButton from '../../../../components/ui/modals/components/CloseModalButton';
import useKeydown from '../../../../components/hooks/app/useKeydown';
import { showErrorMessage } from '../../../../components/base/Notifications';
import { setFormName, setFormUrl, selectFormUrl } from '../../../../store/reducers/forms';

export const SaveFormNameModals = ({
  onCancel, onSubmit, formName, status,
}) => {
  const dispatch = useDispatch();
  const formurl = useSelector(selectFormUrl);

  useKeydown('Escape', onCancel);

  const generateRandomString = () => {
    const chars = 'abcdefghjkmnpqrstuvwxyz23456789';
    let resulting = '';
    for (let i = 0; i < 5; i += 1) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      resulting += chars[randomIndex];
    }
    return resulting;
  };

  useEffect(() => {
    if (!formurl || formurl === '') {
      const gen = generateRandomString();
      dispatch(setFormUrl(gen));
    }
  }, []);

  const handleSave = () => {
    if (!formName) {
      showErrorMessage('Form Name cannot be empty');
      return;
    }
    onSubmit(status);
    onCancel();
  };

  return (
    <div
      className={`modal modal-dialog-centered ${styles.modal_wrapper}`}
      id="confirmModal"
      tabIndex="-1"
      aria-labelledby="confirmModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-md modal-phone w-100 position-relative">
        <div className={`${styles.body} modal-content position-relative border-0`}>
          <CloseModalButton onCancel={onCancel} />
          <div>
            <div className={`${styles.title} d-flex justify-content-start`}>
              Name your form
            </div>
          </div>
          <div className={`${styles.description} mb-3`}>
            <span className={`${styles.description} mb-3`}>
              To save a form you will need to give it a name.
            </span>
          </div>
          <div className="mb-3">
            <input
              type="text"
              className="form-control mb-3"
              id="formName"
              placeholder="Form Name"
              value={formName}
              onChange={(e) => dispatch(setFormName(e.target.value))}
            />
          </div>
          <div className={`${styles.footer} d-flex justify-content-end`}>
            <button
              type="button"
              className="outline-button border border-1"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="button"
              className="regular-button"
              onClick={handleSave}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>

  );
};
