import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './NodeBody.module.scss';
import { audiencesApi } from '../../../../../api/audiences';
import { uuidv4 } from '../../../../../utils/uuidv4';
import {
  createCondition,
  getCreatedConditions,
  mapCreatedSegmentToConditions, setTeamDataset,
} from '../../../../../store/reducers/segment';
import Pill from './components/Pill';
import Condition from '../../../../AddSegment/Condition';
import { selectFlowStatus } from '../../../../../store/reducers/flows';
import Loader from '../../../../../components/base/Loader';
import uniqueId from '../../../../../utils/uniqueId';
import { userApi } from '../../../../../api/user';

const NodeBody = ({ segment }) => {
  const dispatch = useDispatch();

  const status = useSelector(selectFlowStatus);
  const createdConditions = useSelector(getCreatedConditions);

  const [conditionNames, setConditionNames] = useState([]);
  const [formattedTeamData, setFormattedTeamData] = useState();

  const {
    data: segmentData,
    isLoading: isSegmentDataLoading,
    isFetching: isSegmentDataFetching,
  } = audiencesApi.useGetConditionalFlowDataQuery();

  useEffect(() => {
    dispatch(setTeamDataset(segmentData?.segment?.team_dataset || null));
  }, [segmentData, dispatch]);

  const disableChangeStatus = useMemo(() => status === 'running'
    || status === 'scheduled' || status === 'stopped', [status]);

  const {
    data: teamData,
    isLoading: teamDataIsLoading,
    isFetching: teamDataIsFetching,
  } = userApi.useGetAssetsSearchQuery(
    undefined,
  );

  // get and convert team data
  const convertTeamData = useCallback((data) => {
    const coins = data.filter((elem) => elem.asset_type === 'coin');
    const nfts = data.filter((elem) => elem.asset_type === 'nft');
    const formattedCoins = [];
    coins.forEach((coin) => {
      if (coin.platforms) {
        if (coin.platforms.ethereum && coin.platforms.polygon) {
          formattedCoins.push({ ...coin, platform: coin.platforms.ethereum, network: 'ethereum' });
          formattedCoins.push({ ...coin, platform: coin.platforms.polygon, network: 'polygon' });
        } else if (coin.platforms.ethereum) {
          formattedCoins.push({ ...coin, platform: coin.platforms.ethereum, network: 'ethereum' });
        } else if (coin.platforms.polygon) {
          formattedCoins.push({ ...coin, platform: coin.platforms.polygon, network: 'polygon' });
        }
      }
    });
    setFormattedTeamData({ ...data, tokens: formattedCoins, nfts });
  }, []);

  useEffect(() => {
    if (teamData) {
      convertTeamData(teamData);
    }
  }, [teamData, convertTeamData]);

  useEffect(() => {
    if (segment && segmentData) {
      dispatch(mapCreatedSegmentToConditions({ segment: segment.segment, data: segmentData }));
    }
  }, [dispatch, segment, segmentData]);

  useEffect(() => {
    if (segmentData) {
      setConditionNames(segmentData.segment.categories.map((category) => category.name));
    }
  }, [segmentData]);

  const getFilters = useCallback((categoryName) => {
    if (segmentData) {
      const { filters } = segmentData.segment.categories.filter((category) => category.name === categoryName)[0];
      return filters;
    }
    return null;
  }, [segmentData]);

  const menuData = useMemo(() => conditionNames && conditionNames.map((conditionName) => ({
    id: conditionName,
    name: conditionName,
    action: (condName) => {
      const idToSet = uuidv4();
      const category = segmentData?.segment.categories.filter((cond) => cond.name === condName)[0];
      if (category.team_dataset) {
        dispatch(createCondition({
          id: idToSet,
          category: condName,
          team_dataset: category.team_dataset,
          filters: category.filters,
        }));
      } else {
        dispatch(createCondition({
          id: idToSet,
          category: condName,
          filters: category.filters,
        }));
      }
    },
  })), [conditionNames, dispatch, segmentData]);

  const contentToReturn = useMemo(
    () => {
      if (isSegmentDataLoading || isSegmentDataFetching || !segmentData) {
        return (
          <div className="mt-3">
            <Loader />
          </div>
        );
      }

      if (createdConditions.length !== 0) {
        return createdConditions.map((condition) => (
          <div key={uniqueId('condition')}>
            <Condition
              id={condition.id}
              conditionName={condition.category}
              edit={!disableChangeStatus}
              filters={getFilters(condition.category)}
              data={condition}
              page="flows"
              teamData={formattedTeamData}
              teamDataIsLoading={teamDataIsLoading}
              teamDataIsFetching={teamDataIsFetching}
            />
          </div>
        ));
      }
      return (
        <div className={styles.grid}>
          {menuData.map((data) => <Pill pillData={data} key={uniqueId('conditionPill')} />)}
        </div>
      );
    },
    [
      isSegmentDataLoading,
      isSegmentDataFetching,
      getFilters,
      createdConditions,
      menuData,
      disableChangeStatus,
      segmentData,
    ],
  );

  return (
    <div>
      {createdConditions.length === 0 && <div className={styles.title}>Condition</div>}
      {contentToReturn}
    </div>
  );
};

export default NodeBody;
