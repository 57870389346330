import React, { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { format } from 'date-fns';
import CloseModalButton from '../../../components/ui/modals/components/CloseModalButton';
import useKeydown from '../../../components/hooks/app/useKeydown';
import PickDateInput from './PickDateInput';
import styles from './StartStopDateFlowModal.module.scss';
import { handleTimeZone } from '../../../utils/timeout';

const StartStopDateFlowModal = ({ onCancel, onSubmit }) => {
  const [pickDate, setPickDate] = useState(false);

  const {
    register, setValue, watch, handleSubmit,
  } = useForm();

  const onSubmitDates = useCallback((submitData) => {
    const objDates = { ...submitData };
    Object.keys(submitData)
      .forEach((key) => {
        if (objDates[key]) {
          objDates[key] = handleTimeZone(objDates[key]);
        } else {
          delete objDates[key];
        }
      });
    setPickDate(false);
    onSubmit('scheduled', objDates);
  }, [onSubmit]);

  useKeydown('Escape', onCancel);

  const modalContent = useMemo(() => {
    if (pickDate) {
      return (
        <form onSubmit={handleSubmit(onSubmitDates)}>
          <div>
            <div className={`${styles.title} d-flex justify-content-start`}>
              Set start and end dates
            </div>
          </div>
          <PickDateInput text="start" register={register} setValue={setValue} watch={watch} />
          <PickDateInput text="end" register={register} setValue={setValue} watch={watch} />
          <div className={`${styles.footer} d-flex justify-content-end`}>
            <button
              type="button"
              className="outline-button border border-1"
              onClick={() => setPickDate(false)}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="regular-button"
              disabled={!watch('start_at') && !watch('end_at')}
            >
              Apply dates
            </button>
          </div>
        </form>
      );
    }
    return (
      <>
        <div>
          <div className={`${styles.title} d-flex justify-content-start`}>
            Set up your Flow flight dates
          </div>
        </div>
        <div className={`${styles.description}`}>
          <span>
            Set start and end dates for your flow
          </span>
        </div>
        <div className={`${styles.footer} d-flex justify-content-end`}>
          <button
            type="button"
            className="outline-button border border-1"
            onClick={() => onSubmit('running', {
              start_at: handleTimeZone(format(new Date(), 'yyyy-MM-dd,HH:mm').replace(',', 'T')),
            })}
          >
            Launch Flow now
          </button>
          <button
            type="button"
            className="regular-button"
            onClick={() => setPickDate(true)}
          >
            Schedule Flow
          </button>
        </div>
      </>
    );
  }, [handleSubmit, onSubmit, onSubmitDates, pickDate, register, setValue, watch('start_at'), watch('end_at')]);

  return (
    <div
      className={`modal modal-dialog-centered ${styles.modal_wrapper}`}
    >
      <div className="modal-dialog modal-md modal-phone w-100 position-relative">
        <div className={`${styles.body} modal-content position-relative border-0`}>
          <CloseModalButton onCancel={onCancel} />
          {modalContent}
        </div>
      </div>
    </div>
  );
};

export default StartStopDateFlowModal;
