import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { useLocation } from 'react-router-dom';
import TitleSocials from './TitleSocials/TitleSocials';
import { useWindowSize } from '../../../hooks/app';
import ToDashboardButton from '../ToDashboardButton';
import { selectSelectedAsset } from '../../../../store/reducers/app';
import styles from '../TitleSection.module.scss';
import { LoadingAvatar, LoadingLines } from '../../modals/SearchModal/LoadingList';
import DefaultIcon from '../../DefaultIcon';
import { showSuccessMessage } from '../../../base/Notifications';
import { getIsSearchModalOpen, setContractDeployer } from '../../../../store/reducers/search';
import { coinApi } from '../../../../api/coin';
import { userApi } from '../../../../api/user';
import { shortenAddress } from '../../../../utils/shortenAddress';
import { supportedBlockchains } from '../../../../utils/supportedBlockchains';
import { supportedChains } from '../../../base/ChainLogo/chains';
import ChainList from '../../../base/ChainLogo/ChainList';

const SingleCoinTitle = ({ isAssetLoading, singleCoinData }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { width } = useWindowSize();
  const selectedAsset = useSelector(selectSelectedAsset);

  const [data, setData] = useState({});
  const [logoError, setLogoError] = useState(false);

  const {
    data: deployer,
  } = coinApi.useGetTokenDeployerQuery({
    tokenId: pathname.split('/')[2],
  });

  const {
    data: tokenInAsset,
    isLoading,
    isFetching,
  } = userApi.useGetCoinInAssetsQuery([{
    coin: pathname.split('/')[2],
  }]);

  useEffect(() => {
    if (Object.keys(selectedAsset).length && !singleCoinData) {
      setData({
        ...selectedAsset,
        blockchain:
          Object.fromEntries(Object.entries({ ...selectedAsset.network }).map(([key, value]) => [value, key])),
      });
    }
    if (singleCoinData) {
      setData(singleCoinData);
    }
  }, [selectedAsset, singleCoinData]);

  const deployerContent = useMemo(() => {
    if (deployer && !isAssetLoading) {
      return (
        <div className={`${styles.description} ${styles.description_token}
         ${Object.keys(data?.links || {}).length ? '' : 'mb-3'}`}
        >
          {deployer?.show_first ? 'First deployed by' : 'Deployed by'}
          {' '}
          <span
            role="presentation"
            onClick={() => {
              dispatch(getIsSearchModalOpen(true));
              dispatch(setContractDeployer(deployer?.deployer_address));
            }}
          >
            {shortenAddress(deployer.deployer_address)}
          </span>
          {' '}
          on
          {' '}
          {format(new Date(deployer?.deployed_at), 'MMM dd, yyyy')}
        </div>
      );
    }
    return null;
  }, [deployer, dispatch, isAssetLoading]);

  function handleCopyClick(network) {
    const textToCopy = data.blockchain[network];
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        showSuccessMessage(`${supportedChains[network].tooltip} address was copied to your clipboard`);
      });
  }

  return (
    <div className={styles.coinWrapper}>
      <div className={styles.imageContainer}>
        {!data.logo
          ? <LoadingAvatar size={75} />
          : data.logo && !logoError ? (
            <img
              src={data.logo}
              width="75px"
              height="75px"
              alt="logo"
              onError={() => setLogoError(true)}
            />
          ) : <DefaultIcon type="token" size="75px" />}
      </div>
      <div className="d-flex flex-column">
        <div className={styles.row}>
          <div className="d-flex flex-wrap gap-2">
            {isAssetLoading && !data.name
              ? (
                <div className={`${styles.loadingLinesWrapper} w-25`}>
                  <LoadingLines align="start" />
                </div>
              )
              : (
                <div className={`${styles.title} ${data.name?.length > 10 && styles.titleAdaptive}`}>
                  {data.name}
                </div>
              )}
            {data.blockchain
              ? (
                <div className="d-flex gap-2 align-items-center">
                  <div className={`${styles.token}`}>{data.symbol ? `$${data.symbol}` : ''}</div>
                  <div
                    className={`${styles.token_pill} px-3 d-flex align-items-center`}
                  >
                    Token
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <div className={styles.separator_icon} />
                    <ChainList
                      chains={supportedBlockchains(data.blockchain)}
                      tooltip="Click to copy address"
                      // eslint-disable-next-line react/jsx-no-bind
                      onClick={handleCopyClick}
                    />
                  </div>
                </div>
              )
              : null}
          </div>
          <div className={styles.flexAlignRight}>
            <ToDashboardButton
              type="coin"
              data={tokenInAsset}
              loading={isLoading || isFetching || isAssetLoading}
            />
          </div>
        </div>
        {deployerContent && (
          <div className={styles.row}>
            {deployerContent}
            {width > 640 && data.links && (
              <div className={`${styles.icons} ${styles.flexAlignRight}`}>
                <TitleSocials links={data.links} />
              </div>
            )}
          </div>
        )}
        <div className={styles.row}>
          {data.tags && data.tags.map((tag) => (
            <div className={styles.tagContainer} key={tag}>
              <span className={styles.tagText}>{tag}</span>
            </div>
          ))}
        </div>
        <div className="d-flex gap-2">
          <div className={styles.tagWrapper}>
            {width < 640 && !data.links && (
              <div className={`${styles.icons} ${styles.flexAlignRight}`}>
                <TitleSocials links={data.links} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleCoinTitle;
