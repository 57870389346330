import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { localeStorageCache } from '../../utils/localeStorage';

const BASE_API = 'api/users';

export const userApi = createApi({
  reducerPath: 'userAPI',
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers) => {
      const authKey = localeStorageCache.getAuth0Key();
      const authData = localeStorageCache.get(authKey);

      if (authData && authData.body) {
        const { id_token: idToken } = authData.body;
        if (idToken) {
          headers.set('Authorization', `Bearer ${idToken}`);
        }
      }
      return headers;
    },
    baseUrl: import.meta.env.VITE_APP_SERVER_DOMAIN,
  }),
  tagTypes: ['User'],
  endpoints: (build) => ({
    getUserInfo: build.query({
      query: () => ({
        url: `${BASE_API}/myself/`,
        method: 'GET',
      }),
      providesTags: () => ['User'],
    }),
    getUserTeam: build.query({
      query: () => ({
        url: `${BASE_API}/team-members/`,
        method: 'GET',
      }),
      providesTags: () => ['User'],
    }),
    getTeamInfo: build.query({
      query: () => ({
        url: `${BASE_API}/team-managed/`,
        method: 'GET',
      }),
      keepUnusedDataFor: 1,
    }),
    teamInfo: build.mutation({
      query: () => ({
        url: `${BASE_API}/team-managed/`,
        method: 'GET',
      }),
    }),
    inviteNewTeamMember: build.mutation({
      query: (payload) => ({
        url: `${BASE_API}/team-invite/`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['User'],
    }),
    patchTeamMember: build.mutation({
      query: (payload) => ({
        url: `${BASE_API}/team-members/${payload.id}/`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: ['User'],
    }),
    getAssets: build.query({
      query: () => ({
        url: `${BASE_API}/team-assets/`,
        method: 'GET',
      }),
      keepUnusedDataFor: 10,
      invalidatesTags: ['User'],
    }),
    getAssetsSearch: build.query({
      query: () => ({
        url: `${BASE_API}/team-assets/`,
        method: 'GET',
      }),
      keepUnusedDataFor: 300,
      invalidatesTags: ['User'],
    }),
    actionOnAsset: build.mutation({
      query: (payload) => ({
        url: `${BASE_API}/assets-watchlist-management/`,
        method: 'POST',
        body: payload,
      }),
    }),
    patchApiKey: build.mutation({
      query: () => ({
        url: `${BASE_API}/api-keys/`,
        method: 'PATCH',
      }),
      invalidatesTags: ['User'],
    }),
    getApiKey: build.query({
      query: () => ({
        url: `${BASE_API}/api-keys/`,
        method: 'GET',
      }),
      keepUnusedDataFor: 1,
      invalidatesTags: ['User'],
    }),
    getSendGridApiKey: build.query({
      query: () => ({
        url: `${BASE_API}/send-grid-api-key/`,
        method: 'GET',
      }),
      keepUnusedDataFor: 1,
      invalidatesTags: ['User'],
    }),
    actionsSendGridApiKey: build.mutation({
      query: (payload) => ({
        url: `${BASE_API}/send-grid-api-key/`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['User'],
    }),
    deleteSendGridApiKey: build.mutation({
      query: () => ({
        url: `${BASE_API}/send-grid-api-key/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['User'],
    }),
    getSenderEmail: build.query({
      query: () => ({
        url: `${BASE_API}/sender-email/`,
        method: 'GET',
      }),
      keepUnusedDataFor: 1,
      invalidatesTags: ['User'],
    }),
    actionsSenderEmail: build.mutation({
      query: (payload) => ({
        url: `${BASE_API}/sender-email/`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['User'],
    }),
    deleteSenderEmail: build.mutation({
      query: () => ({
        url: `${BASE_API}/sender-email/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['User'],
    }),
    getTeamKeys: build.mutation({
      query: () => ({
        url: `${BASE_API}/my-team/`,
        method: 'GET',
      }),
      keepUnusedDataFor: 1,
    }),
    getCoinInAssets: build.query({
      query: (payload) => ({
        url: `${BASE_API}/coins-in-assets/`,
        method: 'POST',
        body: payload,
      }),
      keepUnusedDataFor: 1,
    }),
    getNFTsInAssets: build.query({
      query: (payload) => ({
        url: `${BASE_API}/nfts-in-assets/`,
        method: 'POST',
        body: payload,
      }),
      keepUnusedDataFor: 1,
    }),
    getXMTPKey: build.mutation({
      query: () => ({
        url: `${BASE_API}/xmtp-key/`,
        method: 'GET',
      }),
      transformResponse: (response, meta) => meta?.response?.status === 200,
    }),
    postXMTPKey: build.mutation({
      query: (payload) => ({
        url: `${BASE_API}/xmtp-key/`,
        method: 'POST',
        body: payload,
      }),
    }),
    deleteXMTPKey: build.mutation({
      query: () => ({
        url: `${BASE_API}/xmtp-key/`,
        method: 'DELETE',
      }),
    }),
    getAccount: build.query({
      query: () => ({
        url: `${BASE_API}/profile/`,
        method: 'GET',
      }),
      keepUnusedDataFor: 1,
      invalidatesTags: ['User'],
    }),
    postAccount: build.mutation({
      query: (payload) => ({
        url: `${BASE_API}/profile/`,
        method: 'PUT',
        body: payload,
      }),
    }),
  }),
});
