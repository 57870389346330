import React from 'react';

import ChainLogo from '../ChainLogo';

import sharedStyles from './Shared.module.scss';

const IconNearby = ({ val }) => (
  <div className="d-flex cursor-pointer justify-content-between align-items-center">
    <div className="d-flex align-items-center flex-wrap">
      <div className={`${sharedStyles.indicatorWrapper} pe-1`}>
        <ChainLogo chain={val?.network} square />
      </div>
      <span className={`${sharedStyles.label} me-2`}>{val?.label}</span>
    </div>
  </div>
);

export default IconNearby;
