import React, { useEffect, useState } from 'react';
import styles from '../../../components/ui/modals/ConfirmModal/ConfirmModal.module.scss';
import CloseModalButton from '../../../components/ui/modals/components/CloseModalButton';
import useKeydown from '../../../components/hooks/app/useKeydown';
import OtherToggle from './OtherToggle';
import EmailToggle from './EmailToggle';

const UniqueModal = ({
  onCancel, onSubmit, uniqueFields, uniqueField, setUniqueField,
}) => {
  useKeydown('Escape', onCancel);
  const [state, setState] = useState(null);
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (uniqueFields) {
      const toExclude = ['wallet address', 'email'];
      const containsWallet = uniqueFields.includes('wallet address');
      const containsEmail = uniqueFields.includes('email');
      const containsOthers = uniqueFields.some((item) => !toExclude.includes(item));

      if (containsWallet && !containsEmail && containsOthers) {
        setState('walletOthers');
        setUniqueField('wallet address');
      } else if (!containsWallet && containsEmail && containsOthers) {
        setState('emailOthers');
        setUniqueField('email');
      } else if (containsWallet && containsEmail && !containsOthers) {
        setState('walletEmail');
        setUniqueField('wallet address');
      } else {
        setState('walletEmailOthers');
        setUniqueField('wallet address');
      }
    }
  }, [uniqueFields]);

  useEffect(() => {
    switch (state) {
      case 'walletOthers':
        setDescription(
          'By default, wallet address will be used as the key for the import (recommended). '
          + 'All other imported fields will update the profile(s) of the matching wallet address. '
          + 'If that wallet address is not in the platform today, a new profile will be created.',
        );
        break;
      case 'emailOthers':
        setDescription(
          'By default, email address will be used as the key for the import (recommended). '
          + 'All imported fields will update the existing profile(s) using the matching email address. '
          + 'If that email address is not in the platform today, a new profile will be created.',
        );
        break;
      case 'walletEmail':
      case 'walletEmailOthers':
        setDescription(
          'By default, wallet address will be used as the key for the import (recommended). '
          + 'All other imported fields will update the profile of the matching wallet address.',
        );
        break;
      default:
        setDescription('');
        break;
    }
  }, [state]);

  return (
    <div
      className={`modal modal-dialog-centered ${styles.modal_wrapper}`}
      id="confirmModal"
      tabIndex="-1"
      aria-labelledby="confirmModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-md modal-phone w-100 position-relative">
        <div className={`${styles.body} modal-content position-relative border-0`}>
          <CloseModalButton onCancel={onCancel} />
          <div>
            <div className={`${styles.title} d-flex justify-content-start`}>
              Import key field
            </div>
          </div>
          <div className={`${styles.description}`}>
            <span>
              { description }
            </span>
            {state === 'walletOthers' && (
              <OtherToggle
                fields={uniqueFields.filter((item) => item !== 'wallet address')}
                uniqueField={uniqueField}
                setUniqueField={setUniqueField}
                defaultValue="wallet address"
              />
            )}
            {state === 'emailOthers' && (
              <OtherToggle
                fields={uniqueFields.filter((item) => item !== 'email')}
                uniqueField={uniqueField}
                setUniqueField={setUniqueField}
                defaultValue="email"
              />
            )}
            {state === 'walletEmail' && (
              <EmailToggle
                uniqueField={uniqueField}
                setUniqueField={setUniqueField}
              />
            )}
            {state === 'walletEmailOthers' && (
              <>
                <EmailToggle
                  uniqueField={uniqueField}
                  setUniqueField={setUniqueField}
                />
                <OtherToggle
                  fields={uniqueFields.filter((item) => item !== 'wallet address' && item !== 'email')}
                  uniqueField={uniqueField}
                  setUniqueField={setUniqueField}
                  defaultValue="wallet address"
                />
              </>
            )}
          </div>
          <div className={`${styles.footer} d-flex justify-content-end`}>
            {onCancel && (
              <button
                type="button"
                className="outline-button border border-1"
                data-bs-dismiss="modal"
                onClick={() => onCancel()}
              >
                Cancel
              </button>
            )}
            <button
              type="button"
              className="regular-button"
              data-bs-dismiss="modal"
              onClick={() => onSubmit()}
              disabled={!uniqueField}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UniqueModal;
