import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import {
  clearActiveSettings,
  onSettingsChange,
  removeErrors,
  selectConfigsList, selectFlowStatus, selectSenderEmail,
} from '../../../../../store/reducers/flows';
import { flowsApi } from '../../../../../api/flows';
import Warning from '../../../../../assets/icons/flows/info.svg';
import { getUser } from '../../../../../store/reducers/user';
import Loader from '../../../../../components/base/Loader';
import uniqueId from '../../../../../utils/uniqueId';
import styles from './EmailNodeSettings.module.scss';
import { showErrorMessage } from '../../../../../components/base/Notifications';
import EditableTitle from '../../../../../components/base/EditableTitle';

const EmailNodeSettings = ({ activeSettings }) => {
  const dispatch = useDispatch();
  const [variablesData, setVariablesData] = useState();
  const [templateData, setTemplateData] = useState();
  const [isSameTemplate, setIsSameTemplate] = useState(false);
  const [titleValue, setTitleValue] = useState('');
  const [templateOptions, setTemplateOptions] = useState([]);

  const AccountInfo = useSelector(getUser);
  const configList = useSelector(selectConfigsList);
  const isSenderEmailSetUp = useSelector(selectSenderEmail);
  const status = useSelector(selectFlowStatus);

  const disableChangeStatus = useMemo(() => status === 'running'
    || status === 'scheduled' || status === 'stopped', [status]);

  const {
    register, handleSubmit, setValue, watch, reset, setFocus,
  } = useForm();

  const {
    data,
    isLoading,
    isFetching,
    isSuccess,
    error,
  } = flowsApi.useGetTemplatesQuery();

  const [templateVariables, result] = flowsApi.useGetSendgridTemplateMutation();

  const handleData = (object) => {
    const obj = { ...object };
    ['name', 'template', 'template_id', 'variables'].forEach((key) => {
      delete obj[key];
    });
    return obj;
  };

  const onSubmit = (submitData) => {
    const hookInfo = {
      template: submitData.template,
      template_id: submitData.template_id,
      variables: handleData(submitData),
    };
    if (titleValue) {
      hookInfo.name = titleValue;
    } else {
      hookInfo.name = 'Email';
    }
    dispatch(removeErrors(activeSettings.node_id));
    dispatch(onSettingsChange({ ...hookInfo, node_id: activeSettings.node_id }));
    dispatch(clearActiveSettings());
    setVariablesData(null);
  };

  const senderWarning = (
    <div className={`${styles.info} d-flex my-2 mt-3 py-3 px-2 gap-1`}>
      <div className={styles.warning}>
        <Warning />
      </div>
      {AccountInfo?.category === 'team_admin' ? (
        <div>
          You need to set a sender email address for Flows to send emails. Please go to
          {' '}
          <Link to="/settings">your team settings</Link>
          {' '}
          to do so.
        </div>
      ) : (
        <div>
          You need to set a sender email address for Flows to send emails.
          Please get in touch with one of your team admins
          or your Customer Success Manager (CSM) to configure a sender address.
        </div>
      )}
    </div>
  );

  useEffect(() => {
    if (result.isSuccess) {
      if (result?.data && Object.keys(result?.data?.variables).length) {
        setFocus(Object.keys(result?.data?.variables)[0]);
      }
    }
    if (result.isError) {
      setValue('template', '');
      showErrorMessage(result?.error?.data?.message || 'Something went wrong');
      result.reset();
    }
  }, [result, setFocus, setValue]);

  useEffect(() => {
    if (data?.templates?.length) {
      setTemplateOptions(data.templates.map((elem) => (
        {
          value: elem.id,
          label: elem.name,
        }
      )));
    }
  }, [data?.templates]);

  useEffect(() => {
    if (configList.length) {
      const index = configList.map((object) => object.node_id).indexOf(activeSettings.node_id);
      if (index >= 0) {
        reset({
          variables: null,
        });
        setTitleValue(configList[index].name);
        setValue('template', configList[index].template);
        setValue('template_id', configList[index].template_id);
        setTemplateData({
          id: configList[index].template_id,
          name: configList[index].template,
        });
        setVariablesData(configList[index].variables);
        setIsSameTemplate(true);
      } else {
        reset({
          template: '',
          variables: null,
        });
        setIsSameTemplate(false);
        setVariablesData(null);
        setTemplateData(null);
        setTitleValue('');
      }
    }
  }, [activeSettings.node_id, configList, reset, setValue]);

  const handleTemplate = useMemo(() => {
    if (result?.isLoading || result?.isFetching) {
      return (
        <div className="w-100 my-5 align-items-center">
          <Loader />
        </div>
      );
    }
    if (isLoading || isFetching) {
      return null;
    }
    if (error?.status === 400) {
      setValue('template', '');
      return (
        <div className={`${styles.info} d-flex my-2 mt-3 py-3 px-2 gap-1`}>
          <div className={styles.warning}>
            <Warning />
          </div>
          {AccountInfo?.category === 'team_admin' ? (
            <div>
              Invalid SendGrid API key. Please go to
              {' '}
              <Link to="/settings">your team settings</Link>
              {' '}
              to provide a valid key.
            </div>
          ) : (
            <div>
              Invalid SendGrid API key. Please get in touch with one of your team admins or your
              Customer Success Manager (CSM) to fix the connection.
            </div>
          )}
        </div>
      );
    }
    if (isSuccess && !data?.templates?.length) {
      setValue('template', '');
      return (
        <div className={`${styles.info} d-flex my-2 mt-3 px-2 pt-3 gap-1`}>
          <div className={styles.warning}>
            <Warning />
          </div>
          <div className="d-flex flex-column gap-3">
            <span>There are no email templates yet in your Sendgrid account.</span>
            <button
              className={`mb-3 ${styles.add_key}`}
              type="button"
              disabled={disableChangeStatus}
              onClick={() => window.open('https://mc.sendgrid.com/dynamic-templates', '_blank')}
            >
              Create one now
            </button>
          </div>
        </div>
      );
    }
    if (result?.data && Object.keys(result?.data?.variables).length && !isSameTemplate) {
      setValue('template', result.originalArgs.label);
      setValue('template_id', result.originalArgs.value);
      return (
        <div className={`${styles.wrapper_variables} d-flex flex-column w-100 mt-3`}>
          <div className={styles.header}>
            <span>Field name</span>
            <span>Value</span>
          </div>
          {Object.keys(result?.data?.variables)
            .map((elem) => (
              <div className={styles.content} key={uniqueId('variables')}>
                <span className="text-truncate w-50">{elem.replace('_', ' ')}</span>
                <input
                  {...register(elem)}
                  onChange={(e) => setValue(elem, e.target.value)}
                  disabled={disableChangeStatus}
                />
              </div>
            ))}
        </div>
      );
    }
    if (variablesData && isSameTemplate) {
      return (
        <div className={`${styles.wrapper_variables} d-flex flex-column w-100 mt-3`}>
          <div className={styles.header}>
            <span>Field name</span>
            <span>Value</span>
          </div>
          {Object.keys(variablesData).map((elem) => (
            <div className={styles.content} key={uniqueId('variables_config')}>
              <span className="text-truncate w-50">{elem.replace('_', ' ')}</span>
              <input
                {...register(elem)}
                onChange={(e) => setValue(elem, e.target.value)}
                defaultValue={variablesData[elem]}
                disabled={disableChangeStatus}
                autoFocus
              />
            </div>
          ))}
        </div>
      );
    }
    return null;
  }, [
    AccountInfo?.category,
    result?.isLoading,
    result?.isFetching,
    result?.data,
    result?.originalArgs?.label,
    result?.originalArgs?.value,
    isLoading,
    isFetching,
    error?.status,
    isSuccess,
    data?.templates?.length,
    variablesData,
    isSameTemplate,
    setValue,
    register,
    disableChangeStatus,
  ]);

  return (
    <div className={`${styles.wrapper} h-100`}>
      <form onSubmit={handleSubmit(onSubmit)} className="d-flex flex-column justify-content-between h-100">
        <div>
          <div className={`${styles.title} d-flex align-items-center gap-2`}>
            <EditableTitle
              defaultTitle="Email"
              titleValue={titleValue}
              setTitleValue={setTitleValue}
              maxLength={25}
              size={16}
              edit={!disableChangeStatus}
            />
          </div>
          <div className="d-flex flex-column w-100 gap-1">
            <div className={styles.title_select}>
              Template
            </div>
            <div className={`${styles.select_area} w-100 mt-1`}>
              <Select
                {...register('template')}
                className="w-100"
                placeholder="Select"
                cacheOptions={false}
                maxMenuHeight={194}
                onChange={(option) => {
                  result.reset();
                  reset({
                    variables: null,
                  });
                  setValue('template', option.label);
                  if (templateData?.id !== option.value) {
                    setIsSameTemplate(false);
                    templateVariables(option);
                  } else {
                    setIsSameTemplate(true);
                  }
                }}
                isLoading={isLoading || isFetching}
                value={templateOptions.filter((elem) => elem.label === watch('template'))}
                options={isLoading || isFetching ? [] : templateOptions}
                isDisabled={disableChangeStatus}
              />
            </div>
          </div>
          {handleTemplate}
          <div className="mt-2">
            {isSenderEmailSetUp || disableChangeStatus ? null : senderWarning}
          </div>
        </div>
        <div className="d-flex gap-4 mt-4">
          <button
            type="button"
            className={`${styles.btn_cancel}`}
            onClick={() => {
              dispatch(clearActiveSettings());
              setVariablesData(null);
              result.reset();
            }}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="regular-button w-100"
            disabled={!watch('template') || result?.isLoading || result?.isFetching || disableChangeStatus}
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default EmailNodeSettings;
