import React from 'react';

import {
  Activity,
  ProfileAttributes,
  Investments,
  Portfolio,
  Lists,
  Segments,
} from '../assets/Svgs';

export const getSegmentSvg = (name, hover = false) => {
  if (!name) return;
  let svgToReturn;
  switch (name) {
    case 'Activity':
      svgToReturn = <Activity hover={hover} />;
      break;
    case 'Profile attributes':
      svgToReturn = <ProfileAttributes hover={hover} />;
      break;
    case 'Investments':
      svgToReturn = <Investments hover={hover} />;
      break;
    case 'Portfolio values':
      svgToReturn = <Portfolio hover={hover} />;
      break;
    case 'Custom lists':
      svgToReturn = <Lists hover={hover} />;
      break;
    case 'Segments':
      svgToReturn = <Segments hover={hover} />;
      break;
  }
  return svgToReturn;
};
