import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCurrPage,
  selectFieldSettings,
  setFieldSettings,
} from '../../../../../../store/reducers/forms';
import ModalSettings from '../../../Components/ModalSettings';
import Input from '../../../Components/ModalSettings/Input';
import Switch from '../../../Components/ModalSettings/Switch';

const EmailSettings = ({ activeSettings }) => {
  const dispatch = useDispatch();
  const initialized = useRef(false);
  const currPage = useSelector(selectCurrPage);
  const fieldSettings = useSelector(selectFieldSettings);
  const [label, setLabel] = useState('Email');
  const [placeholder, setPlaceholder] = useState('Enter email here');
  const [subLabel, setSubLabel] = useState('');
  const [required, setRequired] = useState(true);
  const [change, setChange] = useState(false);
  const [errors, setErrors] = useState({});

  const handleChange = useCallback(() => {
    setChange(false);
    const obj = {
      name: 'email',
      label,
      placeholder,
      subLabel,
      required,
      type: 'email',
    };
    dispatch(setFieldSettings({ ...obj, field_id: activeSettings.field_id }));
  }, [
    label,
    placeholder,
    subLabel,
    required,
    dispatch,
    activeSettings,
  ]);

  const setOptions = (config) => {
    setLabel(config.label);
    setPlaceholder(config.placeholder);
    setSubLabel(config.subLabel);
    setRequired(config.required);
  };

  useEffect(() => {
    if (initialized.current) return;
    const currPageSettings = fieldSettings.find((o) => o.pageName === currPage);
    if (currPageSettings) {
      const config = currPageSettings.fields?.find((x) => x.field_id === activeSettings.field_id);
      if (config) {
        setOptions(config);
      } else {
        handleChange();
      }
    }
    initialized.current = true;
  }, [activeSettings, currPage, fieldSettings]);

  useEffect(() => {
    if (change) {
      handleChange();
    }
  }, [label, placeholder, subLabel, required]);

  useEffect(() => {
    const newErrors = { ...errors };
    if (placeholder.length === 60) {
      newErrors.placeholder = 'Character limit reached';
    } else {
      newErrors.placeholder = '';
    }
    if (label.length === 250) {
      newErrors.label = 'Character limit reached';
    } else {
      newErrors.label = '';
    }
    if (subLabel.length === 250) {
      newErrors.subLabel = 'Character limit reached';
    } else {
      newErrors.subLabel = '';
    }
    setErrors(newErrors);
  }, [placeholder, subLabel, label]);

  return (
    <ModalSettings title="Email Properties">
      <Switch
        text="Required Field"
        checked={required}
        onChange={() => {
          setChange(true);
          setRequired(!required);
        }}
      />

      <Input
        title="Label"
        value={label}
        placeholder="Type here"
        onChange={(e) => {
          setChange(true);
          setLabel(e.currentTarget.value);
        }}
        maxLength={250}
        error={errors.label}
      />

      <Input
        title="Placeholder text"
        value={placeholder}
        placeholder="Type a value"
        onChange={(e) => {
          setChange(true);
          setPlaceholder(e.currentTarget.value);
        }}
        maxLength={60}
        error={errors.placeholder}
        optional
      />

      <Input
        title="Sublabel"
        value={subLabel}
        placeholder="Type here"
        onChange={(e) => {
          setChange(true);
          setSubLabel(e.currentTarget.value);
        }}
        maxLength={250}
        error={errors.subLabel}
        optional
      />

    </ModalSettings>
  );
};

export default EmailSettings;
