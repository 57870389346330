import React, { useMemo } from 'react';
import Chart from 'react-apexcharts';
import NoData from '../../../../../components/base/NoData';
import RoundSpinner from '../../../../../components/base/RoundSpinner';
import { defaultOptions } from '../../../../../components/ui/Market/Price';
import styles from './DailyChart.module.scss';
import { tickFormat } from 'd3-scale';

const DailyChart = ({
  graphData, isGraphDataLoading, isGraphDataFetching, isSuccess, isStaticForm
}) => {
  const noData = !graphData && isSuccess;
  const isLoading = (isGraphDataLoading || isGraphDataFetching) && !graphData;

  const getConversion = (dayData) => {
    if (dayData.visitors === 0) return 0;
    let conversionRate = 0;
    if (isStaticForm) {
      conversionRate = dayData.btn_clicks / dayData.visitors
    } else {
      conversionRate = dayData.responses / dayData.visitors
    }
    return (conversionRate * 100)
  }

  const handleData = (data) => {
    const reversedData = [...data].reverse();
    const conversions = reversedData.map((elem) => getConversion(elem))
    const visitorsMax = Math.max(...reversedData.map((elem) => elem.visitors));
    const actionMax = Math.max(...reversedData.map((elem) => isStaticForm ? elem.btn_clicks : elem.responses));
    const ticksMax = Math.max(visitorsMax, actionMax)

    return {
      series: [
        {
        name: 'Visitors',
        type: 'column',
        data: reversedData.map((elem) => elem.visitors),
        }, 
        isStaticForm 
          ?
            {
              name: 'Clicks',
              type: 'column',
              data: reversedData.map((elem) => elem.btn_clicks),
            }
          :
            {
              name: 'Responses',
              type: 'column',
              data: reversedData.map((elem) => elem.responses),
            },
        {
          name: 'Conversion Rate',
          type: 'area',
          data: conversions,
        }, 
      ],
      options: {
        chart: {
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
          type: 'arena',
          stacked: false,
        },
        stroke: {
          width: [1, 1, 1],
          curve: 'smooth',
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 1,
            columnWidth: '50%',
          },
        },
        grid: {
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        colors: ['#109CF1', '#ffb946', '#CED5DF'],
        fill: {
          opacity: [1, 1, 0.25],
        },
        markers: {
          size: 0,
        },
        xaxis: {
          type: 'datetime',
          categories: reversedData.map((elem) => elem.day),
          axisBorder: {
            show: true,
            color: '#6A707E',
          },
          axisTicks: {
            show: true,
            color: '#6A707E',
          },
          crosshairs: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: [
          {
            axisBorder: {
              show: true,
              color: '#6A707E',
            },
            axisTicks: {
              show: true,
              color: '#6A707E',
              width: 4,
            },
            min: 0,
            max: ticksMax,
            labels: {
              formatter: (value) => value.toFixed(0),
            },
          },
          {
            show: false,
            min: 0,
            max: ticksMax,
          },
          {
            seriesName: 'Conversion Rate',
            axisBorder: {
              show: true,
              color: '#6A707E',
            },
            axisTicks: {
              show: true,
              color: '#6A707E',
              width: 4,
            },
            min: 0,
            max: 100,
            title: {
              text: 'Conversion rate percentage',
              style: {
                color: '#6A707E',
              },
            },
            opposite: true,
            labels: {
              formatter: (value) => `${value.toFixed(0)}%`,
            },
          },
        ],
        tooltip: {
          y: {
            formatter: (y, { ...chartData }) => (chartData?.seriesIndex === 2 ? `${y.toFixed(2)}%` : y),
          },
        },
        legend: {
          show: false,
        },
      },
    };
  };

  const chartContent = useMemo(() => {
    if (isLoading) {
      return (
        <div className={`${styles.load_wrap}`}>
          <RoundSpinner position="position-absolute" theme="light" />
        </div>
      );
    } if (noData) {
      return (
        <div className={`${styles.no_data_wrap}`}>
          <NoData />
        </div>
      );
    } return (
      <Chart
        options={isGraphDataLoading || isGraphDataFetching
          ? defaultOptions.options
          : graphData && !isGraphDataLoading ? handleData(graphData).options : defaultOptions.options}
        series={(isGraphDataLoading || isGraphDataFetching)
          ? defaultOptions.series
          : graphData && !isGraphDataLoading ? handleData(graphData).series : defaultOptions.series}
        type="line"
        height={400}
      />
    );
  }, [graphData, isGraphDataFetching, isGraphDataLoading, isSuccess]);

  return useMemo(() => (
    <>
      <div id="chart" className="position-relative">
        {chartContent}
      </div>
      {!noData && !isLoading ? 
        <div className="d-flex gap-2">
          <div className="d-flex align-items-center gap-2 asset-wrap">
            <div className={`dot ${styles.blue}`} />
            <span>Visitors</span>
          </div>
          <div className="d-flex align-items-center gap-2 asset-wrap">
            <div className={`dot ${styles.yellow}`} />
            <span>{isStaticForm ? 'Clicks' : 'Responses'}</span>
          </div>
          <div className="d-flex align-items-center gap-2 asset-wrap">
            <div className={`dot ${styles.gray}`} />
            <span>Conversion Rate</span>
          </div>
        </div>
      : null}
    </>
  ), [chartContent, graphData, isGraphDataFetching, isGraphDataLoading]);
};

export default DailyChart;
