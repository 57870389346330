import React, { useEffect, useState } from 'react';
import useFolders from '../../hooks/useFolder';
import ManageFoldersModal from '../modals/Folders/ManageFoldersModal';
import FolderTag from './FolderTag';
import styles from './editableFoldersList.module.scss';
import EditButton from './EditButton';

const EditableFoldersList = ({ itemId, itemType }) => {
  const [showManageFoldersModal, setShowManageFoldersModal] = useState(false);
  const {
    folders,
    refetch,
  } = useFolders(itemId, itemType);

  useEffect(() => {
    refetch();
  }, [showManageFoldersModal]);

  return (
    <>
      {showManageFoldersModal && (
        <ManageFoldersModal
          closeModal={() => setShowManageFoldersModal(false)}
          itemId={itemId}
          itemType={itemType}
        />
      )}
      <div className={styles.folders_list_container}>
        {folders.map((folder) => <FolderTag name={folder.name} />)}
        <EditButton onClick={() => setShowManageFoldersModal(true)} />
      </div>
    </>
  );
};

export default EditableFoldersList;
