import React from 'react';
import styles from './ResponseDetailsModal.module.scss';
import CloseModalButton from '../../../../components/ui/modals/components/CloseModalButton';
import useKeydown from '../../../../components/hooks/app/useKeydown';

const ResponseDetailModal = ({
  onCancel, responseData,
}) => {
  useKeydown('Escape', onCancel);

  // const handleSendToFlow = () => {
  //   For now we will do nothing here
  // };

  // const handleSeeFullProfile = () => {
  //   // TODO: Create Endpoint that finds the ALID of the user
  //   if (responseData.wallet_address) {
  //     navigate(`/profile/${responseData.wallet_address}`);
  //   }
  // };
  return (
    <div
      className={`modal modal-dialog-centered ${styles.modal_wrapper}`}
      id="confirmModal"
      tabIndex="-1"
      aria-labelledby="confirmModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-md modal-phone w-100 position-relative">
        <div className={`${styles.body} modal-content position-relative border-0`}>
          <CloseModalButton onCancel={onCancel} />
          <div className={`${styles.body_internal} position-relative`}>
            <div className={`${styles.description} mb-3`}>
              <div className={`${styles.title} d-flex justify-content-start`}>
                {responseData.Email?.trim()
                || (Reflect.has(responseData, 'Wallet Address') && responseData['Wallet Address']?.trim()
                  ? `${responseData['Wallet Address'].substring(0, 29)}...`
                  : false)
                || 'User Response Details'}
              </div>
              <div className={`${styles.response} mb-3`}>
                Posted on
                {' '}
                {responseData.inserted_at}
              </div>
              {Object.entries(responseData)
                .filter(([key]) => (key !== 'inserted_at' && key !== 'uuid'))
                .map(([key, value]) => (
                  <div key={key} className={`${styles.fieldResponseContainer}`}>
                    <div className={`${styles.field}`}>
                      {key.split('&^%')[0]}
                    </div>
                    {typeof value === 'string' && value.includes('First Name:') ? (
                      <div className={`${styles.response} mb-3`} dangerouslySetInnerHTML={{ __html: value }} />
                    ) : (
                      <div className={`${styles.response} mb-3`}>
                        {value?.length > 0 ? value : <div className={styles.noResponse}>Not Provided</div>}
                      </div>
                    )}

                  </div>
                ))}
            </div>
            <div className={`${styles.footer} d-flex justify-content-end`}>
              {/* <button
                type="button"
                className="outline-button border border-1"
                onClick={handleSeeFullProfile}
              >
                See full profile
              </button> */}
              {/* <button
                type="button"
                className="regular-button"
                onClick={handleSendToFlow}
              >
                Send to Flow
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResponseDetailModal;
