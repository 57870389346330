import React from 'react';
import './DropdownMenu.scss';

const DropdownMenu = ({ menuData, breakWord = true }) => (
  <div className="dropdown-menu-wrapper">
    {menuData?.map((menuItem, i) => (
      <div key={menuItem.name}>
        <div
          role="presentation"
          className="dropdown-menu-item"
          onClick={(e) => {
            e.preventDefault();
            if (menuItem.id) {
              menuItem.action(menuItem.id);
            } else {
              menuItem.action(menuItem.name.includes('My Assets') ? 'asset' : 'watchlist');
            }
          }}
        >
          {menuItem.icon && (
            <img src={menuItem.icon} alt="icon" width="16" height="16" />
          )}
          <div className={`${breakWord ? '' : 'text-keep-all'} dropdown-menu-name`}>{menuItem.name}</div>
        </div>
        {i !== menuData.length - 1 && (
          <div className="dropdown-divider m-0 p-0" />
        )}
      </div>
    ))}
  </div>
);

export default DropdownMenu;
