import React, { useEffect, useMemo, useState } from 'react';
import Chart from 'react-apexcharts';
import TitleComponent from '../../base/TitleComponent';
import styles from './Reach.module.scss';
import { LoadingLines } from '../modals/SearchModal/LoadingList';
import NoData from '../../base/NoData';
import { ethFormat } from '../../../utils/singleAssetPage/parseData';
import uniqueId from '../../../utils/uniqueId';

const RadialBarData = {
  options: {
    chart: {
      type: 'donut',
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          size: '72%',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    stroke: {
      width: 0,
      colors: ['#f1f4f8'],
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    fixed: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
      style: {
        fontSize: '12px',
        background: ['rgba(0, 143, 251, 0.85)', 'rgba(194, 207, 224, 0.85)'],
      },
      marker: {
        show: false,
      },
      x: {
        show: false,
      },
      y: {
        formatter: (y) => `${y}%`,
        title: {
          formatter: () => '',
        },
      },
    },
    colors: ['#109cf1', 'rgba(194, 207, 224, 0.85)'],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
        },
      },
    ],
  },
};

const Reach = ({
  data, isLoading, totalCount, hideXMTP = false,
}) => {
  const [content, setContent] = useState([]);

  useEffect(() => {
    if (data) {
      const showContent = [
        {
          title: 'Email',
          data: data.email,
        },
        {
          title: 'Discord',
          data: data.discord,
        },
        {
          title: 'Telegram',
          data: data.telegram,
        },
        {
          title: 'Twitter',
          data: data.twitter,
        },
        {
          title: 'Phone',
          data: data.phone,
        }];
      // const includesInsensitive = (arr, target) => arr.some((el) => el.toLowerCase() === target.toLowerCase());
      if (!hideXMTP) {
        showContent.push({
          title: 'XMTP',
          data: data.xmtp,
        });
      }
      setContent(showContent);
    }
  }, [data, hideXMTP]);

  const reachContent = useMemo(() => {
    if (isLoading) {
      return (
        <LoadingLines rows={1} />
      );
    }
    if (data) {
      const totalValue = (value) => {
        if (!totalCount) return '?';
        const result = (value / totalCount) * 100;
        if (result === 0) return '0%';
        return result < 1 ? '<1%' : `${ethFormat(result)}%`;
      };

      return (
        <div className="d-flex w-100 flex-column flex-md-row">
          <div className={`d-flex flex-column mt-3  ${styles.wrapper_list}`}>
            <span className={styles.at_least_one}>
              {totalValue(data.reach)}
            </span>
            <span className={styles.info}>
              Through at least 1 channel
            </span>
          </div>
          <div className={`${styles.wrap_reach} d-flex justify-content-around flex-column flex-md-row flex-wrap`}>
            {content.slice().sort((a, b) => (a.data > b.data ? -1 : 1)).map((item) => {
              const res = totalCount
                ? item.data === 0
                  ? 0
                  : (item.data / totalCount) * 100 < 1 ? 1 : (item.data / totalCount) * 100
                : 0;
              return (
                <div className="d-flex" key={uniqueId('Reach')}>
                  <Chart
                    options={RadialBarData.options}
                    series={[res, 100 - res]}
                    type="donut"
                    height={100}
                    width={85}
                  />
                  <div className="d-flex flex-column justify-content-center">
                    <div className={styles.channel}>
                      {item.title}
                    </div>
                    <div className="d-flex gap-2 align-items-center justify-content-start">
                      <div className={styles.dot} />
                      <span className={styles.values}>{totalValue(item.data)}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
    return <NoData />;
  }, [content, data, isLoading, totalCount]);

  return (
    <div className={styles.wrapper}>
      <TitleComponent>Reach</TitleComponent>
      {reachContent}
    </div>
  );
};

export default React.memo(Reach);
