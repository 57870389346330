import React from 'react';
import Close from '../../../../../assets/icons/close.svg';
import styles from './CloseModalButton.module.scss';

const CloseModalButton = ({ onCancel }) => (
  <div className={`${styles.close} position-absolute cursor-pointer d-flex flex-column align-items-center`}>
    <button
      type="button"
      data-bs-dismiss="modal"
      aria-label="Close"
      onClick={() => onCancel()}
    >
      <Close />
    </button>
    Esc
  </div>
);

export default CloseModalButton;
