import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import { audiencesApi } from '../../api/audiences';
import {
  clearCustomListState,
  selectCustomListName,
} from '../../store/reducers/customList';
import CustomListTable from './CustomListTable';
import Loader from '../../components/base/Loader';
import { resetSelectedAsset } from '../../store/reducers/app';

const CustomList = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [walletList, setWalletList] = useState([]);
  const [page, setPage] = useState(1);
  const [desc, setDesc] = useState(true);
  const [limit, setLimit] = useState(25);
  const [orderBy, setOrderBy] = useState('profile');
  const [succeedENS, setSucceedENS] = useState([]);

  const {
    data: list,
    isLoading: isListLoading,
    isFetching: isListFetching,
    refetch,
  } = audiencesApi.useGetCustomListQuery({
    id,
    limit,
    offset: (page - 1) * limit,
    order: desc ? 'desc' : 'asc',
    orderBy,
  }, { skip: !id });

  const customListName = useSelector(selectCustomListName);

  if (succeedENS.length) {
    const temArr = walletList.filter((elem) => {
      const index = succeedENS.findIndex((object) => object.resolved_ens_name === elem);
      if (index >= 0 && !succeedENS[index].address) {
        return;
      }
      return elem;
    });
    temArr.forEach((elem, index) => {
      const ensIndex = succeedENS.findIndex((object) => object.resolved_ens_name === elem);
      if (ensIndex >= 0) {
        temArr[index] = `${elem} (${succeedENS[ensIndex].address})`;
      }
    });
    setSucceedENS([]);
    setWalletList([...new Set(temArr)]);
  }

  useEffect(() => () => {
    dispatch(clearCustomListState());
    dispatch(resetSelectedAsset());
  }, [dispatch]);

  if ((isListFetching || isListLoading) && !list) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {customListName}
          {' '}
          Audiences
          {' '}
          - Absolute Labs Platform
        </title>
      </Helmet>
      {id && list
        ? (
          <CustomListTable
            name={list.name}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            data={list.profiles}
            count={list.profile_count}
            isLoading={isListLoading || isListFetching}
            limit={limit}
            setLimit={setLimit}
            page={page}
            setPage={setPage}
            desc={desc}
            setDesc={setDesc}
            refetch={refetch}
            id={id}
          />
        )
        : null}
    </>
  );
};

export default CustomList;
