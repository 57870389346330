import React from 'react';
import Chart from 'react-apexcharts';
import styles from './Info.module.scss';
import Loader from '../../base/Loader';
import GrowthIndicator from '../GrowthIndicator';
import { convertSmallChart } from '../../../tools/OptionsChart';

const Info = ({
  title, value, isLoading, growth, chartData,
}) => (
  <div className={styles.wrapper}>
    <div className={styles.content_wrapper}>
      <h3 className={styles.title}>{title}</h3>
      {isLoading
        ? (
          <div className={styles.loading}>
            <Loader />
          </div>
        )
        : (
          <div className={styles.value_wrapper}>
            <span className={styles.value}>{value}</span>
            {growth && (!growth.hideZero || growth?.percent !== '0')
              ? <GrowthIndicator percent={growth.percent} oldValue={growth.previousValue} />
              : null}
          </div>
        )}
    </div>
    { chartData
      ? (
        <div className={`${styles.chart}`}>
          <Chart
            type="area"
            options={convertSmallChart(chartData).options}
            series={convertSmallChart(chartData).series}
            height={116}
          />
        </div>
      ) : null}
  </div>
);

export default Info;
