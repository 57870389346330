export const shouldDeleteInEffect = (data, shouldProccessData) => {
  const switchedNowValues = data?.options?.some(
    (option) => !['now'].includes(option.value),
  ) && data?.options?.slice(0, 3).every(
    (option) => option.value,
  );
  const emptyValues = data?.options?.some((option) => Array.isArray(option.value) && option.value.length === 0);

  if (switchedNowValues || emptyValues || !shouldProccessData.status) {
    return true;
  }
};
