import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isError: false,
  errorType: '',
  redirect: true,
  assetSlug: null,
  editCustomData: false,
  selectedAsset: {},
  isAddedFromSearch: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setError: (state, action) => {
      state.isError = action.payload.isError;
      state.errorType = action.payload.errorType;
      state.redirect = action.payload.redirect || true;
    },
    clearState: (state) => {
      state.isError = false;
      state.errorType = '';
    },
    setSelectedAsset: (state, action) => {
      state.selectedAsset = action.payload;
    },
    setAssetSlug: (state, action) => {
      state.assetSlug = action.payload;
    },
    resetSelectedAsset: (state) => {
      state.selectedAsset = {};
    },
    isCustomDataEdit: (state, action) => {
      state.editCustomData = action.payload;
    },
    isAddedFromSearch: (state, action) => {
      state.isAddedFromSearch = action.payload;
    },
  },
});

export const {
  setError, clearState, setSelectedAsset, resetSelectedAsset, setAssetSlug, isCustomDataEdit, isAddedFromSearch,
} = appSlice.actions;

export const getIsError = (state) => state.appReducer.isError;
export const getErrorType = (state) => state.appReducer.errorType;
export const getState = (state) => state.appReducer;
export const selectSelectedAsset = (state) => state.appReducer.selectedAsset;
export const getAssetSlug = (state) => state.appReducer.assetSlug;
export const getIsCustomDataEdit = (state) => state.appReducer.editCustomData;
export const getIsAddedFromSearch = (state) => state.appReducer.isAddedFromSearch;
export default appSlice.reducer;
