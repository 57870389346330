import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  name: '',
  notFoundENS: [],
};

export const customListSlice = createSlice({
  name: 'customList',
  initialState,
  reducers: {
    setCustomListName: (state, action) => {
      state.name = action.payload;
    },
    setNotFoundENS: (state, action) => {
      state.notFoundENS = action.payload;
    },
    resetNotFoundENS: (state) => {
      state.notFoundENS = [];
    },
    clearCustomListState: () => initialState,
  },
});

export const {
  setCustomListName, clearCustomListState, setNotFoundENS, resetNotFoundENS,
} = customListSlice.actions;

export const selectCustomListName = (state) => state.customListReducer.name;
export const selectNotFoundENS = (state) => state.customListReducer.notFoundENS;

export default customListSlice.reducer;
