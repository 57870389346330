import React, { useMemo, useState } from 'react';
import NFTCollectionCreationModal from '../modals/NFTCollectionCreationModal';
import styles from '../../Dashboard/Dashboard.module.scss';

const NFTCollectionCreation = ({ id = '', flow = false }) => {
  const [showNFTModal, setShowNFTModal] = useState(false);

  return useMemo(() => (
    <>
      <button
        type="button"
        className={`${Object.keys(id).length === 0 ? 'regular-button' : 'outline-blue-button'} ${flow
          ? styles.nft_button_flow : styles.nft_button}`}
        onClick={() => setShowNFTModal(true)}
      >
        Create an NFT Collection
      </button>
      {showNFTModal && (
        <NFTCollectionCreationModal
          onCancel={() => setShowNFTModal(false)}
          data={id}
        />
      )}
    </>
  ), [showNFTModal, id, flow]);
};

export default NFTCollectionCreation;
