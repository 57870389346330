import React from 'react';
import { components } from 'react-select';

export const placeholderStyle = {
  placeholder: (defaultStyles) => ({
    ...defaultStyles,
    maxWidth: '90%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 1000,
  }),
  multiValueRemove: (defaultStyles) => ({
    ...defaultStyles,
    backgroundColor: '#f1f4f8',
    '&:hover': {
      backgroundColor: '#f1f4f8',
    },
  }),
  multiValueLabel: (defaultStyles) => ({
    ...defaultStyles,
    backgroundColor: '#f1f4f8',

  }),
};

const menuHeaderStyle = {
  padding: '5px 9px',
  background: '#F5F6F8',
  fontSize: 14,
  fontWeight: 600,
  fontStyle: 'italic',
  color: '#90A0B7',
  marginTop: '-5px',
};

export const MenuList = ({ props, showHeader }) => {
  const { children, selectProps } = props;

  return (selectProps?.inputValue || !showHeader) ? (
    <components.MenuList {...props}>
      {children}
    </components.MenuList>
  ) : (
    <components.MenuList {...props}>
      <div style={menuHeaderStyle}>Suggestions from your assets</div>
      {children}
    </components.MenuList>
  );
};
