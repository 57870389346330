import React, { useMemo, useState } from 'react';
import Chart from 'react-apexcharts';
import ApexCharts from 'apexcharts';
import { useParams } from 'react-router-dom';
import NoData from '../../../../components/base/NoData';
import RoundSpinner from '../../../../components/base/RoundSpinner';
import { webSDKApi } from '../../../../api/webSDK';
import { defaultOptions } from '../../../../components/ui/Market/Price';
import styles from './DailyChart.module.scss';
import { convertToReadableFormat } from '../../../../tools/NumberConverterTool';

const DailyChart = () => {
  const { id } = useParams();
  const [legendToggle, setLegendToggle] = useState({
    'Web3 adoption': true,
    'New visitors': true,
    'Returning visitors': true,
  });

  const {
    data: graphData,
    isLoading: isGraphDataLoading,
    isFetching: isGraphDataFetching,
    isSuccess,
  } = webSDKApi.useGetWebSDKDataForChartQuery(id);

  const handleData = (data) => {
    const FutureRemovedData = data.filter((elem) => (new Date(elem.day) <= new Date()));
    const web3AdoptionMax = Math.max(...FutureRemovedData.map((elem) => (elem.web3_footprint * 100)));
    const newVisitorsMax = Math.max(...FutureRemovedData.map((elem) => elem.new_users));
    const ReturningMax = Math.max(...FutureRemovedData.map((elem) => elem.returning_users));
    return (
      {
        series: [{
          name: 'Web3 adoption',
          type: 'area',
          data: FutureRemovedData.map((elem) => (elem.web3_footprint * 100).toFixed(2)),
        },
        {
          name: 'New visitors',
          type: 'column',
          data: FutureRemovedData.map((elem) => elem.new_users),
        },
        {
          name: 'Returning visitors',
          type: 'column',
          data: FutureRemovedData.map((elem) => elem.returning_users),
        },
        ],
        options: {
          chart: {
            zoom: {
              enabled: false,
            },
            toolbar: {
              show: false,
            },
            type: 'arena',
            stacked: true,
            id: 'daily graph',
          },
          stroke: {
            width: [1, 1, 1],
            curve: 'smooth',
          },
          plotOptions: {
            bar: {
              horizontal: false,
              borderRadius: 1,
              columnWidth: '30%',
            },
          },
          grid: {
            yaxis: {
              lines: {
                show: false,
              },
            },
          },
          colors: ['#CED5DF', '#ffb946', '#109CF1'],
          fill: {
            opacity: [0.25, 1, 1],
            gradient: {
              inverseColors: false,
              shade: 'light',
              type: 'vertical',
              opacityFrom: 0.85,
              opacityTo: 0.55,
              stops: [0, 100, 100, 100],
            },
          },
          markers: {
            size: 0,
          },
          xaxis: {
            type: 'datetime',
            categories: FutureRemovedData.map((elem) => elem.day),
            labels: {
              formatter: (x) => new Date(x).toUTCString().substr(4, 8),
            },
            axisBorder: {
              show: true,
              color: '#6A707E',
            },
            axisTicks: {
              show: true,
              color: '#6A707E',
              width: 4,
            },
            crosshairs: {
              show: false,
            },
            tooltip: {
              enabled: false,
            },
          },
          yaxis: [
            {
              seriesName: 'Web3 adoption',
              axisBorder: {
                show: true,
                color: '#6A707E',
              },
              axisTicks: {
                show: true,
                color: '#6A707E',
                width: 4,
              },
              min: 0,
              max: Math.max(20, Math.min(100, web3AdoptionMax * 2)),
              title: {
                text: 'Web3 adoption percentage',
                style: {
                  color: '#6A707E',
                },
              },
              opposite: true,
              labels: {
                formatter: (value) => `${value.toFixed(0)}%`,
              },
            },
            {
              seriesName: 'New visitors',
              axisBorder: {
                show: true,
                color: '#6A707E',
              },
              axisTicks: {
                show: true,
                color: '#6A707E',
                width: 4,
              },
              min: 0,
              max: newVisitorsMax + ReturningMax,
              title: {
                text: 'Total visitors',
                style: {
                  color: '#6A707E',
                },
              },
              labels: {
                formatter: (value) => convertToReadableFormat(value),
              },
            },
            {
              seriesName: 'Returning visitors',
              show: false,
              min: 0,
              max: newVisitorsMax + ReturningMax,
            },
          ],
          tooltip: {
            shared: true,
            intersect: false,
            y: {
              formatter: (y, { ...chartData }) => (typeof (y) !== 'undefined' ? !chartData.seriesIndex
                ? `${y}%` : y.toLocaleString() : ''),
            },
          },
          legend: {
            show: false,
          },
        },
      }
    );
  };
  const chartContent = useMemo(() => {
    if ((isGraphDataLoading || isGraphDataFetching) && !graphData) {
      return (
        <div className={`${styles.load_wrap}`}>
          <RoundSpinner position="position-absolute" theme="light" />
        </div>
      );
    } if (!graphData && isSuccess) {
      return (
        <div className={`${styles.load_wrap}`}>
          <NoData />
        </div>
      );
    } return (
      <Chart
        options={isGraphDataLoading || isGraphDataFetching
          ? defaultOptions.options
          : graphData ? handleData(graphData).options : defaultOptions.options}
        series={(isGraphDataLoading || isGraphDataFetching)
          ? defaultOptions.series
          : graphData ? handleData(graphData).series : defaultOptions.series}
        type="line"
        height={400}
      />
    );
  }, [graphData, isGraphDataFetching, isGraphDataLoading, isSuccess]);

  const toggleSeries = (checkbox) => {
    setLegendToggle({ ...legendToggle, [checkbox]: !legendToggle[checkbox] });
    const chart = ApexCharts.getChartByID('daily graph');
    chart.toggleSeries(checkbox);
  };

  return useMemo(() => (
    <>
      <div id="chart" className="position-relative">
        {chartContent}
      </div>
      <div className="d-flex gap-2">
        <div
          role="presentation"
          className={`d-flex align-items-center gap-2 asset-wrap cursor-pointer 
          ${legendToggle['Web3 adoption'] ? 'opacity-100' : 'opacity-50'}`}
          onClick={() => toggleSeries('Web3 adoption')}
        >
          <div className="dot-3" />
          <span>Web3 adoption</span>
        </div>
        <div
          role="presentation"
          className={`d-flex align-items-center gap-2 asset-wrap cursor-pointer 
          ${legendToggle['New visitors'] ? 'opacity-100' : 'opacity-50'}`}
          onClick={() => toggleSeries('New visitors')}
        >
          <div className="dot-2" />
          <span>New visitors</span>
        </div>
        <div
          role="presentation"
          className={`d-flex align-items-center gap-2 asset-wrap cursor-pointer 
          ${legendToggle['Returning visitors'] ? 'opacity-100' : 'opacity-50'}`}
          onClick={() => toggleSeries('Returning visitors')}
        >
          <div className="dot" />
          <span>Returning visitors</span>
        </div>
      </div>
    </>
  ), [chartContent, graphData, isGraphDataFetching, isGraphDataLoading, legendToggle]);
};

export default DailyChart;
