import React from 'react';
import TickCircle from '../../../assets/icons/tick_circle.svg';
import XCircle from '../../../assets/icons/x-circle.svg';
import close from '../../../assets/images/Vector.png';
import styles from './KeyLoadingModal.module.scss';
import Loader from '../../../components/base/Loader';

const KeyLoadingModal = ({ setIsOpen, result }) => (
  <div
    className={`modal modal-dialog-centered ${styles.modal_wrapper}`}
    id="keyLoadingModal"
    tabIndex="-1"
    aria-labelledby="keyLoadingModalLabel"
    aria-hidden="true"
  >
    <div
      role="presentation"
      className="modal-dialog modal-md modal-phone w-100 position-relative"
    >
      <div className={`modal-content p-3 ${styles.content}`}>
        <div className="d-flex flex-column align-items-center justify-content-center mt-5">
          <div className={styles.loading}>
            {result.isLoading
              ? <Loader />
              : result.isSuccess
                ? <TickCircle className="m-3" />
                : <XCircle className="m-3" />}
          </div>
          <div className="text-h3-regular text-center mb-2 mt-1 fw-bold d-flex flex-column">
            {result.isLoading
              ? (
                <>
                  <span>API Key is being generated.</span>
                  <span>Please hold, this can take up to a minute.</span>
                </>
              )
              : result.isSuccess
                ? 'The API Key has been generated.'
                : (
                  <>
                    <span>Key generation failed.</span>
                    <span>Please try again later.</span>
                  </>
                )}
          </div>
          <button
            className={`position-absolute ${styles.closeButton}`}
            type="button"
            onClick={() => {
              setIsOpen(false);
              result.reset();
            }}
          >
            <img src={close} alt="close" width="12px" height="12px" />
          </button>
        </div>
      </div>
    </div>
  </div>
);

export default KeyLoadingModal;
