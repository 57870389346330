import React, {
  useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
  setCurrentFolder,
  getSelectedItems,
  setSelectedItems,
  getSearchParams,
  setSearchParams,
} from '../../store/reducers/folders';
import { getUser } from '../../store/reducers/user';
import emitter from '../../utils/emitter';
import { audiencesApi } from '../../api/audiences';
import { foldersApi } from '../../api/folders';
import Loader from '../../components/base/Loader';
import SegmentsTable from './SegmentsTable';
import ListWithFolders from '../../components/ui/ListWithFolders';
import useSetSearchParams from '../../utils/useSetSearchParams';

const Segments = () => {
  const dispatch = useDispatch();
  const selection = useSelector((state) => getSelectedItems(state, 'audiences'));
  const storedSearchParams = useSelector((state) => getSearchParams(state, 'audiences'));
  const [searchParams] = useSearchParams();
  const setParams = useSetSearchParams();
  const user = useSelector(getUser);

  const [desc, setDesc] = useState(searchParams.get('desc') || true);
  const [search, setSearch] = useState(searchParams.get('search') || '');
  const [itemType, setItemType] = useState(searchParams.get('filter') || '');
  const [field, setField] = useState(searchParams.get('orderBy') || 'created_at');
  const [page, setPage] = useState(searchParams.get('page') || 1);
  const [limit, setLimit] = useState(searchParams.get('limit') || 10);
  const [folder, setFolder] = useState(searchParams.get('folder') || 'all');

  const {
    data: userSegments,
    isLoading: isUserSegmentsLoading,
    isFetching: isUserSegmentsFetching,
    refetch,
  } = audiencesApi.useGetTeamSegmentsQuery(
    {
      limit,
      offset: (page - 1) * limit,
      type: desc ? 'desc' : 'asc',
      field,
      itemType,
      search,
      my_audiences: folder === 'my',
      folders: (folder === 'all' || folder === 'my') ? null : folder,
    },
  );

  useEffect(() => {
    setParams(storedSearchParams);
  }, [storedSearchParams]);

  useEffect(() => {
    if (user?.audiences) {
      refetch();
    }
  }, [limit, page, desc, field, itemType, search, folder, refetch]);

  useEffect(() => {
    const listener = () => {
      refetch();
    };

    emitter.on('refetch_audiences', listener);

    return () => {
      emitter.off('refetch_audiences', listener);
    };
  }, []);

  useEffect(() => {
    if (userSegments?.results?.length === 0 && page > 1) {
      setParams({ page: page - 1 });
    }
  }, [userSegments]);

  const {
    data: folders,
    isLoading: isLoadingFolders,
    refetch: refetchFolders,
  } = foldersApi.useGetFoldersQuery({
    type: 'audiences',
  });

  useEffect(() => {
    const listener = () => {
      refetchFolders();
    };

    emitter.on('refetch_folders', listener);

    return () => {
      emitter.off('refetch_folders', listener);
    };
  }, []);

  useEffect(() => {
    if (isLoadingFolders) {
      return;
    }
    if (folder === 'all') {
      dispatch(setCurrentFolder({
        type: 'audiences',
        value: {
          id: 'all',
          name: 'All Audiences',
          sub_folders: [],
        },
      }));
    } else if (folder === 'my') {
      dispatch(setCurrentFolder({
        type: 'audiences',
        value: {
          id: 'my',
          name: 'My Audiences',
          sub_folders: [],
        },
      }));
    } else {
      let tempFolder = null;
      let found = false;

      folders.forEach((f) => {
        if (found) return;

        if (f.id === folder) {
          found = true;
          tempFolder = f;
        } else {
          f.sub_folders.forEach((sf) => {
            if (sf.id === folder) {
              found = true;
              tempFolder = sf;
            }
          });
        }
      });

      if (tempFolder) {
        dispatch(setCurrentFolder({
          type: 'audiences',
          value: {
            id: tempFolder.id,
            name: tempFolder.name,
            sub_folders: tempFolder.sub_folders,
          },
        }));
      }
    }
  }, [folder, isLoadingFolders]);

  useEffect(() => {
    if (searchParams.get('sort')) {
      setDesc(searchParams.get('sort') === 'desc');
    } else {
      setDesc(true);
    }

    if (searchParams.get('search')) {
      setSearch(searchParams.get('search'));
    } else {
      setSearch('');
    }

    if (searchParams.get('filter')) {
      setItemType(searchParams.get('filter'));
    } else {
      setItemType('');
    }

    if (searchParams.get('orderBy')) {
      setField(searchParams.get('orderBy'));
    } else {
      setField('created_at');
    }

    if (searchParams.get('page')) {
      setPage(Number(searchParams.get('page')));
    } else {
      setPage(1);
    }

    if (searchParams.get('limit')) {
      setLimit(Number(searchParams.get('limit')));
    } else {
      setLimit(10);
    }

    if (searchParams.get('folder')) {
      if (searchParams.get('folder') === 'all' || searchParams.get('folder') === 'my') {
        setFolder(searchParams.get('folder'));
      } else {
        setFolder(Number(searchParams.get('folder')));
      }
    } else {
      setFolder('all');
    }

    dispatch(setSearchParams({ type: 'audiences', value: Object.fromEntries([...searchParams]) }));
  }, [searchParams]);

  useEffect(() => {
    if (userSegments?.results) {
      const tempSelection = selection.filter((item) => userSegments.results.some((d) => d.id === item.id));
      dispatch(setSelectedItems({ type: 'audiences', value: tempSelection }));
    }
  }, [userSegments?.results]);

  return useMemo(() => {
    if (isLoadingFolders) {
      return <Loader />;
    }

    return (
      <ListWithFolders
        folders={folders}
        allText="All Audiences"
        myText="My Audiences"
        type="audiences"
      >
        <SegmentsTable
          page={page}
          data={userSegments?.results}
          count={userSegments?.count || 0}
          refetch={refetch}
          field={field}
          desc={desc}
          limit={limit}
          search={search}
          itemType={itemType}
          isLoading={isUserSegmentsFetching || isUserSegmentsLoading}
        />
      </ListWithFolders>
    );
  }, [
    isUserSegmentsLoading,
    isUserSegmentsFetching,
    isLoadingFolders,
    userSegments?.results
      .userSegments?.total_count,
    userSegments?.count,
    page,
    refetch,
    field,
    desc,
    limit,
    folders,
    search,
  ]);
};

export default Segments;
