import React, { useRef, useState } from 'react';
import Settings from '../../../assets/images/dashboard/settings.png';
import Trash from '../../../assets/icons/trash_raw.svg';
import Rename from '../../../assets/icons/rename.svg';
import AddSubfolder from '../../../assets/icons/folders/add_subfolder.svg';
import styles from './listWithFolders.module.scss';
import tableStyles from '../../base/Table/Table.module.scss';
import PopupMenuButton from '../PopupMenuButton';
import AddSegmentButton from './AddSegmentButton';
import AddFlowButton from './AddFlowButton';
import AddFormButton from './AddFormButton';

const FolderSettingsButton = ({
  folder,
  addSubfolder,
  renameFolder,
  deleteFolder,
  type,
  foldersRef,
}) => {
  const modalRef = useRef();
  const [isModalBottom, setIsModalBottom] = useState(true);

  const handleAddSubfolder = (e) => {
    e.stopPropagation();
    if (addSubfolder) {
      addSubfolder(folder);
    }
  };

  const handleRenameFolder = (e) => {
    e.stopPropagation();
    if (renameFolder) {
      renameFolder(folder);
    }
  };

  const handleDeleteFolder = (e) => {
    e.stopPropagation();
    if (deleteFolder) {
      deleteFolder(folder);
    }
  };

  const handleMouseEnter = (e) => {
    if (foldersRef.current && modalRef.current) {
      const buttonBottom = e.target.getBoundingClientRect().bottom;
      const foldersBottom = foldersRef.current.getBoundingClientRect().bottom;
      const modalHeight = modalRef.current.getBoundingClientRect().height;
      setIsModalBottom((buttonBottom + modalHeight) < foldersBottom);
    }
  };

  return (
    <div className={`${tableStyles.popover_wrapper} ${styles.folderSettingsButton}`} onMouseEnter={handleMouseEnter}>
      <img
        src={Settings}
        className="cursor-pointer"
        height="25"
        width="25"
        alt="settings"
      />
      <div className={`${styles.invisible_dropdown}`}>
        <div
          className={`
            ${tableStyles.popover_content} 
            ${styles.popover_content} 
            ${isModalBottom ? styles.popover_bottom : styles.popover_top}
          `}
          ref={modalRef}
        >
          {renameFolder && (
            <PopupMenuButton onClick={(e) => handleRenameFolder(e)}>
              <Rename />
              Rename
            </PopupMenuButton>
          )}
          {addSubfolder && (
            <PopupMenuButton key={2} onClick={(e) => handleAddSubfolder(e)}>
              <AddSubfolder color="#90A0B7" />
              Add subfolder
            </PopupMenuButton>
          )}
          {type === 'audiences' && (<AddSegmentButton folderId={folder.id} />)}
          {type === 'flows' && (<AddFlowButton folderId={folder.id} />)}
          {type === 'forms' && (<AddFormButton folderId={folder.id} />)}
          {deleteFolder && (
            <PopupMenuButton key={3} onClick={(e) => handleDeleteFolder(e)} className={styles.deleteButton}>
              <Trash />
              Delete
            </PopupMenuButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default FolderSettingsButton;
