import {
  useEffect, useRef, useState, useCallback,
} from 'react';
import { uuid4 } from '@sentry/utils';
import { utilsApi } from '../../api/utils';

const useCancelableRequest = (apiCall, params, shouldSkip) => {
  const [requestIdList, setRequestIdList] = useState([]);
  const prevParamsRef = useRef(params);
  const [cancelJob] = utilsApi.useJobCancellationByIdMutation();

  const jobCancellation = useCallback(() => {
    if (requestIdList.length > 0) {
      cancelJob(requestIdList.map((ele) => ({ request_id: ele })));
      setRequestIdList([]);
    }
  }, [cancelJob, requestIdList]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = '';
    };
    const handleUnload = () => {
      jobCancellation();
    };
    const handlePopState = () => {
      jobCancellation();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('unload', handleUnload);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('unload', handleUnload);
      window.removeEventListener('popstate', handlePopState);
    };
  }, [jobCancellation]);

  useEffect(() => {
    if (!shouldSkip && JSON.stringify(prevParamsRef.current) !== JSON.stringify(params)) {
      setRequestIdList((prev) => [...prev, uuid4()]);
      prevParamsRef.current = params;
    }
  }, [params, shouldSkip]);

  const {
    data, isLoading, error, isFetching,
  } = apiCall(
    {
      ...params,
      requestId: requestIdList[requestIdList.length - 1],
    },
    { skip: shouldSkip || JSON.stringify(prevParamsRef.current) !== JSON.stringify(params)},
  );

  return {
    data, isLoading, error, isFetching, requestIdList, jobCancellation,
  };
};

export default useCancelableRequest;
