import React from 'react';

import { convertToReadableFormat } from '../../../../tools/NumberConverterTool';
import styles from './CustomDataLabel.module.scss';

export const FormatGroupLabel = () => (
  <div>
    <div className={styles.addresses}>Most frequent values</div>
  </div>
);

export const CustomDataLabel = ({ val }) => (
  <div className="d-flex cursor-pointer justify-content-between align-items-center">
    <div>{val?.value || 'No value'}</div>
    <div className={styles.addresses}>
      {convertToReadableFormat(val?.label)}
      {' '}
      addresses
    </div>
  </div>
);
