import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { webSDKApi } from '../../../api/webSDK';
import UserTabContainer from '../components/UserTabContainer';
import { selectWebSDKFilterParam, selectWebSDKFilterContents } from '../../../store/reducers/webSDK';
import UserTabHeatMap from './UserTabHeatMap';
import uniqueId from '../../../utils/uniqueId';
import styles from './UsersTab.module.scss';

const UsersTab = () => {
  const { id } = useParams();
  const filterParams = useSelector(selectWebSDKFilterParam);
  const filterContents = useSelector(selectWebSDKFilterContents);

  const {
    data: userTabData,
    isLoading: isLoadingUserTab,
    isFetching: isFetchingUserTab,
  } = webSDKApi.useGetWebSDKForUsersTabQuery(
    {
      id,
      ...filterParams,
    },
  );

  const usersTabContent = useMemo(() => [
    {
      data: userTabData?.wallet_detection,
      title: 'Web3 Adoption',
      info: 'The "visitors with wallets" percentage is over the visitors using a browser which can have a wallet extension. The bottom percentages are over the wallets detected.',
    },
    {
      data: userTabData?.blockchain_environments,
      title: 'Chain Environments',
      info: 'The percentage is computed over the visitors having a wallet.'
    },
    {
      data: userTabData?.wallets,
      title: 'Wallet Types',
      info: 'The percentage is computed over the visitors having a wallet.'
    },
    {
      data: userTabData?.chains,
      title: 'EVM Chains',
      info: 'Distribution of chains seen in EVM wallets. The percentage is over the number of visitors with an EVM wallet.'
    },
  ], [
    userTabData?.wallet_detection,
    userTabData?.blockchain_environments,
    userTabData?.wallets,
    userTabData?.chains,
  ]);

  const usersTabRow3Content = useMemo(() => [
    {
      data: userTabData?.languages,
      title: 'Languages',
    },
    {
      data: userTabData?.visitor_users?.length > 0
        ? [...userTabData.visitor_users].sort((a, b) => b.visitor_count - a.visitor_count)
        : [],
      title: 'New Visitors',
    },
  ], [
    userTabData?.languages,
    userTabData?.visitor_users,
  ]);

  const getTotal = (title) => {
    if (!filterContents?.filteredStats) return null;

    switch (title) {
      case 'Web3 Adoption':
        return filterContents.filteredStats.can_have_wallet 
      case 'EVM Chains':
      case 'Wallet Types':
      case 'Chain Environments':
        return filterContents.filteredStats.visitors_with_wallets 
      default:
        return null;
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className="row pb-4">
        {usersTabContent.map((elem) => (
          <UserTabContainer
            key={uniqueId('users_constant')}
            title={elem.title}
            isLoading={isLoadingUserTab || isFetchingUserTab || !filterContents
              || filterContents?.isLoadingFilteredStats
              || filterContents?.isFetchingFilteredStats}
            data={elem.data}
            total={getTotal(elem.title)}
            info={elem.info}
            walletFilterOption={filterParams?.walletFilter}
            activeMultipleEcosystems={
              userTabData?.active_in_multiple_ecosystems && userTabData.active_in_multiple_ecosystems.length 
              ? userTabData?.active_in_multiple_ecosystems[0].visitor_count 
              : 0
            }
          />
        ))}
      </div>
      <div className="d-flex w-100 gap-4 pb-4">
        <UserTabHeatMap
          countriesData={userTabData?.countries || []}
          isLoading={isLoadingUserTab || isFetchingUserTab || !filterContents
            || filterContents?.isLoadingFilteredStats
            || filterContents?.isFetchingFilteredStats}
          maxValue={userTabData?.countries
            ? Math.max(...userTabData.countries.map((elem) => elem.visitor_count)) : null}
          total={filterContents?.filteredStats?.visitors}
        />
        <UserTabContainer
          title="Countries"
          isLoading={isLoadingUserTab || isFetchingUserTab || !filterContents
            || filterContents?.isLoadingFilteredStats
            || filterContents?.isFetchingFilteredStats}
          data={userTabData?.countries.slice(0, 8) || []}
          total={filterContents?.filteredStats?.visitors}
        />
      </div>
      <div className="row pb-4">
        {usersTabRow3Content.map((elem) => (
          <UserTabContainer
            key={uniqueId('users_constant')}
            title={elem.title}
            isLoading={isLoadingUserTab || isFetchingUserTab || !filterContents
              || filterContents?.isLoadingFilteredStats
              || filterContents?.isFetchingFilteredStats}
            data={elem.data}
            total={elem.title === 'EVM Chains' || elem.title === 'Wallet Types'
              ? filterContents?.filteredStats?.visitors_with_wallets : elem.title === 'Web3 Adoption'
                ? filterContents?.filteredStats?.can_have_wallet : filterContents?.filteredStats?.visitors}
            info={elem.info}
            walletFilterOption={filterParams?.walletFilter}
          />
        ))}
      </div>
    </div>
  );
};

export default UsersTab;
