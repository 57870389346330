import React from 'react';
import Close from '../../../../../../assets/icons/close.svg';
import styles from './EmailModal.module.scss';

const EmailModal = ({ onCancel, showDialog }) => {
  const handleCLick = () => {
    showDialog(false);
    window.open('https://docs.absolutelabs.io/features/flows/activation/e-mail', '_blank');
  };

  return (
    <div
      className={`modal modal-dialog-centered ${styles.modal_wrapper}`}
      id="confirmModal"
      tabIndex="-1"
      aria-labelledby="confirmModalLabel"
      aria-hidden="true"
      onMouseDown={() => onCancel()}
    >
      <div
        className="modal-dialog modal-md modal-phone w-100 position-relative"
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
        role="presentation"
      >
        <div className={`${styles.body} modal-content position-relative border-0`}>
          <div className={`${styles.close} d-flex justify-content-end position-absolute`}>
            <Close
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => onCancel()}
            />
          </div>
          <div>
            <div className={`${styles.title} d-flex justify-content-start`}>
              Email block is not ready to be used
            </div>
          </div>
          <div className={`${styles.description} d-flex flex-column gap-2`}>
            <span>
              To send emails using this component,
              you need to set up a Sendgrid account and create a transactional email template.
            </span>
            <div
              role="presentation"
              onClick={handleCLick}
              className={`${styles.learn} cursor-pointer`}
            >
              Learn how to send emails with Absolute Labs
            </div>
          </div>
          <div className={`${styles.footer} d-flex justify-content-end`}>
            <button
              type="button"
              className="regular-button"
              data-bs-dismiss="modal"
              onClick={() => onCancel()}
            >
              Got It
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailModal;
