import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  balance: null,
  contract: null,
  ERC20Contract: null,
  ERC20Address: null,
  selectedERC20: null,
  pendingTransactions: [],
};

export const web3Slice = createSlice({
  name: 'web3',
  initialState,
  reducers: {
    setBalance: (state, action) => {
      state.balance = action.payload;
    },
    setContract: (state, action) => {
      state.contract = action.payload;
    },
    setERC20Contract: (state, action) => {
      state.ERC20Contract = action.payload;
    },
    setERC20Address: (state, action) => {
      state.ERC20Address = action.payload;
    },
    setSelectedERC20: (state, action) => {
      state.selectedERC20 = action.payload;
    },
    clearState: (state) => {
      state.ERC20Contract = null;
      state.contract = null;
      state.balance = null;
    },
    fetchPendingTxns(state, action) {
      state.pendingTransactions.push(action.payload);
    },
    clearPendingTxn(state, action) {
      const target = state.pendingTransactions.find((x) => x.txnHash === action.payload);
      if (target) {
        state.pendingTransactions.splice(state.pendingTransactions.indexOf(target), 1);
      }
    },
  },
});

export const {
  setERC20Address,
  setBalance,
  setContract,
  setERC20Contract,
  setSelectedERC20,
  clearState,
  setLibrary,
  fetchPendingTxns,
  clearPendingTxn,
} = web3Slice.actions;

export const getBalance = (state) => state.web3Reducer.balance;
export const getContract = (state) => state.web3Reducer.contract;
export const getERC20Contract = (state) => state.web3Reducer.ERC20Contract;
export const getERC20Address = (state) => state.web3Reducer.ERC20Address;
export const getSelectedERC20 = (state) => state.web3Reducer.selectedERC20;
export const getPendingTransactions = (state) => state.web3Reducer.pendingTransactions;
export const isPendingTxn = (pendingTransactions, type) => pendingTransactions.map((x) => x.type).includes(type);

export default web3Slice.reducer;
