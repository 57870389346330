import React, { useState } from 'react';
import styles from './AddressTextarea.module.scss';

import Close from '../../../../../../../assets/icons/close.svg';
import { isValidContractAddress } from '../../../../../../../tools/EtherTool';

const Tag = ({ text, remove, disabled }) => {
  const handleOnRemove = (e) => {
    e.stopPropagation();
    remove(text);
  };

  return (
    <span className={`text-truncate ${styles.chips}`}>
      {!disabled && (
        <Close
          className="pe-1 cursor-pointer"
          onClick={handleOnRemove}
          aria-label={`remove ${text}`}
        />
      )}
      <span>{text}</span>

    </span>
  );
};

const defaultSeparators = ['Enter'];

const AddressTextarea = ({
  name,
  placeHolder,
  separators,
  onRemoved,
  disabled,
  beforeAddValidate,
  walletList,
  setWalletList,
  limit,
}) => {
  const [rows, setRows] = useState(1);

  const handleOnKeyUp = (e) => {
    if (limit && walletList.length >= limit) {
      e.target.value = '';
      e.preventDefault();
    }
    e.stopPropagation();

    let text = '';
    if (e.type === 'paste') {
      text = e.clipboardData.getData('Text').toLowerCase();
    } else {
      text = e.target.value.toLowerCase();
    }
    if (text && ((separators || defaultSeparators).includes(e.key) || e.type === 'paste')) {
      if (beforeAddValidate && !beforeAddValidate(text, walletList)) return;

      const textArr = limit ? text.split(/[;, ,\n]/).slice(0, limit) : text.split(/[;, ,\n]/);
      const notIncludedWallets = textArr.filter((elem) => (!walletList.includes(elem) && isValidContractAddress(elem)));
      if (notIncludedWallets.length) {
        setWalletList([...walletList, ...Array.from(new Set(notIncludedWallets))]);
      }
      e.target.value = '';
      e.preventDefault();
      setRows(1);
    }
  };

  const onTagRemove = (text) => {
    setWalletList(walletList.filter((tag) => tag !== text));
    if (onRemoved) {
      onRemoved(text);
    }
  };

  const handleChange = (event) => {
    const textareaLineHeight = 16;

    const previousRows = event.target.rows;
    event.target.rows = 1;

    const currentRows = Math.ceil(event.target.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      event.target.rows = currentRows;
    }

    if (currentRows >= rows) {
      event.target.rows = currentRows;
      event.target.scrollTop = event.target.scrollHeight;
    }
    setRows(currentRows);
  };

  return (
    <div
      aria-labelledby={name}
      className={styles.wrapper}
    >
      <div className="d-flex align-items-center gap-1 flex-wrap mb-2">
        {walletList.map((tag) => (
          <Tag key={tag} text={tag} remove={onTagRemove} disabled={disabled} />
        ))}
      </div>
      <textarea
        onChange={(e) => {
          if ((limit && walletList.length >= limit) || !isValidContractAddress(e.target.value)) {
            e.target.value = '';
            e.preventDefault();
          }
          handleChange(e);
        }}
        className={`
        ${styles.textarea}`}
        name={name}
        rows={rows}
        placeholder={placeHolder}
        onKeyDown={handleOnKeyUp}
        onPaste={handleOnKeyUp}
        disabled={disabled}
      />
    </div>
  );
};

export default AddressTextarea;
