export const colorConfigurations = [
  {
    color: '#ECEFF3',
    buttonColour: '#109CF1',
    buttonTextColour: '#FFFFFF',
    backgroundColour: '#ECEFF3',
  },
  {
    color: '#CED5DF',
    buttonColour: '#7185A3',
    buttonTextColour: '#FFFFFF',
    backgroundColour: '#CED5DF',
  },
  {
    color: '#FFDD59',
    buttonColour: '#FFA801',
    buttonTextColour: '#FFFFFF',
    backgroundColour: '#FFDD59',
  },
  {
    color: '#FF7670',
    buttonColour: '#FF3F34',
    buttonTextColour: '#FFFFFF',
    backgroundColour: '#FF7670',
  },
  {
    color: '#0BE881',
    buttonColour: '#05C46B',
    buttonTextColour: '#FFFFFF',
    backgroundColour: '#0BE881',
  },
  {
    color: '#34E7E4',
    buttonColour: '#05BDBB',
    buttonTextColour: '#FFFFFF',
    backgroundColour: '#34E7E4',
  },
  {
    color: '#303B4A',
    buttonColour: '#445369',
    buttonTextColour: '#FFFFFF',
    backgroundColour: '#303B4A',
  },
];
