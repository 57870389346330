import { showErrorMessage } from '../../components/base/Notifications';

export const preventSearch = (e, exceptions = '') => {
  const str = "!@#$%^*()+=[]\\';,/{}|\"<>?";
  const allowedChars = exceptions.split('');
  if (str.split('').includes(e.key) && !allowedChars.includes(e.key)) {
    e.preventDefault();
  }
};

export const preventPaste = (e, exceptions = '') => {
  const paste = e.clipboardData.getData('text/plain');
  const notPermittedSymbols = "!@#$%^*()+=[]\\';,/{}|\"<>?";
  const allowedChars = exceptions.split('');
  const check = paste.split('').some((sym) => notPermittedSymbols.includes(sym) && !allowedChars.includes(sym));
  if (check) {
    showErrorMessage('The pasted values contain symbols that are not permitted.');
    e.preventDefault();
  }
};
