import React from 'react';
import styles from '../NotableInvestments.module.scss';

const Value = ({ value, owned }) => (
  <span className={`${styles.notable_count} ${owned ? styles.owned : ''}`}>
    {value}
  </span>
);

export default Value;
