import React from 'react';
import ChainLogo from '..';
import styles from './ChainList.module.scss';

const ChainList = ({
  chains, tooltip = '', onClick = null, small = false, square = false,
}) => (
  <div className={`${styles.wrapper} ${small ? styles.small_gap : styles.normal_gap}`}>
    {
      chains.map((chain) => (
        <ChainLogo key={chain} chain={chain} tooltip={tooltip} onClick={onClick} small={small} square={square} />
      ))
    }
  </div>
);

export default ChainList;
