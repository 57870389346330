import React, { useState } from 'react';
import { ChromePicker } from 'react-color';
import styles from './colorSelector.module.scss';
import settingsStyles from '../ModalSettings/ModalSettings.module.scss';

const ColorSelector = ({
  id, title, color, colorChange,
}) => {
  const [showColorPicker, setShowColorPicker] = useState(false);

  const handleColorChange = (newColor) => {
    colorChange(newColor, id);
  };

  return (
    <div className={styles.wrapper}>
      <div className={settingsStyles.title}>{title}</div>
      <div className={styles.input_wrapper}>
        <input
          type="text"
          value={color}
          onChange={(e) => handleColorChange(e.target.value, 'form')}
          className={`form-control ${styles.color_input}`}
          aria-label={title}
          onFocus={() => setShowColorPicker(true)}
        />
        <div
          role="presentation"
          className={styles.color_preview}
          style={{ backgroundColor: color }}
          onClick={() => setShowColorPicker(!showColorPicker)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              setShowColorPicker(!showColorPicker);
            }
          }}
        />
      </div>
      {showColorPicker && (
        <div className={styles.color_picker_wrapper}>
          <div
            role="presentation"
            onClick={() => setShowColorPicker(false)}
            className={styles.color_picker}
          />
          <ChromePicker
            color={color}
            disableAlpha
            onChangeComplete={(c) => handleColorChange(c.hex, 'form')}
          />
        </div>
      )}
    </div>
  );
};

export default ColorSelector;
