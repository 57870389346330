export const regExpEmail = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,50})?$/;
export const regExpSenderEmail = /^(?:[^<>@]*<)?[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}>?$/;
export const regExpTwitter = /^(?=.{1,15}$)([A-Za-z][A-Za-z0-9_]*$)/;
export const regExpDiscord = /^((.{3,32}#[0-9]{4})|)$/;
export const regExpPhone = /^([+]\d+)?$/;
export const regExpALID = /^([a-f0-9]{8}-[a-f0-9]{4}-[4][a-f0-9]{3}-[8|9|aA|bB][a-f0-9]{3}-[a-f0-9]{12})$/;
export const regExpTelegram = /^[a-zA-Z][a-zA-Z0-9_]{4,31}$/;

export const regAirDrop = /^0x[a-fA-F0-9]{40}$/;
export const regExpObj = {
  email: regExpEmail,
  twitter_id: regExpTwitter,
  discord_id: regExpDiscord,
  phone: regExpPhone,
  alid: regExpALID,
  telegram_id: regExpTelegram,
  airdrop: regAirDrop,
};

export const isInValidData = (data) => Object.entries(data)
  .some((elem) => {
    if (elem[1] === '') {
      return false;
    }
    if (elem[0] === 'discord_id' || elem[0] === 'telegram_id') {
      return !(regExpObj[elem[0]].test(elem[1]) || elem[1].includes('/'));
    }
    if (elem[0] === 'twitter_id') {
      return !regExpObj[elem[0]].test(elem[1]) || elem[1].includes('/');
    }
    return !regExpObj[elem[0]].test(elem[1]);
  });
