import React from 'react';
import ReactSwitch from 'react-switch';
import styles from '../ModalSettings.module.scss';

const Switch = ({ text, checked, onChange }) => (
  <div className={styles.switch_wrapper}>
    <ReactSwitch
      checked={checked}
      onChange={onChange}
      checkedIcon={false}
      uncheckedIcon={false}
      height={16}
      width={28}
      handleDiameter={14}
      onColor="#109cf1"
      offColor="#adb5bd"
    />
    <div className={styles.switch_text}>{text}</div>
  </div>
);

export default Switch;
