import { useEffect, useState } from 'react';
import { audiencesApi } from '../../api/audiences';
import { flowsApi } from '../../api/flows';
import { formsApi } from '../../api/forms';

const useFolders = (id, type) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [folders, setFolders] = useState([]);

  const {
    data: segmentFolders,
    isLoading: isSegmentLoading,
    isFetching: isSegmentFetching,
    refetch: refetchSegmentFolders,
  } = audiencesApi.useGetSegmentFoldersByIdQuery(
    { id },
    { skip: type !== 'audiences' || id === undefined },
  );
  const {
    data: listFolders,
    isLoading: isListLoading,
    isFetching: isListFetching,
    refetch: refetchListFolders,
  } = audiencesApi.useGetCustomListFoldersByIdQuery(
    { id },
    { skip: type !== 'custom_lists' || id === undefined },
  );
  const {
    data: flowFolders,
    isLoading: isFlowLoading,
    isFetching: isFlowFetching,
    refetch: refetchFlowFolders,
  } = flowsApi.useGetFlowFoldersByIdQuery(
    { id },
    { skip: type !== 'flows' || id === undefined },
  );
  const {
    data: formFolders,
    isLoading: isFormLoading,
    isFetching: isFormFetching,
    refetch: refetchFormFolders,
  } = formsApi.useGetFormFoldersByIdQuery(
    { id },
    { skip: type !== 'forms' || id === undefined },
  );

  useEffect(() => {
    setFolders(segmentFolders || listFolders || flowFolders || formFolders || []);
  }, [segmentFolders, listFolders, flowFolders, formFolders]);

  useEffect(() => {
    setIsFetching(isSegmentFetching || isListFetching || isFlowFetching || isFormFetching);
  }, [isSegmentFetching, isListFetching, isFlowFetching, isFormFetching]);

  useEffect(() => {
    setIsLoading(isSegmentLoading || isListLoading || isFlowLoading || isFormLoading);
  }, [isSegmentLoading, isListLoading, isFlowLoading, isFormLoading]);

  const refetch = () => {
    if (id === undefined) return;

    switch (type) {
      case 'audiences': {
        refetchSegmentFolders();
        break;
      }
      case 'custom_lists': {
        refetchListFolders();
        break;
      }
      case 'flows': {
        refetchFlowFolders();
        break;
      }
      case 'forms': {
        refetchFormFolders();
        break;
      }
      default:
        break;
    }
  };

  return {
    isFetching, isLoading, folders, refetch,
  };
};

export default useFolders;
