import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Twitter } from '@absolutelabs/react-component-library';
import {
  selectCurrPage,
  selectFieldSettings,
  selectFormTextColor,
  selectInvertColours,
} from '../../../../../store/reducers/forms';

const TwitterField = ({ ...props }) => {
  const currPage = useSelector(selectCurrPage);
  const fieldSettings = useSelector(selectFieldSettings);
  const globalTextColor = useSelector(selectFormTextColor);
  const invertColors = useSelector(selectInvertColours);
  const [fieldConfig, setFieldConfig] = useState({});

  useEffect(() => {
    const currPageSettings = fieldSettings.find((o) => o.pageName === currPage);
    const config = currPageSettings?.fields?.find((x) => x.field_id === props?.field?.id);
    if (config) {
      setFieldConfig(config);
    }
  }, [fieldSettings, currPage, props?.field?.id]);

  return (
    <Twitter
      label={fieldConfig.label || ''}
      placeholder={fieldConfig.placeholder || ''}
      subLabel={fieldConfig.subLabel || ''}
      required={fieldConfig.required || false}
      editMode={false}
      textColor={globalTextColor}
      darkMode={invertColors}
    />
  );
};

export default TwitterField;
