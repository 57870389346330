import React from 'react';
import Papa from 'papaparse';
import Icon from '../../../assets/images/csv_upload.svg';
import styles from './FileInput.module.scss';
import { showErrorMessage } from '../../../components/base/Notifications';

const FileInput = ({ setFields, setFile }) => {
  const [dragActive, setDragActive] = React.useState(false);
  const inputRef = React.useRef(null);

  const handleFileData = (file) => {
    if (file?.type !== 'text/csv') {
      showErrorMessage('Unsupported file format');
      return;
    }
    Papa.parse(file, {
      // header: true,
      preview: 1,
      skipEmptyLines: true,
      complete(results) {
        setFields(results.data[0].filter((elem) => elem.toLowerCase() !== 'alid'));
      },
    });
    setFile(file);
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFileData(e.dataTransfer.files[0]);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFileData(e.target.files[0]);
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <form className={styles.form_file_upload} onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
      <label
        htmlFor="input-file-upload"
        className={`${styles.label_file_upload} ${dragActive ? styles.drag_active : ''}`}
      >
        <input
          id="input-file-upload"
          ref={inputRef}
          type="file"
          accept=".csv"
          className={styles.input_file_upload}
          multiple
          onChange={handleChange}
        />
        <div>
          <Icon />
          <p>Drag and drop a CSV file here </p>
          <p>or</p>
          <button
            type="button"
            className={styles.upload_button}
            onClick={onButtonClick}
          >
            Browse files
          </button>
        </div>
      </label>
      {dragActive
        && (
          <div
            className={styles.drag_file_element}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          />
        )}
    </form>
  );
};

export default FileInput;
