import { combineReducers, configureStore } from '@reduxjs/toolkit';

import userReducer from './reducers/user';
import coinReducer from './reducers/coin';
import pricesReducer from './reducers/price';
import flowsReducer from './reducers/flows';
import foldersReducer from './reducers/folders';
import formsReducer from './reducers/forms';
import customListReducer from './reducers/customList';
import csvUploadReducer from './reducers/csvUpload';
import segmentListReducer from './reducers/segmentList';
import searchReducer from './reducers/search';
import appReducer from './reducers/app';
import segmentReducer from './reducers/segment';
import web3Reducer from './reducers/web3';
import airdropReducer from './reducers/airdrop';
import contractsReducer from './reducers/contracts';
import webSDKReducer from './reducers/webSDK';

import { coinApi } from '../api/coin';
import { userApi } from '../api/user';
import { audiencesApi } from '../api/audiences';
import { contractsApi } from '../api/contracts';
import { profilesApi } from '../api/profiles';
import { flowsApi } from '../api/flows';
import { formsApi } from '../api/forms';
import { webSDKApi } from '../api/webSDK';
import { foldersApi } from '../api/folders';
import { blockchainApi } from '../api/blockchain';
import { utilsApi } from '../api/utils';

import { rtkQueryErrorLogger } from './middlewares/errorBoundary';

const rootReducer = combineReducers({
  [coinApi.reducerPath]: coinApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [audiencesApi.reducerPath]: audiencesApi.reducer,
  [profilesApi.reducerPath]: profilesApi.reducer,
  [flowsApi.reducerPath]: flowsApi.reducer,
  [formsApi.reducerPath]: formsApi.reducer,
  [contractsApi.reducerPath]: contractsApi.reducer,
  [webSDKApi.reducerPath]: webSDKApi.reducer,
  [foldersApi.reducerPath]: foldersApi.reducer,
  [blockchainApi.reducerPath]: blockchainApi.reducer,
  userReducer,
  coinReducer,
  pricesReducer,
  flowsReducer,
  foldersReducer,
  formsReducer,
  customListReducer,
  csvUploadReducer,
  searchReducer,
  segmentListReducer,
  segmentReducer,
  appReducer,
  web3Reducer,
  airdropReducer,
  contractsReducer,
  webSDKReducer,
});

export const setupStore = () => configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  })
    .concat(coinApi.middleware)
    .concat(userApi.middleware)
    .concat(audiencesApi.middleware)
    .concat(contractsApi.middleware)
    .concat(profilesApi.middleware)
    .concat(flowsApi.middleware)
    .concat(formsApi.middleware)
    .concat(contractsApi.middleware)
    .concat(webSDKApi.middleware)
    .concat(foldersApi.middleware)
    .concat(blockchainApi.middleware)
    .concat(utilsApi.middleware)
    .concat(rtkQueryErrorLogger),
});
