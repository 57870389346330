import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectFilterOption } from '../../../store/reducers/coin';
import { coinApi } from '../../../api/coin';
import ExchangesTable from '../../../components/ui/ExchangesTable';
import NotSupportedBlockchain from '../../../components/NotSupportedBlockchain';

const Exchanges = ({ isSupportedBlockchain }) => {
  const { name } = useParams();
  const { pathname } = useLocation();
  const [page, setPage] = useState(1);
  const [desc, setDesc] = useState(true);
  const [orderBy, setOrder] = useState('number_of_users_last_30d');
  const [limit, setLimit] = useState(25);
  const filterOption = useSelector(selectFilterOption);

  const {
    data: exchanges,
    isLoading: isExchangesLoading,
    isFetching: isExchangesFetching,
  } = coinApi.useGetTokenExchangeDataQuery({
    address: name,
    limit,
    order: desc ? 'desc' : 'asc',
    orderBy,
    offset: (page - 1) * limit,
    audience: filterOption,
  });

  const {
    currentData: audienceProfileData,
    isLoading: isAudienceProfileLoading,
    isFetching: isAudienceProfileFetching,
  } = coinApi.useGetTokenAudienceProfileQuery(
    {
      id: pathname.split('/')[2],
      audience: filterOption,
    },
    {
      skip: !pathname?.includes('coin'),
    },
  );

  return (
    isSupportedBlockchain ? (
      <ExchangesTable
        data={exchanges}
        totalUsers={audienceProfileData?.wallet_count}
        isLoading={isExchangesLoading || isExchangesFetching || isAudienceProfileLoading || isAudienceProfileFetching}
        setPage={setPage}
        setDesc={setDesc}
        setOrder={setOrder}
        setLimit={setLimit}
        page={page}
        limit={limit}
        desc={desc}
        orderBy={orderBy}
      />
    ) : <NotSupportedBlockchain />
  );
};

export default Exchanges;
