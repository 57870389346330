import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useWeb3Modal } from '@web3modal/react';
import {
  mainnet,
  polygon,
  sepolia,
} from 'wagmi/chains';
import styles from './AirDropSettings.module.scss';
import {
  clearActiveSettings,
  onSettingsChange,
  removeErrors,
  selectConfigsList, selectFlowStatus,
} from '../../../../../store/reducers/flows';
import TokensAirdrop from './TokensAirdrop';
import { getERC20Address, setERC20Address } from '../../../../../store/reducers/web3';
import {
  setAssetType,
  getAssetType, clearState,
} from '../../../../../store/reducers/airdrop';
import CustomAirdrop from './CustomAirdrop';
import { submitCustomAirdrop, submitTokenAirdrop, submitNftAirdrop } from '../../../../../utils/airdrop/submitUtils';
import { getUser } from '../../../../../store/reducers/user';
import NFTAirdrop from './NFTAirdrop';
import EditableTitle from '../../../../../components/base/EditableTitle';
import { blockChainOptionsWithTestnets } from '../../../../../components/base/ChainLogo/chains';
import {
  amoy, base, baseSepolia, linea, lineaSepolia, chiliz, chilizSpicy,
} from '../../../../../utils/web3/customChains.ts';

const AirDropSettings = ({ activeSettings }) => {
  const dispatch = useDispatch();

  const selectedContract = useSelector(getERC20Address);
  const assetType = useSelector(getAssetType);
  const user = useSelector(getUser);
  const configList = useSelector(selectConfigsList);
  const status = useSelector(selectFlowStatus);
  const { open, setDefaultChain } = useWeb3Modal();
  const [amountToAirdrop, setAmountToAirdrop] = useState();
  const [nftAmountToAirdrop, setNftAmountToAirdrop] = useState();
  const [titleValue, setTitleValue] = useState('');
  const [isAddress, setIsAddress] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedNftItem, setSelectedNftItem] = useState(null);
  const [selectedBlockchain, setSelectedBlockchain] = useState();
  const [contractAddress, setContractAddress] = useState();
  const [etherscanStatus, setEtherscanStatus] = useState();
  const [abi, setAbi] = useState();
  const [contractFunctions, setContractFunctions] = useState();
  const [selectedFunction, setSelectedFunction] = useState();
  const [selectedParameters, setSelectedParameters] = useState();
  const [isRetrieving, setIsRetrieving] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);

  const disableChangeStatus = useMemo(() => status === 'running'
    || status === 'scheduled' || status === 'stopped', [status]);

  const {
    register, handleSubmit, setValue, getValues, reset,
  } = useForm();

  const canSave = useMemo(() => {
    switch (assetType) {
      case 'custom':
        return (isAddress && !isRetrieving);
      case 'tokens':
        return Boolean(amountToAirdrop);
      case 'nft':
        return Boolean(nftAmountToAirdrop);
      default:
        return false;
    }
  }, [assetType, amountToAirdrop, nftAmountToAirdrop, isAddress]);

  const onSubmit = (submitData) => {
    let newObj = {};
    if (titleValue) {
      newObj.name = titleValue;
    } else {
      newObj.name = 'Airdrop';
    }
    if (assetType === 'custom') {
      newObj = submitCustomAirdrop(
        newObj,
        submitData,
        {
          selectedBlockchain,
          contractAddress,
          selectedFunction,
          assetType,
          etherscanStatus,
          abi,
          contractFunctions,
          selectedParameters,
        },
        user?.team,
      );
    }
    if (assetType === 'tokens') {
      newObj = submitTokenAirdrop(
        newObj,
        submitData,
        selectedItem,
        assetType,
        selectedContract,
        selectedBlockchain,
        user?.team,
      );
    }
    if (assetType === 'nft') {
      newObj = submitNftAirdrop(
        newObj,
        submitData,
        assetType,
        selectedNftItem,
        user.team,
      );
    }
    dispatch(removeErrors(activeSettings.node_id));
    dispatch(onSettingsChange({ ...newObj, node_id: activeSettings.node_id }));
    dispatch(clearState());
    dispatch(clearActiveSettings());
  };

  const openWeb3Modal = useCallback(async () => {
    switch (selectedBlockchain) {
      case 'homestead': setDefaultChain(mainnet); break;
      case 'sepolia': setDefaultChain(sepolia); break;
      case 'matic': setDefaultChain(polygon); break;
      case 'amoy': setDefaultChain(amoy); break;
      case 'base': setDefaultChain(base); break;
      case 'base-sepolia': setDefaultChain(baseSepolia); break;
      case 'linea': setDefaultChain(linea); break;
      case 'linea-sepolia': setDefaultChain(lineaSepolia); break;
      case 'chiliz': setDefaultChain(chiliz); break;
      case 'chiliz-spicy': setDefaultChain(chilizSpicy); break;
    }
    await open();
  }, [selectedBlockchain, open, setDefaultChain]);

  useEffect(() => {
    if (configList.length) {
      const index = configList.map((object) => object.node_id).indexOf(activeSettings.node_id);
      if (index >= 0) {
        if (configList[index].asset_type === 'tokens') {
          setSelectedItem({
            value: configList[index].assetName || configList[index].address,
            label: configList[index].asset_name
              || `Token at address
             ${configList[index].address.slice(0, 5)}...${configList[index].address.slice(-5)}`,
            image_url: configList[index].assetImg,
            id: configList[index].asset_id,
            blockchain: configList[index].blockchain,
            contract_address: configList[index].address,
          });
          setSelectedBlockchain(blockChainOptionsWithTestnets.find(
            (blockchain) => blockchain.value === configList[index]?.blockchain,
          )?.network);
          dispatch(setAssetType(configList[index].asset_type));
          setTitleValue(configList[index].name);
          dispatch(setERC20Address(configList[index].token_address));
          setValue('token_amount', configList[index].token_amount);
          setValue('token_decimals', configList[index].token_decimals);
          setValue('blockchain', configList[index].blockchain);
          setValue('address', configList[index].address);
          setValue('asset_name', configList[index].asset_name);
        }

        if (configList[index].asset_type === 'nft') {
          setSelectedNftItem({
            value: configList[index].assetName || configList[index].nft_address,
            label: configList[index].asset_name
              || `NFT at address
             ${configList[index].nft_address.slice(0, 5)}...${configList[index].nft_address.slice(-5)}`,
            image_url: configList[index].assetImg,
            id: configList[index].asset_id,
            blockchain: configList[index].nft_blockchain,
            testnet: configList[index]?.testnet || false,
            created_assets: configList[index]?.created_assets || false,
            verified: configList[index]?.verified || false,
          });
          setSelectedBlockchain(blockChainOptionsWithTestnets.find(
            (blockchain) => blockchain.value === configList[index]?.nft_blockchain,
          )?.network);
          dispatch(setAssetType(configList[index].asset_type));
          setTitleValue(configList[index].name);
          setValue('nft_amount', configList[index].nft_amount);
          setValue('nft_blockchain', configList[index].nft_blockchain);
          setValue('nft_address', configList[index].nft_address);
          setValue('source_of_nfts', configList[index].source_of_nfts);
          setValue('asset_name', configList[index].asset_name);
          setValue('testnet', configList[index]?.testnet || false);
          setValue('created_assets', configList[index]?.created_assets || false);
          setValue('verified', configList[index]?.verified || false);
        }

        if (configList[index].asset_type === 'custom') {
          dispatch(setAssetType(configList[index].asset_type));
          setSelectedBlockchain(blockChainOptionsWithTestnets.find(
            (blockchain) => blockchain.value === configList[index]?.blockchain,
          )?.network);
          setContractAddress(configList[index]?.data?.contract_address);
          setEtherscanStatus(configList[index]?.data?.etherscan_status);
          setAbi(configList[index]?.data?.abi);
          setContractFunctions(configList[index]?.data?.contract_functions);
          setSelectedFunction(configList[index]?.data?.selected_function);
          setSelectedParameters(configList[index]?.data?.selected_parameters);
        }
      } else {
        dispatch(clearState());
        setSelectedItem(null);
        setSelectedNftItem(null);
        setSelectedBlockchain(null);
        reset();
      }
    } else {
      dispatch(clearState());
      setSelectedItem(null);
      setSelectedNftItem(null);
      setSelectedBlockchain(null);
      setAmountToAirdrop(null);
      reset();
    }
  }, [activeSettings, configList, setValue, dispatch, reset]);

  useEffect(() => () => dispatch(clearState()), []);

  useEffect(() => {
    setAmountToAirdrop(getValues('token_amount'));
    setNftAmountToAirdrop(getValues('nft_amount'));
    setIsAddress(!!contractAddress);
  }, [getValues, contractAddress]);

  useEffect(() => {
    if (initialLoad && assetType !== null) {
      setInitialLoad(false);
      return;
    }
    if (assetType === 'custom' && !initialLoad) {
      setSelectedBlockchain(null);
    }
  }, [assetType]);

  const contentToRender = useMemo(() => {
    if (assetType === 'custom') {
      return (
        <CustomAirdrop
          selectedBlockchain={selectedBlockchain}
          setSelectedBlockchain={setSelectedBlockchain}
          contractAddress={contractAddress}
          setContractAddress={setContractAddress}
          etherscanStatus={etherscanStatus}
          setEtherscanStatus={setEtherscanStatus}
          abi={abi}
          setAbi={setAbi}
          activeSettings={activeSettings}
          contractFunctions={contractFunctions}
          setContractFunctions={setContractFunctions}
          selectedFunction={selectedFunction}
          setSelectedFunction={setSelectedFunction}
          selectedParameters={selectedParameters}
          setSelectedParameters={setSelectedParameters}
          disableChangeStatus={disableChangeStatus}
          setIsAddress={setIsAddress}
          isRetrieving={isRetrieving}
          setIsRetrieving={setIsRetrieving}
          setValue={setValue}
        />
      );
    }
    if (assetType === 'tokens') {
      return (
        <TokensAirdrop
          selectedBlockchain={selectedBlockchain}
          setSelectedBlockchain={setSelectedBlockchain}
          setAmountToAirdrop={setAmountToAirdrop}
          register={register}
          setSelectedItem={setSelectedItem}
          selectedItem={selectedItem}
          setValue={setValue}
          getValues={getValues}
          disableChangeStatus={disableChangeStatus}
          openWeb3Modal={openWeb3Modal}
        />
      );
    }
    if (assetType === 'nft') {
      return (
        <NFTAirdrop
          assetType="nft"
          selectedBlockchain={selectedBlockchain}
          setSelectedBlockchain={setSelectedBlockchain}
          setAmountToAirdrop={setNftAmountToAirdrop}
          register={register}
          setSelectedItem={setSelectedNftItem}
          selectedItem={selectedNftItem}
          setValue={setValue}
          getValues={getValues}
          disableChangeStatus={disableChangeStatus}
          openWeb3Modal={openWeb3Modal}
          nodeID={activeSettings.node_id}
        />
      );
    }
  }, [
    assetType,
    selectedBlockchain,
    contractAddress,
    etherscanStatus,
    abi,
    contractFunctions,
    selectedFunction,
    selectedParameters,
    register,
    selectedItem,
    selectedNftItem,
    setValue,
    getValues,
    disableChangeStatus,
    activeSettings,
    openWeb3Modal,
  ]);

  return (
    <div className={styles.wrapper}>
      <form
        onSubmit={
          handleSubmit(onSubmit)
        }
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
        className="d-flex flex-column justify-content-between h-100"
      >
        <div className={styles.scrollable_content}>
          <div>
            <div className={`${styles.title} d-flex align-items-center gap-2 mb-3`}>
              <EditableTitle
                defaultTitle="Airdrop"
                titleValue={titleValue}
                setTitleValue={setTitleValue}
                maxLength={25}
                size={16}
                edit={!disableChangeStatus}
              />
            </div>
            <div className={styles.type}>
              <div className={styles.type_title}>
                Asset type
              </div>
              <div className="d-flex justify-content-between">
                <button
                  type="button"
                  className={assetType === 'tokens' ? 'check-button-checked' : 'check-button'}
                  onClick={() => {
                    dispatch(setAssetType('tokens'));
                  }}
                  disabled={status === 'running' || status === 'scheduled'}
                >
                  Tokens
                </button>
                <div data-for="nftAssetTypeTooltip" data-tip>
                  <button
                    type="button"
                    className={assetType === 'nft' ? 'check-button-checked' : 'check-button'}
                    onClick={() => {
                      dispatch(setAssetType('nft'));
                    }}
                    disabled={status === 'running' || status === 'scheduled'}
                  >
                    NFTs
                  </button>
                </div>
                <button
                  type="button"
                  className={assetType === 'custom' ? 'check-button-checked' : 'check-button'}
                  onClick={() => dispatch(setAssetType('custom'))}
                  disabled={status === 'running' || status === 'scheduled'}
                >
                  Custom
                </button>
              </div>
            </div>
            {contentToRender}
          </div>
        </div>

        <div className="mt-4">
          <div className="d-flex gap-4">
            <button
              type="button"
              className={`${styles.btn_cancel}`}
              onClick={() => {
                dispatch(removeErrors(activeSettings.node_id));
                dispatch(clearState());
                dispatch(setAssetType(null));
                dispatch(clearActiveSettings());
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="regular-button w-100"
              disabled={!canSave || status === 'running' || status === 'scheduled' || isRetrieving}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AirDropSettings;
