import React from 'react';
import CloseModalButton from '../components/CloseModalButton';
import useKeydown from '../../../hooks/app/useKeydown';
import styles from './ForbiddenModal.module.scss';

const ForbiddenModal = ({
  onProceed,
  toLogin,
}) => {
  useKeydown('Escape', onProceed);
  return (
    <div
      className={`modal modal-dialog-centered ${styles.modal_wrapper}`}
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div
        role="presentation"
        className="modal-dialog modal-md modal-phone w-100 position-relative"
      >
        <CloseModalButton onCancel={onProceed} />
        <div className="modal-content p-3">
          <div className={`${styles.title} px-3 mt-2 d-flex justify-content-start`}>
            We are sorry...
          </div>
          <div className="text-md-regular px-3 py-2">
            {`The page you’re trying to use has restricted access. Please reach out to your Customer Success Manager.
           ${toLogin ? 'You will be log out.' : ''}`}
          </div>
          <div className="d-flex justify-content-end align-items-center px-3 mt-3 mb-3">
            <button className="regular-button" type="button" onClick={() => { onProceed(); }}>Okay</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForbiddenModal;
