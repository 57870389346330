import React from 'react';

import {
  preventInvalidSymbols, validateInput, onPaste, onPasteFloat,
} from '../../../../utils/segments';
import styles from './Input.module.scss';

const FloatNumberInput = ({
  option, edit, setDebouncedValue, setInputValue, inputValue,
}) => {
  const {
    value, name, label,
  } = option;

  return (
    <input
      onWheel={(e) => e.target.blur()}
      min="0"
      key={label}
      type="number"
      step="0.001"
      placeholder="Input a number"
      onPaste={label.includes('Floating') ? onPasteFloat : onPaste}
      onKeyPress={(e) => preventInvalidSymbols(e, true)}
      value={inputValue !== null ? inputValue : Array.isArray(value) ? value[0].value : ''}
      className={`form-control ${styles.select} w-100`}
      onChange={(e) => {
        const ifValidValue = validateInput(e.target.value, name === 'transactions', true);
        if (!ifValidValue) {
          return;
        }
        setInputValue(!e.target.value ? '' : e.target.value);
        setDebouncedValue({ value: e.target.value === '' ? null : e.target.value, name });
      }}
      disabled={!edit}
    />
  );
};

export default FloatNumberInput;
