export const formatCategory = (category) => {
  switch (category) {
    case 'app_super_admin':
      return 'App super admin';
    case 'al_team_super_admin':
      return 'AL Team Super Admin';
    case 'al_team_admin':
      return 'AL Team Admin';
    case 'team_admin':
      return 'Admin';
    case 'team_member':
      return 'Member';
  }
  return 'Error';
};
