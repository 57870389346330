import { ethers } from 'ethers';

export const toBN = (val) => ethers.BigNumber.from(val);

export const fromBN = (val) => Number(val.toString());

export const isValidContractAddress = (val) => {
  const regex = /^0x[a-fA-F0-9]{40}$/;
  return regex.test(val);
};
