import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCurrPage,
  selectFieldSettings,
  setFieldSettings,
} from '../../../../../../store/reducers/forms';
import { styleOptions, alignmentOptions } from './options';
import ModalSettings from '../../../Components/ModalSettings';
import SelectionButtons from '../../../../../../components/ui/SelectionButtons';
import styles from './ButtonSettings.module.scss';
import settingsStyles from '../../../Components/ModalSettings/ModalSettings.module.scss';
import Input from '../../../Components/ModalSettings/Input';

const ButtonSettings = ({ activeSettings }) => {
  const dispatch = useDispatch();
  const initialized = useRef(false);
  const currPage = useSelector(selectCurrPage);
  const fieldSettings = useSelector(selectFieldSettings);
  const [text, setText] = useState('Button');
  const [link, setLink] = useState('');
  const [style, setStyle] = useState(styleOptions[0].value);
  const [align, setAlign] = useState(alignmentOptions[2].value);
  const [change, setChange] = useState(false);
  const [errors, setErrors] = useState({});

  const handleChange = useCallback(() => {
    setChange(false);
    const obj = {
      name: 'Button',
      type: 'button',
      text,
      link,
      style,
      align: align === 'full' ? 'center' : align,
      fullWidth: align === 'full',
    };
    dispatch(setFieldSettings({ ...obj, field_id: activeSettings.field_id }));
  }, [text, link, style, align, dispatch, activeSettings]);

  const setOptions = (config) => {
    setText(config.text);
    setLink(config.link);
    setStyle(config.style);
    setAlign(config.fullWidth ? 'full' : config.align);
  };

  useEffect(() => {
    if (initialized.current) return;
    const currPageSettings = fieldSettings.find((o) => o.pageName === currPage);
    if (currPageSettings) {
      const config = currPageSettings.fields?.find((x) => x.field_id === activeSettings.field_id);
      if (config) {
        setOptions(config);
      } else {
        handleChange();
      }
    }
    initialized.current = true;
  }, [activeSettings, currPage, fieldSettings]);

  useEffect(() => {
    if (change) {
      handleChange();
    }
  }, [text, link, style, align]);

  useEffect(() => {
    const newErrors = { ...errors };
    if (text.length === 30) {
      newErrors.text = 'Character limit reached';
    } else {
      newErrors.text = '';
    }
    if (link.length === 200) {
      newErrors.link = 'Character limit reached';
    } else {
      newErrors.link = '';
    }
    setErrors(newErrors);
  }, [text, link]);

  useEffect(() => {
    // Reset state when activeSettings changes
    const currPageSettings = fieldSettings.find((o) => o.pageName === currPage);
    if (currPageSettings) {
      const config = currPageSettings.fields?.find((x) => x.field_id === activeSettings.field_id);
      if (config) {
        setText(config.text || 'Button');
        setLink(config.link || '');
        setStyle(config.style || styleOptions[0].value);
        setAlign(config.fullWidth ? 'full' : config.align || alignmentOptions[2].value);
      } else {
        setText('Button');
        setLink('');
        setStyle(styleOptions[0].value);
        setAlign(alignmentOptions[2].value);
        setChange(true);
      }
    }
  }, [activeSettings]);

  return (
    <ModalSettings title="Button Properties">
      <div className={styles.wrapper}>
        <div className={settingsStyles.title}>Style</div>
        <div className={styles.button_wrapper}>
          <SelectionButtons
            buttons={styleOptions}
            selectedValue={style}
            setSelectedValue={(value) => {
              setStyle(value);
              setChange(true);
            }}
            width={84}
          />
          <SelectionButtons
            buttons={alignmentOptions}
            selectedValue={align}
            setSelectedValue={(value) => {
              setAlign(value);
              setChange(true);
            }}
            width={53}
          />
        </div>
      </div>

      <Input
        title="Label"
        value={text}
        placeholder="Type here"
        onChange={(e) => {
          setText(e.currentTarget.value);
          setChange(true);
        }}
        maxLength={30}
        error={errors.text}
      />

      <Input
        title="Link"
        value={link}
        placeholder="Type in URL"
        onChange={(e) => {
          setLink(e.currentTarget.value);
          setChange(true);
        }}
        maxLength={200}
        error={errors.link}
      />
    </ModalSettings>
  );
};

export default ButtonSettings;
