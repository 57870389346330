import React, {
  useMemo, useState, useEffect, useCallback,
} from 'react';
import AssetsCard from './AssetsCard';
import uniqueId from '../../../utils/uniqueId';
import styles from './YourAssets.module.scss';
import { ControlledList } from '../../../components/ui/modals/SearchModal/LoadingList';

const YourAssets = ({
  isAssetsLoading,
  isAssetsFetching,
  isInvestmentsFetching,
  isInvestmentsLoading,
  assetsData,
  investments,
  portfolioValue,
  title,
}) => {
  const [holdsAddressInAssets, setHoldsAddressInAssets] = useState(0);
  const dataArray = useCallback((assetData, invest) => {
    if (!assetData || !invest) return [];
    const watchAssets = [];
    const filterByPlatform = [];
    assetData.forEach((elem) => {
      invest.forEach((item) => {
        if (elem.asset_type === 'nft' && elem.address === item?.addresses[0]
            && elem.network === item?.blockchains[0] && item.name === elem.name) {
          const itemWithPlatforms = { ...item, platform: item.blockchains };
          watchAssets.push(itemWithPlatforms);
        }
        if (elem.asset_type === 'coin' && elem.coin_id === item.coin_id && elem.network === item.network) {
          const platforms = assetData.find((asset) => asset.coin_id === item.coin_id
        && asset.network === item.network)?.platforms || [];
          const itemWithPlatforms = { ...item, platform: Object.keys(platforms) };
          watchAssets.push(itemWithPlatforms);
        }
      });
    });
    const filterName = [...new Set([...watchAssets]
      .map((elemAsset) => (elemAsset.asset_type === 'token' ? elemAsset.coin_id : elemAsset.address)))];
    watchAssets.forEach((elem) => {
      filterName.forEach((name) => {
        if ((elem.asset_type === 'nft' && elem.address === name)
            || (elem.asset_type === 'token' && elem.coin_id === name)) {
          const filterNameArray = filterByPlatform.filter((itm) => (itm.coin_id === name));
          if (!filterNameArray.length) {
            return filterByPlatform.push({
              ...elem,
              platform: elem.platform,
              assets_on_eth: elem?.platform?.includes('eth') ? elem.token_qty_decimal : null,
              assets_on_pol: elem?.platform?.includes('polygon') ? elem.token_qty_decimal : null,
            });
          }
          return filterByPlatform.forEach((asset) => {
            if ((asset.asset_type === 'nft' && asset.address === elem.address)
                || (asset.asset_type === 'token' && asset.coin_id === elem.coin_id)) {
              asset.platform.push(elem.platform);
              asset.value = asset.value === null
                ? elem.value
                : elem.value === null
                  ? asset.value
                  : asset.value + elem.value;
              asset.token_qty_decimal = asset.token_qty_decimal === null
                ? elem.token_qty_decimal
                : elem.token_qty_decimal === null
                  ? asset.token_qty_decimal
                  : asset.token_qty_decimal + elem.token_qty_decimal;
              if (elem.platform.includes('eth')) {
                asset.assets_on_eth = elem.token_qty_decimal === null
                  ? asset.assets_on_eth
                  : elem.token_qty_decimal;
              }
              if (elem.platform.includes('polygon')) {
                asset.assets_on_pol = elem.token_qty_decimal === null
                  ? asset.assets_on_pol
                  : elem.token_qty_decimal;
              }
            }
          });
        }
      });
    });
    return filterByPlatform;
  }, [investments]);

  const usingWalletAddressesInAssets = useMemo(() => dataArray(assetsData, investments), [assetsData, investments]);

  const totalShareOfPortfolios = useMemo(() => {
    if (!portfolioValue) {
      return '';
    }
    const totalShare = parseFloat(((usingWalletAddressesInAssets
      .reduce((acc, item) => acc + item.value, 0)) / portfolioValue) * 100);
    if (!totalShare) {
      return '';
    }
    return totalShare < 1 ? '<1%' : `${Math.floor(+totalShare.toFixed(2) * 100) / 100}%`;
  }, [usingWalletAddressesInAssets, portfolioValue]);

  useEffect(() => {
    if (usingWalletAddressesInAssets && usingWalletAddressesInAssets?.length) {
      setHoldsAddressInAssets(usingWalletAddressesInAssets.length);
    }
  }, [usingWalletAddressesInAssets]);

  return (
    <>
      <div className={`${styles.title} d-flex justify-content-between flex-column flex-sm-row gap-2`}>
        <div className="d-flex flex-column flex-sm-row w-100 align-items-baseline gap-2">
          <div className="d-flex">
            <span>{title}</span>
          </div>
          <div className="d-flex">
            <span className={`${styles.assets_info}`}>
              {`(Holds ${holdsAddressInAssets} out of your 
              ${assetsData?.length ?? 0} 
              asset${assetsData?.length > 1 ? 's' : ''})`}
            </span>
          </div>
        </div>
        <div className="d-flex justify-content-start justify-content-sm-end w-100">
          {totalShareOfPortfolios ? (
            <div className={`${styles.total_share} d-flex align-items-center justify-content-center text-nowrap gap-1`}>
              Total Share of Portfolio:
              <span className={styles.total_share_value}>
                {totalShareOfPortfolios}
              </span>
            </div>
          ) : null}
        </div>
      </div>
      {isAssetsLoading || isInvestmentsLoading || isAssetsFetching || isInvestmentsFetching ? (
        <div className={`${styles.wrapper_asset} d-flex w-50`}>
          <ControlledList />
        </div>
      ) : usingWalletAddressesInAssets.length ? (
        <div className={`${styles.content} d-flex flex-column gap-2`}>
          {usingWalletAddressesInAssets.map((elem, index) => (
            elem && portfolioValue >= 0 ? (
              <div key={uniqueId('assets-wallet')}>
                <AssetsCard data={elem} portfolioValue={portfolioValue} />
                {usingWalletAddressesInAssets.length - 1 !== index && (
                  <div className={`${styles.divider} w-100 mt-2`} />
                )}
              </div>
            ) : null
          ))}
        </div>
      ) : (
        <div className={styles.no_data_asset}>
          {title.includes('Watch')
            ? 'This profile does not own any asset you watch'
            : 'This profile does not own any of your assets'}
        </div>
      )}
    </>
  );
};

export default React.memo(YourAssets);
