import React from 'react';
import styles from './nameFieldDetails.module.scss';

const NameFieldDetails = ({ creator, updatedAt }) => (
  <div className={styles.wrapper}>
    <span className={styles.label}>Created by </span>
    <span className={styles.value}>{creator}</span>
    {updatedAt
      ? (
        <>
          <span className={styles.label}> | </span>
          <span className={styles.label}>Updated </span>
          <span className={styles.value}>{updatedAt}</span>
        </>
      )
      : null}
  </div>
);

export default NameFieldDetails;
