import React, { useEffect, useState } from 'react';
import AssetImg from '../../../../components/ui/NotableInvestments/AssetImg';
import Table from '../../../../components/base/Table';
import { counter } from '../../../../tools/Counter';
import styles from './ExchangesTable.module.scss';
import AgeDate from '../../../../components/ui/Table/AgeDate';

const ExchangesTable = ({
  exchanges, isLoading, page, setPage, setDesc, limit, setLimit,
  desc, order, setOrder,
}) => {
  const [tableData, setTableData] = useState([]);

  const header = [
    {
      field: '',
      title: '#',
      width: '32px',
    },
    {
      field: '',
      title: 'Name',
      width: '400px',
    },
    {
      field: 'first_txn_timestamp',
      title: 'First Transaction',
    },
    {
      field: 'last_txn_timestamp',
      title: 'Last Transaction',
    },
    {
      field: 'cex_txn_count',
      title: 'Number of Transactions',
    },
  ];

  useEffect(() => {
    if (exchanges) {
      const temp = exchanges.map((elem, index) => ({
        pageNumber: (
          <span className={styles.number}>{(page - 1) * limit + index + 1}</span>
        ),
        name: (
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex gap-2 align-items-center">
              <AssetImg logo={elem.image_url} type="exchange" text="exchange" />
              <span
                role="presentation"
                className={`${styles.content_name} text-truncate`}
              >
                {elem.exchange_name}
              </span>
            </div>
          </div>
        ),
        first_txn_timestamp: <AgeDate date={elem.first_txn_timestamp} defaultText="No Transactions" />,
        last_txn_timestamp: <AgeDate date={elem.last_txn_timestamp} defaultText="No Transactions" />,
        no_of_transactions: elem.cex_txn_count
        ,
      }));
      setTableData(temp);
    }
  }, [exchanges]);
  return (
    <div className={`${styles.wrapper} w-100`}>
      <div className="d-flex flex-column align-self-start">
        <div className={styles.title}>Exchanges</div>
        <div className="d-flex w-100 justify-content-between align-items-center mb-4">
          <div className={styles.content_info}>
            {counter(exchanges?.length, page, limit)}
          </div>
        </div>
      </div>
      <Table
        total={exchanges?.length}
        data={tableData}
        desc={desc}
        setDesc={setDesc}
        ordering={order}
        setOrdering={setOrder}
        page={page}
        setPage={setPage}
        limit={limit}
        setLimit={setLimit}
        headers={header}
        isLoading={isLoading}
        min={25}
      />
    </div>
  );
};

export default ExchangesTable;
