import React from 'react';
import AlignLeft from '../../../../../../assets/icons/forms/align_button_left_raw.svg';
import AlignCenter from '../../../../../../assets/icons/forms/align_button_center_raw.svg';
import AlignFull from '../../../../../../assets/icons/forms/align_button_full_raw.svg';
import AlignRight from '../../../../../../assets/icons/forms/align_button_right_raw.svg';
import styles from './ButtonSettings.module.scss';

export const styleOptions = [
  {
    value: 'filled',
    elem: <span className={styles.style_button}>Filled</span>,
  },
  {
    value: 'outlined',
    elem: <span className={styles.style_button}>Outlined</span>,
  },
];

export const alignmentOptions = [
  {
    value: 'left',
    elem: <AlignLeft />,
  },
  {
    value: 'center',
    elem: <AlignCenter />,
  },
  {
    value: 'full',
    elem: <AlignFull />,
  },
  {
    value: 'right',
    elem: <AlignRight />,
  },
];
