import React from 'react';
import styles from './SegmentsLabel.module.scss';
import { convertNumber } from '../../../../../tools/NumberConverterTool';

const SegmentsLabel = ({ val }) => (
  <div className={`${styles.wrapper} d-flex justify-content-between cursor-pointer`}>
    <div>
      {val.label}
    </div>
    {!val.head
      ? (
        <div className={styles.population}>
          {convertNumber(val.population)}
        </div>
      )
      : null}
  </div>
);

export default SegmentsLabel;
