import React from 'react';

import Input from '../../../../components/base/Input';
import Select from '../../../../components/base/Select';
import styles from './AddModal.module.scss';
import useKeydown from '../../../../components/hooks/app/useKeydown';
import CloseModalButton from '../../../../components/ui/modals/components/CloseModalButton';

const options = [
  { label: 'Select', value: '' },
  { label: 'Member', value: 'team_member' },
  { label: 'Admin', value: 'team_admin' },
];

const AddModal = ({
  setShowModal,
  onInviteMember,
  registerAdd,
  handleSumbitAdd,
  addFormState,
}) => {
  useKeydown('Escape', () => setShowModal(false));
  return (
    <div
      className={`modal modal-dialog-centered ${styles.modalWrapper}`}
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      onMouseDown={() => {
        setShowModal(false);
      }}
    >
      <div
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
        role="presentation"
        className={`modal-dialog modal-md modal-phone ${styles.modalWidth}`}
      >
        <div className="modal-content">
          <CloseModalButton onCancel={() => setShowModal(false)} />
          <form
            className={`d-flex flex-column justify-content-center mt-4 ${styles.modalBodyWrapper}`}
            onSubmit={handleSumbitAdd(onInviteMember)}
          >
            <div className={styles.modalTitle}>New team member creation</div>
            <Input
              name="first_name"
              label="First name*"
              validation={{ required: 'This is a required field' }}
              register={registerAdd}
            />
            {addFormState && addFormState.errors.first_name && (
              <div className={styles.errorMessage}>
                {addFormState.errors.first_name.message}
              </div>
            )}
            <Input
              name="last_name"
              label="Last name*"
              validation={{ required: 'This is a required field' }}
              register={registerAdd}
            />
            {addFormState && addFormState.errors.last_name && (
              <div className={styles.errorMessage}>
                {addFormState.errors.last_name.message}
              </div>
            )}
            <Select
              name="category"
              label="Role*"
              options={options}
              register={registerAdd}
              validation={{
                required: 'Should be selected',
              }}
            />
            {addFormState && addFormState.errors.category && (
              <div className={styles.errorMessageSelect}>
                {addFormState.errors.category.message}
              </div>
            )}
            <Input
              label="Email address*"
              name="email"
              validation={{
                required: 'This is a required field',
                pattern: { value: /^\S+@\S+$/i, message: 'Should be email' },
              }}
              register={registerAdd}
            />
            {addFormState && addFormState.errors.email && (
              <div className={styles.errorMessage}>
                {addFormState.errors.email.message}
              </div>
            )}
            <button type="submit" className={`btn btn-primary ${styles.button}`}>
              <span className={styles.buttonText}>Create</span>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddModal;
