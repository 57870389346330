import React, { useEffect, useState } from 'react';
import { useWeb3Modal } from '@web3modal/react';
import { useAccount, useSigner } from 'wagmi';
import styles from '../Settings.module.scss';
import { showErrorMessage, showSuccessMessage } from '../../../components/base/Notifications';
import { userApi } from '../../../api/user';
import Loader from '../../../components/base/Loader';
import Warning from '../../../assets/icons/warning_rounded.svg';
import ConfirmModal from '../../../components/ui/modals/ConfirmModal';
import { getBrowserName } from '../../../utils/getBrowserName';

const XMTPSettings = ({ XMTPkey, isLoading, walletAddress }) => {
  const [isXMTPKeyExist, setIsXMTPKeyExist] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [walletAddressToShow, setWalletAddressToShow] = useState(null);
  const { open } = useWeb3Modal();
  const { address, isConnected } = useAccount();
  const { data: signer } = useSigner();

  const [signXMTP, signXMTPResult] = userApi.usePostXMTPKeyMutation();
  const [deleteXMTP, deleteXMTPResult] = userApi.useDeleteXMTPKeyMutation();

  const browser = getBrowserName();

  const openWeb3Modal = (async () => {
    await open();
  });

  const initXmtp = async (Client) => {
    const keys = await Client.getKeys(signer, { env: 'production' });
    const xmtp = await Client.create(null, { privateKeyOverride: keys, env: 'production' });

    if (xmtp && keys) {
      signXMTP({
        xmtp_key: btoa(keys),
        wallet_address: address,
      });
    }
  };

  const chooseDescription = () => {
    if (browser === 'safari') {
      return (
        <div className={`${styles.warning} d-flex align-items-center gap-2`}>
          <Warning />
          XMTP block settings are not available in Safari.
          To set up the XMTP block, please use Chrome, Firefox, or Brave
        </div>
      );
    }
    if (!isXMTPKeyExist) {
      if (isConnected) {
        return (
          <div className={styles.key}>
            You have to sign XMTP identity in order to use it as a flow block
          </div>
        );
      }
      return (
        <div className={`${styles.no_key} justify-content-start`}>
          No wallet connected
        </div>
      );
    }
    return (
      <div className={`${styles.key} justify-content-start`}>
        You are ready to send XMTP messages from wallet address&nbsp;
        <span className={styles.wallet}>
          {`${walletAddressToShow.slice(0, 6)}...${walletAddressToShow.slice(-4)}`}
        </span>
      </div>
    );
  };

  const chooseXMTPButton = () => {
    if (!isXMTPKeyExist) {
      if (isConnected) {
        return (
          <button
            type="button"
            className="regular-button mx-auto"
            onClick={() => {
              import('@xmtp/xmtp-js').then(({ Client }) => {
                initXmtp(Client);
              });
            }}
            disabled={signXMTPResult.isLoading || signXMTPResult.isFetching}
          >
            Sign XMTP Identity
          </button>
        );
      }
      return (
        <button
          type="button"
          className="regular-button mx-auto"
          onClick={async () => openWeb3Modal()}
        >
          Connect Wallet
        </button>
      );
    }
    return (
      <button
        type="button"
        className="regular-button mx-auto"
        onClick={() => setShowModal(true)}
        disabled={deleteXMTPResult.isLoading || deleteXMTPResult.isFetching}
      >
        Delete Signature
      </button>
    );
  };

  useEffect(() => {
    if (XMTPkey) {
      setIsXMTPKeyExist(true);
      setWalletAddressToShow(walletAddress);
    }
  }, [XMTPkey, walletAddress]);

  useEffect(() => {
    if (signXMTPResult.isSuccess && signXMTPResult.data.message === 'success') {
      showSuccessMessage('XMTP key added');
      setIsXMTPKeyExist(true);
      setWalletAddressToShow(address);
      signXMTPResult.reset();
    }
    if (signXMTPResult.isError) {
      showErrorMessage('Something went wrong');
      signXMTPResult.reset();
    }
  }, [signXMTPResult]);

  useEffect(() => {
    if (deleteXMTPResult.isSuccess) {
      showSuccessMessage('XMTP key deleted');
      setIsXMTPKeyExist(false);
      setWalletAddressToShow(null);
      deleteXMTPResult.reset();
    }
    if (deleteXMTPResult.isError) {
      showErrorMessage('Something went wrong');
      deleteXMTPResult.reset();
    }
  }, [deleteXMTPResult]);

  return (
    <div className={`${styles.wrapper} mt-4`}>
      {showModal && (
        <ConfirmModal
          onSubmit={() => {
            deleteXMTP();
            setShowModal(false);
          }}
          onCancel={() => setShowModal(false)}
          buttonName="Delete"
          title="Delete Signature"
          description="Delete the signature might break the running
          flow using the XMTP block with the current wallet connected"
        />
      )}
      <div className={styles.header}>
        XMTP
      </div>
      {isLoading
        ? (
          <div className="py-5">
            <Loader />
          </div>
        )
        : (
          <div className={`${styles.labs_section} d-flex flex-column`}>
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-column gap-1">
                <span>
                  XMTP
                </span>
                <span className={styles.key_info}>
                  The XMTP block allows you to send messages to wallets with which you want to engage.
                </span>
              </div>
              {browser === 'chrome' || browser === 'firefox'
                ? (
                  <div className="d-flex align-items-center">
                    {chooseXMTPButton()}
                  </div>
                )
                : null}
            </div>
            {chooseDescription()}
          </div>
        )}
    </div>
  );
};

export default XMTPSettings;
