import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeSettings: null,
  config: [],
  name: '',
  errors: [],
  status: '',
  isUnsaved: false,
  senderEmail: false,
  sendGridApiKey: true,
  isClosedSend: true,
  randomSplitValue: {},
  XMTPKey: false,
  teamBalance: -1,
};

export const flowsSlice = createSlice({
  name: 'flows',
  initialState,
  reducers: {
    setFlowName: (state, action) => {
      state.name = action.payload;
    },
    setFlowStatus: (state, action) => {
      state.status = action.payload;
    },
    setFlowStatusList: (state, action) => {
      state.statusList = action.payload;
    },
    setRandomSplitValue: (state, action) => {
      state.randomSplitValue = action.payload;
    },
    onSettingsChange: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.config = action.payload;
      } else {
        const index = state.config.findIndex((object) => object?.node_id === action.payload.node_id);
        if (index !== -1) {
          state.config[index] = action.payload;
        } else {
          state.config.push(action.payload);
        }
      }
    },
    deleteSettings: (state, action) => {
      state.config = state.config.filter((elem) => elem.node_id !== action.payload);
    },
    setActiveSettings: (state, action) => {
      state.activeSettings = action.payload;
    },
    clearActiveSettings: (state) => {
      state.activeSettings = null;
    },
    setErrors: (state, action) => {
      state.errors = action.payload;
    },
    isUnsavedFlow: (state, action) => {
      state.isUnsaved = action.payload;
    },
    removeErrors: (state, action) => {
      state.errors = state.errors.filter((elem) => elem.node_id !== action.payload);
    },
    setSendGridApiKey: (state, action) => {
      state.sendGridApiKey = action.payload;
    },
    setSenderEmailSetUp: (state, action) => {
      state.senderEmail = action.payload;
    },
    setXMTPKey: (state, action) => {
      state.XMTPKey = action.payload;
    },
    isCloseSendModal: (state, action) => {
      state.isClosedSend = action.payload;
    },
    clearFlowState: () => initialState,
    setTeamBalanceReducer: (state, action) => {
      state.teamBalance = action.payload;
    },
  },
});

export const {
  setActiveSettings,
  clearActiveSettings,
  onSettingsChange,
  setFlowName,
  deleteSettings,
  setErrors,
  removeErrors,
  setFlowStatus,
  setFlowStatusList,
  clearFlowState,
  setSendGridApiKey,
  setSenderEmailSetUp,
  isCloseSendModal,
  setRandomSplitValue,
  setXMTPKey,
  setTeamBalanceReducer,
} = flowsSlice.actions;

export const selectUserFlowName = (state) => state.flowsReducer.name;
export const selectActiveSettings = (state) => state.flowsReducer.activeSettings;
export const selectConfigsList = (state) => state.flowsReducer.config;
export const selectErrors = (state) => state.flowsReducer.errors;
export const selectFlowStatus = (state) => state.flowsReducer.status;
export const selectFlowStatusList = (state) => state.flowsReducer.statusList;
export const selectSendGridApiKey = (state) => state.flowsReducer.sendGridApiKey;
export const selectSenderEmail = (state) => state.flowsReducer.senderEmail;
export const selectIsCloseSendModal = (state) => state.flowsReducer.isClosedSend;
export const selectRandomSplitValue = (state) => state.flowsReducer.randomSplitValue;
export const selectXMTPKey = (state) => state.flowsReducer.XMTPKey;
export const selectTeamBalanceReducer = (state) => state.flowsReducer.teamBalance;
export default flowsSlice.reducer;
