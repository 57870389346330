import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Trash from '../../../assets/icons/trash.svg';
import styles from './CustomListTable.module.scss';
import { audiencesApi } from '../../../api/audiences';
import { showErrorMessage, showSuccessMessage } from '../../../components/base/Notifications';
import { counter } from '../../../tools/Counter';
import Table from '../../../components/base/Table';
import tableStyles from '../../../components/base/Table/Table.module.scss';
import ConfirmModal from '../../../components/ui/modals/ConfirmModal';
import { aliasData, aliasTooltipInfo } from '../../../utils/wallets';
import ItemPreview from '../../../components/ui/ItemPreview';
import { setSelectedCustomList } from '../../../store/reducers/csvUpload';
import EditableTitle from '../../../components/base/EditableTitle';
import Tooltip from '../../../components/ui/Tooltip';

const CustomListTable = ({
  data, count, refetch, desc, setDesc, page, setPage, id, isLoading, limit, setLimit,
  orderBy, setOrderBy, name,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [clickedAddress, setClickedAddress] = useState('');
  const [tableData, setTableData] = useState([]);
  const [titleValue, setTitleValue] = useState(name);

  const [deleteAddress, deleteAddressResult] = audiencesApi.useDeleteWalletAddressFromCustomListMutation();
  const [updateCustomList, updateCustomListResult] = audiencesApi.useUpdateCustomListMutation();

  const truncateString = (str) => (str.length > 50 ? `${str.substring(0, 50)}...` : str);

  const header = [
    {
      field: 'profile',
      title: 'Profile',
      width: '550px',
    },
    {
      field: '',
      title: 'Alias',
    },
    {
      title: (
        <div className="d-flex justify-content-center">
          Action
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (deleteAddressResult.isSuccess) {
      showSuccessMessage('Profile was successfully deleted');
      refetch();
      setClickedAddress('');
      setTableData([]);
      deleteAddressResult.reset();
      if (Array.isArray(data) && data.length === 1 && page > 1) {
        setPage(page - 1);
      } else {
        refetch();
      }
      setClickedAddress('');
      deleteAddressResult.reset();
    }
    if (deleteAddressResult.isError) {
      showErrorMessage(
        Object.keys(deleteAddressResult.error.data).length !== 0
            && deleteAddressResult.error.data.detail,
      );
      setClickedAddress('');
      deleteAddressResult.reset();
    }
  }, [data, deleteAddressResult, page, refetch, setPage]);

  useEffect(() => {
    if (Array.isArray(data)) {
      const temp = data.map((elem, index) => ({
        profile: (
          <>
            <span
              className={`d-block ${tableStyles.underline_on_hover}`}
              data-for={`Name_${elem.ALID || elem.profile}`}
              data-tip="show"
            >
              <Link
                role="presentation"
                to={`/profile/${elem.ALID || elem.profile}`}
                className={`${tableStyles.name_list} text-truncate`}
              >
                {truncateString(elem?.profile_name || elem.profile || elem.ALID)}
              </Link>
            </span>
            <ItemPreview
              id={`Name_${elem.ALID || elem.profile}`}
              data={{
                id: elem.profile,
                name: elem.profile || elem.ALID,
                type: 'wallet',
                alid: elem.ALID || '',
              }}
            />
          </>
        ),
        alias: (
          elem.alias
            ? (
              <span className="d-block">
                <div
                  className="d-flex gap-2 align-items-baseline"
                >
                  <span
                    data-for={`alias ${index}`}
                    data-tip
                  >
                    {aliasData[elem.alias_type]}
                  </span>
                  <Tooltip info={aliasTooltipInfo[elem.alias_type]} id={`alias ${index}`} place="top" />
                  <span>{elem.alias}</span>
                </div>
              </span>
            )
            : '-'
        ),
        button: (
          <div className="d-flex gap-3 justify-content-center align-items-center">
            <Trash
              className="cursor-pointer"
              onClick={() => {
                setClickedAddress({
                  profile: elem.profile,
                  id,
                  alid: elem.ALID,
                });
              }}
            />
          </div>
        ),
      }));
      setTableData(temp);
    } else {
      setTableData([]);
    }
  }, [data]);

  useEffect(() => {
    if (updateCustomListResult.isSuccess) {
      showSuccessMessage('Title name was saved');
      updateCustomListResult.reset();
    }
    if (updateCustomListResult.isError) {
      showErrorMessage(updateCustomListResult?.error?.data?.name[0] || 'Something went wrong');
      updateCustomListResult.reset();
    }
  }, [updateCustomListResult]);

  return (
    <>
      <div
        className={`d-flex justify-content-between align-items-center ${styles.responsive}`}
      >
        <EditableTitle
          defaultTitle="Add the list name"
          titleValue={titleValue}
          setTitleValue={setTitleValue}
          onSave={() => updateCustomList({ id, body: { name: titleValue } })}
          edit
          triggerNotification={false}
        />
        <button
          className="outline-blue-button mb-0 mb-lg-3"
          type="button"
          onClick={() => {
            dispatch(setSelectedCustomList({ name, id }));
            navigate('/csv-upload', { state: { importTitle: `Import profiles to ${titleValue}` } });
          }}
        >
          Import new profiles
        </button>
      </div>
      <div className={`${styles.wrapper} asset-section`}>
        <div className={`title-h4-bold ${styles.adaptive}`}>
          List profiles
        </div>
        <div className={`d-flex justify-content-between align-items-center my-3 ${styles.counter} `}>
          {counter(count, page, limit)}
        </div>
        <div>
          {clickedAddress && (
            <ConfirmModal
              title="Confirm delete"
              description={`Are you sure you want to delete “${clickedAddress.profile}”?`}
              buttonName="Delete"
              onSubmit={() => deleteAddress({ id: clickedAddress.id, alid: clickedAddress.alid })}
              onCancel={() => setClickedAddress('')}
              loading={deleteAddressResult.isLoading || deleteAddressResult.isFetching}
            />
          )}
          <div className="w-100">
            <Table
              total={count}
              data={tableData}
              desc={desc}
              setDesc={setDesc}
              ordering={orderBy}
              setOrdering={setOrderBy}
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              headers={header}
              isLoading={isLoading}
              min={25}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomListTable;
