import React from 'react';
import styles from './Mapping.module.scss';

const Mapping = ({ data, setData, index }) => (
  <button
    type="button"
    className={`
      ${styles.button}
      ${(!data[index].attribute || !data[index].type) && data[index].import ? styles.empty_fields : ''}
      ${data[index].mapping ? styles.mapping : ''}
      d-flex justify-content-center align-items-center`}
    onClick={() => {
      const tempArr = [...data];
      data[index].mapping = !data[index].mapping;
      setData(tempArr);
    }}
    disabled={!data[index].attribute || !data[index].type || !data[index].import}
  >
    {data[index].mapping ? 'Confirmed for import' : 'Confirm mapping and import'}
  </button>
);

export default Mapping;
