import React, { useMemo } from 'react';
import defNft from '../../../assets/icons/default_nft_icon.svg?url';
import defToken from '../../../assets/icons/default_token_icon.svg?url';
import defExchange from '../../../assets/icons/default_exchange_icon.svg?url';

const DefaultIcon = ({
  type, size = '40px',
}) => useMemo(
  () => (
    <img
      src={type === 'exchange'
        ? defExchange
        : type === 'nft'
          ? defNft
          : defToken}
      alt="asset"
      width={size}
      height={size}
    />
  ),
  [size, type],
);

export default DefaultIcon;
