import React from 'react';
import { ToastContainer, } from 'react-toastify';
import { Provider } from 'react-redux';
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
import { Web3Modal } from '@web3modal/react';
import { configureChains, createClient, WagmiConfig } from 'wagmi';
import {
  mainnet,
  polygon,
  sepolia,
} from 'wagmi/chains';
import Auth0ProviderWithHistory from './components/providers/Auth0Provider';
import Layout from './components/Layout';
import { setupStore } from './store';
import { ScrollToTop } from './tools/ScrollToTop';
import {
  amoy, base, baseSepolia, linea, lineaSepolia, chiliz, chilizSpicy,
} from './utils/web3/customChains.ts';

const store = setupStore();

// 1. Get projectID at https://cloud.walletconnect.com
if (!import.meta.env.VITE_APP_PROJECT_ID) {
  throw new Error('You need to provide VITE_APP_PROJECT_ID env variable');
}

export const projectId = import.meta.env.VITE_APP_PROJECT_ID;

// 2. Configure wagmi client
const chains = [mainnet, polygon, amoy, linea, lineaSepolia, base, baseSepolia, sepolia, chiliz, chilizSpicy];
const { provider } = configureChains(chains, [w3mProvider({ projectId })]);
export const wagmiClient = createClient({
  autoConnect: true,
  connectors: w3mConnectors({
    chains,
    version: 2,
    projectId,
  }),
  provider,
});

// 3. Configure modal ethereum client
export const ethereumClient = new EthereumClient(wagmiClient, chains);

const App = () => (
  <Provider store={store}>
    <Auth0ProviderWithHistory>
      <WagmiConfig client={wagmiClient}>
        <ScrollToTop />
        <Layout />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
        />
      </WagmiConfig>
      <Web3Modal
        projectId={projectId}
        ethereumClient={ethereumClient}
        themeMode="light"
        themeColor="default"
        themeBackground="themeColor"
      />
    </Auth0ProviderWithHistory>
  </Provider>
);

export default App;
