import React from 'react';

import styles from './AcceptedInvitationModal.module.scss';
import TickCircle from '../../../../assets/icons/tick_circle.svg';

const AcceptedInvitationModal = ({ openModal }) => (
  <div
    className={`modal modal-dialog-centered ${styles.modal_wrapper}`}
    id="exampleModal"
    tabIndex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div
      role="presentation"
      className="modal-dialog modal-md modal-phone w-100"
    >
      <div className="modal-content p-3">
        <div className="d-flex flex-column align-items-center justify-content-center mt-2">
          <TickCircle className="m-3" />
          <div className="title-h2-regular">Accepted</div>
          <div className="text-md-regular text-center mb-3 mt-1 w-70">
            You have successfully accepted the invitation.
            Please set up your password in the next modal.
          </div>
          <button className="regular-button mb-4" type="button" onClick={() => { openModal(); }}>Got it</button>
        </div>
      </div>
    </div>
  </div>
);

export default AcceptedInvitationModal;
