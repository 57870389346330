import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import styles from '../StartStopDateFlowModal.module.scss';

const PickDateInput = ({
  text, register, setValue, watch,
}) => {
  const [disable, setDisable] = useState(false);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (disable) {
      setInputValue('');
      setValue(text.includes('start') ? 'start_at' : 'end_at', '');
    }
  }, [disable, setValue, text]);

  return (
    <div className="d-flex flex-column w-100 gap-1 mb-3">
      <div className={`${styles.title_input} d-flex justify-content-between w-100`}>
        <div className={`d-flex gap-1 ${disable ? 'opacity-50' : ''} `}>
          <span>Pick</span>
          <span className={styles.text}>{text}</span>
          <span>date and time</span>
        </div>
        <div
          role="presentation"
          className={`${styles.text} cursor-pointer`}
          onClick={() => setDisable(!disable)}
        >
          {disable ? 'Undo' : 'Skip'}
        </div>
      </div>
      <div className={disable ? styles.link_area_disabled : styles.link_area}>
        <input
          {...register(text.includes('start') ? 'start_at' : 'end_at')}
          onChange={(e) => {
            setInputValue(e.target.value);
            setValue(text.includes('start') ? 'start_at' : 'end_at', e.target.value);
          }}
          min={text.includes('end') && watch('start_at')
            ? format(new Date(watch('start_at')), 'yyyy-MM-dd,HH:mm').replace(',', 'T')
            : format(new Date(), 'yyyy-MM-dd,HH:mm').replace(',', 'T')}
          max="9999-12-31T00:00"
          type="datetime-local"
          className="w-100 p-2"
          value={inputValue}
          disabled={disable}
        />
      </div>
    </div>
  );
};

export default PickDateInput;
