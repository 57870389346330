import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { OptIn } from '@absolutelabs/react-component-library';
import {
  selectCurrPage,
  selectFieldSettings,
  selectFormTextColor,
} from '../../../../../store/reducers/forms';

const OptInField = ({ ...props }) => {
  const currPage = useSelector(selectCurrPage);
  const fieldSettings = useSelector(selectFieldSettings);
  const globalTextColor = useSelector(selectFormTextColor);
  const [fieldConfig, setFieldConfig] = useState({});

  useEffect(() => {
    const currPageSettings = fieldSettings.find((o) => o.pageName === currPage);
    const config = currPageSettings?.fields?.find((x) => x.field_id === props?.field?.id);
    if (config) {
      setFieldConfig(config);
    }
  }, [fieldSettings, currPage, props?.field?.id]);

  return (
    <OptIn
      content={fieldConfig.content || '<p>I have read and accept the terms and conditions.</p>'}
      checkbox={fieldConfig.checkbox || false}
      required={fieldConfig.required}
      textColor={globalTextColor}
    />
  );
};

export default OptInField;
