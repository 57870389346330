import React from 'react';
import { useSelector } from 'react-redux';
import { coinApi } from '../../../api/coin';
import styles from './InvestorProfile.module.scss';
import NotableInvestments from '../../../components/ui/NotableInvestments';
import SharedInterests from '../../../components/ui/SharedInterests';
import TopStats from '../../../components/ui/TopStats';
import LastActivity from '../../../components/ui/DonutCharts/LastActivity';
import MultiChainActivity from '../../../components/ui/DonutCharts/MultiChainActivity';
import PortfolioValue from '../../../components/ui/DonutCharts/PortfolioValue';
import BuyingPower from '../../../components/ui/DonutCharts/BuyingPower';
import WalletAge from '../../../components/ui/DonutCharts/WalletAge';
import TypicalPortfolioDistribution from '../../../components/ui/DonutCharts/TypicalPortfolioDistribution';
import { selectFilterOption } from '../../../store/reducers/coin';
import AiAudience from './AiAudience';

const MemoizedMultiChainActivity = React.memo(MultiChainActivity);

const InvestorProfile = ({
  address, holders, symbol, network, audienceProfileData, audienceProfileIsLoading, user, userLoading, isCustomContract,
}) => {
  const filterOption = useSelector(selectFilterOption);
  const isOrdinals = network === 'ordinals';
  const secondLineBlockClass = isOrdinals
    ? "col-sm-12 col-md-6 col-lg-4" 
    : "col-sm-12 col-md-6 col-lg-3 mt-4 mt-lg-0";

  const {
    currentData: notableInvestments,
    isLoading: isNotableInvestmentsLoading,
    isFetching: isNotableInvestmentsFetching,
  } = coinApi.useGetNftNotableInvestmentsQuery({ address, network, audience: filterOption });

  const {
    currentData: sharedInterestsData,
    isLoading: sharedInterestsIsLoading,
    isFetching: sharedInterestsIsFetching,
  } = coinApi.useGetNftGroupInterestQuery({ address, blockchain: network || 'ethereum', audience: filterOption });

  const getOrdinalsPortfolioData = (ordinalsData) => {
    if (!ordinalsData || !ordinalsData.buckets) return null;
    return {...ordinalsData, buckets: ordinalsData.buckets
      .filter(item => item.label === "NFTs" || item.label === "Other tokens")
      .map(item => {
        if (item.label === "Other tokens") {
            return { ...item, label: "Tokens" };
        }
        return item;
      })
    };
  }

  return (
    <div className={`${styles.wrapper}`}>
      {(!userLoading && user && user?.ai_explanations && filterOption !== 'all' && !isCustomContract) && (
        <div className="mb-4 row">
          <div className="col-12">
            <AiAudience
              isReady={!userLoading && user && user.ai_explanations}
              address={address}
              network={network}
              filterOption={filterOption}
            />
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-12">
          <TopStats data={audienceProfileData} isLoading={audienceProfileIsLoading} isOrdinals={isOrdinals} />
        </div>
      </div>
      <div className="mt-4 row">
        <div className={secondLineBlockClass}>
          <PortfolioValue
            activityData={audienceProfileData?.portfolio_value_distribution}
            previousHighlight={audienceProfileData?.previous_values?.portfolio_value_distribution}
            isLoading={audienceProfileIsLoading}
            className="h-100"
          />
        </div>
        {!isOrdinals ? <div className="mt-4 col-sm-12 col-md-6 col-lg-3 mt-md-0">
          <BuyingPower
            activityData={audienceProfileData?.buying_power_distribution}
            previousHighlight={audienceProfileData?.previous_values?.buying_power_distribution}
            isLoading={audienceProfileIsLoading}
            className="h-100"
          />
        </div> : null}
        <div className={secondLineBlockClass}>
          <WalletAge
            activityData={audienceProfileData?.wallet_age_distribution}
            previousHighlight={audienceProfileData?.previous_values?.wallet_age_distribution}
            isLoading={audienceProfileIsLoading}
            className="h-100"
          />
        </div>
        <div className={secondLineBlockClass}>
          <TypicalPortfolioDistribution
            activityData={isOrdinals 
              ? getOrdinalsPortfolioData(audienceProfileData?.typical_portfolio_distribution) 
              : audienceProfileData?.typical_portfolio_distribution
            }
            previousHighlight={audienceProfileData?.previous_values?.typical_portfolio_distribution}
            isLoading={audienceProfileIsLoading}
            className="h-100"
          />
        </div>
      </div>
      <div className="mt-4 row">
        <div className="col-sm-12 col-md-6 col-lg-4">
          <NotableInvestments
            text="tokens"
            symbol={symbol}
            isLoading={isNotableInvestmentsLoading || isNotableInvestmentsFetching}
            data={notableInvestments?.tokens}
            holders={holders}
            info="Tokens most commonly held by holders of this collection"
          />
        </div>
        <div className="mt-4 col-sm-12 col-md-6 col-lg-4 mt-md-0">
          <NotableInvestments
            text="NFTs"
            symbol={symbol}
            isLoading={isNotableInvestmentsLoading || isNotableInvestmentsFetching}
            data={notableInvestments?.nfts}
            holders={holders}
            info="NFTs most commonly held by holders of this collection"
          />
        </div>
        <div className="mt-4 col-sm-12 col-md-6 col-lg-4 mt-lg-0">
          <SharedInterests
            symbol={symbol}
            data={sharedInterestsData}
            isLoading={sharedInterestsIsLoading || sharedInterestsIsFetching}
            info="Topics of interest for holders of this collection"
          />
        </div>
      </div>
      <div className="row">
        <div className="mt-4 col-sm-12 col-md-6 col-lg-6">
          <LastActivity
            activityData={audienceProfileData?.last_activity_distribution}
            previousHighlight={audienceProfileData?.previous_values?.last_activity_distribution}
            isLoading={audienceProfileIsLoading}
            className="h-100"
          />
        </div>
        {network === 'near' || network === 'flow' || network === 'ordinals' ? null
          : (
            <div className="mt-4 col-sm-12 col-md-6 col-lg-6">
              <MemoizedMultiChainActivity
                activityData={audienceProfileData?.multi_chain_activity}
                previousHighlight={audienceProfileData?.previous_values?.multi_chain_activity}
                isLoading={audienceProfileIsLoading}
                walletCount={audienceProfileData?.wallet_count}
                className="h-100"
                type="nft"
              />
            </div>
          )}
      </div>
    </div>
  );
};

export default InvestorProfile;
