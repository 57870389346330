import React, { useEffect, useState } from 'react';
import CircleChart from '../../CircleChart';
import { convertToReadableFormat } from '../../../../tools/NumberConverterTool';
import { getGrowth } from '../../../../utils/values';

const BuyingPower = ({
  activityData, previousHighlight, isLoading, className = '',
}) => {
  const [data, setData] = useState([]);
  const [avgShare, setAvgShare] = useState('');

  useEffect(() => {
    if (activityData && !isLoading) {
      const total = activityData.buckets.reduce((sum, { value }) => sum + value, 0);
      if (total === 0) {
        setData([]);
        return;
      }
      const newData = activityData.buckets.map((bucket) => {
        const percent = total ? ((bucket.value * 100) / total).toFixed(2) : 0;
        return {
          label: bucket.label,
          count: bucket.value,
          percent: +percent,
        };
      });

      const { median } = activityData;
      setAvgShare(`$${convertToReadableFormat(median)}`);
      setData(newData);
    }
  }, [activityData, isLoading]);

  return (
    <div className={className || ''}>
      <CircleChart
        title="Buying Power"
        highlightText="Median Buying Power"
        highlightValue={avgShare}
        data={data}
        isLoading={isLoading || (!data.length && isLoading)}
        growth={previousHighlight
          ? getGrowth(
            activityData?.median,
            previousHighlight?.median,
            `$${previousHighlight?.median}`,
          )
          : null}
      />
    </div>
  );
};

export default BuyingPower;
