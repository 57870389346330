import React from 'react';
import RemoveIcon from '../../../../assets/images/dashboard/remove.png';
import './Alert.scss';

const Alert = ({ alertData }) => (
  <div className={`alert-container alert-bg-${alertData.id} mb-2`}>
    <div className="alert-date-container">
      <div className="alert-date">{alertData.header || 'Mon Oct 4th, 1:34PM'}</div>
      {!alertData.header && (
        <img
          src={RemoveIcon}
          className="ms-auto pointer"
          height="10"
          width="10"
          alt="delete-icon"
        />
      )}
    </div>
    <div>
      <div className="alert-content">{alertData.content}</div>
    </div>
  </div>
);

export default Alert;
