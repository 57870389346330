import React from 'react';

import styles from './InfoModal.module.scss';

const InfoModal = ({ message }) => (
  <div
    className={`modal modal-dialog-centered ${styles.modal_wrapper}`}
    id="infoModal"
    tabIndex="-1"
    aria-labelledby="InfoModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog">
      <div className={`${styles.wrapper} modal-content border-0`}>
        <div className={`${styles.description} d-flex w-100 mt-3`}>
          <span>
            {message}
          </span>
        </div>
      </div>
    </div>
  </div>
);

export default InfoModal;
