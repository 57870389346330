import { isRejectedWithValue } from '@reduxjs/toolkit';
import { setError } from '../reducers/app';
import { showErrorMessage } from '../../components/base/Notifications';

export const rtkQueryErrorLogger = (api) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    if (action?.payload?.status === 401) {
      api.dispatch(setError({
        isError: true,
        errorType: 'Unauthorized',
        // redirect: action?.meta?.arg?.endpointName !== 'postSnapshotOfNftHolderList',
      }));
      showErrorMessage('Your session has expired');
    }
    if (action?.payload?.status === 403) {
      const { pathname } = window.location;
      if (
        ![
          'postSnapshotOfCoinHolderList',
          'postSnapshotOfNftHolderList',
          'getCustomListWalletsCSV',
          'getSegmentWalletsCSV',
          'getTokenHolderListCSV',
          'getNftHolderListCSV',
          'downloadSegmentWalletsCSV',
          'sendWalletInFlow',
        ].includes(action?.meta?.arg?.endpointName)
        && !(pathname.includes('wallet') && action?.meta?.arg?.endpointName === 'getFlows')
      ) {
        api.dispatch(setError({ isError: true, errorType: 'Rejected' }));
      }
    }
    if (action?.payload?.status === 404
      && action?.payload?.data?.detail !== 'List with specified name was not found.') {
      api.dispatch(setError({ isError: true, errorType: 'Not Found' }));
    }
    if (action?.payload?.status === 409 || action?.payload?.status === 410) {
      showErrorMessage('Something went wrong. Please, refresh the page and try again.');
    }
  }

  return next(action);
};
