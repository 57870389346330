import React from 'react';
import uniqueId from '../../../utils/uniqueId';
import styles from './checkbox.module.scss';

const Checkbox = ({
  label, checked, onChange, disabled = false, useFormData = null,
}) => {
  const cbId = uniqueId('checkbox');
  const handleOnChange = (newValue) => {
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <div className={`${styles.wrapper}`}>
      <input
        type="checkbox"
        id={cbId}
        className={`form-check-input ${styles.checkbox}`}
        checked={checked}
        onChange={(e) => handleOnChange(e.target.checked)}
        disabled={disabled}
        {...useFormData}
      />
      {label ? (
        <label className={styles.label} htmlFor={cbId}>
          {label}
        </label>
      ) : null}
    </div>
  );
};

export default Checkbox;
