import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { useSelector } from 'react-redux';
import {
  getSelectedItems,
} from '../../../../../store/reducers/folders';
import { foldersApi } from '../../../../../api/folders';
import CloseModalButton from '../../components/CloseModalButton';
import useKeydown from '../../../../hooks/app/useKeydown';
import { showErrorMessage, showSuccessMessage } from '../../../../base/Notifications';
import Close from '../../../../../assets/icons/close.svg';
import styles from '../foldersModal.module.scss';

const MultiValue = (props) => {
  const { data } = props;

  const { parentName } = data;
  let shortParentName = '';

  if (parentName) {
    if (parentName.length > 20) {
      shortParentName = `${parentName.substring(0, 20)}...`;
    } else {
      shortParentName = parentName;
    }
  }

  return (
    <components.MultiValue {...props}>
      {shortParentName
        ? (
          <>
            <span className={styles.parent}>{shortParentName}</span>
            <span className={styles.multi_value_separator_container}>
              <div className={styles.multi_value_separator}>▸ </div>
            </span>
          </>
        )
        : null}
      {data.label}
    </components.MultiValue>
  );
};

const MultiValueRemove = (props) => (
  <components.MultiValueRemove {...props}>
    <Close />
  </components.MultiValueRemove>
);

const Option = (props) => {
  const { data } = props;
  return (
    <components.Option {...props}>
      {data.parentName
        ? (
          <>
            <span className={styles.parent}>
              {data.parentName}
            </span>
            <span className={styles.option_separator}>▸ </span>
          </>
        )
        : null}
      <span>{data.label}</span>
    </components.Option>
  );
};

const selectStyles = {
  option: (defaultStyles, state) => ({
    ...defaultStyles,
    backgroundColor: state.isFocused ? '#F1F4F8' : 'inherit',
    ':hover': {
      backgroundColor: '#F1F4F8',
    },
  }),
  multiValue: (defaultStyles) => ({
    ...defaultStyles,
    backgroundColor: '#F1F4F8',
    borderRadius: '4px',
  }),
  multiValueRemove: (defaultStyles) => ({
    ...defaultStyles,
    ':hover': {
      backgroundColor: 'inherit',
    },
  }),
};

const AddItemsToFoldersModal = ({
  closeModal, type,
}) => {
  const selection = useSelector((state) => getSelectedItems(state, type));
  const [displayedFolders, setDisplayedFolders] = useState([]);
  const [selectedFolders, setSelectedFolders] = useState([]);
  const {
    data: folders,
    isLoading: isLoadingFolders,
  } = foldersApi.useGetFoldersQuery({
    type,
  });
  const [addItemsToFolders] = foldersApi.useAddItemsToFoldersMutation();

  useKeydown('Escape', closeModal);

  useEffect(() => {
    const dFolders = [];
    folders.forEach((f) => {
      dFolders.push({
        id: f.id,
        name: f.name,
        parentName: null,
      });
      f.sub_folders.forEach((sf) => {
        dFolders.push({
          id: sf.id,
          name: sf.name,
          parentName: f.name,
        });
      });
    });
    setDisplayedFolders(dFolders);
  }, [folders]);

  const handleSave = async () => {
    if (selectedFolders.length === 0) {
      showErrorMessage('Selection cannot be empty');
      return;
    }
    const result = await addItemsToFolders(
      {
        items: selection.map((i) => ({ object_id: i.id, object_type: i.type })),
        folders: selectedFolders.map((f) => f.id),
        type,
      },
    );
    if (result.error) {
      showErrorMessage(`Error during ${type} addition`);
      return;
    }
    showSuccessMessage(`Selected ${type} was successfully added`);
    closeModal();
  };

  useEffect(() => {
    const handleKeyDown = async (event) => {
      if (event.key === 'Enter') {
        await handleSave();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedFolders]);

  return (
    <div
      className={`modal modal-dialog-centered ${styles.modal_wrapper}`}
      id="confirmModal"
      tabIndex="-1"
      aria-labelledby="confirmModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-md modal-phone w-100 position-relative">
        <div className={`${styles.body} modal-content position-relative border-0`}>
          <CloseModalButton onCancel={closeModal} />
          <div>
            <div className={`${styles.title} d-flex justify-content-start`}>
              {`Add selected ${type} to folders`}
            </div>
          </div>

          <Select
            className="mb-4"
            placeholder="Search for the folder"
            maxMenuHeight={194}
            onChange={(options, action) => {
              if (action.action === 'select-option') {
                setSelectedFolders([...selectedFolders, displayedFolders.find((f) => f.id === action.option.value)]);
              } else if (action.action === 'remove-value') {
                setSelectedFolders(selectedFolders.filter((f) => f.id !== action.removedValue.value));
              } else if (action.action === 'clear') {
                setSelectedFolders([]);
              }
            }}
            value={selectedFolders.map((sf) => ({
              value: sf.id,
              label: sf.name,
              parentName: sf.parentName,
            }))}
            isLoading={isLoadingFolders}
            options={isLoadingFolders ? [] : displayedFolders.map((sf) => ({
              value: sf.id,
              label: sf.name,
              parentName: sf.parentName,
            }))}
            isMulti
            styles={selectStyles}
            components={{ MultiValue, MultiValueRemove, Option }}
          />

          <div className={`${styles.footer} d-flex justify-content-end`}>
            <button
              type="button"
              className="outline-button border border-1"
              onClick={closeModal}
            >
              Cancel
            </button>
            <button
              type="button"
              className="regular-button"
              onClick={handleSave}
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddItemsToFoldersModal;
