import React, { useEffect, useMemo, useState } from 'react';
import {
  Link, useLocation, useNavigate, useSearchParams,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigationType } from 'react-router';
import { audiencesApi } from '../../api/audiences';
import InvestorProfile from './InvestorProfile';
import InvestorPortfolio from './InvestorPortfolio';
import List from './List';
import Owned from '../../assets/images/asset/member.png';
import Chart from '../../assets/images/asset/chart.png';
import Cal from '../../assets/images/asset/Icon.png';
import uniqueId from '../../utils/uniqueId';
import { userApi } from '../../api/user';
import styles from './SegmentDetails.module.scss';
import SegmentAnalyticsLoader from './SegmentAnalyticsLoader';
import { ethFormat } from '../../utils/singleAssetPage/parseData';
import Reach from '../../components/ui/Reach';
import ConfirmModal from '../../components/ui/modals/ConfirmModal';
import { selectTabPages, setTabPages } from '../../store/reducers/segment';
import Exchanges from './Exchanges';
import DappUsage from './DappUsage';
import Title from '../../components/base/Title';
import EditableFoldersList from '../../components/ui/EditableFoldersList';
import WidgetCard from '../../components/ui/WidgetCard';
import { getCardValue, getEthNumber, getEthPrice } from '../../utils/values';

const SegmentDetails = () => {
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const navType = useNavigationType();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [itemPage, setItemPage] = useState(searchParams.get('tab')?.replace('+', ' ') || 'Audience Profile');
  const [isLoaded, setIsLoaded] = useState(false);
  const tabPages = useSelector(selectTabPages);
  const {
    data: runRoutine,
  } = audiencesApi.useRunRoutineQuery(
    {
      id: pathname.split('/')[2],
    },
    {
      skip: isLoaded,
      pollingInterval: 5000,
    },
  );

  const {
    data: segment,
    isLoading: isSegmentLoading,
    isFetching: isSegmentFetching,
  } = audiencesApi.useGetSegmentByIdQuery(
    {
      id: pathname.split('/')[2],
    },
  );

  const {
    data: segmentAnalytics,
    isLoading: isSegmentAnalyticsLoading,
    isFetching: isSegmentAnalyticsFetching,
  } = audiencesApi.useGetSegmentAnalyticsQuery(
    {
      id: pathname.split('/')[2],
    },
    {
      skip: !isLoaded,
    },
  );

  const {
    data: analytics,
    isLoading: isAnalyticsLoading,
    isFetching: isAnalyticsFetching,
  } = audiencesApi.useGetSegmentMedianAnalyticsQuery(
    {
      id: pathname.split('/')[2],
    },
    {
      skip: !isLoaded,
    },
  );

  const {
    data: reachData,
    isLoading: isReachDataLoading,
    isFetching: isReachDataFetching,
  } = audiencesApi.useSegmentReachQuery({
    id: pathname.split('/')[2],
  }, {
    skip: !isLoaded,
  });

  const {
    data: insightData,
    isLoading: isInsightDataLoading,
    isFetching: isInsightDataFetching,
  } = audiencesApi.useGetSegmentListInsightQuery(pathname.split('/')[2]);

  const {
    refetch,
  } = userApi.useGetAssetsQuery();

  useEffect(() => {
    if (navType !== 'POP') {
      refetch();
    }
  }, [navType, refetch]);

  useEffect(() => {
    if (runRoutine?.status === 'updated' || segment?.is_restricted) {
      setIsLoaded(true);
    }
  }, [isLoaded, runRoutine?.status, segment?.is_restricted, setSearchParams]);

  useEffect(() => {
    if (searchParams.get('scroll') && navType === 'POP') {
      setTimeout(
        () => window.scrollTo({ top: +searchParams.get('scroll'), left: 0, behavior: 'smooth' }),
        500,
      );
    }
  }, [navType, searchParams]);

  useEffect(() => () => {
    setIsLoaded(false);
    dispatch(setTabPages([]));
  }, [dispatch]);

  useEffect(() => {
    setItemPage(searchParams.get('tab')?.replace('+', ' ') || 'Audience Profile');
  }, [searchParams]);

  return useMemo(() => {
    if (!isLoaded || isSegmentLoading || isSegmentFetching || isSegmentAnalyticsLoading || isSegmentAnalyticsFetching
    || isAnalyticsLoading || isAnalyticsFetching) {
      return (
        <div className="w-100 d-flex align-items-center justify-content-center">
          <SegmentAnalyticsLoader />
        </div>
      );
    }

    if (isLoaded && segment) {
      const pages = [
        {
          label: 'Audience Profile',
          component: <InvestorProfile
            holders={segmentAnalytics?.count_of_addresses}
            audienceProfileData={insightData}
            audienceProfileIsLoading={isInsightDataLoading || isInsightDataFetching}
          />,
          id: 1,
        },
        {
          label: 'Audience Portfolio',
          component: <InvestorPortfolio holders={insightData?.wallet_count} assetsRefetch={refetch} />,
          id: 2,
        },
        { label: 'List', component: <List walletCounts={insightData?.wallet_count} />, id: 3 },
        { label: 'Dapp Usage', component: <DappUsage holders={insightData?.wallet_count} />, id: 4 },
        {
          label: 'Centralized Exchanges',
          component: <Exchanges holders={insightData?.wallet_count} />,
          id: 5,
        },
      ];
      if (pages?.length && navType !== 'POP') {
        dispatch(setTabPages(pages));
      }
      const widgetData = [
        {
          title: 'Profile Count',
          value: getCardValue(segmentAnalytics?.count_of_addresses),
          icon: Owned,
        },
        {
          title: 'Cumulated Portfolio Value',
          value: getEthPrice(segmentAnalytics?.sum_of_portfolio_value),
          icon: Chart,
        },
        {
          title: 'Median Portfolio Value',
          value: getEthPrice(analytics?.total_pct),
          icon: Cal,
        },
      ];

      return (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>
              {segment?.name}
              {' '}
              Audiences
              {' '}
              - Absolute Labs Platform
            </title>
          </Helmet>
          <div className="d-flex gap-2 align-items-center">
            <div className={styles.title}>
              {segment?.name || ''}
            </div>
          </div>

          <div className="mt-2">
            <EditableFoldersList itemId={Number(pathname.split('/')[2])} itemType="audiences" />
          </div>

          <div className="asset-section">
            <div className="mt-4 mx-4 pt-3 pb-2">
              <Title>Portfolio Value</Title>
            </div>
            <div className="dropdown-divider w-100" />
            <div className={`${styles.wrapper} d-gap-3 d-flex flex-column`}>
              {segment?.is_restricted && (
                <ConfirmModal
                  title="Dataset was deleted"
                  description="This segment cannot be accessed because the dataset,
                   that it used, was deleted or edited."
                  onSubmit={() => { navigate('/audiences'); }}
                />
              )}
              <div className="d-flex gap-3 flex-column flex-sm-row pb-4">
                {widgetData.map((item) => (
                  <WidgetCard
                    key={uniqueId('segment-analytics-widget')}
                    icon={item.icon}
                    info={item.info}
                    growth={item.growth}
                    title={item.title}
                    value={item.value}
                    isLoading={isSegmentAnalyticsLoading
                      || isSegmentAnalyticsFetching}
                  />
                ))}
              </div>
              <Reach
                data={reachData}
                isLoading={isReachDataFetching || isReachDataLoading || isSegmentLoading || isSegmentFetching}
                totalCount={segmentAnalytics?.count_of_addresses}
              />
            </div>
          </div>
          <div className="asset-section m-o mt-4">
            <div className="title-gap asset-navigation">
              <ul className="nav nav-pills gap-2">
                {(navType === 'POP' && tabPages?.length ? tabPages : pages).map((elem) => (
                  <li
                    role="presentation"
                    className="nav-item"
                    key={uniqueId('segment-analytics-tab')}
                    onClick={() => {
                      setItemPage(elem.label);
                      setSearchParams({
                        tab: elem.label,
                        scroll: '',
                      });
                    }}
                  >
                    <Link
                      className={`nav-link ${
                        elem.label === itemPage ? 'active' : ''
                      }`}
                      to={`${pathname}${search}`}
                    >
                      {elem.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="dropdown-divider w-100" />
            {(navType === 'POP' && tabPages?.length ? tabPages : pages).map((item) => (item.label === itemPage ? (
              <div key={uniqueId('segment-analytics-page')}>{item.component}</div>
            ) : null))}
          </div>
        </>
      );
    }
    return (
      <>
      </>
    );
  }, [
    analytics?.total_pct,
    dispatch,
    isAnalyticsFetching,
    isAnalyticsLoading,
    isLoaded,
    isReachDataFetching,
    isReachDataLoading,
    isSegmentAnalyticsFetching,
    isSegmentAnalyticsLoading,
    isSegmentFetching,
    isSegmentLoading,
    itemPage,
    navType,
    navigate,
    pathname,
    reachData,
    refetch,
    search,
    segment,
    segmentAnalytics?.count_of_addresses,
    segmentAnalytics?.sum_of_portfolio_value,
    setSearchParams,
    insightData,
    isInsightDataLoading,
    isInsightDataFetching,
  ]);
};

export default SegmentDetails;
