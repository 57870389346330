import React, { useMemo } from 'react'
import DownloadTooltip from '../../DownloadTooltip'
import uniqueId from '../../../../utils/uniqueId'
import ExportIcon from '../../../../assets/icons/export.svg';
import styles from './Export.module.scss'

const Export = ({ onClick, tooltip, tooltipWarnings }) => {
  const tooltipId = useMemo(() => uniqueId('exportButton'), []);

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  }

  return (
    <>
      <button
        type="button"
        className={styles.button}
        data-for={tooltipId}
        data-tip
        onClick={handleClick}
      >
        <ExportIcon height="16px" width="16px" />
      </button>
      <DownloadTooltip
        id={tooltipId}
        header={tooltip}
        warningsList={tooltipWarnings}
      />
    </>
  )
}

export default Export
