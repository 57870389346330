import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  clearActiveSettings,
  onSettingsChange,
  removeErrors,
  selectConfigsList,
  selectFlowStatus,
} from '../../../../../store/reducers/flows';
import styles from './SMSNodeSettings.module.scss';
import EditableTitle from '../../../../../components/base/EditableTitle';

const SMSNodeSettings = ({ activeSettings }) => {
  const [titleValue, setTitleValue] = useState('');

  const dispatch = useDispatch();

  const {
    register, handleSubmit, setValue, reset, watch,
  } = useForm();

  const configList = useSelector(selectConfigsList);
  const status = useSelector(selectFlowStatus);

  const disableChangeStatus = useMemo(() => status === 'running'
    || status === 'scheduled' || status === 'stopped', [status]);

  const onSubmit = (submitData) => {
    const hookInfo = { ...submitData };
    if (titleValue) {
      hookInfo.name = titleValue;
    } else {
      hookInfo.name = 'SMS';
    }
    dispatch(removeErrors(activeSettings.node_id));
    dispatch(onSettingsChange({ ...hookInfo, node_id: activeSettings.node_id }));
    dispatch(clearActiveSettings());
  };

  useEffect(() => {
    if (configList.length) {
      const index = configList.map((object) => object.node_id).indexOf(activeSettings.node_id);
      if (index >= 0) {
        setTitleValue(configList[index].name);
        setValue('message', configList[index].message);
      } else {
        reset({
          message: '',
        });
        setTitleValue('');
      }
    }
  }, [activeSettings.node_id, configList, reset, setValue]);

  return (
    <div className={`${styles.wrapper} h-100`}>
      <form onSubmit={handleSubmit(onSubmit)} className="d-flex flex-column justify-content-between h-100">
        <div>
          <div className={`${styles.title} d-flex align-items-center gap-2`}>
            <EditableTitle
              defaultTitle="SMS"
              titleValue={titleValue}
              setTitleValue={setTitleValue}
              maxLength={25}
              size={16}
              edit={!disableChangeStatus}
            />
          </div>
          <div className={`${styles.message} bm-1`}>
            <textarea
              className="w-100"
              maxLength={160}
              name="sms"
              id="sms"
              placeholder="Write a Message..."
              {...register('message')}
              disabled={disableChangeStatus}
            />
          </div>
          {watch('message')?.length
            ? (
              <div className={styles.info}>
                {watch('message').length}
                &nbsp;
                character(s) used, you have
                &nbsp;
                {160 - watch('message').length}
                &nbsp;
                characters left.
              </div>
            )
            : null}
        </div>
        <div className="d-flex gap-4 mt-4">
          <button
            type="button"
            className={`${styles.btn_cancel}`}
            onClick={() => dispatch(clearActiveSettings())}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="regular-button w-100"
            disabled={!watch('message') || disableChangeStatus}
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default SMSNodeSettings;
