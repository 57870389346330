import React, { useEffect, useRef, useState } from 'react'
import Tooltip from '../../ui/Tooltip';
import uniqueId from '../../../utils/uniqueId';
import styles from './TruncatedText.module.scss'

const TruncatedText = ({ text }) => {
  const toolTipId = uniqueId('truncated')
  const spanRef = useRef(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    const spanElement = spanRef.current;

    if (spanElement) {
      const isOverflowing =
        spanElement.scrollWidth > spanElement.clientWidth;
      setIsTruncated(isOverflowing);
    }
  }, [text]);

  return (
    <>
      <span
        ref={spanRef}
        className={styles.text}
        data-for={toolTipId}
        data-tip
        >
        {text}
      </span>
      {isTruncated ? <Tooltip id={toolTipId} info={text} /> : null}
    </>
  )
}

export default TruncatedText
