import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { coinApi } from '../../../api/coin';
import { selectFilterOption } from '../../../store/reducers/coin';
import NotableInvestments from '../../../components/ui/NotableInvestments';
import SharedInterests from '../../../components/ui/SharedInterests';
import NotSupportedBlockchain from '../../../components/NotSupportedBlockchain';
import styles from './InvestorProfile.module.scss';
import TopStats from '../../../components/ui/TopStats';
import LastActivity from '../../../components/ui/DonutCharts/LastActivity';
import MultiChainActivity from '../../../components/ui/DonutCharts/MultiChainActivity';
import PortfolioValue from '../../../components/ui/DonutCharts/PortfolioValue';
import BuyingPower from '../../../components/ui/DonutCharts/BuyingPower';
import WalletAge from '../../../components/ui/DonutCharts/WalletAge';
import TypicalPortfolioDistribution from '../../../components/ui/DonutCharts/TypicalPortfolioDistribution';
import AiAudience from './AiAudience';

const MemoizedMultiChainActivity = React.memo(MultiChainActivity);

const InvestorProfile = ({
  holders,
  symbol,
  networks,
  isSupportedBlockchain,
  audienceProfileData,
  isAudienceProfileLoading,
  user,
  userLoading,
}) => {
  const { pathname } = useLocation();
  const filterOption = useSelector(selectFilterOption);
  const isOrdinals = Array.isArray(networks) && networks.length === 1 && networks[0] === 'ordinals';
  const secondLineBlockClass = isOrdinals
    ? "col-sm-12 col-md-6 col-lg-4" 
    : "col-sm-12 col-md-6 col-lg-3 mt-4 mt-lg-0";

  const {
    currentData: notableInvestments,
    isLoading: isNotableInvestmentsLoading,
    isFetching: isNotableInvestmentsFetching,
  } = coinApi.useGetTokenNotableInvestmentsQuery({
    id: pathname.split('/')[2],
    limit: 6,
    audience: filterOption,
  }, {
    skip: !pathname?.includes('coin'),
  });

  const {
    currentData: sharedInterestsData,
    isLoading: sharedInterestsIsLoading,
    isFetching: sharedInterestsIsFetching,
  } = coinApi.useGetGroupInterestQuery({
    address: pathname.split('/')[2],
    audience: filterOption,
  }, {
    skip: !pathname?.includes('coin'),
  });

  const getOrdinalsPortfolioData = (ordinalsData) => {
    if (!ordinalsData || !ordinalsData.buckets) return null;
    return {...ordinalsData, buckets: ordinalsData.buckets
      .filter(item => item.label === "NFTs" || item.label === "Other tokens")
      .map(item => {
        if (item.label === "Other tokens") {
            return { ...item, label: "Tokens" };
        }
        return item;
      })
    };
  }

  return useMemo(() => {
    if (!isSupportedBlockchain) {
      return <NotSupportedBlockchain />;
    }
    return (
      <div className={`${styles.wrapper}`}>
        {(!userLoading && user && user?.ai_explanations && filterOption !== 'all') && (
          <div className="row mb-4">
            <div className="col-12">
              <AiAudience
                isReady={!userLoading && user && user.ai_explanations}
                filterOption={filterOption}
              />
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-12">
            <TopStats data={audienceProfileData} isLoading={isAudienceProfileLoading} isOrdinals={isOrdinals} />
          </div>
        </div>
        <div className="row mt-4">
          <div className={secondLineBlockClass}>
            <PortfolioValue
              activityData={audienceProfileData?.portfolio_value_distribution}
              previousHighlight={audienceProfileData?.previous_values?.portfolio_value_distribution}
              isLoading={isAudienceProfileLoading}
              className="h-100"
            />
          </div>
          {!isOrdinals ? <div className="col-sm-12 col-md-6 col-lg-3 mt-4 mt-md-0">
            <BuyingPower
              activityData={audienceProfileData?.buying_power_distribution}
              previousHighlight={audienceProfileData?.previous_values?.buying_power_distribution}
              isLoading={isAudienceProfileLoading}
              className="h-100"
            />
          </div> : null}
          <div className={secondLineBlockClass}>
            <WalletAge
              activityData={audienceProfileData?.wallet_age_distribution}
              previousHighlight={audienceProfileData?.previous_values?.wallet_age_distribution}
              isLoading={isAudienceProfileLoading}
              className="h-100"
            />
          </div>
          <div className={secondLineBlockClass}>
            <TypicalPortfolioDistribution
              activityData={isOrdinals 
                ? getOrdinalsPortfolioData(audienceProfileData?.typical_portfolio_distribution) 
                : audienceProfileData?.typical_portfolio_distribution
              }
              previousHighlight={audienceProfileData?.previous_values?.typical_portfolio_distribution}
              isLoading={isAudienceProfileLoading}
              className="h-100"
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-sm-12 col-md-6 col-lg-4">
            <NotableInvestments
              text="tokens"
              symbol={symbol}
              isLoading={isNotableInvestmentsLoading || isNotableInvestmentsFetching}
              data={notableInvestments?.tokens}
              holders={holders}
              info="Tokens most commonly held by holders of this token"
            />
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 mt-4 mt-md-0">
            <NotableInvestments
              text="NFTs"
              symbol={symbol}
              isLoading={isNotableInvestmentsLoading || isNotableInvestmentsFetching}
              data={notableInvestments?.nfts}
              holders={holders}
              info="NFTs most commonly held by holders of this token"
            />
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 mt-4 mt-lg-0">
            <SharedInterests
              symbol={symbol}
              data={sharedInterestsData}
              isLoading={sharedInterestsIsLoading}
              isFetching={sharedInterestsIsFetching}
              info="Topics of interest for holders of this token"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6 mt-4">
            <LastActivity
              activityData={audienceProfileData?.last_activity_distribution}
              previousHighlight={audienceProfileData?.previous_values?.last_activity_distribution}
              isLoading={isAudienceProfileLoading}
              className="h-100"
            />
          </div>
          {networks.length === 1
          && (networks[0].toLowerCase().includes('near') || networks[0].toLowerCase().includes('flow') || networks[0].toLowerCase().includes('ordinals'))
            ? null
            : (
              <div className="col-sm-12 col-md-6 col-lg-6 mt-4">
                <MemoizedMultiChainActivity
                  activityData={audienceProfileData?.multi_chain_activity}
                  previousHighlight={audienceProfileData?.previous_values?.multi_chain_activity}
                  isLoading={isAudienceProfileLoading}
                  walletCount={audienceProfileData?.wallet_count}
                  className="h-100"
                />
              </div>
            )}
        </div>
      </div>
    );
  }, [
    holders,
    isNotableInvestmentsFetching,
    isNotableInvestmentsLoading,
    isSupportedBlockchain,
    notableInvestments?.nfts,
    notableInvestments?.tokens,
    sharedInterestsData,
    sharedInterestsIsFetching,
    sharedInterestsIsLoading,
    symbol,
    audienceProfileData,
    isAudienceProfileLoading,
  ]);
};

export default InvestorProfile;
