import React from 'react';
import { useSearchParams } from 'react-router-dom';
import Arrow from '../../../assets/icons/table/arrow.svg';
import uniqueId from '../../../utils/uniqueId';
import styles from './Table.module.scss';
import Pagination from './Pagination';
import NoData from '../NoData';
import RoundSpinner from '../RoundSpinner';
import Tooltip from '../../ui/Tooltip';

const Table = ({
  ordering, setOrdering, desc, setDesc, setPage, page, limit, setLimit, data, total, headers, isLoading,
  min = 10,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  if (!isLoading && !data?.length) {
    return (
      <div className="h-100 pb-5">
        <NoData />
      </div>
    );
  }

  return (
    <div className={`${styles.wrapper} position-relative`}>
      {isLoading
        ? <RoundSpinner position="position-absolute" theme="light" />
        : null}
      <div className={styles.table_wrapper}>
        <table className={`${styles.table} w-100 position-relative`}>
          <thead className={`${styles.header} position-sticky top-0`}>
            <tr className="border-bottom-0">
              {headers.map((item) => (
                <>
                  <th
                    className={`
                    text-nowrap
                    user-select-none
                    ${item.field ? 'cursor-pointer' : ''}
                  `}
                    style={{ width: item.width ? item.width : 'auto' }}
                    key={uniqueId('tableTitle')}
                    onClick={() => {
                      if (!item.field || item.field === 'active_on') {
                        return;
                      }
                      if (item.field === ordering) {
                        if (!searchParams.get('tab')?.startsWith('Dapp Usage')
                            && !searchParams.get('tab')?.startsWith('Centralized Exchanges')) {
                          setSearchParams({
                            ...Object.fromEntries([...searchParams]),
                            order: !desc,
                          });
                        }
                        setDesc(!desc);
                      } else {
                        if (!searchParams.get('tab')?.startsWith('Dapp Usage')
                            && !searchParams.get('tab')?.startsWith('Centralized Exchanges')) {
                          setSearchParams({
                            ...Object.fromEntries([...searchParams]),
                            order: '',
                            orderBy: item.field,
                          });
                        }
                        setDesc(true);
                        setOrdering(item.field);
                      }
                    }}
                    data-for={item.title}
                    data-tip
                  >
                    {item.title}
                    {item.field
                      ? (
                        <button
                          type="button"
                          className={styles.arrow}
                        >
                          {item.field && item.field !== 'active_on'
                            ? (
                              <Arrow
                                className={`
                              ${item.field === ordering ? styles.active : ''}
                              ${item.field === ordering && !desc ? styles.rotate : ''}
                              `}
                              />
                            )
                            : null}
                        </button>
                      )
                      : null}
                  </th>
                  {item.info ? <Tooltip id={item.title} info={item.info} /> : null}
                </>
              ))}
            </tr>
          </thead>
          <tbody className="border-top-0">
            {data.map((row) => (
              <tr
                key={uniqueId('bodyRow')}
                className={`
                  ${typeof row?.ready === 'undefined' || row?.ready ? styles.row : styles.row_unready}
                  ${row?.highlight ? styles.highlight : ''}
                  `}
              >
                {Object.keys(row).filter((r) => r !== 'highlight').map((elem) => (
                  elem !== 'ready' ? (
                    <td
                      className={elem === ordering ? styles.selected_td : ''}
                      key={uniqueId('td')}
                    >
                      {row[elem]}
                    </td>
                  )
                    : null
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {total > min
        ? (
          <Pagination
            page={page}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            total={total}
            min={min}
          />
        )
        : null}
    </div>
  );
};

export default Table;
