import { useCallback, useState } from 'react';
import _debounce from 'lodash.debounce';

const useDebounce = (obj = null, wait = 1000) => {
  const [state, setState] = useState(obj);

  const debounce = useCallback(
    _debounce((_prop) => {
      setState(_prop);
    }, wait),
    [],
  );

  const setDebouncedState = (_val) => {
    debounce(_val);
  };

  return [state, setDebouncedState];
};

export default useDebounce;
