import React, { useMemo } from 'react';
import { NumericFormat } from 'react-number-format';
import {
  lengthOf18andstop, validateInput, onPaste, onPasteFloat,
} from '../../../../utils/segments';
import styles from './Input.module.scss';
import { showErrorMessage } from '../../../../components/base/Notifications';

const NumberInput = ({
  option,
  edit,
  setDebouncedValue,
  setInputValue,
  inputValue,
  conditionName = '',
  checkSelectField,
  checkFirstSelectField,
}) => {
  const {
    value, name, label,
  } = option;

  return useMemo(() => {
    if (name.includes('value')) {
      return (
        <NumericFormat
          key={label}
          className={`form-control ${styles.input} w-100`}
          thousandSeparator
          prefix="$"
          allowNegative={false}
          onPaste={onPaste}
          placeholder="Input a value"
          onValueChange={(values) => {
            const { value: newVal } = values;
            const ifValidValue = validateInput(
              newVal,
              name === 'transactions' && conditionName !== 'Social activity',
            );
            if (!ifValidValue) {
              return;
            }
            if (newVal.length <= 18) {
              setInputValue(newVal);
              setDebouncedValue({ value: newVal.replace(/,/g, '') === '' ? null : newVal.replace(/,/g, ''), name });
            }
          }}
          onBlur={(e) => e.target.blur()}
          onKeyDown={(e) => lengthOf18andstop(e)}
          isAllowed={(values) => {
            const { floatValue, formattedValue } = values;
            return Number.isInteger(floatValue) || formattedValue === '';
          }}
          valueIsNumericString
          value={inputValue !== null ? inputValue : Array.isArray(value) ? value[0].value : ''}
          disabled={!edit}
        />
      );
    }
    if ((name === 'dapp-amount' && (checkSelectField[0]?.value === 'and-total-ethereum-value'
        || checkSelectField[0]?.value === 'and-total-ethereum-value-for'))
        || checkFirstSelectField === 'number-of-tokens-of') {
      return (
        <NumericFormat
          key={label}
          className={`form-control ${styles.input} w-100`}
          thousandSeparator=","
          decimalSeparator="."
          allowNegative={false}
          valueIsNumericString
          onPaste={(e) => {
            onPasteFloat(e, e.clipboardData.getData('text/plain').replace(/[,]/g, ''));
          }}
          placeholder="Input a value"
          onValueChange={(values) => {
            const { formattedValue, value: newVal } = values;
            if (formattedValue.replace(/[,.]/g, '').length >= 18) {
              showErrorMessage('18 numbers are already entered. You are not allowed to enter more.');
              return;
            }
            if (formattedValue.replace(/[,.]/g, '').length <= 18) {
              setInputValue(newVal);
              setDebouncedValue({ value: newVal.replace(/,/g, '') === '' ? null : newVal.replace(/,/g, ''), name });
            }
          }}
          onBlur={(e) => e.target.blur()}
          onKeyDown={(e) => {
            if (e.target.value.replace(/[,.]/g, '').length >= 18
                    && e.keyCode !== 8 && e.keyCode !== 37 && e.keyCode !== 39
                    && e.keyCode !== 17 && e.keyCode !== 67) {
              e.preventDefault();
            }
          }}
          value={inputValue !== null ? inputValue : Array.isArray(value) ? value[0].value : ''}
          disabled={!edit}
        />
      );
    }

    return (
      <NumericFormat
        onWheel={(e) => e.target.blur()}
        thousandSeparator
        key={label}
        placeholder="Input a value"
        onPaste={onPaste}
        onKeyDown={(e) => lengthOf18andstop(e)}
        className={`form-control ${styles.input} w-100`}
        onValueChange={(e) => {
          const ifValidValue = validateInput(
            e.value,
            name === 'transactions' && conditionName !== 'Social activity',
          );
          if (!ifValidValue) {
            return;
          }
          setInputValue(!e.value ? '' : e.value);
          setDebouncedValue({ value: e.value.replace(/,/g, '') === '' ? null : e.value.replace(/,/g, ''), name });
        }}
        valueIsNumericString
        value={inputValue !== null ? inputValue : Array.isArray(value) ? value[0].value : ''}
        disabled={!edit}
      />
    );
  }, [
    checkFirstSelectField,
    checkSelectField,
    conditionName, edit, inputValue, label, name, setDebouncedValue, setInputValue, value]);
};

export default NumberInput;
