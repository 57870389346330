import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@absolutelabs/react-component-library';
import {
  selectCurrPage,
  selectFieldSettings,
  selectButtonColor,
  selectButtonTextColor,
} from '../../../../../store/reducers/forms';

const ButtonField = ({ ...props }) => {
  const currPage = useSelector(selectCurrPage);
  const fieldSettings = useSelector(selectFieldSettings);
  const globalButtonColor = useSelector(selectButtonColor);
  const globalButtonTextColor = useSelector(selectButtonTextColor);
  const [fieldConfig, setFieldConfig] = useState({});

  useEffect(() => {
    const currPageSettings = fieldSettings.find((o) => o.pageName === currPage);
    const config = currPageSettings?.fields?.find((x) => x.field_id === props?.field?.id);
    if (config) {
      setFieldConfig(config);
    }
  }, [fieldSettings, currPage, props?.field?.id]);

  return (
    <Button
      text={fieldConfig.text || 'Button'}
      link={fieldConfig.link || '#'}
      style={fieldConfig.style || 'filled'}
      align={fieldConfig.align || 'full'}
      fullWidth={fieldConfig.fullWidth || false}
      textColor={globalButtonTextColor || '#ffffff'}
      color={globalButtonColor || '#109cf1'}
    />
  );
};

export default ButtonField;
