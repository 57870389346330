import React from 'react';
import styles from './Select.module.scss';

const TimeSelect = ({ option, pushSelectedOptionToArray, edit }) => {
  const { value, name, type } = option;
  const curr = new Date(new Date().setHours(0, 0, 0));
  return (
    <input
      style={{ height: '39px' }}
      className={`form-control ${styles.select} w-100`}
      type="time"
      value={Array.isArray(value) ? `${value[0]?.value?.split(':')[0]}:${value[0]?.value?.split(':')[1]}` : null}
      onChange={(e) => {
        pushSelectedOptionToArray(e.target.value ? `${e.target.value}:00`
          : curr.toString().slice(16, 24), name, type);
      }}
      disabled={!edit}
    />
  );
};

export default TimeSelect;
