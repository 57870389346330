import { ethers } from 'ethers';
import { erc20AirdropAbi } from './erc20AirdropAbi';
import { erc721AirdropAbi } from './erc721AirdropAbi';
import { erc1155AirdropAbi } from './erc1155AirdropAbi';
import { erc20Abi } from './erc20abi';

const ETH_ADDRESS = import.meta.env.VITE_APP_AIRDROP_ERC20_ETHEREUM_CONTRACT_ADDRESS;
const POLYGON_ADDRESS = import.meta.env.VITE_APP_AIRDROP_ERC20_POLYGON_CONTRACT_ADDRESS;
const BASE_ADDRESS = import.meta.env.VITE_APP_AIRDROP_ERC20_BASE_CONTRACT_ADDRESS;
const LINEA_ADDRESS = import.meta.env.VITE_APP_AIRDROP_ERC20_LINEA_CONTRACT_ADDRESS;
const CHILIZ_ADDRESS = import.meta.env.VITE_APP_AIRDROP_ERC20_CHILIZ_CONTRACT_ADDRESS;
const SEPOLIA_ADDRESS = import.meta.env.VITE_APP_AIRDROP_ERC20_SEPOLIA_CONTRACT_ADDRESS;
const AMOY_ADDRESS = import.meta.env.VITE_APP_AIRDROP_ERC20_AMOY_CONTRACT_ADDRESS;
const BASE_SEPOLIA_ADDRESS = import.meta.env.VITE_APP_AIRDROP_ERC20_BASE_SEPOLIA_CONTRACT_ADDRESS;
const LINEA_SEPOLIA_ADDRESS = import.meta.env.VITE_APP_AIRDROP_ERC20_LINEA_SEPOLIA_CONTRACT_ADDRESS;
const CHILIZ_SPICY_ADDRESS = import.meta.env.VITE_APP_AIRDROP_ERC20_CHILIZ_SPICY_CONTRACT_ADDRESS;

const ERC721_ETH_ADDRESS = import.meta.env.VITE_APP_AIRDROP_ERC721_ETHEREUM_CONTRACT_ADDRESS;
const ERC721_POLYGON_ADDRESS = import.meta.env.VITE_APP_AIRDROP_ERC721_POLYGON_CONTRACT_ADDRESS;
const ERC721_BASE_ADDRESS = import.meta.env.VITE_APP_AIRDROP_ERC721_BASE_CONTRACT_ADDRESS;
const ERC721_LINEA_ADDRESS = import.meta.env.VITE_APP_AIRDROP_ERC721_LINEA_CONTRACT_ADDRESS;
const ERC721_CHILIZ_ADDRESS = import.meta.env.VITE_APP_AIRDROP_ERC721_CHILIZ_CONTRACT_ADDRESS;
const ERC721_SEPOLIA_ADDRESS = import.meta.env.VITE_APP_AIRDROP_ERC721_SEPOLIA_CONTRACT_ADDRESS;
const ERC721_AMOY_ADDRESS = import.meta.env.VITE_APP_AIRDROP_ERC721_AMOY_CONTRACT_ADDRESS;
const ERC721_BASE_SEPOLIA_ADDRESS = import.meta.env.VITE_APP_AIRDROP_ERC721_BASE_SEPOLIA_CONTRACT_ADDRESS;
const ERC721_LINEA_SEPOLIA_ADDRESS = import.meta.env.VITE_APP_AIRDROP_ERC721_LINEA_SEPOLIA_CONTRACT_ADDRESS;
const ERC721_CHILIZ_SPICY_ADDRESS = import.meta.env.VITE_APP_AIRDROP_ERC721_CHILIZ_SPICY_CONTRACT_ADDRESS;

const ERC1155_ETH_ADDRESS = import.meta.env.VITE_APP_AIRDROP_ERC1155_ETHEREUM_CONTRACT_ADDRESS;
const ERC1155_POLYGON_ADDRESS = import.meta.env.VITE_APP_AIRDROP_ERC1155_POLYGON_CONTRACT_ADDRESS;
const ERC1155_BASE_ADDRESS = import.meta.env.VITE_APP_AIRDROP_ERC1155_BASE_CONTRACT_ADDRESS;
const ERC1155_LINEA_ADDRESS = import.meta.env.VITE_APP_AIRDROP_ERC1155_LINEA_CONTRACT_ADDRESS;
const ERC1155_CHILIZ_ADDRESS = import.meta.env.VITE_APP_AIRDROP_ERC1155_CHILIZ_CONTRACT_ADDRESS;
const ERC1155_SEPOLIA_ADDRESS = import.meta.env.VITE_APP_AIRDROP_ERC1155_SEPOLIA_CONTRACT_ADDRESS;
const ERC1155_AMOY_ADDRESS = import.meta.env.VITE_APP_AIRDROP_ERC1155_AMOY_CONTRACT_ADDRESS;
const ERC1155_BASE_SEPOLIA_ADDRESS = import.meta.env.VITE_APP_AIRDROP_ERC1155_BASE_SEPOLIA_CONTRACT_ADDRESS;
const ERC1155_LINEA_SEPOLIA_ADDRESS = import.meta.env.VITE_APP_AIRDROP_ERC1155_LINEA_SEPOLIA_CONTRACT_ADDRESS;
const ERC1155_CHILIZ_SPICY_ADDRESS = import.meta.env.VITE_APP_AIRDROP_ERC1155_CHILIZ_SPICY_CONTRACT_ADDRESS;

export const getAirdropContractAddress = (chain) => {
  let address;
  // Load Airdrop contract
  switch (chain) {
    case 'polygon': address = POLYGON_ADDRESS; break;
    case 'amoy': address = AMOY_ADDRESS; break;
    case 'base': address = BASE_ADDRESS; break;
    case 'linea': address = LINEA_ADDRESS; break;
    case 'eth-sepolia': address = SEPOLIA_ADDRESS; break;
    case 'base-sepolia': address = BASE_SEPOLIA_ADDRESS; break;
    case 'linea-sepolia': address = LINEA_SEPOLIA_ADDRESS; break;
    case 'chiliz': address = CHILIZ_ADDRESS; break;
    case 'chiliz-spicy': address = CHILIZ_SPICY_ADDRESS; break;
    default: address = ETH_ADDRESS;
  }

  return address;
};

export const getERC721AirdropContractAddress = (chain) => {
  let address;
  // Load Airdrop contract
  switch (chain) {
    case 'polygon': address = ERC721_POLYGON_ADDRESS; break;
    case 'amoy': address = ERC721_AMOY_ADDRESS; break;
    case 'base': address = ERC721_BASE_ADDRESS; break;
    case 'linea': address = ERC721_LINEA_ADDRESS; break;
    case 'eth-sepolia': address = ERC721_SEPOLIA_ADDRESS; break;
    case 'base-sepolia': address = ERC721_BASE_SEPOLIA_ADDRESS; break;
    case 'linea-sepolia': address = ERC721_LINEA_SEPOLIA_ADDRESS; break;
    default: address = ERC721_ETH_ADDRESS;
  }

  return address;
};

export const loadERC20Contract = (signer, erc20Address, chainId) => {
  try {
    let contract;
    if (erc20Address) {
      // Load standard ERC20 token
      contract = new ethers.Contract(erc20Address, erc20Abi, signer);
    } else {
      // Load Airdrop contract
      switch (chainId) {
        case 137: contract = new ethers.Contract(POLYGON_ADDRESS, erc20AirdropAbi, signer); break;
        case 8453: contract = new ethers.Contract(BASE_ADDRESS, erc20AirdropAbi, signer); break;
        case 59144: contract = new ethers.Contract(LINEA_ADDRESS, erc20AirdropAbi, signer); break;
        case 88888: contract = new ethers.Contract(CHILIZ_ADDRESS, erc20AirdropAbi, signer); break;
        case 11155111: contract = new ethers.Contract(SEPOLIA_ADDRESS, erc20AirdropAbi, signer); break;
        case 80002: contract = new ethers.Contract(AMOY_ADDRESS, erc20AirdropAbi, signer); break;
        case 84532: contract = new ethers.Contract(BASE_SEPOLIA_ADDRESS, erc20AirdropAbi, signer); break;
        case 59141: contract = new ethers.Contract(LINEA_SEPOLIA_ADDRESS, erc20AirdropAbi, signer); break;
        case 88882: contract = new ethers.Contract(CHILIZ_SPICY_ADDRESS, erc20AirdropAbi, signer); break;
        default: contract = new ethers.Contract(ETH_ADDRESS, erc20AirdropAbi, signer);
      }
    }
    return contract;
  } catch (err) {
    return Promise.reject(
      new Error('There was an error connecting to contract!'),
    );
  }
};

export const loadNFTAirdropContract = (signer, isERC1155, chainId) => {
  try {
    let contract;
    if (!isERC1155) {
      // Load ERC721 Airdrop contract
      switch (chainId) {
        case 137: contract = new ethers.Contract(ERC721_POLYGON_ADDRESS, erc721AirdropAbi, signer); break;
        case 8453: contract = new ethers.Contract(ERC721_BASE_ADDRESS, erc721AirdropAbi, signer); break;
        case 59144: contract = new ethers.Contract(ERC721_LINEA_ADDRESS, erc721AirdropAbi, signer); break;
        case 88888: contract = new ethers.Contract(ERC721_CHILIZ_ADDRESS, erc721AirdropAbi, signer); break;
        case 11155111: contract = new ethers.Contract(ERC721_SEPOLIA_ADDRESS, erc721AirdropAbi, signer); break;
        case 80002: contract = new ethers.Contract(ERC721_AMOY_ADDRESS, erc721AirdropAbi, signer); break;
        case 84532: contract = new ethers.Contract(ERC721_BASE_SEPOLIA_ADDRESS, erc721AirdropAbi, signer); break;
        case 59141: contract = new ethers.Contract(ERC721_LINEA_SEPOLIA_ADDRESS, erc721AirdropAbi, signer); break;
        case 88882: contract = new ethers.Contract(ERC721_CHILIZ_SPICY_ADDRESS, erc721AirdropAbi, signer); break;
        default: contract = new ethers.Contract(ERC721_ETH_ADDRESS, erc721AirdropAbi, signer);
      }
    } else {
      // Load ERC1155 Airdrop contract
      switch (chainId) {
        case 137: contract = new ethers.Contract(ERC1155_POLYGON_ADDRESS, erc1155AirdropAbi, signer); break;
        case 8453: contract = new ethers.Contract(ERC1155_BASE_ADDRESS, erc1155AirdropAbi, signer); break;
        case 59144: contract = new ethers.Contract(ERC1155_LINEA_ADDRESS, erc1155AirdropAbi, signer); break;
        case 88888: contract = new ethers.Contract(ERC1155_CHILIZ_ADDRESS, erc1155AirdropAbi, signer); break;
        case 11155111: contract = new ethers.Contract(ERC1155_SEPOLIA_ADDRESS, erc1155AirdropAbi, signer); break;
        case 80002: contract = new ethers.Contract(ERC1155_AMOY_ADDRESS, erc1155AirdropAbi, signer); break;
        case 84532: contract = new ethers.Contract(ERC1155_BASE_SEPOLIA_ADDRESS, erc1155AirdropAbi, signer); break;
        case 59141: contract = new ethers.Contract(ERC1155_LINEA_SEPOLIA_ADDRESS, erc1155AirdropAbi, signer); break;
        case 88882: contract = new ethers.Contract(ERC1155_CHILIZ_SPICY_ADDRESS, erc1155AirdropAbi, signer); break;
        default: contract = new ethers.Contract(ERC1155_ETH_ADDRESS, erc1155AirdropAbi, signer);
      }
    }
    return contract;
  } catch (err) {
    return Promise.reject(
      new Error('There was an error connecting to contract!'),
    );
  }
};
