import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { useForm } from 'react-hook-form';
import {
  clearActiveSettings,
  onSettingsChange,
  removeErrors,
  selectConfigsList,
  selectFlowStatus,
} from '../../../../../store/reducers/flows';
import styles from './WaitUntilNodeSettings.module.scss';
import Warning from '../../../../../assets/icons/flows/info.svg';
import { formatDate } from '../../../../../utils/flows';
import EditableTitle from '../../../../../components/base/EditableTitle';

const WaitUntilNodeSettings = ({ activeSettings }) => {
  const [titleValue, setTitleValue] = useState('');
  const dispatch = useDispatch();

  const {
    register, handleSubmit, setValue, watch, reset,
  } = useForm();

  const configList = useSelector(selectConfigsList);
  const status = useSelector(selectFlowStatus);

  const disableChangeStatus = useMemo(() => status === 'running'
    || status === 'scheduled' || status === 'stopped', [status]);

  const onSubmit = (submitData) => {
    const waitInfo = {
      ...submitData,
      date: formatInTimeZone(
        new Date(submitData.date),
        'UTC',
        'yyyy-MM-dd,HH:mm',
      ).replace(',', 'T'),
    };
    if (titleValue) {
      waitInfo.name = titleValue;
    } else {
      waitInfo.name = 'Wait until';
    }
    dispatch(removeErrors(activeSettings.node_id));
    dispatch(onSettingsChange({ ...waitInfo, node_id: activeSettings.node_id }));
    dispatch(clearActiveSettings());
  };

  useEffect(() => {
    if (configList.length) {
      const index = configList.map((object) => object.node_id).indexOf(activeSettings.node_id);
      if (index >= 0) {
        setTitleValue(configList[index].name);
        setValue('date', formatDate(configList[index].date, 'yyyy-MM-dd,HH:mm').replace(',', 'T'));
      } else {
        reset({
          date: '',
        });
        setTitleValue('');
      }
    }
  }, [activeSettings.node_id, configList, reset, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="d-flex flex-column justify-content-between h-100">
      <div>
        <div className={`${styles.title} d-flex align-items-center gap-2`}>
          <EditableTitle
            defaultTitle="Wait until"
            titleValue={titleValue}
            setTitleValue={setTitleValue}
            maxLength={25}
            size={16}
            edit={!disableChangeStatus}
          />
        </div>
        <div className="d-flex flex-column w-100 gap-1">
          <div className={styles.title_input}>
            Pick Date & Time
          </div>
          <div className={disableChangeStatus ? styles.link_area_disabled : styles.link_area}>
            <input
              {...register('date')}
              min={new Date().toISOString().slice(0, -8)}
              max="9999-12-31T00:00"
              type="datetime-local"
              className="w-100 p-2"
              disabled={disableChangeStatus}
            />
          </div>
          {watch('date') && (
            <div className={`${styles.date_info} d-flex px-2 py-3 gap-1`}>
              <Warning />
              <div>
                {`This step will hold wallets until ${format(new Date(watch('date')), 'MMMM dd, yyyy, p OOOO')}`}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="d-flex gap-4 mt-4">
        <button
          type="button"
          className={`${styles.btn_cancel}`}
          onClick={() => dispatch(clearActiveSettings())}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="regular-button w-100"
          disabled={!watch('date') || disableChangeStatus}
        >
          Save
        </button>
      </div>
    </form>
  );
};

export default WaitUntilNodeSettings;
