import React from 'react'
import AiBlock from '../../../components/ui/AiBlock'
import useWebSocket from '../../../components/hooks/app/useWebSocket';

const subTitle = {
  current: 'Current Holders',
  new: 'Newcomers',
  lost: 'Churned Holders',
};

const AiAudience = ({ isReady, address, network, filterOption, isCustomContract }) => {
  const shouldConnect = isReady && !['relevant', 'all'].includes(filterOption) && !isCustomContract;
  const url = shouldConnect ? 'ws/nft/audience-profile/ai-overview/' : null;
  const payload = shouldConnect ? { address, blockchain: network, audience: filterOption } : null;
  const { aiOverview } = useWebSocket(url, payload, !shouldConnect, address, filterOption);

  return (
    <AiBlock
      data={aiOverview}
      linkText="Read more"
      title={`Deep-Dive on ${subTitle[filterOption]} -`}
      wBackground
      isProfile
    />
  )
}

export default AiAudience
