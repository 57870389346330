import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getCurrentFolder,
} from '../../../store/reducers/folders';
import emitter from '../../../utils/emitter';
import { foldersApi } from '../../../api/folders';
import FolderButton from './FolderButton';
import CollapsableFolderButton from './CollapsableFolderButton';
import AddFolderIcon from '../../../assets/icons/folders/add_folder.svg';
import ManageFoldersModal from '../modals/Folders/ManageFoldersModal';
import AddFolderModal from '../modals/Folders/AddFolderModal';
import AddSubfolderModal from '../modals/Folders/AddSubfolderModal';
import RenameFolderModal from '../modals/Folders/RenameFolderModal';
import ConfirmModal from '../modals/ConfirmModal';
import styles from './listWithFolders.module.scss';
import { showErrorMessage, showSuccessMessage } from '../../base/Notifications';
import useSetSearchParams from '../../../utils/useSetSearchParams';

const ListWithFolders = ({
  folders,
  allText,
  myText,
  type,
  children,
}) => {
  const setParams = useSetSearchParams();
  const containerRef = useRef();
  const foldersRef = useRef();
  const [deleteFolder] = foldersApi.useDeleteFolderMutation();
  const [foldersHeight, setFoldersHeight] = useState('auto');
  const [editingItemId, setEditingItemId] = useState(null);
  const [editingItemType, setEditingItemType] = useState(null);
  const [editingFolder, setEditingFolder] = useState(null);
  const [deleteFolderMessage, setDeleteFolderMessage] = useState('');
  const [showManageFoldersModal, setShowManageFoldersModal] = useState(false);
  const [showAddFolderModal, setShowAddFolderModal] = useState(false);
  const [showAddSubfolderModal, setShowAddSubfolderModal] = useState(false);
  const [showRenameFolderModal, setShowRenameFolderModal] = useState(false);
  const [showDeleteFolderModal, setShowDeleteFolderModal] = useState(false);
  const selectedFolder = useSelector((state) => getCurrentFolder(state, type));

  useEffect(() => {
    if (containerRef.current) {
      const topMargin = 38;
      const headerHeight = containerRef.current.getBoundingClientRect().top;
      setFoldersHeight(`calc(100vh - ${headerHeight + topMargin}px)`);
    } else {
      setFoldersHeight('auto');
    }
  }, [containerRef.current]);

  const openManageFolderModal = (id, itemType) => {
    setEditingItemId(id);
    setEditingItemType(itemType);
    setShowManageFoldersModal(true);
  };

  const handleAddFolder = () => {
    setShowAddFolderModal(true);
  };

  const openAddSubfolderModal = (folder) => {
    setEditingFolder(folder);
    setShowAddSubfolderModal(true);
  };

  const openRenameFolderModal = (folder) => {
    setEditingFolder(folder);
    setShowRenameFolderModal(true);
  };

  const openDeleteFolderModal = (folder) => {
    if (folder.sub_folders) {
      setDeleteFolderMessage(
        `This folder and all its subfolders will be removed. This action will not delete any ${type}.`,
      );
    } else {
      setDeleteFolderMessage(`This folder will be removed. This action will not delete any ${type}.`);
    }
    setEditingFolder(folder);
    setShowDeleteFolderModal(true);
  };

  const handleDelete = async () => {
    const result = await deleteFolder({ id: editingFolder.id, type });
    if (result.error) {
      showErrorMessage('Error during folder deletion');
    } else {
      if (selectedFolder.id === editingFolder.id) {
        setParams({
          folder: 'all',
        });
      }
      emitter.emit('refetch_folders');
      showSuccessMessage('Folder was successfully deleted');
      setShowDeleteFolderModal(false);
    }
  };

  return (
    <>
      {showManageFoldersModal && (
        <ManageFoldersModal
          closeModal={() => setShowManageFoldersModal(false)}
          itemId={editingItemId}
          itemType={editingItemType}
        />
      )}
      {showAddFolderModal && (
        <AddFolderModal
          closeModal={() => setShowAddFolderModal(false)}
          type={type}
        />
      )}
      {showAddSubfolderModal && (
        <AddSubfolderModal
          closeModal={() => setShowAddSubfolderModal(false)}
          parent={editingFolder}
          type={type}
        />
      )}
      {showRenameFolderModal && (
        <RenameFolderModal
          closeModal={() => setShowRenameFolderModal(false)}
          folder={editingFolder}
          type={type}
        />
      )}
      {showDeleteFolderModal && (
        <ConfirmModal
          title="Confirm deletion"
          description={deleteFolderMessage}
          buttonName="Delete"
          onCancel={() => setShowDeleteFolderModal(false)}
          onSubmit={handleDelete}
          deleteMode
        />
      )}
      <div className={styles.container} ref={containerRef}>
        <div className={styles.folders} ref={foldersRef} style={{ maxHeight: foldersHeight }}>
          <div className={styles.first_folders}>
            <FolderButton
              folder={{
                id: 'all',
                name: allText,
                sub_folder: [],
              }}
              type={type}
            />
            <FolderButton
              folder={{
                id: 'my',
                name: myText,
                sub_folder: [],
              }}
              type={type}
            />
          </div>
          {folders.map((f) => {
            if (f.sub_folders && f.sub_folders.length > 0) {
              return (
                <CollapsableFolderButton
                  key={f.id}
                  folder={f}
                  type={type}
                  addSubfolder={openAddSubfolderModal}
                  renameFolder={openRenameFolderModal}
                  deleteFolder={openDeleteFolderModal}
                  foldersRef={foldersRef}
                />
              );
            }
            return (
              <FolderButton
                key={f.id}
                position={3}
                folder={f}
                type={type}
                addSubfolder={openAddSubfolderModal}
                renameFolder={openRenameFolderModal}
                deleteFolder={openDeleteFolderModal}
                foldersRef={foldersRef}
              />
            );
          })}
          <div className={`${styles.last_folder}`}>
            <button
              type="button"
              className="regular-button btn_with_icon"
              onClick={handleAddFolder}
            >
              <AddFolderIcon />
              New folder
            </button>
          </div>
        </div>

        <div className={styles.list}>
          {React.Children.map(children, (child) => React.cloneElement(child, {
            manageFolders: openManageFolderModal,
          }))}
        </div>
      </div>
    </>
  );
};

export default ListWithFolders;
