import { format } from 'date-fns';

const defineWrapper = (func) => (typeof func !== 'function' ? () => func : func);

const timeout = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const sleep = async (ms, func) => {
  await timeout(ms);
  const wrappedFunc = defineWrapper(func);
  wrappedFunc();
};

const handleTimeZone = (time) => {
  if (!time) return;
  const timeOffset = new Date().getTimezoneOffset();
  const currentTimeInMilliSec = +format(new Date(time), 'T') + (timeOffset * (-1));
  return new Date(currentTimeInMilliSec).toISOString();
};

export {
  defineWrapper, timeout, sleep, handleTimeZone,
};
