import React, { useMemo } from 'react'
import uniqueId from '../../../../utils/uniqueId'
import CameraIcon from '../../../../assets/icons/camera_raw.svg';
import styles from './Snapshot.module.scss'
import Tooltip from '../../Tooltip';

const Snapshot = ({ onClick, tooltip }) => {
  const tooltipId = useMemo(() => uniqueId('snapshotButton'), []);

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  }

  return (
    <>
      <button
        type="button"
        className={styles.button}
        data-for={tooltipId}
        data-tip
        onClick={handleClick}
      >
        <CameraIcon height="16px" width="16px" />
      </button>
      <Tooltip
        id={tooltipId}
        info={tooltip}
      />
    </>
  )
}

export default Snapshot
