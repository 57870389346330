import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import DappUsageTable from '../../../components/ui/DappUsageTable';
import { audiencesApi } from '../../../api/audiences';

const DappUsage = ({ holders }) => {
  const { id } = useParams();
  const [page, setPage] = useState(1);
  const [desc, setDesc] = useState(true);
  const [orderBy, setOrder] = useState('number_of_users_last_30d');
  const [limit, setLimit] = useState(25);

  const {
    data: dappData,
    isLoading: isDappDataLoading,
    isFetching: isDappDataFetching,
  } = audiencesApi.useGetCustomListDappUsageDataQuery({
    id,
    limit,
    offset: (page - 1) * limit,
    order: desc ? 'desc' : 'asc',
    orderBy,
  });

  return (
    <DappUsageTable
      holders={holders}
      data={dappData}
      isLoading={isDappDataLoading || isDappDataFetching}
      setPage={setPage}
      setDesc={setDesc}
      setOrder={setOrder}
      setLimit={setLimit}
      page={page}
      limit={limit}
      desc={desc}
      orderBy={orderBy}
    />
  );
};

export default DappUsage;
