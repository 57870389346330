import React, { useState } from 'react';
import remove from '../../../assets/images/homepage/remove.png';
import { formatAssetData } from '../../../utils/assetsData/formatAssetData';
import DefaultIcon from '../../../components/ui/DefaultIcon';

const SelectAsset = ({
  name, img, id, flag, assetType, onAddOrDeleteAsset, network,
}) => {
  const [cardLogoError, setCardLogoError] = useState(false);

  return (
    <div className="select d-flex align-items-center gap-4">
      <div className="d-flex align-items-center gap-2 select-info">
        {
          !cardLogoError
            ? (
              <img
                onError={() => setCardLogoError(true)}
                src={img || ''}
                alt="title"
                width="18"
                height="18"
              />
            )
            : <DefaultIcon size="18px" type="nft" />
        }
        <span>{name?.toUpperCase()}</span>
      </div>
      <button type="button" className="close">
        <img
          src={remove}
          alt="close"
          width="12"
          height="12"
          onClick={() => {
            onAddOrDeleteAsset(
              formatAssetData('delete', flag, assetType, id, network),
            );
          }}
        />
      </button>
    </div>
  );
};

export default SelectAsset;
