import React from 'react';

import styles from './Select.module.scss';

const Select = ({
  label, name, register, options, ...props
}) => (
  <div className="form-floating ms-2 p-1">
    <select
      defaultValue=""
      className={`form-select ${styles.input}`}
      id="floatingSelect"
      aria-label="Floating label select example"
      {...register(name)}
      {...props}
    >
      {options.map((option, index) => {
        if (index === 0) {
          return (
            <option
              disabled
              key={option.value}
              value={option.value}
              defaultValue={option.value}
            >
              {option.label}
            </option>
          );
        }
        return (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        );
      })}
    </select>
    <label htmlFor="floatingSelect" className={styles.label}>
      {label}
    </label>
  </div>
);

export default Select;
