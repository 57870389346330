import React from 'react';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import './LoginModal.scss';
import { getPopupOptions } from '../../../../utils/getPopupConfiguration';
import { clearState } from '../../../../store/reducers/app';

const LoginModal = () => {
  const { loginWithPopup } = useAuth0();
  const dispatch = useDispatch();

  const modalStyle = {
    opacity: 1,
    backgroundColor: 'rgba(0,0,0,0.5)',
  };

  return (
    <div
      className="modal modal-dialog-centered modal-phones"
      id="exampleModal"
      style={modalStyle}
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div
        role="presentation"
        onClick={(e) => e.stopPropagation()}
        className="modal-dialog modal-md modal-phone"
        style={{ width: '100%' }}
      >
        <div className="modal-content login-modal-wrapper">
          <div className="modal-title">Please log in to use the platform</div>
          <button
            type="button"
            onClick={async () => {
              dispatch(clearState());
              const popup = getPopupOptions();
              await loginWithPopup(null, { popup, timeoutInSeconds: 1000 });
              document.location.reload();
            }}
            className="btn btn-primary modal-button"
          >
            <span className="button-text">Log In</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginModal;
