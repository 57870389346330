import React from 'react';
import Intelligence from '../../assets/icons/interests/artificial_intelligence.svg';
import Augmented from '../../assets/icons/interests/augmented.svg';
import BigData from '../../assets/icons/interests/big_data.svg';
import Charity from '../../assets/icons/interests/charity.svg';
import Communication from '../../assets/icons/interests/communication.svg';
import CEX from '../../assets/icons/interests/cex.svg';
import DEX from '../../assets/icons/interests/DEX.svg';
import Defi from '../../assets/icons/interests/defi.svg';
import Derivatives from '../../assets/icons/interests/derivatives.svg';
import Education from '../../assets/icons/interests/education.svg';
import Energy from '../../assets/icons/interests/energy.svg';
import Entertainment from '../../assets/icons/interests/entertainment.svg';
import Fractionalized from '../../assets/icons/interests/fractionalized_nft.svg';
import Gambling from '../../assets/icons/interests/Gambling.svg';
import Gaming from '../../assets/icons/interests/gaming.svg';
import Governance from '../../assets/icons/interests/governance.svg';
import Healthcare from '../../assets/icons/interests/healthcare.svg';
import Infrastructure from '../../assets/icons/interests/infrastructure.svg';
import Insurance from '../../assets/icons/interests/insurance.svg';
import IOT from '../../assets/icons/interests/iot.svg';
import Launchpad from '../../assets/icons/interests/Launchpad.svg';
import Borrowing from '../../assets/icons/interests/Borrowing.svg';
import Marketing from '../../assets/icons/interests/Marketing.svg';
import Meme from '../../assets/icons/interests/memes.svg';
import Metaverse from '../../assets/icons/interests/metaverse.svg';
import Music from '../../assets/icons/interests/Music.svg';
import Options from '../../assets/icons/interests/Options.svg';
import Earn from '../../assets/icons/interests/earn.svg';
import Privacy from '../../assets/icons/interests/Privacy Coins.svg';
import Estate from '../../assets/icons/interests/real Estate.svg';
import Retail from '../../assets/icons/interests/Retail.svg';
import Sports from '../../assets/icons/interests/Sports.svg';
import Science from '../../assets/icons/interests/Technology & science.svg';
import Tourism from '../../assets/icons/interests/Tourism.svg';
import Virtual from '../../assets/icons/interests/Virtual Reality.svg';
import Wallets from '../../assets/icons/interests/wallets.svg';
import Aggregator from '../../assets/icons/interests/Yield aggregator.svg';
import Farming from '../../assets/icons/interests/yield_farming.svg';
import Collectibles from '../../assets/icons/interests/Collectibles.svg';
import DomainNames from '../../assets/icons/interests/Domain Names.svg';
import Art from '../../assets/icons/interests/ART.svg';
import Photography from '../../assets/icons/interests/Photography.svg';
import TradingCards from '../../assets/icons/interests/Trading Cards.svg';
import Membership from '../../assets/icons/interests/Membership.svg';
import Email from '../../assets/icons/alias/email.svg';
import Discord from '../../assets/icons/alias/discrod.svg';
import Twitter from '../../assets/icons/alias/twitter.svg';
import Name from '../../assets/icons/alias/name.svg';
import Telegram from '../../assets/icons/alias/telegram.svg';
import Opensea from '../../assets/icons/alias/opensea.svg';
import AMM from '../../assets/icons/interests/amm.svg';
import Business from '../../assets/icons/interests/business.svg';
import DePIN from '../../assets/icons/interests/depin.svg';
import Environment from '../../assets/icons/interests/environment.svg';
import Identity from '../../assets/icons/interests/identity.svg';
import MoveToEarn from '../../assets/icons/interests/MoveToEarn.svg';
import Oracles from '../../assets/icons/interests/oracles.svg';
import PaymentSolutions from '../../assets/icons/interests/payment_solutions.svg';
import Perpetuals from '../../assets/icons/interests/perpetuals.svg';
import PredictionMarkets from '../../assets/icons/interests/predict_markets.svg';
import RealWorldAssets from '../../assets/icons/interests/rwa.svg';
import Restaking from '../../assets/icons/interests/restaking.svg';
import SocialFi from '../../assets/icons/interests/socialFi.svg';
import Storage from '../../assets/icons/interests/storage.svg';

export const interestData = {
  AI: <Intelligence />,
  Art: <Art />,
  'Augmented Reality': <Augmented />,
  'Automated Market Maker (AMM)': <AMM />,
  'Big Data': <BigData />,
  Business: <Business />,
  'Card Games': <TradingCards />,
  'Centralized Exchanges': <CEX />,
  Charity: <Charity />,
  Collectibles: <Collectibles />,
  Communication: <Communication />,
  DePIN: <DePIN />,
  'Decentralized Exchanges': <DEX />,
  'Decentralized Finance (DeFi)': <Defi />,
  Derivatives: <Derivatives />,
  Education: <Education />,
  Energy: <Energy />,
  Entertainment: <Entertainment />,
  Environment: <Environment />,
  'Fractionalized NFTs': <Fractionalized />,
  Gambling: <Gambling />,
  Gaming: <Gaming />,
  Governance: <Governance />,
  Healthcare: <Healthcare />,
  Identity: <Identity />,
  Infrastructure: <Infrastructure />,
  Insurance: <Insurance />,
  'Internet of Things (IOT)': <IOT />,
  Launchpads: <Launchpad />,
  'Lending/Borrowing': <Borrowing />,
  Marketing: <Marketing />,
  Memberships: <Membership />,
  Memes: <Meme />,
  Metaverse: <Metaverse />,
  'Move To Earn': <MoveToEarn />,
  Music: <Music />,
  Options: <Options />,
  Oracles: <Oracles />,
  PFPs: <Collectibles />,
  'Payment Solutions': <PaymentSolutions />,
  Perpetuals: <Perpetuals />,
  Photography: <Photography />,
  'Play To Earn': <Earn />,
  'Prediction Markets': <PredictionMarkets />,
  'Privacy Coins': <Privacy />,
  'Real Estate': <Estate />,
  'Real World Assets (RWA)': <RealWorldAssets />,
  Restaking: <Restaking />,
  Retail: <Retail />,
  SocialFi: <SocialFi />,
  Sports: <Sports />,
  Storage: <Storage />,
  'Technology & Science': <Science />,
  Tourism: <Tourism />,
  'Virtual Reality': <Virtual />,
  Wallets: <Wallets />,
  'Yield Aggregator': <Aggregator />,
  'Yield Farming': <Farming />,
  'Domain Names': <DomainNames />,
};

export const aliasData = {
  email: <Email />,
  name: <Name />,
  twitter_id: <Twitter />,
  discord_id: <Discord />,
  telegram_id: <Telegram />,
  marketplace_name: <Opensea />,
};

export const aliasTooltipInfo = {
  email: 'Email Address',
  name: 'First and Last Name',
  twitter_id: 'Twitter ID',
  discord_id: 'Discord ID',
  telegram_id: 'Telegram ID',
  marketplace_name: 'OpenSea Name',
};
