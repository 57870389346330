import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import AcceptedInvitationModal from '../../components/ui/modals/AcceptedInvitationModal';
import { getPopupOptions } from '../../utils/getPopupConfiguration';

const AcceptedInvitation = () => {
  const {
    isLoading,
    loginWithPopup,
    error,
    isAuthenticated,
  } = useAuth0();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const userEmail = searchParams.get('email');

  useEffect(() => {
    if (error || isAuthenticated) navigate('/dashboard');
  }, [error, navigate, isAuthenticated]);

  const formatEmail = (email) => {
    if (email.includes(' ')) {
      return email.replace(' ', '+');
    }
    return email;
  };

  return isLoading ? null
    : (
      <AcceptedInvitationModal openModal={async () => {
        const popup = getPopupOptions();
        const formattedEmail = formatEmail(userEmail);
        await loginWithPopup({
          login_hint: formattedEmail || '',
        }, { popup, timeoutInSeconds: 1000 });
      }}
      />
    );
};

export default AcceptedInvitation;
