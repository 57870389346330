import React from 'react';
import styles from '../ModalSettings.module.scss';

const TextArea = ({
  title,
  value,
  placeholder,
  onChange,
  rows = 1,
  error = '',
}) => (
  <div className={styles.settings_wrapper}>
    <div className={styles.title}>{title}</div>
    <div className={`${styles.text_area_wrapper}`}>
      <textarea
        rows={rows}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
    {error && (
      <div className={`${styles.error}`}>
        {error}
      </div>
    )}
  </div>
);

export default TextArea;
