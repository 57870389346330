import React from 'react';
import { convertNumber } from '../../../tools/NumberConverterTool';
import CircleChart from '../CircleChart';

const TypicalPortfolio = ({
  data, isLoading, isFetching,
}) => {
  const seriesDataCount = () => {
    if (data) {
      const defaultValueNFT = Math.max(0, data.nft50);
      const defaultValueStable = Math.max(0, data.stable50);
      const defaultValueToken = Math.max(0, data.token50);
      const defaultValueNative = Math.max(0, data.total50 - data.nft50 - data.stable50 - data.token50);
      const total = defaultValueNFT + defaultValueStable + defaultValueToken + defaultValueNative;
      if (total === 0 || total === undefined) return [];
      return [
        {
          label: 'NFTs',
          percent: defaultValueNFT ? Math.max(0, Math.min(((defaultValueNFT / total) * 100).toFixed(2), 100)) : 0,
          count: `$${defaultValueNFT}`,
        },
        {
          label: 'Stable Coins',
          percent: defaultValueStable ? Math.max(0, Math.min(((defaultValueStable / total) * 100).toFixed(2), 100)) : 0,
          count: `$${defaultValueStable}`,
        },
        {
          label: 'Token',
          percent: defaultValueToken ? Math.max(0, Math.min(((defaultValueToken / total) * 100).toFixed(2), 100)) : 0,
          count: `$${defaultValueToken}`,
        },
        {
          label: 'Native Tokens',
          percent: defaultValueNative ? Math.max(0, Math.min(((defaultValueNative / total) * 100).toFixed(2), 100)) : 0,
          count: `$${defaultValueNative}`,
        },
      ].sort((a, b) => b.percent - a.percent);
    }
    return [];
  };
  const walletAge = data?.first_txn50
    ? convertNumber(((Date.now() - Date.parse(data?.first_txn50)) / 86400000).toFixed(0)) : null;
  let highValue = 'No Transactions';
  if (walletAge !== undefined && walletAge !== null && !Number.isNaN(walletAge)) {
    highValue = `${walletAge} Days Old`;
  }

  return (
    <div>
      <CircleChart
        title="Typical Portfolio"
        highlightText="Wallet Age"
        highlightValue={highValue}
        data={seriesDataCount().map((elem) => elem)}
        info="Typical portfolio distribution among the audience, based on the median value of each category of asset"
        isLoading={isLoading || isFetching}
        landscape
      />
    </div>
  );
};

export default React.memo(TypicalPortfolio);
