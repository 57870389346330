import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

import { getPopupOptions } from '../../utils/getPopupConfiguration';
import RejectedInvitationModal from '../../components/ui/modals/RejectedInvitationModal';

const RejectedInvitation = () => {
  const {
    loginWithPopup,
    error,
    isAuthenticated,
  } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (error || isAuthenticated) navigate('/dashboard');
  }, [error, navigate, isAuthenticated]);

  return (
    <div>
      <RejectedInvitationModal openModal={async () => {
        const popup = getPopupOptions();
        await loginWithPopup({}, { popup, timeoutInSeconds: 1000 });
      }}
      />
    </div>
  );
};

export default RejectedInvitation;
