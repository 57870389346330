import React from 'react';
import { useSelector } from 'react-redux';
import {
  getCurrentFolder,
  hasSelectedItems,
} from '../../../../store/reducers/folders';
import Counter from '../../Counter';
import SearchBar from '../../SearchBar';
import FolderActions from '../FolderActions';
import styles from './tableHeader.module.scss';

const TableHeader = ({
  count,
  page,
  limit,
  searchValue,
  handleSearch,
  children,
  className,
  type,
}) => {
  const selectedFolder = useSelector((state) => getCurrentFolder(state, type));
  const hasSelection = useSelector((state) => hasSelectedItems(state, type));

  return (
    <div className={`${styles.wrapper} ${hasSelection ? styles.hasSelection : ''} ${className || ''}`}>
      {hasSelection
      && (
        <div className={styles.selection_options}>
          <FolderActions type={type} />
        </div>
      )}
      <Counter count={count} page={page} limit={limit} />

      {!hasSelection && (
        <SearchBar
          value={searchValue}
          handleSearch={handleSearch}
          placeholder={`Search in ${selectedFolder.name}`}
        />
      )}
      {children}
    </div>
  );
};

export default TableHeader;
