export const colors = [
  {
    color: '#109cf1',
    class: 'blue',
  },
  {
    color: '#FFB946',
    class: 'yellow',
  },
  {
    color: '#2ED47A',
    class: 'green',
  },
  {
    color: '#885AF8',
    class: 'purple',
  },
  {
    color: '#F7685B',
    class: 'red',
  },
  {
    color: '#90a0b7',
    class: 'gray',
  },
  {
    color: '#FF8C56',
    class: 'orange',
  },
  {
    color: '#000000',
    class: 'black',
  },
  {
    color: '#0A1F44',
    class: 'darkBlue',
  },
];

export const options = {
  chart: {
    type: 'donut',
    events: {
      dataPointMouseEnter(event) {
        event.target.style.cursor = 'pointer';
      },
    },
  },
  plotOptions: {
    pie: {
      expandOnClick: false,
    },
  },
  states: {
    normal: {
      filter: {
        type: 'none',
        value: 0,
      },
    },
    hover: {
      filter: {
        type: 'none',
        value: 0,
      },
    },
    active: {
      allowMultipleDataPointsSelection: false,
      filter: {
        type: 'none',
        value: 0,
      },
    },
  },
  stroke: {
    width: 3,
    colors: ['#f1f4f8'],
  },
  dataLabels: {
    enabled: false,
  },
  legend: {
    show: false,
  },
  tooltip: {
    enabled: true,
    marker: {
      show: false,
    },
    x: {
      show: false,
    },
    y: {
      formatter: (val, opts) => {
        const { labels } = opts.globals;
        const serieIndex = opts.seriesIndex;
        return labels.length > serieIndex ? labels[serieIndex] : '';
      },
      title: {
        formatter: () => '',
      },
    },
  },
  fixed: {
    enabled: false,
  },
  colors: colors.map((color) => color.color),
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
        },
      },
    },
  ],
};

export const tooltip = (value, label, percent, count) => `
${value || label}: ${percent}% (${count?.toLocaleString('en-US')})
`;
