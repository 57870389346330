import { ethFormat } from './singleAssetPage/parseData';

export const getTablePercent = (value, total) => {
  if (total === 0) {
    return '';
  }

  const percent = (value / total) * 100;

  if (percent === 0) return '0';
  if (percent >= 0.01) return `${ethFormat(percent, 1)}%`;
  return '<0.01%';
};

export const toMaxNDecimals = (value, n = 2) => {
  if (value === null || value === undefined) return '';
  if (value === 0) return '0';

  let formattedNum = value.toFixed(n);
  let decimalsZero = '.';

  for (let i = 0; i < n; i += 1) {
    decimalsZero += '0';
  }

  if (formattedNum.endsWith(decimalsZero)) {
    formattedNum = formattedNum.slice(0, -(n + 1));
  }

  if (formattedNum === '-0' || formattedNum === `-0.${'0'.repeat(n)}`) {
    formattedNum = formattedNum.slice(1);
  }

  return formattedNum;
};
