import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getEdgeCenter, getSmoothStepPath } from 'react-flow-renderer';
import styles from './CustomEdge.module.scss';
import { selectFlowStatus } from '../../../../store/reducers/flows';
import BlinkingDot from '../../../../components/ui/BlinkingDot';

const CustomEdge = ({
  id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, style = {},
  markerEnd, borderRadius = 0, label, data, selected,
}) => {
  const runningContent = useRef(null);

  const [contentWidth, setContentWidth] = useState(15);
  const [edgeData, setEdgeData] = useState(null);

  const edgePath = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    borderRadius,
  });

  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  const calculateMiddle = () => {
    const lMiddle = +((Math.abs(targetY - sourceY) + Math.abs(targetX - sourceX)) / 2).toFixed(0);
    let xMiddle;
    let yMiddle;
    if (Math.abs(targetX - sourceX) >= (Math.abs(targetY - sourceY))) {
      yMiddle = targetY;
      if (targetX > sourceX) {
        xMiddle = targetX - lMiddle;
      } else {
        xMiddle = targetX + lMiddle;
      }
    } else {
      xMiddle = sourceX;
      if (targetY > sourceY) {
        yMiddle = sourceY + lMiddle;
      } else {
        yMiddle = sourceY - lMiddle;
      }
    }
    return ({ x: xMiddle, y: yMiddle });
  };
  const status = useSelector(selectFlowStatus);

  useEffect(() => {
    if (runningContent?.current?.offsetWidth) {
      setContentWidth(runningContent.current.offsetWidth / 2);
    }
  }, [runningContent?.current?.offsetWidth]);

  useEffect(() => {
    if (data.stat) {
      setEdgeData(data.stat);
    }
  }, [data?.stat]);
  return (
    <>
      <path
        id={id}
        style={style}
        className={`${styles.flow} ${selected && status !== 'running' && status !== 'scheduled' ? styles.draw : ''}`}
        d={edgePath}
        markerEnd={markerEnd}
      />
      {selected && status !== 'running' && status !== 'stopped' && status !== 'scheduled'
        ? (
          <foreignObject
            width={20}
            height={20}
            x={sourcePosition === 'bottom' ? calculateMiddle().x - 10 : edgeCenterX - 20 / 2}
            y={sourcePosition === 'bottom' ? calculateMiddle().y - 10 : edgeCenterY - 20 / 2}
            className={styles.button_block}
          >
            <button
              type="button"
              className={styles.edge_button}
              onClick={(event) => {
                event.stopPropagation();
                data.onEdgeRemove(id);
              }}
            >
              <span>
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.45998 3.99998L7.99983 6.53983V7.99983H6.53983L3.99998 5.45998L1.46015
                    7.99983H0.000146866V6.53983L2.53998 3.99998L0 1.46002V2.28882e-05H1.46L3.99998
                    2.53998L6.53996 0H7.99995V1.46L5.45998 3.99998Z"
                    fill="white"
                  />
                </svg>
              </span>
            </button>
          </foreignObject>
        )
        : null}
      {edgeData || data.isLoading
        ? (
          <foreignObject
            width={100}
            height={24}
            x={sourcePosition === 'bottom' ? calculateMiddle().x - contentWidth : edgeCenterX - contentWidth}
            y={sourcePosition === 'bottom' ? calculateMiddle().y - 12 : edgeCenterY - 12}
            className={styles.button_block}
          >
            <div className={styles.running_data} ref={runningContent}>
              {edgeData || <BlinkingDot />}
            </div>
          </foreignObject>
        )
        : null}
      {label && !selected
        ? (
          <foreignObject
            width={50}
            height={24}
            x={sourcePosition === 'bottom' ? edgeCenterX - 25 : edgeCenterX - 16}
            y={sourcePosition === 'bottom' ? targetY - 12 : edgeCenterY - 12}
            className={styles.button_block}
          >
            <div className={styles.running_data}>
              {label}
            </div>
          </foreignObject>
        )
        : null}
    </>
  );
};

export default CustomEdge;
