import React from 'react';
import { useNavigate } from 'react-router-dom';
import PopupMenuButton from '../../PopupMenuButton';
import CreateFlow from '../../../../assets/icons/flow.svg';

const AddFlowButton = ({ folderId }) => {
  const navigate = useNavigate();

  const handleClick = (e) => {
    e.stopPropagation();
    navigate('/add-flow', { state: { folder: folderId } });
  };

  return (
    <PopupMenuButton onClick={(e) => handleClick(e)}>
      <CreateFlow color="#90A0B7" />
      Create flow
    </PopupMenuButton>
  );
};

export default AddFlowButton;
