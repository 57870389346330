import React from 'react';
import Tooltip from '../../ui/Tooltip';
import uniqueId from '../../../utils/uniqueId';
import { suppertedWallets } from './wallets'
import { formatWalletName } from '../../../utils/walletIcons';
import styles from './WalletIcon.module.scss'

const WalletIcon = ({ wallet, small = false, medium = false, large = false, square = false, rounded = false, rounded_s = false }) => {
  let style = small
    ? styles.small
    : medium
      ? styles.medium
      : large
        ? styles.large
        : styles.normal;
  if (square) {
    style = [style, styles.square].join(' ');
  }
  if (rounded) {
    style = [style, styles.rounded].join(' ');
  } else if (rounded_s) {
    style = [style, styles.rounded_s].join(' ');
  }
  const tooltipId = uniqueId(wallet);

  if (!wallet) {
    return null;
  }

  const walletData = suppertedWallets[formatWalletName(wallet)];
  if (!walletData) {
    console.error(`No icon found for ${wallet}`);
    return null;
  }

  return (
    <div
      role="presentation"
    >
      <div
        className="d-flex"
        data-for={tooltipId}
        data-tip
      >
        <img src={walletData.icon} className={style} />
      </div>
      <Tooltip id={tooltipId} info={walletData.tooltip} />
    </div>
  )
}

export default WalletIcon
