export const adjustDateByDays = (days, fromDate = null) => {
  const startDate = fromDate || new Date();
  startDate.setHours(0, 0, 0, 0);
  const resultDate = new Date(startDate);
  resultDate.setDate(startDate.getDate() + days);
  return resultDate;
};

export const stringToDate = (dateString) => {
  const regex = /^\d{4}-\d{2}-\d{2}$/;

  if (!regex.test(dateString)) {
    throw new Error('Invalid date string!');
  }

  const [year, month, day] = dateString.split('-');
  const result = new Date(year, month - 1, day);
  return result;
};

export const dateToString = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const getAgeInMilliseconds = (date) => {
  const now = Date.now();
  const givenDate = new Date(date).getTime();
  return now - givenDate;
};
