import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCurrPage,
  selectFieldSettings,
  setFieldSettings,
} from '../../../../../../store/reducers/forms';
import ModalSettings from '../../../Components/ModalSettings';
import Input from '../../../Components/ModalSettings/Input';
import Switch from '../../../Components/ModalSettings/Switch';

const NameSettings = ({ activeSettings }) => {
  const dispatch = useDispatch();
  const initialized = useRef(false);
  const currPage = useSelector(selectCurrPage);
  const fieldSettings = useSelector(selectFieldSettings);
  const [label, setLabel] = useState('Name');
  const [nameType, setNameType] = useState('firstAndLast');
  const [isFirstnameOnly, setIsFirstnameOnly] = useState(false);
  const [firstPlaceholder, setFirstPlaceholder] = useState('First Name');
  const [lastPlaceholder, setLastPlaceholder] = useState('Last Name');
  const [firstSubLabel, setFirstSubLabel] = useState('');
  const [lastSubLabel, setLastSubLabel] = useState('');
  const [required, setRequired] = useState(true);
  const [change, setChange] = useState(false);
  const [errors, setErrors] = useState({});

  const handleChange = useCallback(() => {
    setChange(false);
    const obj = {
      name: 'name',
      type: 'name',
      label,
      nameType,
      firstPlaceholder,
      lastPlaceholder,
      firstSubLabel,
      lastSubLabel,
      required,
    };
    dispatch(setFieldSettings({ ...obj, field_id: activeSettings.field_id }));
  }, [
    label, nameType, firstPlaceholder, lastPlaceholder, firstSubLabel, lastSubLabel, required, dispatch, activeSettings,
  ]);

  const setOptions = (config) => {
    setLabel(config.label);
    setIsFirstnameOnly(config.nameType === 'first');
    setFirstPlaceholder(config.firstPlaceholder);
    setLastPlaceholder(config.lastPlaceholder);
    setFirstSubLabel(config.firstSubLabel);
    setLastSubLabel(config.lastSubLabel);
    setRequired(config.required);
  };

  useEffect(() => {
    if (initialized.current) return;
    const currPageSettings = fieldSettings.find((o) => o.pageName === currPage);
    if (currPageSettings) {
      const config = currPageSettings.fields?.find((x) => x.field_id === activeSettings.field_id);
      if (config) {
        setOptions(config);
      } else {
        handleChange();
      }
    }
    initialized.current = true;
  }, [activeSettings, currPage, fieldSettings]);

  useEffect(() => {
    if (change) {
      handleChange();
    }
  }, [label, nameType, firstPlaceholder, lastPlaceholder, firstSubLabel, lastSubLabel, required]);

  useEffect(() => {
    const newErrors = { ...errors };
    if (firstPlaceholder.length === 30) {
      newErrors.firstPlaceholder = 'Character limit reached';
    } else {
      newErrors.firstPlaceholder = '';
    }
    if (lastPlaceholder.length === 30) {
      newErrors.lastPlaceholder = 'Character limit reached';
    } else {
      newErrors.lastPlaceholder = '';
    }
    if (label.length === 250) {
      newErrors.label = 'Character limit reached';
    } else {
      newErrors.label = '';
    }
    if (firstSubLabel.length === 250) {
      newErrors.firstSubLabel = 'Character limit reached';
    } else {
      newErrors.firstSubLabel = '';
    }
    if (lastSubLabel.length === 250) {
      newErrors.lastSubLabel = 'Character limit reached';
    } else {
      newErrors.lastSubLabel = '';
    }
    setErrors(newErrors);
  }, [firstPlaceholder, lastPlaceholder, label, firstSubLabel, lastSubLabel]);

  useEffect(() => {
    if (isFirstnameOnly) {
      setNameType('first');
    } else {
      setNameType('firstAndLast');
    }
  }, [isFirstnameOnly]);

  return (
    <ModalSettings title="Name Properties">
      <Switch
        text="Required Field"
        checked={required}
        onChange={() => {
          setChange(true);
          setRequired(!required);
        }}
      />

      <Input
        title="Label"
        value={label}
        placeholder="Type here"
        onChange={(e) => {
          setChange(true);
          setLabel(e.currentTarget.value);
        }}
        maxLength={250}
        error={errors.label}
      />

      <Switch
        text="First name only"
        checked={isFirstnameOnly}
        onChange={() => {
          setChange(true);
          setIsFirstnameOnly(!isFirstnameOnly);
        }}
      />

      <Input
        title="First name placeholder text"
        value={firstPlaceholder}
        placeholder="Type here"
        onChange={(e) => {
          setChange(true);
          setFirstPlaceholder(e.currentTarget.value);
        }}
        maxLength={30}
        error={errors.firstPlaceholder}
        optional
      />

      {nameType === 'firstAndLast' && (
        <Input
          title="Last name placeholder text"
          value={lastPlaceholder}
          placeholder="Type here"
          onChange={(e) => {
            setChange(true);
            setLastPlaceholder(e.currentTarget.value);
          }}
          maxLength={30}
          error={errors.lastPlaceholder}
          optional
        />
      )}

      <Input
        title="First name sublabel"
        value={firstSubLabel}
        placeholder="Type here"
        onChange={(e) => {
          setChange(true);
          setFirstSubLabel(e.currentTarget.value);
        }}
        maxLength={250}
        error={errors.firstSubLabel}
        optional
      />

      {nameType === 'firstAndLast' && (
        <Input
          title="Last name sublabel"
          value={lastSubLabel}
          placeholder="Type here"
          onChange={(e) => {
            setChange(true);
            setLastSubLabel(e.currentTarget.value);
          }}
          maxLength={250}
          error={errors.lastSubLabel}
          optional
        />
      )}

    </ModalSettings>
  );
};

export default NameSettings;
