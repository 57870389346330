import React, { useEffect, useState } from 'react';
import CircleChart from '../../CircleChart';
import { getGrowth } from '../../../../utils/values';

const TypicalPortfolioDistribution = ({
  activityData, previousHighlight, isLoading, className = '',
}) => {
  const [data, setData] = useState([]);
  const [avgShare, setAvgShare] = useState('');
  const [avgPercent, setAvgPercent] = useState(0);
  const [previousAvgPercent, setPreviousAvgPercent] = useState(0);

  const processData = (dataToProccess) => {
    if (!dataToProccess) return [];
    const total = dataToProccess.buckets.reduce((sum, { value }) => sum + value, 0);
    if (total === 0) {
      return [];
    }
    const newData = dataToProccess.buckets.map((bucket) => {
      const percent = total ? ((bucket.value * 100) / total).toFixed(2) : 0;
      return {
        label: bucket.label,
        count: bucket.value,
        percent: +percent,
      };
    }).sort((a, b) => b.percent - a.percent);

    return newData;
  };

  useEffect(() => {
    if (activityData && !isLoading) {
      const processedData = processData(activityData);

      if (processedData && processedData.length > 0) {
        setAvgShare(`${processedData[0].label} (${processedData[0].percent}%)`);
        setAvgPercent(processedData[0].percent);
        setData(processedData);
      } else {
        setData([]);
        setAvgPercent(0);
        setAvgShare('');
      }
    }
  }, [activityData, isLoading]);

  useEffect(() => {
    if (previousHighlight && !isLoading) {
      const processedData = processData(previousHighlight);

      if (processedData && processedData.length > 0) {
        setPreviousAvgPercent(processedData[0].percent);
      } else {
        setPreviousAvgPercent(0);
      }
    }
  }, [previousHighlight, isLoading]);

  return (
    <div className={className || ''}>
      <CircleChart
        title="Portfolio Distribution"
        info="Typical portfolio distribution among the audience, based on the median value of each category of asset"
        highlightText="Main Asset Category"
        highlightValue={avgShare}
        data={data}
        isLoading={isLoading || (!data.length && isLoading)}
        growth={getGrowth(
          avgPercent,
          previousAvgPercent,
          `${previousAvgPercent.toFixed(2)}%`,
        )}
      />
    </div>
  );
};

export default TypicalPortfolioDistribution;
