import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import styles from './RandomSplitTooltip.module.scss';
import Check from '../../../../../assets/icons/flows/check.svg';
import Close from '../../../../../assets/icons/flows/close.svg';
import useOutsideClick from '../../../../../components/hooks/app/useOutsideClick';
import useKeydown from '../../../../../components/hooks/app/useKeydown';

const RandomSplitTooltip = ({
  edit, setEdit, onLabelChange, id, source, unselectEdge, value,
}) => {
  const wrapperRef = useRef(null);
  const {
    register, handleSubmit, setFocus, setValue,
  } = useForm();

  const onSubmit = (data) => {
    onLabelChange(id, source, data.value);
    setEdit(false);
  };

  const onCancel = () => {
    unselectEdge(id);
    setEdit(false);
  };

  useOutsideClick(wrapperRef, () => setEdit(false));
  useKeydown('Escape', onCancel);

  useEffect(() => {
    setFocus('value');
  }, [edit, setFocus]);

  useEffect(() => {
    if (value) {
      setValue('value', value);
    }
  }, [value]);

  return (
    <div
      ref={wrapperRef}
      className={`${styles.wrapper} ${edit ? styles.active : ''} position-absolute`}
    >
      <div
        className={`${styles.triangle} position-absolute w-100`}
      />
      {!edit
        ? (
          <span>Click to edit</span>
        )
        : (
          <form
            className={`${styles.input} d-flex align-items-center justify-content-center`}
            onSubmit={handleSubmit(onSubmit)}
          >
            <input
              type="number"
              onWheel={(e) => e.target.blur()}
              min={0}
              max={100}
              {...register('value')}
            />
            <button type="submit">
              <Check />
            </button>
            <div className={styles.divider} />
            <Close
              className="cursor-pointer"
              onClick={() => onCancel()}
            />
          </form>
        )}
    </div>
  );
};

export default RandomSplitTooltip;
