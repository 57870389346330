import React, { useState, useRef, useEffect } from 'react';
import emitter from '../../../../../utils/emitter';
import CloseModalButton from '../../components/CloseModalButton';
import useKeydown from '../../../../hooks/app/useKeydown';
import { showErrorMessage, showSuccessMessage } from '../../../../base/Notifications';
import { foldersApi } from '../../../../../api/folders';
import styles from '../foldersModal.module.scss';

const AddFolderModal = ({ closeModal, type }) => {
  const [createFolder] = foldersApi.useCreateFolderMutation();
  const [folderName, setFolderName] = useState('');
  const inputRef = useRef(null);

  useKeydown('Escape', closeModal);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleConfirm = async () => {
    if (!folderName) {
      showErrorMessage('Folder name cannot be empty');
      return;
    }
    const result = await createFolder({ name: folderName, type });
    if (result.error) {
      showErrorMessage('Error during folder creation, please try again');
      return;
    }
    showSuccessMessage('Folder was successfully created');
    emitter.emit('refetch_folders');
    closeModal();
  };

  useEffect(() => {
    const handleKeyDown = async (event) => {
      if (event.key === 'Enter') {
        await handleConfirm();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [folderName]);

  return (
    <div
      className={`modal modal-dialog-centered ${styles.modal_wrapper}`}
      id="confirmModal"
      tabIndex="-1"
      aria-labelledby="confirmModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-md modal-phone w-100 position-relative">
        <div className={`${styles.body} modal-content position-relative border-0`}>
          <CloseModalButton onCancel={closeModal} />
          <div>
            <div className={`${styles.title} d-flex justify-content-start`}>
              Create a new folder
            </div>
          </div>

          <input
            ref={inputRef}
            type="text"
            maxLength={100}
            className={`form-control mb-4 ${styles.input}`}
            placeholder="Add folder name here"
            value={folderName}
            onChange={(e) => setFolderName(e.target.value)}
          />

          <div className={`${styles.footer} d-flex justify-content-end`}>
            <button
              type="button"
              className="outline-button border border-1"
              onClick={closeModal}
            >
              Cancel
            </button>
            <button
              type="button"
              className="regular-button"
              onClick={handleConfirm}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddFolderModal;
