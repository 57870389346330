export const convertValues = (data, type, filter = {}) => {
  if (!data?.options || !type) {
    return { status: false };
  }

  const { options } = data;

  let convertedValues = { status: false };
  switch (type) {
    // TODO: [TESTED]
    case 'general-values': {
      convertedValues = { status: true, options };
      break;
    }
    // TODO: [TESTED]
    case 'set-values': {
      const optionsToPush = data.options
        .filter((option) => Array.isArray(option.value))
        .slice(
          0,
          (data.type === 'string' && !['is-set', "isn't-set"].includes(data.options[0].value[0].value))
            ? 2 : 1,
        );
      convertedValues = { status: true, options: optionsToPush };
      break;
    }
    case 'cex-boolean-values': {
      const optionsToPush = ['true', 'false'].includes(options[0].value[0].value)
        ? [...options.slice(0, 1), ...options.slice(9, 10)]
        : [...options.slice(0, 2), ...options.slice(9, 10)];
      if (optionsToPush.every((opt) => opt.value)) {
        convertedValues = { status: true, options: optionsToPush };
      }

      break;
    }
    // TODO: [TESTED]
    case 'cex-values': {
      const transactionValue = data?.options[3]?.value?.[0]?.value;

      let optionsToPush = [];

      if (transactionValue === 'ever') {
        optionsToPush = data.options.slice(0, 3);
      } else if (['and-first-transaction', 'and-last-transaction'].includes(transactionValue)) {
        if (Array.isArray(data.options[4].value)
        && (data.options[4]?.value[0]?.value === 'is-before'
        || data.options[4]?.value[0]?.value === 'is-after')) {
          optionsToPush = [...data.options.slice(0, 5), ...data.options.slice(6, 7)];
        } else {
          optionsToPush = [...data.options.slice(0, 5), ...data.options.slice(7, 9)];
        }
      } else if (transactionValue === 'and-transaction-count') {
        optionsToPush = [...data.options.slice(0, 4), ...data.options.slice(5, 6), ...data.options.slice(7, 8)];
      }

      if (optionsToPush.every((opt) => opt.value)
      && (transactionValue === 'ever' ? optionsToPush.length === 3 : optionsToPush.length > 3)) {
        convertedValues = { status: true, options: optionsToPush };
      }
      break;
    }
    // TODO: [TESTED]
    case 'int-values': {
      let optionsToPush;
      if (data.type === 'date') {
        optionsToPush = [...data.options.slice(0, 1), ...data.options.slice(2, 4)];
      } else if (['float', 'number'].includes(data.type)) {
        optionsToPush = [...data.options];
      } else {
        optionsToPush = [...data.options.slice(0, 1), ...data.options.slice(3, 5)];
      }
      if (optionsToPush.every((opt) => opt.value)) {
        convertedValues = { status: true, options: optionsToPush };
      }
      break;
    }
    // TODO: [TESTED]
    case 'date-values': {
      const optionsToPush = data.type === 'date' ? data.options.slice(0, 2) : data.options.slice(0, 3);
      if (optionsToPush.every((opt) => opt.value)) {
        convertedValues = { status: true, options: optionsToPush };
      }
      break;
    }
    // TODO: [TESTED]
    case 'contain-values': {
      const optionsToPush = data.options.filter((option) => option.type !== 'custom-search');
      convertedValues = { status: true, options: optionsToPush };
      break;
    }
    // TODO: [TESTED]
    case 'search-values': {
      const optionsToPush = data.options.filter((option) => option.type !== 'multi-input');
      convertedValues = { status: true, options: optionsToPush };
      break;
    }
    // TODO: [TESTED]
    case 'filter-values': {
      const optionsToPush = data.options.slice(0, 3);
      convertedValues = { status: true, options: optionsToPush };
      break;
    }
    // TODO: [TEST]
    case 'dapp-values': {
      let optionsToPush;

      if (filter.dappTypeKey === 'dappTotalTransaction') {
        optionsToPush = [...data.options.slice(0, 3), ...data.options.slice(4, 5), ...data.options.slice(6, 7)];
      } else if (filter.dappTypeKey === 'dappTransactionValues') {
        optionsToPush = [...data.options.slice(0, 4), ...data.options.slice(4, 5), ...data.options.slice(6, 7)];
      } else if (filter.dappTypeKey === 'dappFirstLastTransaction') {
        if (filter.subFilter === 'is-before' || filter.subFilter === 'is-after') {
          optionsToPush = [...data.options.slice(0, 3), ...data.options.slice(5, 6), ...data.options.slice(7, 8)];
        } else {
          optionsToPush = [...data.options.slice(0, 3), ...data.options.slice(5, 7), ...data.options.slice(8, 9)];
        }
      }

      convertedValues = { status: true, options: optionsToPush };
      break;
    }
    case 'search-forms': {
      const optionsToPush = data.options.filter((option) => option.type !== 'multi-input');
      convertedValues = { status: true, options: optionsToPush };
      break;
    }
    case 'set-values-web-activity': {
      const optionsToPush = data.options
        .filter((option) => Array.isArray(option.value));
      convertedValues = { status: true, options: optionsToPush };
      break;
    }
  }

  return convertedValues;
};
