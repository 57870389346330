import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import Tooltip from '../../../components/ui/Tooltip';
import styles from './Filters.module.scss';
import { setFilterOption } from '../../../store/reducers/coin';

const Filters = () => {
  const [option, setOption] = useState('relevant');
  const dispatch = useDispatch();
  const buttonRefs = useRef({});

  const buttonData = [
    {
      type: 'relevant',
      text: 'Relevant Holders',
      info: 'Holders owning at least $5 worth of this token',
    },
    {
      type: 'new',
      text: 'Newcomers 7d',
      info: 'Holder who bought in for the first time in the past 7 days',
    },
    {
      type: 'lost',
      text: 'Churned 7d',
      info: 'Former holders who sold out their last tokens in the last 7 days',
    },
    {
      type: 'current',
      text: 'All Current Holders',
      info: 'All current holders of this token, regardless of their balance',
    },
    {
      type: 'all',
      text: 'All Historical Holders',
      info: 'All wallets that ever held this token',
    },
    {
      type: 'lookalike',
      text: 'Lookalikes',
      info: `Coming soon: wallets the most similar to the current holders of this asset,
      out of all the active addresses on the blockchain`,
    },
  ];

  useEffect(() => {
    dispatch(setFilterOption(option));
  }, [option, dispatch]);

  useEffect(() => {
    const buttonKeys = Object.keys(buttonRefs.current);
    buttonKeys.forEach((key) => {
      const button = buttonRefs.current[key];
      if (button) {
        const width = button.offsetWidth;
        button.style.maxWidth = `${width}px`;
      }
    });
  }, []);

  return (
    <div className={`${styles.wrapper} mt-4 mb-3 d-flex gap-4`}>
      <div className="btn-group me-4 group" role="group" aria-label="Second group">
        {buttonData.map((button, index) => (
          <div
            key={button.type}
            data-for={button.type}
            data-tip={button.info}
            className={`
            ${index === 0 ? `${styles.first_button}` : ''}
            ${index === buttonData.length - 1 ? `${styles.last_button}` : `${styles.middle_button}`}`}
          >
            <button
              // eslint-disable-next-line no-return-assign
              ref={(el) => buttonRefs.current[button.type] = el}
              type="button"
              className={`
              btn shadow-none 
              ${option === button.type ? 'active' : ''} 
              d-flex align-items-center justify-content-space-between nowrap
              ${index === 0 ? `${styles.first_button}` : index === buttonData.length - 1
            ? `${styles.last_button}` : `${styles.middle_button}`}`}
              onClick={() => setOption(button.type)}
              disabled={button.type === 'lookalike'}
            >
              {button.text}
              {button.type === 'lookalike' ? (<div className={styles.beta}>AI</div>) : null}
            </button>
            <Tooltip id={button.type} info={button.info} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Filters;
