import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import {
  selectCurrPage, selectFieldSettings, setFieldSettings,
} from '../../../../../../store/reducers/forms';
import styles from './OptInSettings.module.scss';
import formStyles from '../../FormSettings.module.scss';
import ModalSettings from '../../../Components/ModalSettings';
import settingsStyles from '../../../Components/ModalSettings/ModalSettings.module.scss';
import Switch from '../../../Components/ModalSettings/Switch';

const OptInSettings = ({ activeSettings }) => {
  const quillRef = useRef(null);
  const initialized = useRef(false);
  const dispatch = useDispatch();
  const currPage = useSelector(selectCurrPage);
  const fieldSettings = useSelector(selectFieldSettings);
  const [content, setContent] = useState('<p>I have read and accept the terms and conditions.</p>');
  const [align, setAlign] = useState('left');
  const [required, setRequired] = useState(true);
  const debouncedUpdateStoreRef = useRef();
  const [errors, setErrors] = useState({});

  const updateStore = useCallback(async () => {
    const obj = {
      name: 'optIn',
      content,
      align,
      type: 'optIn',
      required,
    };
    dispatch(setFieldSettings({ ...obj, field_id: activeSettings.field_id }));
  }, [content,
    align,
    required,
    dispatch,
    activeSettings,
  ]);

  useEffect(() => {
    if (debouncedUpdateStoreRef.current) {
      debouncedUpdateStoreRef.current.cancel();
    }

    debouncedUpdateStoreRef.current = _.debounce(updateStore, 300);
    debouncedUpdateStoreRef.current();

    return () => {
      if (debouncedUpdateStoreRef.current) {
        debouncedUpdateStoreRef.current.cancel();
      }
    };
  }, [content, align, required, updateStore]);

  const handleContentChange = (value) => {
    const textContent = value.replace(/<[^>]+>/g, '');
    if (textContent.length <= 1000) {
      setContent(value);
      if (errors.content) {
        setErrors({});
      }
    } else {
      const truncatedContent = textContent.slice(0, 1000);
      setContent(truncatedContent);
      setErrors({ ...errors, content: 'Character limit of 1000 reached' });
    }
  };

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline',
        { list: 'ordered' },
        { list: 'bullet' }, 'link',
      ],
    ],
  };

  const formats = [
    'bold', 'italic', 'underline', 'list', 'link',
  ];

  const setOptions = (config) => {
    setContent(config.content);
    setAlign(config.align);
    setRequired(config.required);
  };

  useEffect(() => {
    if (initialized.current) return;
    const currPageSettings = fieldSettings.find((o) => o.pageName === currPage);
    if (currPageSettings) {
      const config = currPageSettings.fields?.find((x) => x.field_id === activeSettings.field_id);
      if (config) {
        setOptions(config);
      } else {
        handleContentChange(content);
      }
    }
    initialized.current = true;
  }, [activeSettings, currPage, fieldSettings]);

  const handleClick = (e) => {
    if (!e.target.closest('.ql-container') && !e.target.closest('.ql-toolbar')) {
      if (quillRef.current) {
        quillRef.current.focus();
        const quill = quillRef.current.getEditor();
        quill.setSelection(quill.getLength());
      }
    }
  };

  return (
    <ModalSettings title="Opt in Properties">
      <Switch
        text="Required Field"
        checked={required}
        onChange={() => {
          setRequired(!required);
        }}
      />

      <div className={styles.wrapper}>
        <div className={settingsStyles.title}>Opt in text</div>
        <div role="none" onClick={handleClick}>
          <ReactQuill
            ref={quillRef}
            formats={formats}
            className={styles.quillContainer}
            theme="snow"
            value={content}
            onChange={handleContentChange}
            modules={modules}
          />
        </div>
        {errors.content && (
          <div className={`${formStyles.error}`}>
            {errors.content}
          </div>
        )}
      </div>
    </ModalSettings>
  );
};

export default OptInSettings;
