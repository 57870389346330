import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  name: '',
  activeSettings: null,
  currPage: '',
  fieldSettings: [],
  url: '',
  allowMultipleSubmissions: false,
  backgroundImage: {},
  formImage: {},
  images: {},
  accessType: '',
  style: {
    formStyle: {
      colourTheme: '#FFFFFF',
      formColour: '#FFFFFF',
      formImageUrl: '',
      textColour: '#000000',
    },
    buttonStyle: {
      buttonColour: '#109CF1',
      textColour: '#FFFFFF',
    },
    backgroundStyle: {
      backgroundColour: '#ECEFF3',
      backgroundImageUrl: '',
    },
    fieldStyle: {
      invertColours: false,
    },
  },
  errors: [],
  isUnsaved: false,
};

export const formsSlice = createSlice({
  name: 'forms',
  initialState,
  reducers: {
    setFormName: (state, action) => {
      state.name = action.payload;
    },
    setFormUrl: (state, action) => {
      state.url = action.payload;
    },
    setAllowMultipleSubmissions: (state, action) => {
      state.allowMultipleSubmissions = action.payload;
    },
    setAccessType: (state, action) => {
      state.accessType = action.payload;
    },
    setCurrPage: (state, action) => {
      state.currPage = action.payload;
    },
    setFieldSettings: (state, action) => {
      const currPageindex = state.fieldSettings
        .findIndex((object) => object?.pageName === state.currPage);
      const index = state.fieldSettings[currPageindex]
        ?.fields?.findIndex((object) => object?.field_id === action.payload.field_id);
      if (currPageindex !== -1 && index !== -1) {
        state.fieldSettings[currPageindex].fields[index] = action.payload;
      } else {
        if (!state.fieldSettings[currPageindex]) {
          state.fieldSettings.push({
            pageName: state.currPage,
            fields: [],
          });
        }
        state.fieldSettings[state.fieldSettings
          .findIndex((object) => object?.pageName === state.currPage)]?.fields.push(action.payload);
      }
    },
    deleteFieldSettings: (state, action) => {
      state.fieldSettings[state.currPage] = state.fieldSettings[state.currPage]
        .filter((elem) => elem.field_id !== action.payload);
    },
    setFormStyle: (state, action) => {
      state.style = {
        formStyle: {
          colourTheme: action.payload.formStyle.colourTheme,
          formColour: action.payload.formStyle.formColour,
          formImageUrl: action.payload.formStyle.formImageUrl,
          textColour: action.payload.formStyle.textColour,
        },
        buttonStyle: {
          buttonColour: action.payload.buttonStyle.buttonColour,
          textColour: action.payload.buttonStyle.textColour,
        },
        backgroundStyle: {
          backgroundColour: action.payload.backgroundStyle.backgroundColour,
          backgroundImageUrl: action.payload.backgroundStyle.backgroundImageUrl,
        },
        fieldStyle: {
          invertColours: action.payload.fieldStyle.invertColours,
        },
      };
    },
    setActiveSettings: (state, action) => {
      state.activeSettings = action.payload;
    },
    clearActiveSettings: (state) => {
      state.activeSettings = null;
    },
    setErrors: (state, action) => {
      state.errors = action.payload;
    },
    isUnsavedForm: (state, action) => {
      state.isUnsaved = action.payload;
    },
    removeErrors: (state, action) => {
      state.errors = state.errors.filter((elem) => elem.field_id !== action.payload);
    },
    clearFormState: () => initialState,
    loadFieldSettings: (state, action) => {
      const currPageindex = state.fieldSettings
        .findIndex((object) => object?.pageName === action.payload.pageName);
      const index = (state.fieldSettings[currPageindex]?.fields || [])
        .findIndex((object) => object?.field_id === action.payload.field.field_id
        || object?.id === action.payload.field.id);

      let processedField = action.payload.field;
      if (action.payload.field?.options) {
        processedField = {
          ...action.payload.field.options,
          ...action.payload.field,
          field_id: action.payload.field.id,
        };
        delete processedField.options;
        delete processedField.id;
      }
      if (currPageindex !== -1 && index !== -1) {
        state.fieldSettings[currPageindex].fields[index] = processedField;
      } else {
        if (!state.fieldSettings[currPageindex]) {
          state.fieldSettings.push({
            pageName: action.payload.pageName,
            fields: [],
          });
        }
        state.fieldSettings[state.fieldSettings
          .findIndex((object) => object?.pageName === action.payload.pageName)]?.fields.push(processedField);
      }
    },
    removeFieldSettings: (state, action) => {
      const currPageindex = state.fieldSettings.findIndex((object) => object?.pageName === action.payload.pageName);
      if (currPageindex === -1) return;
      const index = (
        state.fieldSettings[currPageindex]?.fields || []).findIndex(
        (object) => object?.field_id === action.payload.field.field_id || object?.field_id === action.payload.field.id,
      );
      if (index !== -1) {
        state.fieldSettings[currPageindex].fields.splice(index, 1);
      }
    },
    initializeFieldSettings: (state) => {
      const hasMainForm = state.fieldSettings.some((settings) => settings.pageName === 'main-form');
      if (!hasMainForm) {
        state.fieldSettings.push({
          pageName: 'main-form',
          fields: [],
        });
      }
      const hasThankYouForm = state.fieldSettings.some((settings) => settings.pageName === 'thank-you');
      if (!hasThankYouForm) {
        state.fieldSettings.push({
          pageName: 'thank-you',
          fields: [],
        });
      }
    },
    updateFieldSettingsForPage: (state, action) => {
      const { pageName, fields } = action.payload;
      const pageIndex = state.fieldSettings.findIndex((page) => page.pageName === pageName);

      if (pageIndex !== -1) {
        state.fieldSettings[pageIndex].fields = fields;
      } else {
        state.fieldSettings.push({
          pageName,
          fields,
        });
      }
    },
    setImageFile: (state, action) => {
      const { fieldId, file } = action.payload;
      if (!state.images[fieldId]) {
        state.images[fieldId] = [];
      }
      state.images[fieldId].push(file);
    },
    setBackgroundFile: (state, action) => {
      const { file } = action.payload;
      state.backgroundImage = file;
    },
    setFormImageFile: (state, action) => {
      const { file } = action.payload;
      state.formImage = file;
    },
    removeImageFile: (state, action) => {
      const { fieldId } = action.payload;

      if (state.images[fieldId] && state.images[fieldId].length > 0) {
        state.images[fieldId].pop();
      }

      if (state.images[fieldId] && state.images[fieldId].length === 0) {
        delete state.images[fieldId];
      }
    },
    setTabPages: (state, action) => {
      state.tabPages = action.payload;
    },
  },
});

export const {
  setFormName,
  setFormUrl,
  setAllowMultipleSubmissions,
  setAccessType,
  setCurrPage,
  setFieldSettings,
  deleteFieldSettings,
  setFormStyle,
  setActiveSettings,
  clearActiveSettings,
  setErrors,
  isUnsavedForm,
  removeErrors,
  clearFormState,
  loadFieldSettings,
  initializeFieldSettings,
  removeFieldSettings,
  updateFieldSettingsForPage,
  setImageFile,
  removeImageFile,
  setBackgroundFile,
  setFormImageFile,
  setTabPages,
} = formsSlice.actions;

// Add FormsReducer
export const selectFormName = (state) => state.formsReducer.name;
export const selectFormUrl = (state) => state.formsReducer.url;
export const selectAllowMultipleSubmissions = (state) => state.formsReducer.allowMultipleSubmissions;
export const selectAccessType = (state) => state.formsReducer.accessType;
export const selectCurrPage = (state) => state.formsReducer.currPage;
export const selectFieldSettings = (state) => state.formsReducer.fieldSettings;
export const selectFormStyle = (state) => state.formsReducer.style;
export const selectActiveSettings = (state) => state.formsReducer.activeSettings;
export const selectErrors = (state) => state.formsReducer.errors;
export const selectFormStatus = (state) => state.formsReducer.status;
export const selectImageByFieldId = (state, fieldId) => state.formsReducer.images[fieldId];
export const selectAllImages = (state) => state.formsReducer.images;
export const selectBackgroundImage = (state) => state.formsReducer.backgroundImage;
export const selectFormImage = (state) => state.formsReducer.formImage;
export const selectFormColor = (state) => state.formsReducer.style.formStyle.formColour;
export const selectFormTextColor = (state) => state.formsReducer.style.formStyle.textColour;
export const selectButtonColor = (state) => state.formsReducer.style.buttonStyle.buttonColour;
export const selectButtonTextColor = (state) => state.formsReducer.style.buttonStyle.textColour;
export const selectBackgroundColor = (state) => state.formsReducer.style.backgroundStyle.backgroundColour;
export const selectFormImageUrl = (state) => state.formsReducer.style.formStyle.formImageUrl;
export const selectBackgroundImageUrl = (state) => state.formsReducer.style.backgroundStyle.backgroundImageUrl;
export const selectInvertColours = (state) => state.formsReducer.style.fieldStyle.invertColours;
export const selectTabPages = (state) => state.segmentReducer.tabPages;

export default formsSlice.reducer;
