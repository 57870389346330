import React, { useMemo, useState } from 'react';
import WebSDKCreationModal from './modals/WebSDKCreationModal';
import styles from '../../Dashboard/Dashboard.module.scss';

const WebSDKCreation = () => {
  const [showWebSDKModal, setShowWebSDKModal] = useState(false);

  return useMemo(() => (
    <>
      <button
        type="button"
        className={`regular-button ${styles.nft_button}`}
        onClick={() => setShowWebSDKModal(true)}
      >
        Track a Website
      </button>
      {showWebSDKModal && (
        <WebSDKCreationModal
          onSubmit={() => setShowWebSDKModal(false)}
          onCancel={() => setShowWebSDKModal(false)}
        />
      )}
    </>
  ), [showWebSDKModal]);
};

export default WebSDKCreation;
