import React from 'react';
import { counter } from '../../../tools/Counter';
import styles from './counter.module.scss';

const Counter = ({
  count, page, limit, className,
}) => (
  <div className={styles.wrapper}>
    <div className={`${styles.showing} ${className || ''}`}>
      {counter(count, page, limit)}
    </div>
  </div>
);

export default Counter;
