import React from 'react';

import { useWindowSize } from '../../../hooks/app';
import './Asset.scss';

const Asset = ({ assetData }) => {
  const { width } = useWindowSize();
  return (
    <div className="side-asset-container">
      <img className="image" src={assetData.thumb} alt="asset" />
      {width > 1210 ? (
        <>
          <div className="name">{assetData.name}</div>
          <div className="collection">{assetData.collection}</div>
          <div className="info-container">
            <div className="info">{assetData.info}</div>
            <div className="price">{assetData.price}</div>
          </div>
        </>
      ) : (
        <div className="media-wrapper">
          <div className="name">{assetData.name}</div>
          <div className="collection">{assetData.collection}</div>
          <div className="info-container">
            <div className="info">{assetData.info}</div>
            <div className="price">{assetData.price}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Asset;
