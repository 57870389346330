import React, {
  useState, useRef, useEffect, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import List from './List';
import NoResults from './NoResults';
import { coinApi } from '../../../../api/coin';
import { userApi } from '../../../../api/user';
import { useDebounce } from '../../../hooks/app';
import { convertNumber, convertToReadableFormat } from '../../../../tools/NumberConverterTool';
import Search from '../../../../assets/icons/search.svg?url';
import Delete from '../../../../assets/images/delete.png';
import './SearchModal.scss';
import { ethFormat } from '../../../../utils/singleAssetPage/parseData';
import { preventPaste, preventSearch } from '../../../../utils/search/preventSearch';
import ModalCloseButton from '../../../base/ModalCloseButton';
import { profilesApi } from '../../../../api/profiles';
import LoadingList from './LoadingList';
import SearchList from './SearchList';
import uniqueId from '../../../../utils/uniqueId';
import { getIsSearchModalOpen, selectContractDeployer } from '../../../../store/reducers/search';
import styles from './SearchModal.module.scss';
import Checkbox from '../../Checkbox';
import { getTokenUnit } from '../../../../utils/chains';

const SearchModal = ({ setShowModal }) => {
  const dispatch = useDispatch();
  const [debouncedText, setDebouncedText] = useDebounce('');
  const [displayList, setDisplayList] = useState([false, false, false, false]);
  const inputRef = useRef(null);

  const contractDeployer = useSelector(selectContractDeployer);

  const {
    currentData: coins,
  } = coinApi.useSearchCoinQuery(debouncedText, {
    skip: (displayList.some((checkbox) => checkbox) && !displayList[2]) || debouncedText === '',
  });

  const {
    currentData: wallets,
  } = profilesApi.useSearchWalletsQuery(debouncedText, {
    skip: (displayList.some((checkbox) => checkbox) && !displayList[3]) || debouncedText === '',
  });

  const {
    currentData: nfts,
  } = coinApi.useSearchNftQuery(debouncedText, {
    skip: (displayList.some((checkbox) => checkbox) && !displayList[1]) || debouncedText === '',
  });

  const {
    data: nftInAsset,
    refetch: refetchNftInAsset,
  } = userApi.useGetNFTsInAssetsQuery(nfts && nfts.length ? nfts.map((elem) => ({
    address: elem.contract_address,
    network: elem.blockchain,
  })) : undefined, {
    skip: !(nfts && nfts.length),
  });

  const {
    data: tokensInAsset,
    refetch: refetchTokenInAsset,
  } = userApi.useGetCoinInAssetsQuery(coins && coins.length ? coins.map((elem) => ({
    coin: elem.id,
  })) : undefined, {
    skip: !(coins && coins.length),
  });

  useEffect(() => {
    if (contractDeployer) {
      inputRef.current.value = contractDeployer;
      setDebouncedText(contractDeployer);
    }
  }, [contractDeployer]);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const handleTextModal = (event) => {
    setDebouncedText(event.target.value);
  };

  const handleCheckbox = (checked, numberOf) => {
    const newDisplayList = [...displayList];
    newDisplayList[numberOf] = checked;
    setDisplayList(newDisplayList);
  };

  const modalStyle = {
    opacity: 1,
    backgroundColor: 'rgba(0,0,0,0.5)',
  };

  const nftsData = useMemo(() => ({
    id: 1,
    check: displayList[1],
    name: 'NFT Collections',
    data: nfts ? nfts.map((nft) => (
      {
        img: nft.image_url,
        name: nft.opensea_slug_contract_count > 1 ? nft.contract_name || nft.name : nft.name,
        transcript: nft.opensea_slug_contract_count > 1 ? nft.name : '',
        symbol: nft.symbol,
        holders: 'Holders',
        boldHolders: nft.holder_count !== null ? convertNumber(nft.holder_count) : '?',
        nameId: { id: nft.contract_address },
        value: 'Floor price',
        floor: 'Market cap',
        boldFloor: nft.market_cap !== null ? `${convertToReadableFormat(nft.market_cap)} ${getTokenUnit(nft.blockchain)}` : '-',
        boldValue: nft.floor_price !== null
          ? `${ethFormat(nft.floor_price)} ${getTokenUnit(nft.blockchain)} ${nft.floor_price_usd !== null
            ? `($${ethFormat(nft.floor_price_usd)})`
            : ''}`
          : '-',
        category: 'NFT Collection',
        type: 'nft',
        platforms: [nft.blockchain],
        verified: nft.is_verified_on_opensea,
        onDashboard: nftInAsset && nftInAsset.length
          ? nftInAsset.filter((elem) => elem.address === nft.contract_address && elem.network === nft.blockchain)
          : null,
      }
    )) : null,
  }), [displayList, nfts, nftInAsset]);

  const tokenData = useMemo(() => ({
    id: 2,
    check: displayList[2],
    name: 'Tokens',
    data: coins ? coins.map((coin) => (
      {
        id: coin.id,
        img: coin.image_url,
        nameId: { id: coin.id },
        name: coin.name,
        symbol: coin.symbol,
        value: 'Current Price',
        floor: 'Market cap',
        boldValue: coin.current_price ? `$${ethFormat(coin.current_price)}` : '?',
        boldFloor: coin.market_cap ? `$${convertToReadableFormat(coin.market_cap)}` : '?',
        category: 'Token',
        type: 'token',
        platforms: coin.blockchain?.split(','),
        onDashboard: tokensInAsset && tokensInAsset.length
          ? tokensInAsset.filter((elem) => elem.coin === coin.id)
          : null,
      }
    )) : null,
  }), [coins, displayList, tokensInAsset]);

  const addressesData = useMemo(() => ({
    id: 3,
    check: displayList[3],
    name: 'Profiles',
    data: wallets ? wallets.map((wallet) => (
      {
        id: wallet.address,
        nameId: { id: wallet.ALID || wallet.address },
        name: wallet.ens_name,
        category: 'Wallet',
        type: 'wallet',
        alias: wallet.alias,
        img: wallet.image,
      }
    )) : [],
  }), [displayList, wallets]);

  const fetchRender = useMemo(() => {
    const resultFromSearch = [nftsData, tokenData, addressesData];
    if (coins && nfts && wallets && !coins.length && !nfts.length && !wallets.length) {
      return <NoResults />;
    }
    if (coins || nfts || wallets) {
      if (displayList.some((checkbox) => checkbox)) {
        const checkingData = resultFromSearch.filter((assetsFromSearch) => assetsFromSearch.check)
          .map((elem) => elem.data).flat();
        if (checkingData.includes(null) && !checkingData.filter((item) => !!item).length) {
          return <LoadingList />;
        }
        if (checkingData.filter((item) => !!item).length) {
          return (
            <SearchList
              filterDataList={checkingData.filter((item) => !!item)}
              setShowModal={setShowModal}
              refetchNftInAsset={refetchNftInAsset}
              refetchTokenInAsset={refetchTokenInAsset}
            />
          );
        }
        if (!checkingData.includes(null) && !checkingData.length) {
          return <NoResults />;
        }
      }
      if ((coins?.length || nfts?.length || wallets?.length) && !displayList.some((checkbox) => checkbox)) {
        return (
          resultFromSearch.filter((obj) => obj.data?.length).map((assetData) => (
            <div className={styles.search_list_wrapper} key={uniqueId('assetDataFromSearch')}>
              <List
                searchItems={assetData}
                setShowModal={setShowModal}
                setDisplayList={setDisplayList}
                displayList={displayList}
                refetchNftInAsset={refetchNftInAsset}
                refetchTokenInAsset={refetchTokenInAsset}
              />
            </div>
          ))
        );
      }
    }
    return <LoadingList />;
  }, [addressesData, coins, tokenData, displayList, nfts, nftsData, setShowModal, debouncedText, wallets,
    refetchTokenInAsset, refetchNftInAsset]);

  return (
    <div
      className="modal modal-dialog-centered modal-phones search-modal"
      id="exampleModal"
      style={modalStyle}
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      onMouseDown={() => {
        setShowModal(false);
        dispatch(getIsSearchModalOpen(false));
      }}
    >
      <div
        role="presentation"
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
        className="modal-dialog modal-xl modal-phone w-100"
      >
        <div className="modal-content">
          <div className="modal-header d-flex">
            <div className="search-wrapper-modal d-flex justify-content-around align-items-center">
              <img src={Search} alt="search" height="20" width="20" />
              <input
                onChange={(e) => {
                  handleTextModal(e);
                  // TODO: uncomment if need
                  // if (isValidContractAddress(e.target.value)) {
                  //   setDebouncedText('');
                  // } else {
                  //   handleTextModal(e);
                  // }
                }}
                onPaste={(e) => { preventPaste(e, '@'); }}
                onKeyPress={(e) => { preventSearch(e, '@'); }}
                ref={inputRef}
                placeholder="NFT collections, Tokens, contract address"
                className="search-input-modal text-black"
              />
              {(debouncedText) && (
                <img
                  role="presentation"
                  src={Delete}
                  alt="search"
                  height="20"
                  width="20"
                  className="cursor-pointer"
                  onClick={() => {
                    setDebouncedText('');
                    inputRef.current.value = '';
                    inputRef.current.focus();
                  }}
                />
              )}
            </div>
            <ModalCloseButton setShowModal={setShowModal} />
          </div>
          <div className="modal-body modal-body-wrapper">
            <p className="body-text">I am looking for...</p>
            <div className="form-check checkbox-container align-items-center">
              <Checkbox
                label="NFT Collections"
                checked={displayList[1]}
                onChange={(checked) => handleCheckbox(checked, 1)}
              />
              <Checkbox
                label="Tokens"
                checked={displayList[2]}
                onChange={(checked) => handleCheckbox(checked, 2)}
              />
              <Checkbox
                label="Profiles"
                checked={displayList[3]}
                onChange={(checked) => handleCheckbox(checked, 3)}
              />
              <div className="ms-auto grid-button">
                <button
                  type="button"
                  className="apply-button"
                  onClick={() => {
                    if (displayList.some((checkbox) => checkbox)) {
                      setDisplayList([false, false, false, false]);
                    } else {
                      setDisplayList([false, true, true, true]);
                    }
                  }}
                >
                  {displayList.some((checkbox) => checkbox)
                    ? 'Remove all'
                    : 'Apply all'}
                </button>
              </div>
            </div>
          </div>
          {(!!debouncedText)
            && (
              <div className={`modal-footer modal-footer-wrapper ${styles.scroll} pt-0`}>
                {fetchRender}
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default SearchModal;
