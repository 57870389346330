import React, { useState } from 'react';
import TitleComponent from '../../../../components/base/TitleComponent';
import Loader from '../../../../components/base/Loader';
import NoData from '../../../../components/base/NoData';
import HeatMap from '../../components/HeatMap';
import styles from './UserTabHeatMap.module.scss';
import Tooltip from '../../../../components/ui/Tooltip';

const UserTabHeatMap = ({
  countriesData, isLoading, maxValue, info = '', total,
}) => {
  const [tooltipContent, setTooltipContent] = useState('');

  return (
    <div className={styles.wrapper}>
      <TitleComponent info={info}>Heatmap</TitleComponent>
      {isLoading
        ? (
          <div className="p-5">
            <Loader />
          </div>
        )
        : countriesData.length ? (
          <>
            <div className={`position-absolute d-flex align-items-center justify-content-between ${styles.legend}`}>
              <span>Lowest</span>
              <div className={styles.gradient} />
              <span>Highest</span>
            </div>
            <HeatMap
              setTooltipContent={setTooltipContent}
              total={total}
              maxValue={maxValue}
              countriesData={countriesData}
            />
            {tooltipContent ? (
              <Tooltip
                info={tooltipContent}
              />
            ) : null}
          </>
        ) : (
          <div className="d-flex justify-content-center align-items-center">
            <NoData />
          </div>
        )}
    </div>
  );
};

export default UserTabHeatMap;
