import React, { useEffect, useState } from 'react';
import { formatCategory } from '../../../utils/myTeam/formatCategory';
import Edit from '../../../assets/images/edit.png';
import styles from '../MyTeam.module.scss';
import Table from '../../../components/base/Table';

const TeamTable = ({
  myTeamData, setShowEditModal, setEditValue, setEditId, isLoading,
}) => {
  const [tableData, setTableData] = useState([]);
  const header = [
    {
      title: 'First name',
    },
    {
      title: 'Last name',
    },
    {
      title: 'Role',
    },
    {
      title: 'Status',
    },
    {
      title: 'Edit',
    },
  ];

  useEffect(() => {
    if (myTeamData) {
      const temp = myTeamData.map((elem) => ({
        first_name: elem.first_name,
        last_name: elem.last_name,
        category: formatCategory(elem.category),
        is_active: elem.is_active ? 'Active' : 'Pending',
        edit: (
          <img
            role="presentation"
            onClick={() => {
              setShowEditModal(true);
              setEditId(elem.id);
              setEditValue('first_name', elem.first_name);
              setEditValue('last_name', elem.last_name);
              setEditValue('category', elem.category);
            }}
            src={Edit}
            alt="icon"
            width="20"
            height="20"
            className="cursor-pointer"
          />
        ),
      }));
      setTableData(temp);
    }
  }, [myTeamData]);

  return (
    <div className={styles.tableContainer}>
      <div className="w-100">
        <Table
          data={tableData}
          headers={header}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default TeamTable;
