import React from 'react';
import { components } from 'react-select';

export const ControlComponent = ({ props }, onChain = false) => {
  const propsToSet = { ...props };
  propsToSet.selectProps.styles.dropdownIndicator = (style) => ({
    ...style,
  });
  propsToSet.selectProps.styles.indicatorSeparator = (style) => ({
    ...style,
  });
  if (props.hasValue) {
    propsToSet.innerProps.className = null;
    propsToSet.selectProps.selectProps.head = true;
    delete propsToSet.selectProps.selectProps.description;
    if (onChain) {
      propsToSet.selectProps.styles.dropdownIndicator = (style) => ({
        ...style,
        '& svg': { display: 'none' },
        padding: 0,
      });
      propsToSet.selectProps.styles.indicatorSeparator = (style) => ({
        ...style,
        display: 'none',
        padding: 0,
      });
      propsToSet.selectProps.styles.singleValue = (style) => ({
        ...style,
        '.mcapWrapper': {
          justifyContent: 'flex-start !important',
        },
        '.mcapLabel': { display: 'none' },
      });
    }
  }

  return (
    <div>
      <components.Control {...propsToSet} />
    </div>
  );
};

export const onChainInputStyles = {
  valueContainer: (style) => ({
    ...style,
    padding: '0 0px 0px 10px',
    minHeight: '46px',
  }),
  singleValue: (style) => ({
    ...style,
    padding: 0,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    opacity: 1,
  }),
  placeholder: (style) => ({
    ...style,
    fontSize: '14px',
  }),
  option: (style) => ({
    ...style,
    padding: '12px',
    boxShadow: '1px',
    border: '1px solid #F1F4F8',
  }),
  menuList: (style) => ({
    ...style,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  dropdownIndicator: (style) => ({
    ...style,
  }),
  indicatorSeparator: (style) => ({
    ...style,
  }),
};

export const sourceInputStyles = {
  valueContainer: (style) => ({
    ...style,
    padding: '0 0px 0px 10px',
    minHeight: '46px',
  }),
  singleValue: (style) => ({
    ...style,
    padding: 0,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    opacity: 1,
  }),
  placeholder: (style) => ({
    ...style,
    fontSize: '14px',
  }),
  option: (style) => ({
    ...style,
    padding: '12px',
    boxShadow: '1px',
    border: '1px solid #F1F4F8',
  }),
  menuList: (style) => ({
    ...style,
    paddingTop: 0,
    paddingBottom: 0,
  }),
};
