import React, { useCallback } from 'react';
import Loader from '../../../base/Loader';
import NoData from '../../../base/NoData';
import uniqueId from '../../../../utils/uniqueId';
import styles from './PercentageBased.module.scss';
import Highlight from '../../CircleChart/Highlight';
import CardTitle from '../../CardTitle';
import OneBarChart from '../../../base/OneBarChart';
import TruncatedText from '../../../base/TruncatedText';

const HoriontalPercentBarChart = ({
  title, highlightText, highlightValue, data, isLoading, total, info, legend, growth,
}) => {
  const maxValue = Math.max(
    ...data.map((item) => ((item.value) / total) * 100),
  );
  const filterNumberLessOne = Math.max(...data
    .map((elem) => ((elem.value) / total) * 100)
    .filter((elem) => elem < 1)
  );

  const getValue = useCallback((value) => {
    const res = (value / total) * 100;
    return res < 1
      ? Math.ceil(filterNumberLessOne)
      : res > 99
        ? Math.ceil(maxValue)
        : res === maxValue
          ? Math.ceil(res) 
          : res;
  }, [data])

  const getResult = useCallback((value) => {
    const res = (value / total) * 100;
    if (res < 1) {
      return '<1%';
    }
    if (res < 10) {
      return `${+res.toFixed(2)}%`;
    }
    if (res <= 99) {
      return `${+res.toFixed(1)}%`;
    }
    if (res === 100) {
      return '100%';
    }
    return '>99%';
  }, [data])

  return (
    <div className={`${styles.wrapper} h-100`}>
      <CardTitle title={title} info={info} />
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center p-5 h-75">
          <Loader />
        </div>
      ) : (
        total && total !== 0 && data && data.length ? (
          <>
            <div className="d-flex w-100 flex-column h-100">
              <div className={styles.subTitle}>
                <Highlight text={highlightText} value={highlightValue} growth={growth} />
              </div>
              <div className={styles.chart_container}>
              {data.map((item) => (
                <div key={uniqueId('Chart_row')} className={styles.row}>
                    {item.logo ? item.logo : <div className={styles.logo_placeholder} />}
                  <div className={styles.row_right}>
                    <div className={styles.text}>
                      <span
                        className={`${styles.name} text-truncate cursor-pointer`}
                        role="presentation"
                      >
                        <TruncatedText text={item.name} />
                      </span>
                      <span className={styles.notable_count}>
                        {getResult(item.value)}
                      </span>
                    </div>
                    <OneBarChart value={getValue(item.value)} max={maxValue} tooltip={item.message} />
                  </div>
                </div>
              ))}
              </div>
            </div>
            {legend ? (
              <div className="d-flex">
                <div className="d-flex align-items-center gap-2 asset-wrap">
                  <div className="dot" />
                  <span>
                    {`${legend}`}
                  </span>
                </div>
              </div>
            ) : null}
          </>
        ) : (
          <div>
            <NoData />
          </div>
        ))}
    </div>
  );
};

export default React.memo(HoriontalPercentBarChart);
