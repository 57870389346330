import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import styles from './Account.module.scss';
import { userApi } from '../../api/user';
import Loader from '../../components/base/Loader';
import { showErrorMessage, showSuccessMessage } from '../../components/base/Notifications';

const Account = () => {
  const { data, isLoading, isFetching } = userApi.useGetAccountQuery();
  const [postData, postDataResult] = userApi.usePostAccountMutation();

  const {
    register, handleSubmit, setValue,
  } = useForm();

  const onSubmit = (formData) => {
    if (formData) {
      postData(formData);
    }
  };

  useEffect(() => {
    if (data) {
      setValue('first_name', data.first_name);
      setValue('last_name', data.last_name);
    }
  }, [data]);

  useEffect(() => {
    if (postDataResult.isSuccess) {
      showSuccessMessage('Account settings successfully changed');
      postDataResult.reset();
    }
    if (postDataResult.isError) {
      showErrorMessage('Something went wrong');
      postDataResult.reset();
    }
  });

  return (
    <>
      <div className={`${styles.title} mb-4`}>
        My account settings
      </div>
      {isLoading || isFetching
        ? (
          <div className="mt-5">
            <Loader />
          </div>
        )
        : (
          <form
            className={styles.wrapper}
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className={`${styles.head} d-flex justify-content-between`}>
              <span>
                Basic info
              </span>
              <button
                type="submit"
                className="outline-blue-button"
                onClick={() => onSubmit()}
                disabled={postDataResult.isLoading || postDataResult.isFetching}
              >
                Save
              </button>
            </div>
            <div className={`${styles.row} d-flex flex-column`}>
              <span className="mb-1">
                First name
              </span>
              <input
                type="text"
                {...register('first_name', { required: true })}
              />
            </div>
            <div className={`${styles.row} d-flex flex-column`}>
              <span className="mb-1">
                Last name
              </span>
              <input
                type="text"
                {...register('last_name', { required: true })}
              />
            </div>
          </form>
        )}
    </>
  );
};

export default Account;
