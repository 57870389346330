import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Title from '../../components/base/Title';
import Card from '../../components/ui/Card';
import NFTCreationCard from '../NFTCreation/NFTCreationCard';
import {
  setUserAssets,
  selectDashboardData, changeColumn, deleteAsset,
  setUserTrackedWebsite,
} from '../../store/reducers/coin';
import AddCard from '../../components/base/AddCard';
import AddNFTCreationCard from '../../components/base/AddNFTCreationCard';
import { useWindowSize } from '../../components/hooks/app';
import { userApi } from '../../api/user';
import {
  getUser, selectUserLoginStatus, setUserLogin, isLoadingUser,
} from '../../store/reducers/user';
import { showErrorMessage, showSuccessMessage } from '../../components/base/Notifications';
import uniqueId from '../../utils/uniqueId';
import { getIsAddedFromSearch, isAddedFromSearch } from '../../store/reducers/app';
import NFTCollectionCreation from '../NFTCreation/NFTCollectionCreation';
import { contractsApi } from '../../api/contracts';
import {
  selectNFTCreationAssets, setNFTCreationAssets, getIsNFTCreationAssetAdded, isNFTCreationAssetAdded,
} from '../../store/reducers/contracts';
import WebSDKCreation from '../WebSDK/WebSDKCreation';
import { webSDKApi } from '../../api/webSDK';
import styles from './Dashboard.module.scss';

const Dashboard = () => {
  const size = useWindowSize();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const userLoading = useSelector(isLoadingUser);

  // Fetch assets and watchlist
  const {
    data,
    isLoading: isLoadingAssets,
    isFetching: isFetchingAssets,
    refetch: assetsRefetch,
  } = userApi.useGetAssetsQuery();

  //  Fetch the web sdk data
  const {
    data: trackedWebsite,
    isLoading: isLoadingWebSDK,
    refetch: webSDKRefetch,
  } = webSDKApi.useGetWebSDKQuery(
    null,
    { skip: !user || userLoading || !user?.web_sdk },
  );

  // Fetch for nft creation assets
  const {
    data: NFTCreationData,
    isLoading: isLoadingNFTCreation,
    refetch: assetsRefetchNFTCreation,
  } = contractsApi.useGetContractsQuery(
    null,
    { skip: !user || userLoading || !user?.nft_creation },
  );

  const [actionOnAsset, result] = userApi.useActionOnAssetMutation();
  const { assets, watchList } = useSelector(selectDashboardData);
  const NFTCreationAssets = useSelector(selectNFTCreationAssets);
  const isNotFirstLogin = useSelector(selectUserLoginStatus);
  const isAssetAddedFromSearch = useSelector(getIsAddedFromSearch);
  const isNFTCreationAdded = useSelector(getIsNFTCreationAssetAdded);
  const navigateToSearch = useCallback((to) => {
    navigate(`/search/?from=dashboard&to=${to}`);
  }, [navigate]);

  const loadingCards = useCallback(() => {
    if (size.width > 1536) {
      return [{}, {}, {}];
    }
    if (size.width > 1300) {
      return [{}, {}];
    }
    return [{}];
  }, [size.width]);

  useEffect(() => {
    if (result.isSuccess) {
      if (result.originalArgs.action === 'move') {
        dispatch(changeColumn(result.originalArgs));
        result.reset();
      }
      if (result.originalArgs.action === 'delete') {
        dispatch(deleteAsset(result.originalArgs));
        showSuccessMessage(result?.data?.message || 'The object has been successfully removed');
        result.reset();
      }
    }
    if (result.isError && result?.error?.status !== 410) {
      showErrorMessage('Something went wrong. Try to refresh page and repeat the action');
      result.reset();
    }
  }, [dispatch, result]);

  useEffect(() => {
    if (data) {
      dispatch(setUserAssets(data));
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (trackedWebsite && trackedWebsite.length) {
      dispatch(setUserTrackedWebsite(trackedWebsite));
    }
  }, [trackedWebsite, dispatch]);

  useEffect(() => {
    assetsRefetch();
  }, [assetsRefetch]);

  useEffect(() => {
    if (user?.web_sdk && !isLoadingUser) {
      webSDKRefetch();
    }
  }, []);

  useEffect(() => {
    if (isAssetAddedFromSearch) {
      assetsRefetch();
      dispatch(isAddedFromSearch(false));
    }
  }, [isAssetAddedFromSearch]);

  useEffect(() => {
    if (isNFTCreationAdded && NFTCreationData && user?.nft_creation && !userLoading) {
      assetsRefetchNFTCreation();
      dispatch(isNFTCreationAssetAdded(false));
    }
  }, [isNFTCreationAdded, NFTCreationData, user, userLoading]);

  useEffect(() => {
    if (data && !isNotFirstLogin) {
      if (
        !data.length
        && !trackedWebsite?.length
        && !isLoadingAssets
        && !isLoadingWebSDK
        && !isFetchingAssets
        && !isLoadingNFTCreation
        && !NFTCreationData?.length) {
        navigate('/search');
      }
      dispatch(setUserLogin());
    }
  }, [
    data,
    dispatch,
    isFetchingAssets,
    isLoadingWebSDK,
    isLoadingAssets,
    isNotFirstLogin,
    isLoadingNFTCreation,
    NFTCreationData,
    navigate]);

  // Assign the NFT creation data
  useEffect(() => {
    if (NFTCreationData) {
      dispatch(setNFTCreationAssets(NFTCreationData));
    }
  }, [NFTCreationData, dispatch]);

  // For refetch of nft creation data
  useEffect(() => {
    if (user?.nft_creation && !userLoading) {
      assetsRefetchNFTCreation();
    }
  }, [assetsRefetchNFTCreation, user?.nft_creation]);

  const fetchRender = useMemo(() => (
    <div className="d-flex flex-column">
      <div className="d-flex justify-content-end gap-4">
        {user?.web_sdk ? <WebSDKCreation /> : null}
        {user?.nft_creation ? <NFTCollectionCreation /> : null}
      </div>
      <div className={styles.section}>
        <div className="row">
          <div className={styles.title_gap}>
            <Title>My Assets</Title>
          </div>
          <div className={styles.divider} />
        </div>
        <div
          className={`${styles.assets_container} ${styles.gap} ${size.width > 640 ? 'p-5' : 'p-3'}`}
        >
          {assets.map((assetData) => (
            <Card
              actionOnAsset={actionOnAsset}
              flag="asset"
              key={uniqueId('dashboard')}
              cardData={assetData}
              isLoading={false}
            />
          ))}
          {(isLoadingAssets || isLoadingWebSDK) && loadingCards().map((assetData) => (
            <Card
              actionOnAsset={actionOnAsset}
              flag="asset"
              key={uniqueId('dashboard')}
              cardData={assetData}
              isLoading
            />
          ))}
          <AddCard navigate={() => navigateToSearch('my-assets')} />
        </div>
      </div>
      {user?.nft_creation
        && (
          <div className={`${styles.section} mt-3`}>
            <div className="row">
              <div className={styles.title_gap}>
                <Title>Created Assets</Title>
              </div>
              <div className={styles.divider} />
            </div>
            <div
              className={`${styles.assets_container} ${styles.gap} ${size.width > 640 ? 'p-5' : 'p-3'}`}
            >
              {(isLoadingNFTCreation ? loadingCards() : NFTCreationAssets)?.map((assetData) => (
                <NFTCreationCard
                  key={uniqueId('dashboard')}
                  cardData={assetData}
                  isLoading={isLoadingNFTCreation}
                />
              ))}
              <AddNFTCreationCard />
            </div>
          </div>
        )}
      <div className={`${styles.section} mt-3`}>
        <div className="row">
          <div className={styles.title_gap}>
            <Title>Watchlist</Title>
          </div>
          <div className={styles.divider} />
        </div>
        <div
          className={`${styles.assets_container} ${styles.gap} ${size.width > 640 ? 'p-5' : 'p-3'}`}
        >
          {(isLoadingAssets ? loadingCards() : watchList).map((assetData) => (
            <Card
              actionOnAsset={actionOnAsset}
              flag="watchlist"
              key={uniqueId('dashboard')}
              cardData={assetData}
              marketData={assetData.market_data}
              isLoading={isLoadingAssets}
            />
          ))}
          <AddCard navigate={() => navigateToSearch('watchlist')} />
        </div>
      </div>
    </div>
  ), [user?.web_sdk, user?.nft_creation, size.width, isLoadingAssets, loadingCards, isLoadingNFTCreation,
    assets, watchList, NFTCreationAssets, actionOnAsset, navigateToSearch, isLoadingWebSDK]);

  return (
    <div>
      {fetchRender}
    </div>
  );
};

export default Dashboard;
