import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectFilterOption } from '../../../store/reducers/coin';
import DappUsageTable from '../../../components/ui/DappUsageTable';
import NotSupportedBlockchain from '../../../components/NotSupportedBlockchain';
import { coinApi } from '../../../api/coin';

const DappUsage = ({ holders, isSupportedBlockchain }) => {
  const { name } = useParams();
  const [page, setPage] = useState(1);
  const [desc, setDesc] = useState(true);
  const [orderBy, setOrder] = useState('number_of_users_last_30d');
  const [limit, setLimit] = useState(25);
  const filterOption = useSelector(selectFilterOption);

  const {
    data: dappData,
    isLoading: isDappDataLoading,
    isFetching: isDappDataFetching,
  } = coinApi.useGetTokenDappUsageDataQuery({
    id: name,
    limit,
    offset: (page - 1) * limit,
    order: desc ? 'desc' : 'asc',
    orderBy,
    audience: filterOption,
  });

  return (
    isSupportedBlockchain ? (
      <DappUsageTable
        holders={holders}
        data={dappData}
        isLoading={isDappDataLoading || isDappDataFetching}
        setPage={setPage}
        setDesc={setDesc}
        setOrder={setOrder}
        setLimit={setLimit}
        page={page}
        limit={limit}
        desc={desc}
        orderBy={orderBy}
      />
    ) : <NotSupportedBlockchain />
  );
};

export default DappUsage;
