import React from 'react';
import Badge from '../../../assets/icons/badge.svg';
import Tooltip from '../Tooltip';

const Verification = ({ width = '20px', height = '20px' }) => (
  <>
    <div data-for="verified" data-tip style={{ zIndex: 1 }}>
      <Badge width={width} height={height} />
    </div>
    <Tooltip id="verified" info="Verified on OpenSea" place="top" />
  </>
);

export default Verification;
