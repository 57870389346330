import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  segments: [],
  offset: 0,
  limit: 10,
  sort: 'asc',
  sort_by: 'name',
};

export const segmentListSlice = createSlice({
  name: 'segmentList',
  initialState,
  reducers: {
    changeSegments: (state, action) => {
      state.segments = action.payload;
    },
    changeOffset: (state, action) => {
      state.offset = action.payload;
    },
    changeLimit: (state, action) => {
      state.limit = action.payload;
    },
    changeField: (state, action) => {
      state.sort_by = action.payload;
    },
    changeSort: (state, action) => {
      state.sort = action.payload;
    },
    resetAll: (state) => {
      state.segments = [];
      state.offset = 0;
      state.limit = 10;
    },
  },
});

export const {
  changeSegments, changeOffset, changeLimit, resetAll, changeField, changeSort,
} = segmentListSlice.actions;
export const getSegments = (state) => state.segmentListReducer.segments;
export const getOffset = (state) => state.segmentListReducer.offset;
export const getLimit = (state) => state.segmentListReducer.limit;
export default segmentListSlice.reducer;
