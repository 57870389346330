import React, { useEffect, useMemo, useState } from 'react';
import Chart from 'react-apexcharts';
import ApexCharts from 'apexcharts';
import TitleComponent from '../../base/TitleComponent';
import NoData from '../../base/NoData';
import styles from './HistoricalHoldersGraph.module.scss';
import Loader from '../../base/Loader';
import infoImg from '../../../assets/icons/info.svg?url';
import Tooltip from '../Tooltip';
import ChartIndicator from '../ChartIndicator';
import { toMaxNDecimals } from '../../../utils/percent';
import { dateToString, adjustDateByDays, stringToDate } from '../../../utils/date';

const HistoricalHoldersGraph = ({
  graphData, isLoading, isSuccess, audience,
}) => {
  const [day, setDay] = useState(['new', 'lost'].includes(audience) ? '7' : '180');
  const [legendToggle, setLegendToggle] = useState({
    Trend: true,
    'New Holders': true,
  });

  const calculateTickAmount = (maxValue) => {
    if (maxValue <= 10) return maxValue;
    return 10;
  };

  useEffect(() => {
    setDay(['new', 'lost'].includes(audience) ? '7' : '180');
  }, [audience]);

  const chartTitle = {
    current: 'Holders by Join Date',
    new: 'Newcomers by Join Date',
    relevant: 'Relevant Holders by Join Date',
    lost: 'Join Date of Holders Lost Past 7 Days',
    all: 'Daily Net New Holders',
    lookalike: 'Daily Net New Holders',
  };

  const createEmptyData = (date) => ({
    txn_date: dateToString(date),
    new_users: 0,
    new_users_trendline: 0,
  });

  const hasDataAtDate = (data, date) => data.some((item) => item.txn_date === dateToString(date));

  const getGrowth = (data, startDate, endDate) => {
    const startValue = hasDataAtDate(data, startDate)
      ? data.find((item) => item.txn_date === dateToString(startDate))?.new_users_trendline
      : hasDataAtDate(data, adjustDateByDays(1, startDate))
        ? data.find((item) => item.txn_date === dateToString(adjustDateByDays(1, startDate)))?.new_users_trendline
        : null;

    if (!startValue || startValue === 0) return null;

    const endValue = hasDataAtDate(data, endDate)
      ? data.find((item) => item.txn_date === dateToString(endDate))?.new_users_trendline
      : hasDataAtDate(data, adjustDateByDays(-1, endDate))
        ? data.find((item) => item.txn_date === dateToString(adjustDateByDays(-1, endDate)))?.new_users_trendline
        : null;

    if (!endValue) return null;

    const result = ((endValue - startValue) / startValue) * 100;
    return toMaxNDecimals(result, 1);
  };

  const createChartSeries = (days) => {
    if (!graphData) return null;

    const tomorrow = adjustDateByDays(1);
    const endDate = hasDataAtDate(graphData, tomorrow) ? tomorrow : adjustDateByDays(0);

    const startDate = adjustDateByDays(-days, endDate);

    const dataWithLastDay = hasDataAtDate(graphData, endDate)
      ? graphData
      : [...graphData, createEmptyData(endDate)];
    const dataWithFirstDay = hasDataAtDate(dataWithLastDay, startDate)
      ? dataWithLastDay
      : [createEmptyData(startDate), ...dataWithLastDay];
    const periodData = dataWithFirstDay.filter((elem) => (stringToDate(elem.txn_date) >= startDate));
    const holdersMax = Math.max(...periodData.map((elem) => elem.new_users));
    const trendlineMax = Math.max(...periodData.map((elem) => elem.new_users_trendline));

    return (
      {
        data: {
          series: [{
            name: 'Trend',
            type: 'area',
            data: periodData.map((elem) => (elem.new_users_trendline).toFixed(2)),
          },
          {
            name: 'New Holders',
            type: 'column',
            data: periodData.map((elem) => elem.new_users),
          },
          ],
          options: {
            chart: {
              zoom: {
                enabled: false,
              },
              toolbar: {
                show: false,
              },
              type: 'arena',
              stacked: true,
              id: 'historical holders graph2',
            },
            stroke: {
              width: legendToggle.Trend && legendToggle['New Holders']
                ? [3, 1]
                : legendToggle.Trend ? [4, 0] : [0, 1],
              curve: 'smooth',
            },
            plotOptions: {
              bar: {
                horizontal: false,
                borderRadius: 1,
                columnWidth: '30%',
              },
            },
            grid: {
              yaxis: {
                lines: {
                  show: false,
                },
              },
            },
            colors: ['#C2CFE0', '#109CF1'],
            fill: {
              opacity: [0, 1, 1],
            },
            markers: {
              size: 0,
            },
            xaxis: {
              type: 'datetime',
              categories: periodData.map((elem) => elem.txn_date),
              labels: {
                formatter: (x) => new Date(x).toUTCString().substr(4, 12),
              },
              axisBorder: {
                show: true,
                color: '#6A707E',
              },
              axisTicks: {
                show: true,
                color: '#6A707E',
                width: 4,
              },
              crosshairs: {
                show: false,
              },
              tooltip: {
                enabled: false,
              },
            },
            yaxis: [
              {
                seriesName: 'Trend',
                axisBorder: {
                  show: true,
                  color: '#6A707E',
                },
                axisTicks: {
                  show: true,
                  color: '#6A707E',
                  width: 4,
                },
                min: 0,
                max: legendToggle.Trend && legendToggle['New Holders']
                  ? Math.max(holdersMax, trendlineMax)
                  : legendToggle.Trend ? trendlineMax : holdersMax,
                title: {
                  text: 'Trend',
                  style: {
                    color: '#6A707E',
                  },
                },
                opposite: true,
                labels: {
                  formatter: (value) => `${Math.round(value)}`,
                },
                show: false,
              },
              {
                seriesName: 'New Holders',
                axisBorder: {
                  show: true,
                  color: '#6A707E',
                },
                axisTicks: {
                  show: true,
                  color: '#6A707E',
                  width: 4,
                },
                min: 0,
                max: Math.max(holdersMax, trendlineMax),
                title: {
                  text: 'New Holders',
                  style: {
                    color: '#6A707E',
                  },
                },
                labels: {
                  formatter: (value) => `${value.toFixed(0)}`,
                },
                tickAmount: calculateTickAmount(holdersMax, trendlineMax),
                type: 'numeric',
              },
            ],
            tooltip: {
              shared: true,
              intersect: false,
              y: {
                formatter: (y, { ...chartData }) => (typeof (y) !== 'undefined' ? !chartData.seriesIndex
                  ? `${y}` : y.toLocaleString() : ''),
              },
            },
            legend: {
              show: false,
            },
          },
        },
        growth: getGrowth(graphData, startDate, endDate),
      }
    );
  };

  const precomputedData = useMemo(() => ({
    7: createChartSeries(7),
    30: createChartSeries(30),
    90: createChartSeries(90),
    180: createChartSeries(180),
  }), [graphData]);

  const chartData = precomputedData[day];

  const chartContent = useMemo(() => {
    if (isLoading && !graphData) {
      return (
        <div className={`${styles.load_wrap}`}>
          <Loader />
        </div>
      );
    }
  
    if (isSuccess && (!graphData || graphData.length === 0)) {
      return (
        <div className={`${styles.load_wrap}`}>
          <NoData />
        </div>
      );
    }
  
    if (graphData && graphData.length > 0 && chartData?.data) {
      return (
        <Chart
          options={chartData.data.options}
          series={chartData.data.series}
          type="line"
          height={300}
        />
      );
    }
  
    // Handle the edge case where data might be empty or undefined
    return (
      <div className={`${styles.load_wrap}`}>
        <NoData />
      </div>
    );
  }, [isLoading, graphData, chartData, isSuccess, day, legendToggle]);  

  const toggleSeries = (checkbox) => {
    setLegendToggle((prev) => ({ ...prev, [checkbox]: !prev[checkbox] }));
    const chart = ApexCharts.getChartByID('historical holders graph2');
    chart.toggleSeries(checkbox);
    chart.redraw();
  };

  return useMemo(() => (
    <div className={`${styles.sales_wrapper} d-flex flex-column`}>
      <div className={styles.title_wrapper}>
        <TitleComponent>
          {chartTitle[audience]}
        </TitleComponent>

        <div className={styles.holders_chart_right_info_wrapper}>
          {!['new', 'lost'].includes(audience) && chartData?.data && chartData?.growth !== null ? (
            <ChartIndicator text={`${day}d Growth Rate Change:`} value={chartData.growth} />
          ) : null}

          <div className="d-flex justify-content-end align-items-center">
            {!['new', 'lost'].includes(audience) && (
              <div className="d-flex justify-content-end align-items-center">
                <div
                  className="btn-toolbar"
                  role="toolbar"
                  aria-label="Toolbar with button groups"
                >
                  <div
                    className="btn-group me-4 group"
                    role="group"
                    aria-label="Second group"
                  >
                    <button
                      type="button"
                      className={`btn btn-outline-secondary shadow-none ${
                        day === '30' ? 'active' : ''
                      }`}
                      onClick={() => setDay('30')}
                    >
                      30d
                    </button>
                    <button
                      type="button"
                      className={`btn btn-outline-secondary shadow-none ${
                        day === '90' ? 'active' : ''
                      }`}
                      onClick={() => setDay('90')}
                    >
                      90d
                    </button>
                    <button
                      type="button"
                      className={`btn btn-outline-secondary shadow-none ${
                        day === '180' ? 'active' : ''
                      }`}
                      onClick={() => setDay('180')}
                    >
                      180d
                    </button>
                  </div>
                </div>
              </div>
            )}

            <button
              type="button"
              className={`${styles.btn_component} btn d-flex align-items-center justify-content-center shadow-none`}
              data-for="holder_graph"
              data-tip
            >
              <img src={infoImg} alt="info" width="20px" height="20px" />
            </button>
            <Tooltip
              id="holder_graph"
              info="Daily number of new wallets owning the asset for the very first time"
              truncate={false}
              place="top"
            />
          </div>
        </div>
      </div>
      <div id="chart" className="position-relative">
        {chartContent}
      </div>
      <div className="d-flex gap-2">
        <div
          role="presentation"
          className={`d-flex align-items-center gap-2 asset-wrap cursor-pointer 
          ${legendToggle.Trend ? 'opacity-100' : 'opacity-50'}`}
          onClick={() => toggleSeries('Trend')}
        >
          <div className="dot-3" />
          <span>Trend</span>
        </div>
        <div
          role="presentation"
          className={`d-flex align-items-center gap-2 asset-wrap cursor-pointer 
          ${legendToggle['New Holders'] ? 'opacity-100' : 'opacity-50'}`}
          onClick={() => toggleSeries('New Holders')}
        >
          <div className="dot" />
          <span>New Holders</span>
        </div>
      </div>
    </div>
  ), [chartContent, chartData, isLoading, legendToggle]);
};

export default HistoricalHoldersGraph;
