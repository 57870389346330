/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Search from '../../../assets/icons/search.svg?url';
import ArrowDown from '../../../assets/images/Header/arrow-down.png';
import { setUser, fetchingUser } from '../../../store/reducers/user';
import Navigation from './Navigation';
import SearchModal from '../../ui/modals/SearchModal';
import Sidebar from './Sidebar';
import { showErrorMessage } from '../../base/Notifications';
import { useWindowSize } from '../../hooks/app';
import Logo from '../../../assets/icons/logo.svg';
import Burger from '../../../assets/icons/menu-icon.svg';
import styles from './Header.module.scss';
import { userApi } from '../../../api/user';
import { getPopupOptions } from '../../../utils/getPopupConfiguration';
import { selectIsSearchModalOpen } from '../../../store/reducers/search';
import { trackLogin } from '../../../utils/mixpanel/mixpanelEvents';

const Header = ({ setShowDropdown, showDropdown }) => {
  const {
    isAuthenticated, logout, user, loginWithPopup, error,
  } = useAuth0();
  const [avatarError, setAvatarError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [sidebar, setSideBAr] = useState(false);
  const [userAdmin, setUserAdmin] = useState(false);

  const selectSearchModal = useSelector(selectIsSearchModalOpen);

  const dispatch = useDispatch();
  const location = useLocation();

  const size = useWindowSize();
  const {
    data: userInfo,
    isLoading: isLoadingUserInfo,
    isFetching: isFetchingUserInfo,
    refetch: refetchUserInfo,
  } = userApi.useGetUserInfoQuery(null, {
    skip: !isAuthenticated,
  });

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchingUser(true));
      refetchUserInfo();
    }
  }, [location, isAuthenticated, refetchUserInfo, dispatch]);

  useEffect(() => {
    if (isLoadingUserInfo || isFetchingUserInfo) {
      dispatch(fetchingUser(true));
    } else {
      dispatch(fetchingUser(false));
    }
  }, [isLoadingUserInfo, isFetchingUserInfo, dispatch]);

  useEffect(() => {
    if (userInfo) {
      if (process.env.NODE_ENV === 'development' && import.meta.env.VITE_APP_MIXPANEL_TOKEN && userInfo) {
        trackLogin(userInfo);
      }
      dispatch(setUser(userInfo));
      dispatch(fetchingUser(false));
    }
    if (userInfo && userInfo.category === 'team_admin') {
      setUserAdmin(true);
    }
  }, [userInfo, dispatch]);

  useEffect(() => {
    if (error && error?.message !== 'Popup closed') showErrorMessage(error.message);
  }, [error]);

  return (
    <header className={`${styles.header} py-1`}>
      <div
        className={`${styles.wrapper} d-flex flex-column justify-content-between row-1`}
      >
        <div className="d-flex justify-content-between align-items-center mb-2">
          <Link
            to="/dashboard"
            className="m-0 cursor-pointer"
            role="presentation"
          >
            <Logo />
          </Link>
          <div
            className={`${styles.wrapper_search} ${styles.gap} d-flex align-items-center`}
          >
            <div
              role="presentation"
              className={`${styles.search} d-flex align-items-center gap-2 search`}
              onClick={() => {
                setShowModal(true);
              }}
            >
              <img className="img" src={Search} alt="search" />
              {size.width > 768 && (
                <span className={styles.search_text}>
                  Search by NFT collections, Tokens...
                </span>
              )}
            </div>
            {size.width > 768 && (
              <>
                <div className={styles.separator} />
                <div className="d-flex align-items-center justify-content-around gap-2">
                  {isAuthenticated ? (
                    <>
                      <div className={`${styles.auth_img_wrapper} d-flex align-items-center`}>
                        {
                          user?.picture && !avatarError
                            ? (
                              <img
                                onError={() => setAvatarError(true)}
                                src={user.picture}
                                alt="avatar"
                                width="24"
                                height="24"
                              />
                            )
                            : <i style={{ fontSize: '24px' }} className="bi bi-person-fill" />
                        }
                      </div>
                      <div className="dropdown position-relative">
                        <div
                          role="button"
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowDropdown(!showDropdown);
                          }}
                          className={`${styles.userName} btn p-0`}
                          tabIndex={-1}
                        >
                          {user.name}
                          <img
                            className="arrow ms-2"
                            src={ArrowDown}
                            alt="notification"
                          />
                        </div>
                        {showDropdown && (
                          <ul
                            className={`${styles.dropDown} border-0`}
                            aria-labelledby="dropdownMenuProfile"
                          >
                            <div
                              className={`${styles.triangle} position-absolute w-100`}
                            />
                            <li>
                              <Link to="/account" className={`disable-link-styles ${styles.header_dropdown_menu}`}>
                                <button
                                  className={`${styles.dropDown__item} dropdown-item p-0 h-100`}
                                  type="button"
                                >
                                  My Account
                                </button>
                              </Link>
                            </li>
                            {userAdmin && (
                              <>
                                <li>
                                  <Link to="/my-team" className={`disable-link-styles ${styles.header_dropdown_menu}`}>
                                    <button
                                      className={`${styles.dropDown__item} dropdown-item p-0 h-100`}
                                      to="/my-team"
                                      type="button"
                                    >
                                      My Team
                                    </button>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/settings" className={`disable-link-styles ${styles.header_dropdown_menu}`}>
                                    <button
                                      className={`${styles.dropDown__item} dropdown-item p-0 h-100`}
                                      type="button"
                                    >
                                      Settings
                                    </button>
                                  </Link>
                                </li>
                              </>
                            )}
                            <li>
                              <a
                                href="https://docs.absolutelabs.io/"
                                className={`disable-link-styles ${styles.header_dropdown_menu}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <button
                                  className={`${styles.dropDown__item} dropdown-item p-0 h-100`}
                                  type="button"
                                >
                                  Documentation
                                </button>
                              </a>
                            </li>
                            <div
                              className={`dropdown-divider ${styles.divider}`}
                            />
                            <li>
                              <a
                                className={`
                              ${styles.dropDown__item}
                              ${styles.dropDown__item_logOut}
                              dropdown-item
                              p-0
                              d-flex
                              align-items-center
                              h-100`}
                                href="/"
                                onClick={(e) => {
                                  e.preventDefault();
                                  // window.intercomSettings.email = null;
                                  // window.intercomSettings.name = null;
                                  logout({ returnTo: window.location.origin });
                                }}
                              >
                                Log out
                              </a>
                            </li>
                          </ul>
                        ) }

                      </div>
                    </>
                  ) : (
                    <button
                      type="button"
                      onClick={async () => {
                        const popup = getPopupOptions();
                        await loginWithPopup(null, { popup, timeoutInSeconds: 1000 });
                      }}
                      className={styles.login}
                    >
                      Login
                    </button>
                  )}
                </div>
              </>
            )}
            {size.width <= 768 && (
              <button
                className="navbar-toggler navbar-light shadow-none"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasNavbar"
                aria-controls="offcanvasNavbar"
                onClick={() => setSideBAr(true)}
              >
                <Burger />
              </button>
            )}
          </div>
        </div>
        <Navigation />
        {(showModal || selectSearchModal) && <SearchModal setShowModal={setShowModal} />}
        <Sidebar
          setSideBAr={setSideBAr}
          sidebar={sidebar}
          name={user?.name}
          picture={user?.picture}
          isAuthenticated={isAuthenticated}
          logout={logout}
          userAdmin={userAdmin}
        />
      </div>
    </header>
  );
};

export default Header;
