import React, {
  useMemo, useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
  setCurrentFolder,
  getSelectedItems,
  setSelectedItems,
  getSearchParams,
  setSearchParams,
} from '../../store/reducers/folders';
import emitter from '../../utils/emitter';
import { formsApi } from '../../api/forms';
import { foldersApi } from '../../api/folders';
import NoForm from './NoForm';
import Loader from '../../components/base/Loader';
import FormsList from './FormsList';
import ListWithFolders from '../../components/ui/ListWithFolders';
import useSetSearchParams from '../../utils/useSetSearchParams';

const getStatus = (status) => {
  switch (status) {
    case 'running':
      return 'Active';
    case 'draft':
      return 'Draft';
    case 'stopped':
      return 'Completed';
    case 'all':
    default:
      return '';
  }
};

const Forms = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const setParams = useSetSearchParams();
  const selection = useSelector((state) => getSelectedItems(state, 'forms'));
  const storedSearchParams = useSelector((state) => getSearchParams(state, 'forms'));

  const [ordering, setOrdering] = useState(searchParams.get('orderBy') || 'created_at');
  const [page, setPage] = useState(searchParams.get('page') || 1);
  const [desc, setDesc] = useState(searchParams.get('desc') || true);
  const [limit, setLimit] = useState(searchParams.get('limit') || 10);
  const [status, setStatus] = useState(getStatus(searchParams.get('status')) || '');
  const [search, setSearch] = useState(searchParams.get('search') || '');
  const [folder, setFolder] = useState(searchParams.get('folder') || 'all');

  const {
    data: forms,
    isLoading,
    isFetching,
    refetch,
  } = formsApi.useGetFormsQuery(
    {
      limit,
      offset: (page - 1) * limit,
      order_by: `${ordering}`,
      order: `${desc ? 'desc' : 'asc'}`,
      search,
      status,
      my_items: folder === 'my',
      folders: (folder === 'all' || folder === 'my') ? null : folder,
    },
  );

  useEffect(() => {
    setParams(storedSearchParams);
  }, [storedSearchParams]);

  useEffect(() => {
    refetch();
  }, [limit, page, desc, ordering, search, status, folder, refetch]);

  useEffect(() => {
    const listener = () => {
      refetch();
    };

    emitter.on('refetch_forms', listener);

    return () => {
      emitter.off('refetch_forms', listener);
    };
  }, []);

  useEffect(() => {
    if (forms?.results?.length === 0 && page > 1) {
      setParams({ page: page - 1 });
    }
  }, [forms]);

  const {
    data: folders,
    isLoading: isLoadingFolders,
    refetch: refetchFolders,
  } = foldersApi.useGetFoldersQuery({
    type: 'forms',
  });

  useEffect(() => {
    const listener = () => {
      refetchFolders();
    };

    emitter.on('refetch_folders', listener);

    return () => {
      emitter.off('refetch_folders', listener);
    };
  }, []);

  useEffect(() => {
    if (isLoadingFolders) {
      return;
    }
    if (folder === 'all') {
      dispatch(setCurrentFolder({
        type: 'forms',
        value: {
          id: 'all',
          name: 'All Forms',
          sub_folders: [],
        },
      }));
    } else if (folder === 'my') {
      dispatch(setCurrentFolder({
        type: 'forms',
        value: {
          id: 'my',
          name: 'My Forms',
          sub_folders: [],
        },
      }));
    } else {
      let tempFolder = null;
      let found = false;

      folders.forEach((f) => {
        if (found) return;

        if (f.id === folder) {
          found = true;
          tempFolder = f;
        } else {
          f.sub_folders.forEach((sf) => {
            if (sf.id === folder) {
              found = true;
              tempFolder = sf;
            }
          });
        }
      });

      if (tempFolder) {
        dispatch(setCurrentFolder({
          type: 'forms',
          value: {
            id: tempFolder.id,
            name: tempFolder.name,
            sub_folders: tempFolder.sub_folders,
          },
        }));
      }
    }
  }, [folder, isLoadingFolders]);

  useEffect(() => {
    if (searchParams.get('sort')) {
      setDesc(searchParams.get('sort') === 'desc');
    } else {
      setDesc(true);
    }

    if (searchParams.get('search')) {
      setSearch(searchParams.get('search'));
    } else {
      setSearch('');
    }

    if (searchParams.get('status')) {
      setStatus(getStatus(searchParams.get('status')));
    } else {
      setStatus('');
    }

    if (searchParams.get('orderBy')) {
      setOrdering(searchParams.get('orderBy'));
    } else {
      setOrdering('created_at');
    }

    if (searchParams.get('page')) {
      setPage(Number(searchParams.get('page')));
    } else {
      setPage(1);
    }

    if (searchParams.get('limit')) {
      setLimit(Number(searchParams.get('limit')));
    } else {
      setLimit(10);
    }

    if (searchParams.get('folder')) {
      if (searchParams.get('folder') === 'all' || searchParams.get('folder') === 'my') {
        setFolder(searchParams.get('folder'));
      } else {
        setFolder(Number(searchParams.get('folder')));
      }
    } else {
      setFolder('all');
    }

    dispatch(setSearchParams({ type: 'forms', value: Object.fromEntries([...searchParams]) }));
  }, [searchParams]);

  useEffect(() => {
    if (forms?.results) {
      const tempSelection = selection.filter((item) => forms.results.some((d) => d.id === item.id));
      dispatch(setSelectedItems({ type: 'forms', value: tempSelection }));
    }
  }, [forms?.results]);

  return useMemo(() => {
    if (isLoadingFolders) {
      return <Loader />;
    }

    return (
      <ListWithFolders
        folders={folders}
        allText="All Forms"
        myText="My Forms"
        type="forms"
      >
        <FormsList
          data={forms?.results}
          count={forms?.count || 0}
          limit={limit}
          isLoading={isLoading || isFetching}
          refetch={refetch}
          desc={desc}
          ordering={ordering}
          page={page}
          search={search}
          status={status}
        />
      </ListWithFolders>
    );
  }, [
    isLoading,
    isFetching,
    forms,
    forms?.results,
    forms?.total_count,
    forms?.count,
    limit,
    refetch,
    desc,
    ordering,
    page,
    folders,
    search,
  ]);
};

export default Forms;
