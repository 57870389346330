import React from 'react';
import { coinApi } from '../../../../api/coin';
import HistoricalHoldersGraph from '../../../../components/ui/HistoricalHoldersGraph';

const HistoricalHolders = ({ address, network, audience }) => {
  const {
    data: graphData,
    isLoading: isGraphDataFetching,
    isFetching: isGraphDataLoading,
    isSuccess,
  } = coinApi.useGetNftHistoricalHoldersQuery(
    {
      address,
      chain: network,
      period: '365',
      audience,
    },
  );

  return (
    <>
    {graphData && graphData.length > 0 && (
      <HistoricalHoldersGraph
        graphData={graphData}
        isLoading={isGraphDataLoading || isGraphDataFetching}
        isSuccess={isSuccess}
        audience={audience}
      />
    )}
    </>
  );
};

export default HistoricalHolders;
