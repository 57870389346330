import React from 'react';
import AsyncSelect from 'react-select/async';
import {
  MultiValueLabel, MultiValueRemove,
} from '../labels';
import { placeholderStyle } from '../../Condition/Condition.constants';

const AsyncMultiSelect = ({
  disabled, value, options, onChange, getLabel, placeholder,
}) => {
  const handleChange = (val) => {
    if (onChange) {
      onChange(val);
    }
  };

  return (
    <div className="w-100">
      <AsyncSelect
        styles={placeholderStyle}
        maxMenuHeight={194}
        components={
          {
            // eslint-disable-next-line react/no-unstable-nested-components
            MultiValueRemove: (props) => <MultiValueRemove props={props} show="label" />,
            // eslint-disable-next-line react/no-unstable-nested-components
            MultiValueLabel: (props) => <MultiValueLabel props={props} show="label" />,
          }
        }
        disabled={disabled}
        isDisabled={disabled}
        className="w-100"
        value={value}
        loadOptions={options}
        selectProps={{ val: value }}
        getOptionLabel={(val) => getLabel(val)}
        isSearchable
        cacheOptions={false}
        onChange={handleChange}
        defaultOptions
        isMulti
        placeholder={placeholder}
      />
    </div>
  );
};

export default AsyncMultiSelect;
