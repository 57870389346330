import React from 'react';
import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './Notifications.scss';
import styles from './Notifications.module.scss';

export function showErrorMessage(message) {
  if (!toast.isActive(message)){
    toast.error(message, { className: 'error', toastId: message });
  }
}

export function showSuccessMessage(message) {
  if (!toast.isActive(message)){
    toast.success(message, { toastId: message });
  }
}

export function showInfoMessage(message) {
  if (!toast.isActive(message)){
    toast(message, {
      toastId: message,
      position: 'bottom-center',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      progress: undefined,
      style: {
        textAlign: 'center',
        background: 'rgba(85, 97, 111, 0.9)',
        borderRadius: '3px',
        padding: '8px 12px',
        fontFamily: 'Poppins',
        fontSize: '14px',
        lineHeight: '21px',
        color: '#FFFFFF',
      },
    });
  }
}
export function showErrorMessageWithButton(message, onClick) {
  if (!toast.isActive(message)){
    toast.error(
      (
        <div>
          {message}
          {onClick
            ? (
              <div
                role="presentation"
                onClick={() => onClick()}
                className={styles.see_all}
              >
                See all
              </div>
            )
            : null}
        </div>),
      {
        hideProgressBar: true,
        className: `error ${styles.max_content}`,
        toastId: message,
      },
    );
  }
}

export function showWarningMessage(message) {
  if (!toast.isActive(message)){
    toast.warning(message, { toastId: message });
  }
}
