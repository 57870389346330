import { formatDistanceToNow } from 'date-fns';
import { showErrorMessage } from '../../components/base/Notifications';
import { toBN } from '../../tools/EtherTool';
import { supportedChains } from '../../components/base/ChainLogo/chains';

const intValues = ['is-more-than', 'is-less-than'];
const isSetValues = ['is-set', "isn't-set"];

export const convertWeiToEth = (wei) => (wei / 10 ** 18).toFixed(5);

export const getWalletAge = (firstTx, addSuffix = false) => {
  if (typeof firstTx !== 'string') return 'No Transactions';
  const date = Date.parse(firstTx);
  return formatDistanceToNow(date, { addSuffix });
};

export const validateInput = (val, isWalletAge, float = false) => {
  if (!val) return true;
  if (isWalletAge) {
    if (val > 9999) {
      showErrorMessage(
        '4 numbers are already entered. You are not allowed to enter more.',
      );
      return false;
    }
    return true;
  }
  if (!float && toBN(val).gt(toBN('1000000000000000000'))) {
    showErrorMessage(
      '18 numbers are already entered. You are not allowed to enter more.',
    );
    return false;
  }
  return true;
};

export const lengthOf18andstop = (e) => {
  if (e.keyCode === 8) {
    return;
  }
  if (e.target.value.replace(/[$,]/g, '').length >= 18) {
    showErrorMessage(
      '18 numbers are already entered. You are not allowed to enter more.',
    );
  }
  if (
    e.keyCode === 190
    || e.keyCode === 110
    || e.target.value.replace(/[$,]/g, '').length >= 18
  ) {
    e.preventDefault();
  }
};

export const onPaste = (e) => {
  const paste = e.clipboardData.getData('text/plain');
  if (paste.match(/[-]/)) {
    showErrorMessage('Negative amounts are not permitted.');
    e.preventDefault();
  }

  if (paste.match(/[,.eE+]/)) {
    showErrorMessage('Paste value contains invalid symbols.');
    e.preventDefault();
  }

  if (paste.length > 18) {
    showErrorMessage(
      'Paste input exceeds the required length. You are not allowed to enter more than 18 digits.',
    );
    e.preventDefault();
  }
};

export const onPasteFloat = (e, value = '') => {
  const paste = value || e.clipboardData.getData('text/plain');
  if (paste.match(/[-]/)) {
    showErrorMessage('Negative amounts are not permitted.');
    e.preventDefault();
  }

  if (paste.match(/[,eE+]/)) {
    showErrorMessage('Paste value contains invalid symbols.');
    e.preventDefault();
  }
};

export const preventSymbols = (e) => {
  const arr = ['e', 'E', '+', '-', '.'];
  if (
    arr.includes(e.key)
    || (e.target.value.slice(-1) === ',' && e.code === 'Comma')
  ) {
    e.preventDefault();
  }
};

export const preventInvalidSymbols = (e, float = false) => {
  const arr = float ? ['e', 'E', '+', '-', ','] : ['e', 'E', '+', '-', '.', ','];
  if (arr.includes(e.key)) {
    e.preventDefault();
  }
};

export const checkTxCountValues = (data) => {
  if (data.options.length < 3) return false;
  return data.options.every((option) => {
    if (option.name === 'date' || option.name === 'chain') {
      return true;
    }
    return Array.isArray(option?.value) ? option?.value[0]?.value : null;
  });
};

export const formatTxCountValues = (data) => {
  const newData = data.options.map((option, index) => {
    if (option.name === 'chain' && !option.value) {
      return { ...option, value: [{ value: 'across-all-chains' }] };
    }
    if (index === 1) {
      if (
        Array.isArray(data.options[0].value)
          ? data.options[0].value[0].value === 'total'
          : false
      ) {
        if (option.name === 'date') {
          return;
        }
        return option;
      }
      if (
        Array.isArray(data.options[0].value)
          ? data.options[0].value[0].value === 'trailing-period'
          : false
      ) {
        const newOption = { ...option, options: [option.options[0]] };
        return newOption;
      }
      if (
        Array.isArray(data.options[0].value)
          ? data.options[0].value[0].value === 'since-data'
          : false
      ) {
        const newOption = { ...option, options: [option.options[1]] };
        return newOption;
      }
      return option;
    }
    return option;
  });
  const filteredData = newData.filter((option) => option);
  return filteredData;
};

export const shouldSkipWalletFetch = (data) => !data || Boolean(!data?.segment?.categories[0]?.filters?.length);

const chainsArray = Object.keys(supportedChains);
export const formatString = (str) => {
  const foundChain = chainsArray.find((chain) => str.includes(chain));
  if (foundChain) {
    return `On ${str.slice(3, 4).toUpperCase() + str.slice(4)}`;
  }
  return (str.slice(0, 1).toUpperCase() + str.slice(1)).replace(/-/g, ' ');
};

export const convertCreatedTxCount = (data) => {
  const t = [...data.options[0].options];
  const newOptions = [];
  t.forEach((option, index) => {
    if (index === 1) {
      if (
        ['is-more-than', 'is-less-than'].includes(
          Array.isArray(option.value) ? option.value[0].value : false,
        )
      ) {
        newOptions.push({
          name: 'date',
          type: 'select',
          options: [
            {
              name: 'period',
              options: [
                { label: 'Last 24 hours', value: 'last-24-hours' },
                { label: 'Last 7 days', value: 'last-7-days' },
                { label: 'Last 30 days', value: 'last-30-days' },
              ],
              type: 'select',
            },
            {
              label: 'Since',
              name: 'since',
              type: 'date-picker',
            },
          ],
        });
        newOptions.push(option);
        return;
      }
      if (option.options[0].name === 'period' && !option?.options[1]?.value) {
        newOptions.push({
          name: 'date',
          type: 'select',
          options: [
            {
              ...option.options[0],
            },
            {
              label: 'Since',
              name: 'since',
              type: 'date-picker',
            },
          ],
        });
        return;
      }
      if (option.options[0].name === 'since') {
        newOptions.push({
          name: 'date',
          type: 'select',
          options: [
            {
              name: 'period',
              options: [
                { label: 'Last 24 hours', value: 'last-24-hours' },
                { label: 'Last 7 days', value: 'last-7-days' },
                { label: 'Last 30 days', value: 'last-30-days' },
              ],
              type: 'select',
            },
            {
              ...option.options[0],
            },
          ],
        });
        return;
      }
      newOptions.push(option);
      return;
    }
    newOptions.push(option);
  });
  const setData = newOptions.length > 1
    ? [{ name: 'select-option', options: newOptions }]
    : newOptions;
  return {
    category: data.category,
    id: data.id,
    label: data.label,
    name: data.name,
    options: setData,
  };
};

export const convertNftCoinsSearchToNewStructure = (data, category) => {
  const type = (value) => (Array.isArray(value) || typeof value === 'string' ? value : [value]);
  const optionsToSet = data.options.map((option) => ({
    ...option,
    value: ['search-coin', 'search-nft'].includes(option.type)
      ? type(option.value)
      : option.value,
  }));
  const newData = { ...data, options: optionsToSet };
  return { category: category.name, ...newData };
};

export const convertCEXValues = (data, category, segmentData) => {
  const cexDataCategory = segmentData.segment.categories.filter(
    (cat) => cat.name === category.name,
  )[0];
  const dataFilters = cexDataCategory.filters.filter(
    (filter) => filter.name === data.name,
  )[0];
  let optionsToSet;
  const hasYesNoFilters = data.options.some(
    (option) => option.value[0].value !== 'search-cex' && option.name === 'is-cex',
  );
  if (hasYesNoFilters) {
    optionsToSet = [
      data.options[0],
      ...dataFilters.options.slice(1, 9),
      data.options[1],
    ];
  } else {
    const dataOptions = dataFilters.options.map((filter) => {
      const newData = data.options.find((f) => f.name === filter.name);
      return {
        ...filter,
        value: newData ? newData.value : filter.value,
      };
    });
    optionsToSet = [...dataOptions];
  }
  return { ...data, category: category.name, options: optionsToSet };
};

export const convertDappValues = (data, category, segmentData) => {
  const dappDataCategory = segmentData.segment.categories.filter(
    (cat) => cat.name === category.name,
  )[0];
  const dataFilters = dappDataCategory.filters.filter(
    (filter) => filter.name === data.name,
  )[0];
  const dataOptions = dataFilters.options.map((filter) => {
    const newData = data.options.find((f) => f.name === filter.name);
    return {
      ...filter,
      value: newData ? newData.value : filter.value,
    };
  });
  const optionsToSet = [...dataOptions];
  return { ...data, category: category.name, options: optionsToSet };
};

export const convertInvestmentsValue = (data, category) => {
  const optionsToSet = [];
  data.options.forEach((option, index) => {
    if (index === 2 && option.value[0].value === 'now') {
      optionsToSet.push(option);
      optionsToSet.push({
        label: 'Amount',
        name: 'date',
        post_name: 'filter-type',
        pre_name: 'filter-type',
        type: 'input',
        value: null,
      });
      optionsToSet.push({
        name: 'date-type',
        options: [
          { label: 'Days', value: 'days' },
          { label: 'Months', value: 'months' },
        ],
        type: 'select',
      });
      return;
    }
    optionsToSet.push(option);
  });

  return { ...data, category: category.name, options: optionsToSet };
};

export const convertCustomDataValues = (data, category) => {
  const optionsToSet = [];
  data.options.forEach((option, index) => {
    if (index === 1 && option.type === 'multi-input') {
      optionsToSet.push(option);
      optionsToSet.push({
        label: 'Custom  Search',
        name: 'string',
        pre_name: 'is-than',
        type: 'custom-search',
        value: null,
      });
      return;
    }
    if (index === 1 && option.type === 'custom-search') {
      optionsToSet.push({
        label: 'Custom  Search',
        name: 'multi-string',
        pre_name: 'is-than',
        type: 'multi-input',
        value: null,
      });
      optionsToSet.push(option);
      return;
    }
    optionsToSet.push(option);
  });
  return { ...data, category: category.name, options: optionsToSet };
};

export const convertTxCountValues = (data, category) => {
  const optionsToSet = data.options.map((option, index) => {
    if (index === 1) {
      if (option.name === 'period') {
        return {
          name: 'date',
          type: 'select',
          options: [
            {
              ...option,
            },
            {
              label: 'Since',
              name: 'since',
              type: 'date-picker',
            },
          ],
        };
      }
      if (option.name === 'since') {
        return {
          name: 'date',
          type: 'select',
          options: [
            {
              name: 'period',
              options: [
                { label: 'Last 24 hours', value: 'last-24-hours' },
                { label: 'Last 7 days', value: 'last-7-days' },
                { label: 'Last 30 days', value: 'last-30-days' },
              ],
              type: 'select',
            },
            {
              ...option,
            },
          ],
        };
      }
    }
    return option;
  });
  let dataToInsert = optionsToSet;
  if (optionsToSet.length === 3) {
    const optionToPush = {
      name: 'date',
      type: 'select',
      options: [
        {
          name: 'period',
          type: 'select',
          options: [
            { label: 'Last 24 hours', value: 'last-24-hours' },
            { label: 'Last 7 days', value: 'last-7-days' },
            { label: 'Last 30 days', value: 'last-30-days' },
          ],
        },
        {
          label: 'Since',
          name: 'since',
          type: 'date-picker',
          value: null,
        },
      ],
    };
    const buf = optionsToSet.slice(1, 3);
    dataToInsert = [optionsToSet[0], optionToPush, ...buf];
  }
  return {
    ...data,
    category: category.name,
    options: [{ name: 'select-option', options: dataToInsert }],
  };
};

export const convertCustomDataString = (data, category, segmentData) => {
  const customDataCategory = segmentData.segment.categories[segmentData.segment.categories.length - 1];
  const dataFilters = customDataCategory.filters.filter(
    (filter) => filter.name === data.name,
  )[0];
  const includesMultiStringValues = data.options.some(
    (opt) => opt.type === 'multi-input',
  );
  let optionsToSet;
  if (includesMultiStringValues) {
    optionsToSet = [...data.options, dataFilters.options[3]];
  } else {
    optionsToSet = [
      ...data.options.slice(0, 2),
      dataFilters.options[2],
      ...data.options.slice(2, 3),
    ];
  }

  return { ...data, category: category.name, options: optionsToSet };
};

export const convertCustomDataDate = (data, category, segmentData) => {
  const customDataCategory = segmentData.segment.categories[segmentData.segment.categories.length - 1];
  const dataFilters = customDataCategory.filters.filter(
    (filter) => filter.name === data.name,
  )[0];
  const includesDateValues = data.options.some(
    (opt) => opt.type === 'date-picker',
  );
  let optionsToSet;

  if (includesDateValues) {
    optionsToSet = [...data.options, ...dataFilters.options.slice(2, 4)];
  } else {
    optionsToSet = [
      ...data.options.slice(0, 1),
      dataFilters.options[1],
      ...data.options.slice(1, 3),
    ];
  }

  return { ...data, category: category.name, options: optionsToSet };
};

export const convertCustomDataIsSet = (data, category, segmentData) => {
  const customDataCategory = segmentData.segment.categories[segmentData.segment.categories.length - 1];
  const dataFilters = customDataCategory.filters.filter(
    (filter) => filter.name === data.name,
  )[0];
  const optionsToSet = [
    ...data.options,
    ...dataFilters.options.slice(1, dataFilters.options.length),
  ];
  return { ...data, category: category.name, options: optionsToSet };
};

export const convertCustomDataCreationDateValues = (data, category) => {
  const optionsToSet = [];
  data.options.forEach((option) => {
    if (isSetValues.includes(option.value)) {
      optionsToSet.push(option);
      optionsToSet.push({
        type: 'date-picker',
        name: 'date picker',
        label: 'Date picker',
        value: null,
        pre_name: 'is-than',
      });
      optionsToSet.push({
        type: 'input',
        name: 'date input',
        label: 'Date input',
        value: null,
        pre_name: 'is-than',
      });
      optionsToSet.push({
        type: 'select',
        name: 'ago',
        options: [
          {
            label: 'Days ago',
            value: 'days-ago',
          },
          {
            label: 'Months ago',
            value: 'months-ago',
          },
        ],
      });
      return;
    }

    if (intValues.includes(option.value)) {
      optionsToSet.push(option);
      optionsToSet.push({
        type: 'date-picker',
        name: 'date picker',
        label: 'Date picker',
        value: null,
        pre_name: 'is-than',
      });
      return;
    }

    if (option.name === 'date picker') {
      optionsToSet.push(option);
      optionsToSet.push({
        type: 'input',
        name: 'date input',
        label: 'Date input',
        value: null,
        pre_name: 'is-than',
      });
      optionsToSet.push({
        type: 'select',
        name: 'ago',
        options: [
          {
            label: 'Days ago',
            value: 'days-ago',
          },
          {
            label: 'Months ago',
            value: 'months-ago',
          },
        ],
      });
      return;
    }

    optionsToSet.push(option);
  });
  return { ...data, category: category.name, options: optionsToSet };
};

export const convertCustomDataTimestampValues = (
  data,
  category,
  segmentData,
) => {
  let optionsToSet = [];
  const customDataCategory = segmentData.segment.categories[segmentData.segment.categories.length - 1];
  const dataFilters = customDataCategory.filters.filter(
    (filter) => filter.name === data.name,
  )[0];
  const hasAgoOption = data.options.some((option) => option.name === 'ago');
  const hasTimeOption = data.options.some(
    (option) => option.type === 'time-picker',
  );
  if (hasAgoOption) {
    optionsToSet = [
      ...data.options.slice(0, 1),
      ...dataFilters.options.slice(1, 3),
      ...data.options.slice(1, 3),
    ];
  }
  if (hasTimeOption) {
    optionsToSet = [...data.options, ...dataFilters.options.slice(3, 5)];
  }
  return { ...data, category: category.name, options: optionsToSet };
};

export const convertWebActivityValues = (data, category) => {
  const optionsToSet = [];
  if (data.options.length > 2 && data.options[2].name === 'is-not') {
    if (
      data.options[2].value
      && data.options[2].value.length === 1
      && (data.options[2].value[0].value === 'is-set'
        || data.options[2].value[0].value === "isn't-set")
    ) {
      optionsToSet.push(...data.options.slice(0, 3), {
        type: 'select',
        name: 'any-of-or-all-of',
        options: [
          {
            label: 'Any of',
            value: 'any-of',
          },
          {
            label: 'All of',
            value: 'all-of',
          },
        ],
      });
      switch (data.name) {
        case 'visitors_country':
          optionsToSet.push({
            type: 'search-country',
            name: 'country',
            label: 'Country',
            value: null,
          });
          break;
        case 'visitors_language':
          optionsToSet.push({
            type: 'search-language',
            name: 'language',
            label: 'Language',
            value: null,
          });
          break;
        case 'visitors_wallet_type':
          optionsToSet.push({
            type: 'search-wallet-type',
            name: 'wallet-type',
            label: 'wallet-type',
            value: null,
          });
          break;
        case 'visitors_source':
          optionsToSet.push({
            type: 'search-source',
            name: 'source',
            label: 'source',
            value: null,
          });
          break;
        case 'visitors_blockchain':
          optionsToSet.push({
            type: 'search-blockchain',
            name: 'blockchain',
            label: 'blockchain',
            value: null,
          });
          break;
      }
    } else {
      optionsToSet.push(...data.options);
    }
  } else {
    optionsToSet.push(...data.options);
  }
  return { ...data, category, options: optionsToSet };
};
