import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Image } from '@absolutelabs/react-component-library';
import {
  selectCurrPage,
  selectFieldSettings,
} from '../../../../../store/reducers/forms';

const ImageField = ({ ...props }) => {
  const currPage = useSelector(selectCurrPage);
  const fieldSettings = useSelector(selectFieldSettings);
  const [fieldConfig, setFieldConfig] = useState({});

  useEffect(() => {
    const currPageSettings = fieldSettings.find((o) => o.pageName === currPage);
    const config = currPageSettings?.fields?.find((x) => x.field_id === props?.field?.id);
    if (config) {
      setFieldConfig(config);
    }
  }, [fieldSettings, currPage, props?.field?.id]);

  return (
    <Image
      url={fieldConfig.url || 'https://storage.googleapis.com/abs-assets/form/819875e0-1fa1-4588-adb8-8d98b663a05e.png'}
      width={fieldConfig.width || 50}
      imageAlign={fieldConfig.imageAlign || 'center'}
      editMode={props.editMode}
      caption={fieldConfig.caption || ''}
    />
  );
};

export default ImageField;
