import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedItems, setSelectedItems } from '../../../../store/reducers/folders';
import Checkbox from '../../../ui/Checkbox';

const TableCheckbox = ({
  id, type, itemType,
}) => {
  const dispatch = useDispatch();
  const selection = useSelector((state) => getSelectedItems(state, type));

  const handleChange = (checked) => {
    if (checked) {
      const tempSelection = [...selection, {
        id,
        type: itemType,
      }];
      dispatch(setSelectedItems({ type, value: tempSelection }));
    } else {
      const tempSelection = selection.filter((item) => item.id !== id);
      dispatch(setSelectedItems({ type, value: tempSelection }));
    }
  };

  return (
    <Checkbox
      checked={selection.some((item) => item.id === id)}
      onChange={(checked) => handleChange(checked)}
    />
  );
};

export default TableCheckbox;
