import React from 'react';
import Loading from '../../../assets/icons/anaytics/default layout.svg';
import Data from '../../../assets/icons/anaytics/data.svg';
import CheckMark from '../../../assets/icons/anaytics/check_mark.svg';
import ChartMini from '../../../assets/icons/anaytics/mimi chart.svg';
import Chart from '../../../assets/icons/anaytics/chart.svg';
import Tab from '../../../assets/icons/anaytics/tab.svg';
import Dashboard from '../../../assets/icons/anaytics/Dashboard.svg';
import ShadowDashboard from '../../../assets/icons/anaytics/dashboard shadow.svg';
import ShadowChartMini from '../../../assets/icons/anaytics/mini chart shadow.svg';
import ShadowTab from '../../../assets/icons/anaytics/tab shadow.svg';
import Human from '../../../assets/icons/anaytics/human.svg';
import User1 from '../../../assets/icons/anaytics/User1.svg';
import User2 from '../../../assets/icons/anaytics/User2.svg';
import ShadowUser from '../../../assets/icons/anaytics/User_shadow.svg';
import ShadowChart from '../../../assets/icons/anaytics/chart shadow.svg';
import Gears from '../../../assets/icons/anaytics/gears.svg';
import styles from './SegmentAnalyticsLoader.module.scss';

const SegmentAnalyticsLoader = () => (
  <div className="d-flex flex-column gap-2 align-items-center justify-content-center w-100">
    <div className={`${styles.wrapper} position-relative`}>
      <Loading />
      <div className={`${styles.dashboard} position-absolute`}>
        <Dashboard />
      </div>
      <div className={`${styles.gears} position-absolute`}>
        <Gears />
      </div>
      <div className={`${styles.shadow_dashboard} position-absolute`}>
        <ShadowDashboard />
      </div>
      <div className={`${styles.tab} position-absolute`}>
        <Tab />
      </div>
      <div className={`${styles.shadow_tab} position-absolute`}>
        <ShadowTab />
      </div>
      <div className={`${styles.human} position-absolute`}>
        <Human />
      </div>
      <div className={`${styles.user1} position-absolute`}>
        <User1 />
      </div>
      <div className={`${styles.user2} position-absolute`}>
        <User2 />
      </div>
      <div className={`${styles.shadow_user1} position-absolute`}>
        <ShadowUser />
      </div>
      <div className={`${styles.shadow_user2} position-absolute`}>
        <ShadowUser />
      </div>
      <div className={`${styles.chart} position-absolute`}>
        <Chart />
      </div>
      <div className={`${styles.shadow_chart} position-absolute`}>
        <ShadowChart />
      </div>
      <div className={`${styles.chart_mini} position-absolute`}>
        <ChartMini />
      </div>
      <div className={`${styles.shadow_chart_mini} position-absolute`}>
        <ShadowChartMini />
      </div>
      <div className={`${styles.check} position-absolute`}>
        <CheckMark />
      </div>
      <div className={`${styles.data} position-absolute w-25`}>
        <Data />
      </div>
    </div>
    <div className={`d-flex align-items-center justify-content-center w-50 ${styles.text}`}>
      Please hold while we generate your report…
    </div>
  </div>
);

export default SegmentAnalyticsLoader;
