import React from 'react';
import styles from './popupMenuButton.module.scss';

const PopupMenuButton = ({ children, onClick, className }) => (
  <div
    className={`
                    d-flex
                    justify-content-start
                    align-items-center
                    gap-1
                    text-truncate
                    ${styles.popup_menu_button}
                    ${className}`}
    onClick={(e) => onClick(e)}
    role="presentation"
  >
    {children}
  </div>
);

export default PopupMenuButton;
