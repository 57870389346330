const assetTypeSwitch = (assetType, id, network) => {
  switch (assetType) {
    case 'coin': {
      return {
        coin: {
          coin_id: id,
        },
      };
    }
    case 'nft': {
      return {
        nft_collection: {
          address: id,
          network,
        },
      };
    }
    default:
      return null;
  }
};

export const formatAssetData = (action, placeType, assetType, id, network = 'ethereum') => ({
  action,
  place_type: placeType,
  ...assetTypeSwitch(assetType, id, network),
});

export const getAssetIdAfterFetch = (data) => {
  let id = null;
  if ('coin' in data) {
    id = data.coin.coin_id;
  }
  if ('nft_collection' in data) {
    id = data.nft_collection.address;
  }
  return id;
};

export const getNetworkAfterFetch = (data) => {
  if ('nft_collection' in data) {
    return data.nft_collection.network;
  }
  return null;
};
