export const types = [
  'general-values',
  'set-values',
  'cex-values',
  'int-values',
  'date-values',
  'contain-values',
  'search-values',
  'filter-values',
  'cex-boolean-values',
  'dapp-values',
  'set-values-web-activity',
];

export const apiTypes = [
  'interests',
  'custom',
  'customList',
  'cexOptions',
  'segments',
  'dapps',
  'forms',
  'websites',
  'walletType',
  'sources',
  'blockchains',
];

export const dropdownOptions = {
  dappTotalTransaction: [
    'and-total-transaction-count',
    'and-total-ethereum-value',
    'and-total-NFT-sales',
    'and-total-NFT-buys',
  ],
  dappTransactionValues: [
    'and-number-of-transactions-for',
    'and-total-ethereum-value-for',
  ],
  dappFirstLastTransaction: [
    'and-first-transaction',
    'and-last-transaction',
  ],
};

export const excludeOptionNames = {
  dappTotalTransaction: ['is-than-combo', 'duration', 'ago', 'date'],
  dappTransactionValues: ['is-than-combo', 'ago', 'date'],
  dappFirstLastTransaction: ['duration', 'is-than'],
};

export const setValues = ['is-set', "isn't-set"];
export const booleanValues = ['true', 'false'];
export const CEXValues = ['search-cex'];
export const DappValues = ['search-dapp'];
export const intValues = ['is-more-than', 'is-less-than'];
export const datePickerValues = ['is', 'is-not', 'is-before', 'is-after'];
export const containValues = ['contains', 'does-not-contain'];
export const searchValues = ['is', 'is-not'];
export const filterValues = ['now'];
export const nftCoinValues = ['owns-token', 'owns-nft'];
export const booleanCEXValues = [...booleanValues, CEXValues];
export const anyOfAllOfValues = ['any-of', 'all-of'];
