import React from 'react'
import AiBlock from '../../AiBlock'
import { useParams } from 'react-router';
import useWebSocket from '../../../hooks/app/useWebSocket';

const AiMarket = ({ itemName, type, isReady, isCustomContract}) => {
  const { network, name } = useParams();
  const shouldConnect = type === 'coin'
    ? isReady
    : isReady && !isCustomContract;
  const url = shouldConnect
    ? type === 'coin'
      ? 'ws/ft/ai-overview/'
      : 'ws/nft/ai-overview/'
    : null;
  const payload = shouldConnect
    ? type === 'coin'
      ? { coin_id: name }
      : { address: name, blockchain: network }
    : null;

  const { aiOverview } = useWebSocket(url, payload, !shouldConnect, name);

  return (
    <AiBlock
      data={aiOverview}
      linkText="Full AI-Generated Report"
      reportTitle={`AI Summary of ${itemName}`}
      wBackground
      title="AI Overview -"
    />
  )
}

export default AiMarket
