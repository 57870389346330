import React from 'react';
import { colors, tooltip } from './ChartOptions';
import styles from './CircleChart.module.scss';
import Tooltip from '../Tooltip';
import uniqueId from '../../../utils/uniqueId';

const Legend = ({ data, className }) => {
  const getColorClassName = (index) => {
    const color = data[index]?.color ? data[index].color : colors[index]?.class || 'blue';
    switch (color) {
      case 'blue':
        return styles.blue;
      case 'yellow':
        return styles.yellow;
      case 'green':
        return styles.green;
      case 'purple':
        return styles.purple;
      case 'red':
        return styles.red;
      case 'gray':
        return styles.gray;
      case 'orange':
        return styles.orange;
      case 'black':
        return styles.black;
      case 'darkBlue':
        return styles.darkBlue;
      default:
        return '';
    }
  };

  return (
    <div className={`${styles.legend_wrapper} ${className}`}>
      {data.map((item, index) => {
        const id = uniqueId('legend');
        return (
          <div key={id}>
            <div className={styles.row} data-for={id} data-tip>
              <div className={styles.left}>
                <div className={`${styles.color} ${getColorClassName(index)}`} />
                <span className={styles.label}>
                  {item.label}
                </span>
              </div>
              <span className={styles.percent}>
                {`${item.percent.toFixed(1)}%`}
              </span>
            </div>
            <Tooltip id={id} info={tooltip(item.value, item.label, item.percent, item.count)} />
          </div>
        );
      })}
    </div>
  );
};

export default Legend;
