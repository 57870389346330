import React from 'react';
import Arrow from '../../../assets/icons/table/arrow.svg';
import uniqueId from '../../../utils/uniqueId';
import Pagination from './Pagination';
import RoundSpinner from '../RoundSpinner';
import styles from './mainTable.module.scss';
import useSetSearchParams from '../../../utils/useSetSearchParams';

const MainTable = ({
  ordering, desc, page, limit, data, total, headers, isLoading,
  min = 10, nothingComponent,
}) => {
  const setParams = useSetSearchParams();

  if (!data) {
    return (
      <div className={`${styles.loader} position-relative`}>
        <RoundSpinner position="position-absolute" theme="light" />
      </div>
    );
  }

  if (!isLoading && data.length === 0) {
    return (
      <div className="h-100 pb-5">
        {nothingComponent}
      </div>
    );
  }

  return (
    <div className={`${styles.wrapper} position-relative`}>
      {isLoading
        ? <RoundSpinner position="position-absolute" theme="light" />
        : null}
      <div className={styles.table_wrapper}>
        <table className={`${styles.table} w-100 position-relative`}>
          <thead className={`${styles.header} position-sticky top-0`}>
            <tr className="border-bottom-0">
              {headers.map((item) => (
                <th
                  className={`
                  text-nowrap
                  user-select-none
                  ${item.field ? 'cursor-pointer' : ''}
                `}
                  style={{ width: item.width ? item.width : 'auto', padding: '0 16px' }}
                  key={uniqueId('tableTitle')}
                  onClick={() => {
                    if (!item.field) {
                      return;
                    }
                    if (item.field === ordering) {
                      setParams({ sort: desc ? 'asc' : 'desc' });
                    } else {
                      setParams({ orderBy: item.field, sort: 'desc', page: 1 });
                    }
                  }}
                >
                  {item.title}
                  {item.field
                    ? (
                      <button
                        type="button"
                        className={styles.arrow}
                      >
                        {item.field
                          ? (
                            <Arrow
                              className={`
                            ${item.field === ordering ? styles.active : ''}
                            ${item.field === ordering && !desc ? styles.rotate : ''}
                            `}
                            />
                          )
                          : null}
                      </button>
                    )
                    : null}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="border-top-0">
            {data.map((row) => (
              <tr
                key={uniqueId('bodyRow')}
                className={typeof row?.ready === 'undefined'
                || row?.ready ? styles.row : styles.row_unready}
              >
                {Object.keys(row).map((elem) => (
                  elem !== 'ready' ? (
                    <td
                      className={elem === ordering ? styles.selected_td : ''}
                      style={{ padding: '0 16px' }}
                      key={uniqueId('td')}
                    >
                      {row[elem]}
                    </td>
                  )
                    : null
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {total > min
        ? (
          <Pagination
            page={page}
            limit={limit}
            total={total}
            min={min}
          />
        )
        : null}
    </div>
  );
};

export default MainTable;
