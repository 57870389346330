import React from 'react';
import styles from './BlinkingDot.module.scss';

const BlinkingDot = ({ isSuccess = true }) => (
  <svg height="8" width="8" className={styles.blinking}>
    <circle cx="4" cy="4" r="4" fill={isSuccess ? '#2ED47A' : '#F7685B'} />
  </svg>
);

export default BlinkingDot;
