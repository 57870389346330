import React from 'react';

import './AddCard.scss';
import Plus from '../../../assets/images/dashboard/plus.png';

const AddCard = ({ navigate }) => (
  <div
    role="presentation"
    className="add-card-container"
    onClick={() => {
      navigate();
    }}
  >
    <img src={Plus} alt="plus" />
  </div>
);

export default AddCard;
