import React from 'react';

import styles from '../inputs/Input.module.scss';

const CexLabel = ({ val }) => (
  <div className="d-flex cursor-pointer justify-content-between align-items-center">
    <div className="d-flex align-items-baseline flex-wrap">
      <span className={`${styles.label} me-2`}>{val?.label}</span>
    </div>
  </div>
);

export default CexLabel;
