import React, { useEffect, useState } from 'react';
import { coinApi } from '../../../../api/coin';
import CircleChart from '../../../../components/ui/CircleChart';

const Distribution = ({ address, network, audience, className }) => {
  const [data, setData] = useState([]);
  const [top10, setTop10] = useState(0);

  const {
    data: coinData,
    isLoading,
    isFetching,
  } = coinApi.useGetNftTopHoldersQuery({ address, chain: network, audience });

  const {
    data: nftData,
    isLoading: isLoadingNFT,
    isFetching: isFetchingNFT,
  } = coinApi.useGetNftBasicInfoQuery({ address, network, audience });

  useEffect(() => {
    if (coinData && nftData) {
      const total = nftData.count;

      if (total === 0) return;

      const [holdersTokensCount] = coinData.reduce((acc, obj) => {
        acc[0] += obj.token_qty;
        return acc;
      }, [0]);

      const [top10Data] = coinData.slice(0, 10).reduce((acc, obj) => {
        acc[0] += obj.token_qty;
        return acc;
      }, [0]);

      const [first3] = coinData.slice(0, 3).reduce((acc, obj) => {
        acc[0] += obj.token_qty;
        return acc;
      }, [0]);

      const [first10] = coinData.slice(3, 10).reduce((acc, obj) => {
        acc[0] += obj.token_qty;
        return acc;
      }, [0]);

      const [first25] = coinData.slice(10, 25).reduce((acc, obj) => {
        acc[0] += obj.token_qty;
        return acc;
      }, [0]);

      const [first100] = coinData.slice(25, 100).reduce((acc, obj) => {
        acc[0] += obj.token_qty;
        return acc;
      }, [0]);

      const othersCount = total - holdersTokensCount;
      const othersPercent = (othersCount * 100) / total;
      const first3Percent = (first3 * 100) / total;
      const first10Percent = (first10 * 100) / total;
      const first25Percent = (first25 * 100) / total;
      const first100Percent = (first100 * 100) / total;

      const top10Items = top10Data.toLocaleString('en-US');
      const top10Percent = top10Data ? (first3Percent + first10Percent).toFixed(2) : 0;
      setTop10(`${top10Items} items (${top10Percent}%)`);

      setData([
        {
          label: 'Top 3 Holders',
          count: first3,
          percent: first3Percent >= 0 ? +first3Percent.toFixed(2) : 0,
        },
        {
          label: 'Holders 4 to 10',
          count: first10,
          percent: first10Percent >= 0 ? +first10Percent.toFixed(2) : 0,
        },
        {
          label: 'Holders 11 to 25',
          count: first25,
          percent: first25Percent >= 0 ? +first25Percent.toFixed(2) : 0,
        },
        {
          label: 'Holders 26 to 100',
          count: first100,
          percent: first100Percent >= 0 ? +first100Percent.toFixed(2) : 0,
        },
        {
          label: 'Others',
          count: othersCount,
          percent: othersPercent >= 0 ? +othersPercent.toFixed(2) : 0,
        },
      ]);
    } else {
      setData([]);
      setTop10('');
    }
  }, [coinData, nftData]);

  return (
    <div className={className || ''}>
      {data && data.length > 0 && (
        <CircleChart
          title="Distribution"
          highlightText="Owned by top 10 wallets"
          highlightValue={top10}
          data={data}
          isLoading={isLoading || isFetching || isLoadingNFT || isFetchingNFT}
          landscape
        />
      )}
    </div>
  );
};

export default Distribution;
