import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getIsSearchModalOpen } from '../../../store/reducers/search';
import styles from './ModalCloseButton.module.scss';

const ModalCloseButton = ({ setShowModal }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setShowModal(false);
        dispatch(getIsSearchModalOpen(false));
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, [dispatch, setShowModal]);

  return (
    <div className={`${styles.wrapper}`} role="presentation">
      <div
        role="presentation"
        className={`cursor-pointer ${styles.button_wrapper}`}
        onClick={() => {
          setShowModal(false);
          dispatch(getIsSearchModalOpen(false));
        }}
      >
        <button
          type="button"
          className="btn-close btn-close-white m-0 p-0"
          data-bs-dismiss="exampleModal"
          aria-label="Close"
        />
      </div>
      <div className={styles.text}>Esc</div>
    </div>

  );
};

export default ModalCloseButton;
