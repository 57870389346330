import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { supportedBlockchains } from '../../../../utils/supportedBlockchains';
import { ethFormat } from '../../../../utils/singleAssetPage/parseData';
import styles from './AssetsCard.module.scss';
import DefaultIcon from '../../../../components/ui/DefaultIcon';
import ItemPreview from '../../../../components/ui/ItemPreview';
import ChainList from '../../../../components/base/ChainLogo/ChainList';

const AssetsCard = ({ data, portfolioValue }) => {
  const [onLogoError, setOnLogoError] = useState(false);
  return (
    <div className="d-flex justify-content-between">
      <div className="d-flex gap-3 align-items-center">
        <Link
          to={data.asset_type === 'token'
            ? `/coins/${data.coin_id}`
            : `/nfts/${data.blockchains && data.blockchains[0]}/${data.addresses && data.addresses[0]}`}
          data-for={
            data.asset_type === 'nft'
              ? `asset_preview_${data.addresses && data.addresses[0]}_${data.platform && data.platform[0]}`
              : data.asset_type === 'token'
                ? `preview_${data.coin_id}`
                : null
          }
          data-tip="show"
        >
          {!onLogoError && data.logo ? (
            <img
              className="rounded-circle cursor-pointer"
              src={data.logo}
              alt="logo"
              width="40px"
              height="40px"
              onError={() => setOnLogoError(true)}
            />
          ) : (
            <div
              className={`${styles.wrapper_img} d-flex justify-content-center align-items-center`}
            >
              <DefaultIcon type={data.asset_type} size="40px" />
            </div>
          )}
        </Link>
        <div className="d-flex flex-column align-items-start gap-1">
          <div
            className={styles.info}
            data-for={
              data.asset_type === 'nft'
                ? `asset_preview_${data.addresses && data.addresses[0]}_${data.platform && data.platform[0]}`
                : data.asset_type === 'token'
                  ? `preview_${data.coin_id}`
                  : null
            }
            data-tip="show"
          >
            {data?.opensea_slug_contract_count && data.opensea_slug_contract_count > 1
              ? data.contract_name || data.name
              : data.name}
          </div>
          {data.asset_type === 'nft'
            ? (
              <ItemPreview
                id={`asset_preview_${data.addresses && data.addresses[0]}_${data.platform && data.platform[0]}`}
                data={{
                  address: data.addresses && data.addresses[0],
                  type: data.asset_type,
                  blockchain: data.blockchains && data.blockchains[0],
                  img: data.logo,
                  name: data.name,
                }}
              />
            )
            : null}
          {data.asset_type === 'token'
            ? (
              <ItemPreview
                id={`preview_${data.coin_id}`}
                data={{
                  id: data.coin_id,
                  type: 'coin',
                }}
              />
            )
            : null}
          <div className="d-flex gap-2 align-items-center">
            <div
              className={`${styles.pills} ${data.asset_type.includes('token') ? styles.red : ''}
                                 px-3 d-flex align-items-center`}
            >
              {data.asset_type.includes('token') ? 'Token' : 'NFT Collection'}
            </div>
            {data?.platform ? (
              <>
                <div className={styles.separator_icon} />
                <ChainList chains={supportedBlockchains(data.platform)} />
              </>
            ) : null}
          </div>
        </div>
      </div>
      <div className="d-flex align-items-end flex-column">
        <span className={styles.info}>{data.value !== null ? `$${ethFormat(data.value, 1)}` : '?'}</span>
        <span className={styles.info_data}>
          {data.token_qty_decimal !== null
            ? `${ethFormat(data.token_qty_decimal) < 1 ? '<1' : ethFormat(data.token_qty_decimal)}
           ${data.asset_type.includes('token')
      ? `Token${data.token_qty_decimal <= 1 ? '' : 's'}` : `NFT${data.token_qty_decimal === 1 ? '' : 's'}`}`
            : '?'}
        </span>
        <span className={styles.info_portfolio}>
          {portfolioValue && data.value !== null
            ? `${((data.value / portfolioValue) * 100) < 1
              ? '<1' : ethFormat((data.value / portfolioValue) * 100)}% of Portfolio`
            : '?'}
        </span>
      </div>
    </div>
  );
};

export default AssetsCard;
