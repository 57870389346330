import React, { useState } from 'react';

import styles from './Pill.module.scss';
import { getSegmentSvg } from '../helpers/getSegmentSvg';

const Pill = ({ pillData }) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <div
      className={styles.container}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={() => pillData.action(pillData.name)}
      role="presentation"
    >
      {getSegmentSvg(pillData.name, isHover)}
      <div className={styles.title}>{pillData.name}</div>
    </div>
  );
};

export default Pill;
