import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUser } from '../../store/reducers/user';
import styles from './CustomInsights.module.scss';

const CustomInsights = () => {
  const navigate = useNavigate();
  const iframeRef = useRef(null);
  const user = useSelector(getUser);

  useEffect(() => {
    const iframe = iframeRef.current;

    const handleHeightMessage = (event) => {
      if (event.origin === window.location.origin && event.data.type === 'iframeHeight') {
        iframe.style.height = `${event.data.height}px`;
      }
    };

    window.addEventListener('message', handleHeightMessage);

    return () => {
      window.removeEventListener('message', handleHeightMessage);
    };
  }, []);

  useEffect(() => {
    if (user.id) {
      if (user.custom_dashboard_url) {
        window.parent.postMessage({ type: 'iframeHeight', height: document.body.scrollHeight }, '*');
      } else {
        navigate('/dashboard');
      }
    }
  }, [user]);

  return (
    <iframe
      title="Custom Insights"
      ref={iframeRef}
      src={user?.custom_dashboard_url}
      className={styles.iframe}
    />
  );
};

export default CustomInsights;
