import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  clearActiveSettings,
  onSettingsChange,
  removeErrors,
  selectConfigsList,
  selectFlowStatus,
} from '../../../../../store/reducers/flows';
import styles from './ConditionNodeSettings.module.scss';
import NodeBody from './NodeBody';
import {
  getCreatedConditions,
  getUserSegment,
  clearState,
  getFormIsReady,
} from '../../../../../store/reducers/segment';
import EditableTitle from '../../../../../components/base/EditableTitle';

const ConditionNodeSettings = ({ activeSettings }) => {
  const [titleValue, setTitleValue] = useState('');
  const [segment, setSegment] = useState('');

  const dispatch = useDispatch();

  const createdConditions = useSelector(getCreatedConditions);
  const userSegment = useSelector(getUserSegment);
  const formIsReady = useSelector(getFormIsReady);

  const { handleSubmit } = useForm();

  const configList = useSelector(selectConfigsList);
  const status = useSelector(selectFlowStatus);

  const disableChangeStatus = useMemo(() => status === 'running'
    || status === 'scheduled' || status === 'stopped', [status]);

  const onSubmit = (submitData) => {
    const hookInfo = { ...submitData };
    if (titleValue) {
      hookInfo.name = titleValue;
    } else {
      hookInfo.name = 'New condition';
    }
    if (userSegment?.segment?.categories?.length > 0) {
      hookInfo.segment = {
        name: userSegment.name,
        segment: {
          categories: userSegment.segment.categories.filter((elem) => elem.name === createdConditions[0].category),
        },
      };
    }
    dispatch(clearState());
    dispatch(removeErrors(activeSettings.node_id));
    dispatch(onSettingsChange({ ...hookInfo, node_id: activeSettings.node_id }));
    dispatch(clearActiveSettings());
  };

  useEffect(() => {
    if (configList.length) {
      const index = configList.map((object) => object.node_id).indexOf(activeSettings.node_id);
      if (index >= 0) {
        setTitleValue(configList[index].name);
        setSegment(configList[index].segment);
      } else {
        setTitleValue('');
        setSegment(null);
      }
    }
  }, [activeSettings.node_id, configList]);

  return (
    <div className={`${styles.wrapper} h-100`}>
      <form onSubmit={handleSubmit(onSubmit)} className="d-flex flex-column justify-content-between h-100">
        <div>
          <div className={`${styles.title} d-flex align-items-center gap-2`}>
            <EditableTitle
              defaultTitle="New condition"
              titleValue={titleValue}
              setTitleValue={setTitleValue}
              maxLength={25}
              size={16}
              edit={!disableChangeStatus}
            />
          </div>
          <div className={`${styles.message} bm-1`}>
            <NodeBody segment={segment} />
          </div>
        </div>
        <div className="d-flex gap-4 mt-4">
          <button
            type="button"
            className={`${styles.btn_cancel}`}
            onClick={() => {
              dispatch(clearState());
              dispatch(clearActiveSettings());
            }}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="regular-button w-100"
            disabled={!createdConditions.length || disableChangeStatus || !formIsReady}
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default ConditionNodeSettings;
