/* eslint-disable max-len */
import React, { useState, useEffect, useCallback } from 'react';
import { ethers } from 'ethers';
import { useSelector, useDispatch } from 'react-redux';
import {
  useAccount,
  useSigner,
  useNetwork,
  useProvider,
  useSwitchNetwork,
} from 'wagmi';
import styles from './ProvideTokensModal.module.scss';
import Plus from '../../../../../../../assets/icons/flows/plus_modal.svg';
import Equals from '../../../../../../../assets/icons/flows/equals_modal.svg';
import { truncateAddress } from '../../../../../../../utils/web3/truncateAddress';
import { onPaste, validateInput } from '../../../../../../../utils/segments';
import { showErrorMessage, showSuccessMessage } from '../../../../../../../components/base/Notifications';
import { loadERC20Contract } from '../../../../../../../utils/web3/loadContract';
import { getGasPrice } from '../../../../../../../utils/web3/getGasPrice';
import { metamaskErrorWrap } from '../../../../../../../utils/web3/metamaskErrorWrap';
import { getUser } from '../../../../../../../store/reducers/user';
import RoundSpinner from '../../../../../../../components/base/RoundSpinner';
import {
  getContract as getAirDropContract,
  getERC20Contract,
  setContract,
  setERC20Contract,
  getERC20Address,
  setBalance,
  getBalance,
  getPendingTransactions,
  fetchPendingTxns,
  clearPendingTxn,
  isPendingTxn,
} from '../../../../../../../store/reducers/web3';
import CloseModalButton from '../../../../../../../components/ui/modals/components/CloseModalButton';
import useKeydown from '../../../../../../../components/hooks/app/useKeydown';
import { blockChainOptionsWithTestnets } from '../../../../../../../components/base/ChainLogo/chains';

const ProvideTokensModal = ({
  setShowProvideTokenModel,
  setTeamBalanceOnSettings,
  getValues,
  selectedItem,
  openWeb3Modal,
  teamBalance,
  getTeamBalance,
}) => {
  const dispatch = useDispatch();
  const { address } = useAccount();
  const { chain } = useNetwork();
  const provider = useProvider();
  const { data: signer } = useSigner({ chainId: chain?.id });
  const { switchNetwork } = useSwitchNetwork();
  const balance = useSelector(getBalance);
  const airdropContract = useSelector(getAirDropContract);
  const ERC20Address = useSelector(getERC20Address);
  const ERC20Contract = useSelector(getERC20Contract);
  const user = useSelector(getUser);
  const pendingTxns = useSelector(getPendingTransactions);
  const [inputValue, setInputValue] = useState('');
  const [unsupportedNetwork, setUnsupportedNetwork] = useState(false);
  const [customTokenSymbol, setCustomTokenSymbol] = useState('');
  const [fundingAllowance, setFundingAllowance] = useState(0);

  const approveText = 'Approve Funding ERC20';
  const approveType = 'approve_funding_erc20';
  const fundText = 'Fund ERC20 Contract';
  const fundType = 'fund_erc20_contract';

  const onCancel = () => {
    setShowProvideTokenModel(false);
  };
  useKeydown('Escape', onCancel);

  const getTokenContract = useCallback(async () => {
    if (signer && chain) {
      let erc20Contract;
      try {
        setUnsupportedNetwork(false);
        erc20Contract = await loadERC20Contract(signer, ERC20Address);
        dispatch(setERC20Contract(erc20Contract));

        if (!selectedItem.symbol) {
          const symbol = await erc20Contract.symbol();
          setCustomTokenSymbol(symbol);
        }

        if (airdropContract) {
          const erc20Balance = await erc20Contract.balanceOf(address);
          const allowance = await erc20Contract.allowance(address, airdropContract.address);
          setFundingAllowance(allowance / (10 ** await erc20Contract.decimals()));
          dispatch(setBalance((erc20Balance / (10 ** await erc20Contract.decimals())).toFixed(4)));
        }
      } catch (err) {
        if (!erc20Contract) {
          setUnsupportedNetwork(true);
        }
      }
    }
  }, [ERC20Address, dispatch, address, airdropContract, chain, selectedItem.symbol, signer]);

  const initModal = useCallback(async () => {
    const selectedBlockchain = blockChainOptionsWithTestnets.find((elem) => elem.value
        === (selectedItem?.blockchain ? selectedItem.blockchain : getValues('blockchain')))?.networkId;
    if (selectedBlockchain !== chain?.id) {
      switchNetwork(selectedBlockchain);
    }

    const _airdropContract = await loadERC20Contract(signer, null, chain?.id);
    dispatch(setContract(_airdropContract));
  }, [selectedItem.blockchain, getValues, chain?.id, signer, dispatch, switchNetwork]);

  const changeApproval = async () => {
    let approveTx;
    try {
      const decimals = await ERC20Contract.decimals();
      const value = ethers.utils.parseUnits(inputValue, decimals);
      const gasPrice = await getGasPrice(provider);

      approveTx = await ERC20Contract.approve(airdropContract.address, value, { gasPrice });

      dispatch(fetchPendingTxns({ txnHash: approveTx.hash, approveText, type: approveType }));
      await approveTx.wait();
    } catch (err) {
      return metamaskErrorWrap(err, showErrorMessage);
    } finally {
      if (approveTx) {
        dispatch(clearPendingTxn(approveTx.hash));
      }

      const allowance = await ERC20Contract.allowance(address, airdropContract.address);
      setFundingAllowance(allowance / (10 ** await ERC20Contract.decimals()));
    }
  };

  const fundAirdropContract = async () => {
    let fundTx;
    const decimals = await ERC20Contract.decimals();
    try {
      const gasPrice = await getGasPrice(provider);
      const value = ethers.utils.parseUnits(inputValue, decimals);

      fundTx = await airdropContract.fundERC20(String(user.team), ERC20Contract.address, value, { gasPrice });

      dispatch(fetchPendingTxns({ txnHash: fundTx.hash, fundText, type: fundType }));
      await fundTx.wait();
      showSuccessMessage('Token balance transfer succeeded');
    } catch (err) {
      return metamaskErrorWrap(err, showErrorMessage);
    } finally {
      if (fundTx) {
        dispatch(clearPendingTxn(fundTx.hash));
      }
      const erc20Balance = await ERC20Contract.balanceOf(address);
      dispatch(setBalance((erc20Balance / (10 ** decimals)).toFixed(4)));
      await getTeamBalance();
      setShowProvideTokenModel(false);
    }
  };

  useEffect(() => {
    if (signer) {
      initModal();
    }
  }, [initModal, signer, chain]);

  useEffect(() => {
    if (signer && airdropContract) {
      getTeamBalance();
      getTokenContract();
    }
  }, [airdropContract, getTokenContract, getTeamBalance, signer]);

  useEffect(() => {
    setTeamBalanceOnSettings(teamBalance);
  }, [teamBalance, setTeamBalanceOnSettings]);

  return (
    <div className={`modal modal-dialog-centered ${styles.modal_wrapper}`} onMouseDown={() => { setShowProvideTokenModel(false); }} id="provideModal" tabIndex="-1" aria-labelledby="provideModalLabel" aria-hidden="true">
      <div
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
        role="presentation"
        className="modal-dialog modal-md modal-phone w-100 position-relative"
      >
        <div className={`${styles.content} modal-content border-0 position-relative`}>
          <CloseModalButton onCancel={onCancel} />
          <div className={styles.title}>
            Provide Tokens for airdrop
          </div>
          <div className={styles.row}>
            <div className={styles.block_title}>
              Remaining tokens available for airdrop:
            </div>
            <div className="d-flex mt-1 align-items-center gap-2">
              {typeof teamBalance === 'number' && chain && (
                <span className={` ${styles.main_price}`}>
                  {teamBalance}
                  {' '}
                  {selectedItem?.symbol ? selectedItem?.symbol?.toUpperCase() : customTokenSymbol}
                </span>
              )}
              {!chain && (
                <span className={`${styles.main_price} text-center`}>
                  {
                    typeof getValues('balance') === 'number'
                      ? `${getValues('balance')} ${!selectedItem?.symbol ? customTokenSymbol : selectedItem?.symbol?.toUpperCase() || ''}`
                      : 'Connect your wallet'
                  }
                </span>
              )}
            </div>
          </div>
          <div className={`${styles.row_button} d-flex justify-content-center`}>
            <Plus />
          </div>
          <div className={styles.row}>
            <div className={styles.block_title}>
              Enter amount to top up the airdrop balance
            </div>
            <div className="d-flex mt-2 mb-1 align-items-center gap-2">
              <input
                value={inputValue}
                type="number"
                min="0"
                step=".00000001"
                disabled={unsupportedNetwork}
                onPaste={onPaste}
                onWheel={(e) => e.target.blur()}
                onChange={(e) => {
                  const ifValidValue = validateInput(e.target.value, false, true);
                  if (!ifValidValue) {
                    return;
                  }
                  setInputValue(e.target.value);
                }}
                className={styles.balance_input}
              />
            </div>
            {address && chain && (
              Number(balance) > Number(inputValue)
                ? (
                  <div className={styles.balance}>
                    Your balance:
                    {' '}
                    {balance}
                    {' '}
                    {selectedItem?.symbol ? selectedItem?.symbol?.toUpperCase() : customTokenSymbol}
                  </div>
                ) : (
                  <div className={styles.insufficient_balance}>
                    Insufficient
                    {' '}
                    {selectedItem?.symbol ? selectedItem?.symbol?.toUpperCase() : customTokenSymbol}
                    {' '}
                    balance
                  </div>
                )
            )}
          </div>
          <div className={`${styles.row_button} d-flex justify-content-center`}>
            <Equals />
          </div>
          <div className={styles.row}>
            <div className={styles.block_title}>
              Total balance available to airdrop after transfer:
            </div>
            <div className="d-flex mt-1 align-items-center gap-2">
              {typeof teamBalance === 'number' ? (
                <span className={styles.main_price}>
                  {Number(inputValue) + (teamBalance)}
                  {' '}
                  {selectedItem?.symbol ? selectedItem?.symbol?.toUpperCase() : customTokenSymbol}
                </span>
              )
                : <span className={`${styles.main_price} text-center`}>{unsupportedNetwork ? '' : 'Connect your wallet'}</span>}
            </div>
          </div>
          <div className={`${styles.footer} mt-4 d-flex flex-column justify-content-center align-items-center gap-2`}>
            {
              address
                ? (
                  fundingAllowance > 0 && fundingAllowance >= inputValue
                    ? (
                      <button
                        type="button"
                        disabled={(address && (unsupportedNetwork || !Number(inputValue) || Number(balance) < Number(inputValue) || Number(balance) === 0 || isPendingTxn(pendingTxns, fundType)))}
                        className="regular-button w-40 mx-auto"
                        onClick={async () => { fundAirdropContract(); }}
                      >
                        {isPendingTxn(pendingTxns, fundType) ? (
                          <div>
                            <RoundSpinner position="position-absolute" theme="light" />
                            Funding...
                          </div>
                        ) : 'Provide Token'}
                      </button>
                    )
                    : (
                      <button
                        type="button"
                        disabled={(address && (unsupportedNetwork || Number(balance) < Number(inputValue) || Number(inputValue) === 0 || isPendingTxn(pendingTxns, approveType)))}
                        className="regular-button w-40 mx-auto"
                        onClick={async () => { changeApproval(); }}
                      >
                        {isPendingTxn(pendingTxns, approveType) ? (
                          <div>
                            <RoundSpinner position="position-absolute" theme="light" />
                            Approving...
                          </div>
                        ) : 'Approve'}
                      </button>
                    )
                )
                : (
                  <button
                    type="button"
                    className="regular-button w-40 mx-auto"
                    onClick={async () => { openWeb3Modal(); }}
                  >
                    Connect Wallet
                  </button>
                )
            }
            {address && (
              <div className={styles.wallet}>
                Connected wallet:
                {' '}
                <div role="presentation" className={styles.wallet_address} onClick={() => openWeb3Modal()}>{truncateAddress(address)}</div>
                {' '}
              </div>
            )}
            {chain && (
              <div className={styles.wallet}>
                Current Chain:
                {' '}
                {chain.name}
                {' '}
                {unsupportedNetwork && '(Unsupported)'}
                {' '}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProvideTokensModal;
