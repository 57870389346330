import React from 'react';
import { Link } from 'react-router-dom';

const Homepage = () => (
  <footer className="wrapper-footer mt-4 d-flex align-items-center justify-content-end">
    <Link to="dashboard" className="link align-items-center">To dashboard</Link>
  </footer>
);

export default Homepage;
