import React, { useEffect, useRef } from 'react';
import styles from './SaveSnapshotModal.module.scss';
import RoundSpinner from '../../../base/RoundSpinner';
import useKeydown from '../../../hooks/app/useKeydown';
import CloseModalButton from '../components/CloseModalButton';
import { useDebounce } from '../../../hooks/app';

const SaveSnapshotModal = ({
  setValue,
  onSubmit,
  onCancel,
  loading,
  value,
}) => {
  useKeydown('Escape', () => onCancel());

  const [debouncedText, setDebouncedText] = useDebounce('', 100);
  const inputRef = useRef();

  useEffect(() => {
    setValue(debouncedText);
  }, [debouncedText]);

  return (
    <div
      className={`modal modal-dialog-centered ${styles.modal_wrapper}`}
      id="confirmModal"
      tabIndex="-1"
      aria-labelledby="confirmModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-md modal-phone w-100 position-relative">
        <div className={`${styles.body} modal-content position-relative border-0`}>
          {loading
            ? <RoundSpinner />
            : null}
          <CloseModalButton onCancel={onCancel} />
          <div>
            <div className={`${styles.title} d-flex justify-content-start`}>
              Save snapshot
            </div>
          </div>
          <div className={`${styles.description}`}>
            <span>
              To save a snapshot of this audience, please provide a name for the list to be created
            </span>
          </div>
          <div className={`${styles.description}`}>
            <input
              ref={inputRef}
              onChange={(e) => {
                setDebouncedText(e.target.value.trim());
              }}
              className="shadow-none form-control"
              maxLength={50}
              autoFocus
              placeholder="Add name for List "
            />
          </div>
          <div className={`${styles.footer} d-flex justify-content-end`}>
            <button
              type="button"
              className="outline-button border border-1"
              data-bs-dismiss="modal"
              onClick={() => onCancel()}
            >
              Cancel
            </button>
            <button
              disabled={!value}
              type="button"
              className="regular-button"
              data-bs-dismiss="modal"
              onClick={() => {
                setValue(inputRef?.current?.value);
                onSubmit();
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaveSnapshotModal;
