import React, { useEffect, useState } from 'react';
import { coinApi } from '../../../../api/coin';
import CircleChart from '../../../../components/ui/CircleChart';
import { getGrowth } from '../../../../utils/values';

const PortfolioShare = ({ coin, audience, className = '' }) => {
  const [data, setData] = useState([]);
  const [avgShare, setAvgShare] = useState('');
  const { data: coinData, isLoading, isFetching } = coinApi.useGetSharePortfolioQuery({ id: coin, audience });

  useEffect(() => {
    if (coinData?.buckets && !isLoading && !isFetching) {
      const total = coinData.buckets.reduce((sum, { value }) => sum + value, 0);
      if (total === 0) return;
      const newData = coinData.buckets.map((bucket, index) => {
        const labelMap = ['< 5%', '5% to < 25%', '25% to < 50%', '50% to < 75%', '75% to < 99%', '100%'];
        const percent = total ? ((bucket.value * 100) / total).toFixed(2) : 0;
        return {
          label: labelMap[index],
          count: bucket.value,
          percent: +percent,
        };
      });

      const median = coinData.median ? coinData.median.toFixed(2) : 0;
      setAvgShare(`${median}%`);
      setData(newData);
    } else {
      setData([]);
    }
  }, [coinData, isLoading, isFetching]);

  return (
    <div className={className || ''}>
      <CircleChart
        title="Share of Portfolio"
        highlightText="Median Share of Portfolio"
        highlightValue={avgShare}
        data={data}
        isLoading={isLoading || isFetching}
        landscape
        growth={coinData
          ? getGrowth(
            coinData?.median,
            coinData?.previous_values?.median,
            `${(coinData?.previous_values?.median || 0).toFixed(2)}%`,
          )
          : null}
      />
    </div>
  );
};

export default PortfolioShare;
