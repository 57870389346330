import React, { useState, useEffect } from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  setFormName,
  selectFormName,
  setFormUrl,
  selectFormUrl,
  setAllowMultipleSubmissions,
  selectAllowMultipleSubmissions,
} from '../../../../../store/reducers/forms';
import StyleSettings from './StyleSettings';
import { showErrorMessage } from '../../../../../components/base/Notifications';
import ModalSettings from '../../Components/ModalSettings';
import Input from '../../Components/ModalSettings/Input';
import Switch from '../../Components/ModalSettings/Switch';

const OverallSettings = () => {
  const dispatch = useDispatch();
  const formName = useSelector(selectFormName);
  const formUrl = useSelector(selectFormUrl);
  const allowMultipleSubmissions = useSelector(selectAllowMultipleSubmissions);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (formName.length === 50) {
      setErrors({ ...errors, formName: 'Character limit reached' });
    } else {
      setErrors({ ...errors, formName: '' });
    }
  }, [formName]);

  return (
    <ModalSettings title="Settings">

      <Input
        title="Form name"
        value={formName}
        placeholder="Type here"
        onChange={(e) => {
          dispatch(setFormName(e.currentTarget.value));
        }}
        maxLength={50}
        error={errors.formName}
      />

      <Input
        title="Form URL"
        value={formUrl}
        prefix="wrm.link/"
        placeholder="Type here"
        onChange={(e) => {
          const userInput = e.currentTarget.value;
          if ((userInput.length <= 20 && /^[a-z0-9-]+$/.test(userInput)) || userInput === '') {
            dispatch(setFormUrl(userInput));
          } else if (userInput.length > 20) {
            showErrorMessage('URL string can be a maximum of 20 characters.');
          } else {
            showErrorMessage('Invalid URL string. Only use lower-case letters, numbers, and dashes.');
          }
        }}
        maxLength={20}
      />

      <Switch
        text="Prevent multiple submissions"
        checked={!allowMultipleSubmissions}
        onChange={() => dispatch(setAllowMultipleSubmissions(!allowMultipleSubmissions))}
      />

      <StyleSettings activeSettings />
    </ModalSettings>
  );
};

export default OverallSettings;
