import React, { useState, useEffect, useRef } from 'react';
import { preventInvalidSymbols, preventSymbols } from '../../../../../../../utils/segments';

import styles from './MultiSelectInput.module.scss';

function useOutsideAlerter(ref, capture, addToArray) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        capture(false);
        addToArray();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, capture, addToArray]);
}

const MultipleValuesInput = ({
  values,
  setValues,
  isDisabled = false,
  fromAirdrop = false,
}) => {
  const wrapperRef = useRef(null);
  const [selectCapture, setSelectCapture] = useState(false);
  const [focus, setFocus] = useState(false);
  const [input, setInput] = useState('');

  const pushValueToArray = () => {
    if (input.length > 0) {
      const newArr = [...values];
      const str = input;
      if (str.includes(',')) {
        const handleCommaArray = str.split(',').filter((elem) => !!elem).map((elem) => ({
          value: +elem, label: +elem,
        }));
        newArr.push(...handleCommaArray);
      } else {
        newArr.push({ value: input, label: input });
      }
      setInput('');
      setValues(newArr);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === '\\') {
      e.preventDefault();
    }
  };

  useOutsideAlerter(wrapperRef, setSelectCapture, pushValueToArray);

  return (
    <div
      className={`w-100 ${styles.container} ${selectCapture && styles.active}`}
      onSelectCapture={() => setSelectCapture(true)}
      ref={wrapperRef}
    >
      <span id="react-select-9-live-region" />
      <span
        aria-live="polite"
        aria-atomic="false"
        aria-relevant="additions text"
      />
      <div className={`${styles.control} ${selectCapture && styles.active}`}>
        <div className={styles.wrapper}>
          {values && values.map((val, index) => (
            <div className={styles.multiValue}>
              <div>
                <div className={`${styles.multiValueTextWrapper} d-flex align-items-center`}>
                  <span className="text-truncate ms-1">{val.value}</span>
                </div>
              </div>
              <div
                role="presentation"
                onClick={() => {
                  const newArr = [...values];
                  newArr.splice(index, 1);
                  setValues(newArr);
                }}
                aria-label={`Remove ${val.value}`}
              >
                <div
                  className={`
                      ${styles.multiValueIconWrapper} d-flex ms-auto
                       flex-column align-items-center justify-content-center h-100
                       `}
                >
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11.8346 1.34102L10.6596 0.166016L6.0013 4.82435L1.34297
                          0.166016L0.167969 1.34102L4.8263 5.99935L0.167969 10.6577L1.34297
                           11.8327L6.0013 7.17435L10.6596 11.8327L11.8346 10.6577L7.1763
                            5.99935L11.8346 1.34102Z"
                      fill="#90A0B7"
                    />
                  </svg>
                </div>
              </div>
            </div>
          ))}
          <div className={styles.inputWrapper} data-value="">
            <input
              className={`${values.length && !focus ? styles.multi_input : styles.input}`}
              autoCapitalize="none"
              autoComplete="off"
              disabled={isDisabled}
              autoCorrect="off"
              placeholder={!values.length && 'Input your values...'}
              id="react-select-9-input"
              spellCheck="false"
              tabIndex="0"
              onBlur={() => setFocus(false)}
              onFocus={() => setFocus(true)}
              onWheel={(e) => e.target.blur()}
              onKeyPress={(e) => (fromAirdrop
                ? preventSymbols(e)
                : preventInvalidSymbols(e))}
              min={0}
              type={fromAirdrop ? '' : 'number'}
              aria-expanded="false"
              aria-haspopup="true"
              onKeyDown={handleKeyDown}
              onChange={(e) => {
                const lastValue = e.target.value.slice(e.target.value.lastIndexOf(',') === -1
                  ? 0
                  : e.target.value.lastIndexOf(',') + 1, String(e.target.value).length);
                if (lastValue.length > 22) return;
                setInput(fromAirdrop
                  ? e.target.value.replace(/[^\d,]/g, '')
                  : e.target.value);
              }}
              onKeyUp={(e) => {
                if (e.key === 'Enter' && input) {
                  pushValueToArray();
                }
              }}
              value={input}
              // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
              role="combobox"
            />
          </div>
        </div>
        {!!values.length && (
          <div className={styles.indicatorWrapper}>
            <span className={styles.separator} />
            <div
              className={styles.indicatorContainer}
              aria-hidden="true"
              onClick={() => {
                setValues([]);
              }}
            >
              <svg
                height="20"
                width="20"
                viewBox="0 0 20 20"
                aria-hidden="true"
                focusable="false"
                className={styles.svg}
              >
                <path
                  d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651
                        3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229
                        0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469
                        1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229
                        0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"
                />
              </svg>
            </div>
          </div>
        )}

      </div>
    </div>
  );
};

export default MultipleValuesInput;
