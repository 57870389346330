import React, { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { coinApi } from '../../../api/coin';
import Investments from '../../../components/ui/Investments';
import NotSupportedBlockchain from '../../../components/NotSupportedBlockchain';
import { selectFilterOption } from '../../../store/reducers/coin';
import styles from './InvestPortfolio.module.scss';

const InvestPortfolio = ({ holders, assetsRefetch, isSupportedBlockchain }) => {
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const filterOption = useSelector(selectFilterOption);
  const initialSearchParams = {
    ...Object.fromEntries([...searchParams]),
  };

  const [queryParams, setQueryParams] = useState({
    page: Number(initialSearchParams.page) || 1,
    desc: initialSearchParams.order !== 'false',
    type: initialSearchParams.sort,
    order: initialSearchParams.orderBy || 'value',
    limit: initialSearchParams.perPage || 25,
  });

  const [checkedToken, setCheckedToken] = useState(queryParams.type !== 'nft');
  const [checkedNft, setCheckedNft] = useState(queryParams.type !== 'token');

  const {
    page, desc, type, order, limit,
  } = queryParams;

  const {
    data: investments,
    isLoading: isInvestmentsLoading,
    isFetching: isInvestmentsFetching,
  } = coinApi.useGetTokenInvestmentsQuery({
    id: pathname.split('/')[2],
    des: desc ? '1' : '0',
    asset_type: type || '',
    chain: 'ethereum',
    limit: +limit,
    order,
    page,
    audience: filterOption,
  });

  useEffect(() => {
    let newType = '';

    if (checkedNft && checkedToken) {
      newType = '';
    } else if (checkedNft && !checkedToken) {
      newType = 'nft';
    } else if (!checkedNft && checkedToken) {
      newType = 'token';
    }

    setQueryParams((prevState) => ({
      ...prevState,
      type: newType,
    }));

    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.set('sort', newType);
    newSearchParams.set('page', page.toString());
    newSearchParams.set('order', desc ? 'true' : 'false');
    setSearchParams(newSearchParams);
  }, [checkedNft, checkedToken, page, desc]);

  return (
    isSupportedBlockchain
      ? (
        <div className={styles.wrapper}>
          <Investments
            investments={investments}
            isLoading={isInvestmentsLoading || isInvestmentsFetching}
            limit={limit}
            setLimit={(newLimit) => setQueryParams({ ...queryParams, limit: newLimit })}
            holders={holders}
            page={page}
            desc={desc}
            setPage={(newPage) => setQueryParams({ ...queryParams, page: newPage })}
            setDesc={(newDesc) => setQueryParams({ ...queryParams, desc: newDesc })}
            setCheckedToken={setCheckedToken}
            setCheckedNft={setCheckedNft}
            checkedToken={checkedToken}
            checkedNft={checkedNft}
            order={order}
            setOrder={(newOrder) => setQueryParams({ ...queryParams, order: newOrder })}
            assetsRefetch={assetsRefetch}
          />
        </div>
      )
      : <NotSupportedBlockchain />
  );
};

export default InvestPortfolio;
