export const localeStorageCache = {
  setAuth0Key(key) {
    localStorage.setItem('key_@@auth0spajs@@', key);
  },

  getAuth0Key() {
    return localStorage.getItem('key_@@auth0spajs@@');
  },

  removeAuth0Key() {
    localStorage.removeItem('key_@@auth0spajs@@');
  },

  get(key) {
    return JSON.parse(localStorage.getItem(key));
  },

  set(key, value) {
    this.setAuth0Key(key);
    localStorage.setItem(key, JSON.stringify(value));
  },

  remove(key) {
    this.removeAuth0Key(key);
    localStorage.removeItem(key);
  },

  allKeys() {
    return Object.keys(localStorage);
  },
};
