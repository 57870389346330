import React from 'react';
import FolderIcon from '../../../assets/icons/folders/folder.svg';
import styles from './editableFoldersList.module.scss';

const FolderTag = ({ name }) => (
  <div className={styles.folder_tag_container}>
    <FolderIcon />
    {name}
  </div>
);

export default FolderTag;
