import React, { useEffect, useCallback, useState } from 'react';
import {
  formatDistanceToNow, format, isValid,
} from 'date-fns';
import styles from './AgeDate.module.scss';
import uniqueId from '../../../../utils/uniqueId';
import Tooltip from '../../Tooltip';

const AgeDate = ({ date, defaultText = '', isAge = false }) => {
  const [textToDisplay, setTextToDisplay] = useState(defaultText);
  const [parsedDate, setParsedDate] = useState('');
  const tooltipId = uniqueId('date');

  const reset = useCallback(() => {
    setTextToDisplay(defaultText);
    setParsedDate('');
  }, [defaultText]);

  useEffect(() => {
    if (typeof date === 'string') {
      const parsingDate = Date.parse(date);
      if (isValid(parsingDate)) {
        setParsedDate(parsingDate);
        let formatedDate = formatDistanceToNow(parsingDate, { addSuffix: true });
        if (isAge) {
          formatedDate = formatedDate.replace(/ago/g, 'old');
        }
        setTextToDisplay(formatedDate);
      } else {
        reset();
      }
    } else {
      reset();
    }
  }, [date, defaultText, isAge, reset]);

  return (
    <div>
      <div
        className={styles.date_wrapper}
        data-for={tooltipId}
        data-tip
      >
        {textToDisplay}
      </div>
      {parsedDate && <Tooltip id={tooltipId} info={format(parsedDate, "MMMM d, yyyy 'at' h:mma 'UTC'")} />}
    </div>
  );
};

export default AgeDate;
