import React from 'react'
import AiBlock from '../../../components/ui/AiBlock'
import useWebSocket from '../../../components/hooks/app/useWebSocket';
import { useLocation } from 'react-router';

const subTitle = {
  current: 'Current Holders',
  new: 'Newcomers',
  lost: 'Churned Holders',
  relevant: 'Relevant Holders',
};

const AiAudience = ({ isReady, filterOption }) => {
  const { pathname } = useLocation();
  const name = pathname.split('/')[2];
  const shouldConnect = isReady && filterOption !== 'all';
  const url = shouldConnect ? 'ws/ft/audience-profile/ai-overview/' : null;
  const payload = shouldConnect ? { coin_id: name, audience: filterOption } : null;
  const { aiOverview } = useWebSocket(url, payload, !shouldConnect, name, filterOption);

  return (
    <AiBlock
      data={aiOverview}
      linkText="Read more"
      title={`Deep-Dive on ${subTitle[filterOption]} -`}
      wBackground
      isProfile
    />
  )
}

export default AiAudience
