import { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useNavigationType } from 'react-router';

export const ScrollToTop = () => {
  const { pathname } = useLocation();
  const navType = useNavigationType();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!Object.keys(Object.fromEntries([...searchParams])).length && navType !== 'POP' && navType !== 'REPLACE') {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [pathname, navType, searchParams]);

  return null;
};
