import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import { useLocation, useNavigationType, useParams } from 'react-router';
import { Helmet } from 'react-helmet';
import { audiencesApi } from '../../api/audiences';
import { userApi } from '../../api/user';
import InvestorProfile from './InvestorProfile';
import InvestorPortfolio from './InvestorPortfolio';
import List from './List';
import DappUsage from './DappUsagge';
import WidgetCard from '../../components/ui/WidgetCard';
import { LoadingLines } from '../../components/ui/modals/SearchModal/LoadingList';
import Owned from '../../assets/images/asset/member.png';
import Matched from '../../assets/images/asset/wallets_matched.svg?url';
import Inactive from '../../assets/icons/addresses.svg?url';
import Chart from '../../assets/images/asset/chart.png';
import Cal from '../../assets/images/asset/Icon.png';
import uniqueId from '../../utils/uniqueId';
import Reach from '../../components/ui/Reach';
import { selectTabPages, setTabPages } from '../../store/reducers/coin';
import styles from './CustomListDetails.module.scss';
import Exchanges from './Exchanges';
import Title from '../../components/base/Title';
import EditableTitle from '../../components/base/EditableTitle';
import EditableFoldersList from '../../components/ui/EditableFoldersList';
import { showErrorMessage, showSuccessMessage } from '../../components/base/Notifications';
import { getCardValue, getEthPrice } from '../../utils/values';

const CustomListDetails = () => {
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const navType = useNavigationType();
  const [searchParams, setSearchParams] = useSearchParams();
  const [itemPage, setItemPage] = useState(searchParams.get('tab')?.replace('+', ' ') || 'Audience Profile');
  const [pages, setPages] = useState([]);
  const [widgetData, setWidgetData] = useState([{}, {}, {}, {}, {}]);

  const tabPages = useSelector(selectTabPages);
  const { id } = useParams();

  const [titleValue, setTitleValue] = useState('');

  const {
    data: customList,
    isLoading: isCustomListLoading,
    isFetching: isCustomListFetching,
  } = audiencesApi.useGetCustomListAnalyticsQuery(
    { id, order: 'ASC' },
  );

  const {
    data: reachData,
    isLoading: isReachDataLoading,
    isFetching: isReachDataFetching,
  } = audiencesApi.useCustomListReachQuery(id);

  const {
    data: insightData,
    isLoading: isInsightDataLoading,
    isFetching: isInsightDataFetching,
  } = audiencesApi.useGetCustomListInsightQuery(id);

  const [updateCustomList, updateCustomListResult] = audiencesApi.useUpdateCustomListMutation();

  const {
    refetch,
  } = userApi.useGetAssetsQuery();

  useEffect(() => {
    if (customList && !(isCustomListLoading || isCustomListFetching)) {
      setTitleValue(customList.custom_list_name);
    }
  }, [customList, isCustomListFetching, isCustomListLoading]);

  useEffect(() => {
    if (customList) {
      const ListCount = (customList?.member_count || 0) - (customList?.inactive_address_count || 0);
      setPages([
        {
          label: 'Audience Profile',
          component: <InvestorProfile
            holders={customList?.member_count}
            audienceProfileData={insightData}
            audienceProfileIsLoading={isInsightDataLoading || isInsightDataFetching}
          />,
        },
        {
          label: 'Audience Portfolio',
          component: <InvestorPortfolio holders={insightData?.wallet_count} assetsRefetch={refetch} />,
        },
        {
          label: 'List',
          component: <List walletCounts={insightData?.wallet_count} />,
        },
        {
          label: 'Dapp Usage',
          component: <DappUsage holders={insightData?.wallet_count} />,
        },
        {
          label: 'Centralized Exchanges',
          component: <Exchanges holders={insightData?.wallet_count} />,
        },
      ]);

      setWidgetData([
        {
          title: 'Profile Count',
          value: getCardValue(customList.member_count),
          icon: Owned,
        },
        {
          title: 'Wallets Matched',
          value: getCardValue(customList.wallets_matched),
          icon: Matched,
          info: 'Profiles that have wallet addresses associated with them',
        },
        {
          title: 'Inactive Wallets',
          value: customList.inactive_address_count ? getCardValue(customList.inactive_address_count) : 0,
          icon: Inactive,
          info: 'Addresses without on-chain activity',
        },
        {
          title: 'Cumulated Portfolio Value',
          value: getEthPrice(customList.cumulated_portfolio_value),
          icon: Chart,
        },
        {
          title: 'Median Portfolio Value',
          value: getEthPrice(
            customList.cumulated_portfolio_value
              ? customList.median_portfolio_value
              : 0,
          ),
          icon: Cal,
        },
      ]);
    }
  }, [customList, insightData, isInsightDataLoading, isInsightDataFetching, refetch]);

  useEffect(() => {
    setItemPage(searchParams.get('tab')?.replace('+', ' ') || 'Audience Profile');
  }, [searchParams]);

  useEffect(() => {
    if (pages?.length && navType !== 'POP') {
      dispatch(setTabPages(pages));
    }
  }, [dispatch, navType, pages]);

  useEffect(() => {
    if (searchParams.get('scroll') && navType === 'POP') {
      setTimeout(
        () => window.scrollTo({ top: +searchParams.get('scroll'), left: 0, behavior: 'smooth' }),
        500,
      );
    }
  }, [navType, searchParams]);

  useEffect(() => () => {
    dispatch(setTabPages([]));
  }, [dispatch]);

  useEffect(() => {
    if (updateCustomListResult.isSuccess) {
      showSuccessMessage('Title name was saved');
      updateCustomListResult.reset();
    }
    if (updateCustomListResult.isError) {
      showErrorMessage(updateCustomListResult?.error?.data?.name[0] || 'Something went wrong');
      updateCustomListResult.reset();
    }
  }, [updateCustomListResult]);

  const fetchRender = useMemo(() => (
    <>
      <div className="asset-section">
        <div className="mt-4 mx-4 pt-3 pb-2">
          <Title>Portfolio value</Title>
        </div>
        <div className="dropdown-divider w-100" />
        <div className={`${styles.wrapper} d-gap-3 d-flex flex-column`}>
          <div className="d-flex gap-3 flex-column flex-sm-row pb-4">
            {widgetData.map((item) => (
              <WidgetCard
                key={uniqueId('custom-list-widget')}
                icon={item.icon}
                info={item.info}
                growth={item.growth}
                title={item.title}
                value={item.value}
                isLoading={isCustomListLoading || isCustomListFetching || item.isLoading}
              />
            ))}
          </div>
          <Reach
            data={reachData}
            isLoading={isReachDataFetching || isReachDataLoading || isCustomListLoading || isCustomListFetching}
            totalCount={customList?.member_count}
          />
        </div>
      </div>
      <div className="asset-section m-o mt-4">
        {isCustomListLoading || isCustomListFetching ? (
          <div className="mt-2">
            <LoadingLines />
          </div>
        ) : (
          <>
            <div className="title-gap asset-navigation">
              <ul className="nav nav-pills gap-2">
                {(navType === 'POP' && tabPages?.length ? tabPages : pages).map((elem) => (
                  <li
                    role="presentation"
                    className="nav-item"
                    key={uniqueId('custom-tab')}
                    onClick={() => {
                      setItemPage(elem.label);
                      setSearchParams({
                        tab: elem.label,
                        scroll: '',
                      });
                    }}
                  >
                    <Link
                      className={`nav-link ${
                        elem.label === itemPage ? 'active' : ''
                      }`}
                      to={`${pathname}${search}`}
                    >
                      {elem.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="dropdown-divider w-100" />
            <div className="">
              {(navType === 'POP' && tabPages?.length ? tabPages : pages).map((item) => (item.label === itemPage ? (
                <div key={uniqueId('custom-page')}>{item.component}</div>
              ) : null))}
            </div>
          </>
        )}
      </div>
    </>
  ), [
    customList?.custom_list_name,
    customList?.member_count,
    isCustomListFetching,
    isCustomListLoading,
    isReachDataFetching,
    isReachDataLoading,
    itemPage,
    pages,
    pathname,
    reachData,
    search,
    setSearchParams,
    widgetData,
    tabPages,
    navType,
    insightData,
    isInsightDataLoading,
    isInsightDataFetching,
  ]);

  return (
    <>
      {!(isCustomListLoading || isCustomListFetching) && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            {titleValue}
            {' '}
            Audiences
            {' '}
            - Absolute Labs Platform
          </title>
        </Helmet>
      )}
      <div className="d-flex gap-2 align-items-center">
        {!titleValue || isCustomListLoading || isCustomListFetching ? <LoadingLines /> : (
          <EditableTitle
            defaultTitle="Add the list name"
            titleValue={titleValue}
            setTitleValue={setTitleValue}
            onSave={() => updateCustomList({ id, body: { name: titleValue } })}
            edit={false}
            triggerNotification={false}
          />
        )}
      </div>
      <div className="mt-2">
        <EditableFoldersList itemId={id} itemType="custom_lists" />
      </div>
      {fetchRender}
    </>
  );
};

export default CustomListDetails;
