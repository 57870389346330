import React from 'react';

import styles from './Connector.module.scss';

const Connector = () => (
  <div className={`${styles.wrapper} d-flex flex-column align-items-center`}>
    <div className={styles.arrow} />
    <div className={`${styles.container} text-sm-regular`}>AND</div>
    <div className={styles.arrow} />
  </div>
);

export default Connector;
