import { assetData } from '../../pages/AssetDetails/dummy-data';
import { convertNumber, convertToReadableFormat } from '../../tools/NumberConverterTool';
import { supportedBlockchains } from '../supportedBlockchains';

export const parseTitleData = (asset, flag) => {
  switch (flag) {
    case 'coin':
      return {
        id: asset.id,
        thumb: asset.image.large,
        title: asset.name,
        symbol: asset.symbol.toUpperCase(),
        tags: asset.categories
          .filter((category) => category !== null)
          .slice(0, 5),
        icons: assetData.icons,
        links: asset.links,
        platforms: supportedBlockchains(asset.platforms),
      };
    case 'nft':
      return {};
  }
  return {};
};

export const handleClasses = (index, length) => {
  if (!index) return 'first-title';
  if (index === length - 1) return 'last-title';
  return 'next-title';
};

export const ethFormat = (number, eth) => {
  if (number === null) return '-';
  const exchange = eth ? (number * eth) : number;
  if (exchange > 1000 && eth === 1) return convertNumber(+exchange.toFixed(0));
  if (exchange > 1000) return convertToReadableFormat(exchange);
  if (exchange >= 100) return Math.floor(exchange);
  if (exchange >= 99.99) return 99.99;
  if (exchange > 1) return +exchange.toFixed(2);
  if (exchange > 0.01) return +exchange.toFixed(3);
  if (exchange > 0.0001) return +exchange.toFixed(4);
  if (exchange === 0) return 0;
  return `0.00..${(String(exchange).includes('e')
    ? +(String(exchange).slice(0, 4)
      .replace('.', '')
      .replace('e', '')
      .replace('-', '')
    )
    : +String(exchange).slice(-4))}`;
};
