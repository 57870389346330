import React from 'react';
import styles from './NoResults.module.scss';

const NoResults = () => (
  <div
    className={`${styles.no_results_container}
     d-flex flex-column align-items-center justify-content-center text-center`}
  >
    <div className={`${styles.no_results_main_text} fw-bold`}>
      Sorry, we didn’t find anything. Please try again.
    </div>
    <div className={`${styles.no_results_secondary_text} d-flex flex-column`}>
      <div>Check your spelling</div>
      <div>Use a different keyword</div>
    </div>
  </div>
);

export default NoResults;
