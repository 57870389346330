import React, { useMemo } from 'react';

import Homepage from './Homepage';
import './Footer.scss';

const Footer = ({ type }) => useMemo(() => {
  if (type === 'homepage') return <Homepage />;
  return null;
}, [type]);

export default Footer;
