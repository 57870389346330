import React, { useState } from 'react';
import Edit from '../../../assets/icons/edit.svg';
import { showSuccessMessage } from '../Notifications';
import styles from './EditableTitle.module.scss';

const EditableTitle = ({
  titleValue, setTitleValue, onSave, size, defaultTitle = 'Name', edit = true, maxLength = 50,
  triggerNotification = true,
}) => {
  const [editMode, setEditMode] = useState(false);

  if (editMode) {
    return (
      <div className="d-flex gap-2 align-items-center">
        <input
          onBlur={() => {
            if (titleValue.trim() && triggerNotification) {
              showSuccessMessage('Title name was saved');
            }
            setTitleValue(titleValue.trim());
            if (onSave) {
              onSave();
            }
            setEditMode(false);
          }}
          value={titleValue === defaultTitle ? '' : titleValue}
          onChange={(e) => setTitleValue(e.target.value)}
          className={`${styles.input} ${size === 16 ? styles.input_16 : ''} shadow-none`}
          maxLength={maxLength}
          autoFocus={editMode}
        />
      </div>
    );
  }

  return (
    <div className="d-flex gap-2 align-items-center">
      <div
        role="presentation"
        className={`${styles.title} ${size === 16 ? styles.title_16 : ''}`}
        onClick={() => edit && setEditMode(true)}
      >
        {titleValue || defaultTitle}
      </div>
      {edit
        ? (
          <Edit
            className={`${styles.fill} ${size === 16 ? styles.fill_16 : ''} cursor-pointer`}
            onClick={() => {
              setEditMode(true);
            }}
          />
        )
        : null}
    </div>
  );
};

export default EditableTitle;
