import React, { useState } from 'react';
import styles from '../NotableInvestments.module.scss';
import DefaultIcon from '../../DefaultIcon';

const AssetImg = ({ logo, type, invest }) => {
  const [logoError, setLogoError] = useState(false);
  return (
    logo && !logoError
      ? (
        <img
          src={logo}
          alt="logo"
          width={invest ? '32px' : '38px'}
          height={invest ? '32px' : '38px'}
          className="rounded-circle"
          onError={() => setLogoError(true)}
        />
      )
      : (
        <div className={`${styles.wrapper_img} d-flex justify-content-center align-items-center`}>
          <DefaultIcon type={type} size={invest ? '32px' : '38px'} />
        </div>
      )
  );
};

export default AssetImg;
