import React, { useMemo, useState } from 'react';

import './AddCard.scss';
import Plus from '../../../assets/images/dashboard/plus.png';
import NFTCollectionCreationModal from '../../../pages/NFTCreation/modals/NFTCollectionCreationModal';

const AddCard = () => {
  const [showNFTModal, setShowNFTModal] = useState(false);
  return useMemo(() => (
    <>
      <div
        role="presentation"
        className="add-card-container"
        onClick={() => {
          setShowNFTModal(true);
        }}
      >
        <img src={Plus} alt="plus" />
      </div>

      {showNFTModal && (
        <NFTCollectionCreationModal
          onCancel={() => setShowNFTModal(false)}
        />
      )}
    </>
  ), [showNFTModal]);
};

export default AddCard;
