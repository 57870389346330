import React from 'react';
import Arrow from '../../../../assets/icons/table/pagination-arrow.svg';
import styles from './Pagination.module.scss';
import uniqueId from '../../../../utils/uniqueId';
import { onPaste, preventInvalidSymbols } from '../../../../utils/segments';
import useSetSearchParams from '../../../../utils/useSetSearchParams';

const PageButton = ({
  number, page,
}) => {
  const setParams = useSetSearchParams();

  return (
    <button
      type="button"
      className={`
    ${number === page ? styles.activeButton : ''}
    ${styles.pageButton}`}
      onClick={() => {
        setParams({ page: number });
      }}
    >
      {number}
    </button>
  );
};

const getRangeArray = (start, end) => Array(end - start + 1).fill().map((_, idx) => start + idx);

const Pagination = ({
  page, limit = 10, total, min = 10,
}) => {
  const setParams = useSetSearchParams();

  const getPages = () => {
    const pageCount = Math.ceil(total / limit);
    if (pageCount <= 8) {
      return (
        Array.from({ length: pageCount }, (_, index) => index + 1).map((elem) => (
          <PageButton number={elem} page={page} key={uniqueId('button')} />
        ))
      );
    }
    if (page <= 2) {
      return (
        <>
          {getRangeArray(1, 3).map((elem) => (
            <PageButton number={elem} page={page} key={uniqueId('button')} />
          ))}
          <div>...</div>
          {getRangeArray(pageCount - 2, pageCount).map((elem) => (
            <PageButton number={elem} page={page} key={uniqueId('button')} />
          ))}
        </>
      );
    }
    if (page <= 5) {
      return (
        <>
          {getRangeArray(1, page + 1).map((elem) => (
            <PageButton number={elem} page={page} key={uniqueId('button')} />
          ))}
          <div>...</div>
          {getRangeArray(pageCount - 2, pageCount).map((elem) => (
            <PageButton number={elem} page={page} key={uniqueId('button')} />
          ))}
        </>
      );
    }
    if (page - pageCount === -2) {
      return (
        <>
          {getRangeArray(1, 1).map((elem) => (
            <PageButton number={elem} page={page} key={uniqueId('button')} />
          ))}
          <div>...</div>
          {getRangeArray(pageCount - 3, pageCount).map((elem) => (
            <PageButton number={elem} page={page} key={uniqueId('button')} />
          ))}
        </>
      );
    }
    if (page - pageCount >= -1) {
      return (
        <>
          {getRangeArray(1, 1).map((elem) => (
            <PageButton number={elem} page={page} key={uniqueId('button')} />
          ))}
          <div>...</div>
          {getRangeArray(pageCount - 2, pageCount).map((elem) => (
            <PageButton number={elem} page={page} key={uniqueId('button')} />
          ))}
        </>
      );
    }
    return (
      <>
        {getRangeArray(1, 1).map((elem) => (
          <PageButton number={elem} page={page} key={uniqueId('button')} />
        ))}
        <div>...</div>
        {getRangeArray(page - 1, page + 1).map((elem) => (
          <PageButton number={elem} page={page} key={uniqueId('button')} />
        ))}
        <div>...</div>
        {getRangeArray(pageCount, pageCount).map((elem) => (
          <PageButton number={elem} page={page} key={uniqueId('button')} />
        ))}
      </>
    );
  };

  const handleOnKeyUp = (e) => {
    e.stopPropagation();
    if (e.target.value <= 0) return;
    if (e.key === 'Enter' && e.target.value <= Math.ceil(total / limit)) {
      setParams({ page: +e.target.value });
    }
  };

  return (
    <div className={`${styles.wrapper} row g-3 g-md-0`}>
      <div
        className={`
          ${styles.jump}
          order-1
          order-md-0
          d-flex
          align-items-center
          col-12
          col-sm-6
          col-md-3`}
      >
        Jump to page:
        <input
          className={styles.input}
          onWheel={(e) => e.target.blur()}
          onKeyDown={handleOnKeyUp}
          type="number"
          min="0"
          max={Math.ceil(total / limit)}
          step={1}
          onPaste={onPaste}
          onKeyPress={(e) => { preventInvalidSymbols(e); }}
        />
      </div>
      <div
        className="
          order-0
          order-md-1
          col-12
          col-md-6
          d-flex
          justify-content-center
          align-items-center"
      >
        <Arrow
          className={`
            ${page !== 1 ? styles.active : ''}
            cursor-pointer`}
          onClick={() => {
            if (page !== 1) {
              setParams({ page: page - 1 });
            }
          }}
        />
        {getPages()}
        <Arrow
          className={`
            ${page !== Math.ceil(total / limit) ? styles.active : ''}
            ${styles.rotate}
            cursor-pointer`}
          onClick={() => {
            if (page !== Math.ceil(total / limit)) {
              setParams({ page: page + 1 });
            }
          }}
        />
      </div>
      <div
        className={`
          ${styles.results}
          order-2
          col-12
          col-sm-6
          col-md-3
          d-flex
          justify-content-start
          justify-content-sm-end
          align-items-center`}
      >
        Results per page:
        <select
          onChange={(e) => {
            setParams({ limit: e.target.value, page: 1 });
          }}
          className={styles.select}
          value={limit}
        >
          {min === 25
            ? null
            : <option value={10}>10</option>}
          <option value={25}>25</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      </div>
    </div>
  );
};

export default Pagination;
