import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { useLocation } from 'react-router';
import { webSDKApi } from '../../api/webSDK';
import SingleWebSDKTitle from '../../components/ui/TitleSection/Titles/SingleWebSDKTitle';
import MonthlyActivity from './components/MonthlyActivity';
import UsersTab from './UsersTab';
import InvestorProfile from './WalletTab';
import WebSDKFilters from './WebSDKFilters';
import IdentifiedProfilesTab from './IdentifiedProfilesTab';
import AttributionTab from './AttributionTab';
import './WebSDK.scss';

const WebSDK = () => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const [itemPage, setItemPage] = useState('Visitors');
  const [, setSearchParams] = useSearchParams();

  const {
    data,
    isLoading,
    isFetching,
  } = webSDKApi.useGetWebSDKByIdQuery(id);

  const [pages] = useState([
    { label: 'Visitors', component: <UsersTab />, id: 1 },
    { label: 'Wallets', component: <InvestorProfile id={id} />, id: 2 },
    { label: 'Identified profiles', component: <IdentifiedProfilesTab />, id: 3 },
    { label: 'Attribution', component: <AttributionTab />, id: 4 },
  ]);

  return (
    <>
      {!(isLoading || isFetching) && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            {data?.website_name || ''}
            {' '}
            Insights
            {' '}
            - Absolute Labs Platform
          </title>
        </Helmet>
      )}
      <SingleWebSDKTitle isWebLoading={isLoading || isFetching} trackedData={data} />
      <div className="asset-section">
        <MonthlyActivity isLoading={isLoading || isFetching} />
      </div>
      <WebSDKFilters />
      <div className="asset-section">
        <div className="title-gap asset-navigation">
          <ul className="nav nav-pills gap-2">
            {pages.map((elem) => (
              <li
                role="presentation"
                className="nav-item cursor-pointer"
                key={elem.id}
                onClick={() => {
                  setItemPage(elem.label);
                  setSearchParams({
                    scroll: false,
                  });
                }}
              >
                <Link
                  className={`nav-link ${
                    elem.label === itemPage ? 'active' : ''
                  }`}
                  to={pathname}
                >
                  {elem.label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="dropdown-divider w-100" />
        {pages.map((item) => (item.label === itemPage ? (
          <div
            key={item.id}
          >
            {item.component}
          </div>
        ) : null))}
      </div>
    </>
  );
};

export default WebSDK;
