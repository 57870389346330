import React from 'react';
import styles from './selectionButtons.module.scss';

const SelectionButtons = ({
  buttons, selectedValue, setSelectedValue, width,
}) => {
  const handleButtonClick = (value) => {
    setSelectedValue(value);
  };

  const renderButtons = () => buttons.map((button) => (
    <button
      key={button.value}
      type="button"
      className={`${styles.button} ${selectedValue === button.value ? styles.selected : ''}`}
      onClick={() => handleButtonClick(button.value)}
      style={{ width: width ? `${width}px` : 'auto' }}
    >
      {button.elem}
    </button>
  ));

  return (
    <div className={styles.wrapper}>
      {renderButtons()}
    </div>
  );
};

export default SelectionButtons;
