import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setFormStyle,
  setFormImageFile,
  setBackgroundFile,
  selectFormStyle,
} from '../../../../../../store/reducers/forms';
import { formsApi } from '../../../../../../api/forms';
import { showErrorMessage } from '../../../../../../components/base/Notifications';
import Switch from '../../../Components/ModalSettings/Switch';
import ColorScheme from '../../../Components/ColorScheme';
import settingsStyles from '../../../Components/ModalSettings/ModalSettings.module.scss';
import ColorSelector from '../../../Components/ColorSelector';
import PictureSelector from '../../../Components/PictureSelector';

const StyleSettings = ({ activeSettings }) => {
  const dispatch = useDispatch();
  const formStyle = useSelector(selectFormStyle);
  const [formColor, setFormColor] = useState('#FFFFFF');
  const [textColor, setTextColor] = useState('#000000');
  const [buttonColor, setButtonColor] = useState('#109CF1');
  const [buttonTextColor, setButtonTextColor] = useState('#FFFFFF');
  const [backgroundColor, setBackgroundColor] = useState('#FFFFFF');
  const [backgroundUrl, setBackgroundUrl] = useState('');
  const [fileBackgroundName, setBackgroundFileName] = useState('');
  const [formUrl, setFormUrl] = useState('');
  const [fileFormName, setFormFileName] = useState('');
  const reduxState = useSelector((state) => state.formsReducer);
  const [uploadBackgroundImage, uploadBackgroundResult] = formsApi.useUploadImageMutation();
  const [uploadFormImage, uploadFormResult] = formsApi.useUploadImageMutation();
  const [invertFieldColors, setInvertFieldColors] = useState(formStyle.fieldStyle.invertColours);

  const handleColorClick = (config) => {
    setButtonColor(config.buttonColour);
    setButtonTextColor(config.buttonTextColour);
    setBackgroundColor(config.backgroundColour);

    dispatch(setFormStyle({
      formStyle: {
        colourTheme: config.color,
        formColour: formColor,
        formImageUrl: formUrl,
        textColour: textColor,
      },
      buttonStyle: {
        buttonColour: config.buttonColour,
        textColour: config.buttonTextColour,
      },
      backgroundStyle: {
        backgroundColour: config.backgroundColour,
        backgroundImageUrl: '',
      },
      fieldStyle: {
        invertColours: false,
      },
    }));
  };

  const isValidHex = (hex) => /^#([0-9A-Fa-f]{3}){1,2}$/.test(hex) || /^#[0-9A-Fa-f]{0,5}$/.test(hex);

  const handleColorChange = (color, type) => {
    if (isValidHex(color)) {
      switch (type) {
        case 'form':
          setFormColor(color);
          dispatch(setFormStyle({
            formStyle: {
              ...reduxState.style.formStyle,
              formColour: color,
            },
            buttonStyle: {
              ...reduxState.style.buttonStyle,
            },
            backgroundStyle: {
              ...reduxState.style.backgroundStyle,
            },
            fieldStyle: {
              ...reduxState.style.fieldStyle,
            },
          }));
          break;

        case 'text':
          setTextColor(color);
          dispatch(setFormStyle({
            formStyle: {
              ...reduxState.style.formStyle,
              textColour: color,
            },
            buttonStyle: {
              ...reduxState.style.buttonStyle,
            },
            backgroundStyle: {
              ...reduxState.style.backgroundStyle,
            },
            fieldStyle: {
              ...reduxState.style.fieldStyle,
            },
          }));
          break;

        case 'button':
          setButtonColor(color);
          dispatch(setFormStyle({
            formStyle: {
              ...reduxState.style.formStyle,
            },
            buttonStyle: {
              ...reduxState.style.buttonStyle,
              buttonColour: color,
            },
            backgroundStyle: {
              ...reduxState.style.backgroundStyle,
            },
            fieldStyle: {
              ...reduxState.style.fieldStyle,
            },
          }));
          break;

        case 'buttonText':
          setButtonTextColor(color);
          dispatch(setFormStyle({
            formStyle: {
              ...reduxState.style.formStyle,
            },
            buttonStyle: {
              ...reduxState.style.buttonStyle,
              textColour: color,
            },
            backgroundStyle: {
              ...reduxState.style.backgroundStyle,
            },
            fieldStyle: {
              ...reduxState.style.fieldStyle,
            },
          }));
          break;

        case 'background':
          setBackgroundColor(color);
          dispatch(setFormStyle({
            formStyle: {
              ...reduxState.style.formStyle,
            },
            buttonStyle: {
              ...reduxState.style.buttonStyle,
            },
            backgroundStyle: {
              ...reduxState.style.backgroundStyle,
              backgroundColour: color,
            },
            fieldStyle: {
              ...reduxState.style.fieldStyle,
            },
          }));
          break;

        default:
          break;
      }
    }
  };

  const handleBackgroundFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file
      && (file.type === 'image/jpeg' || file.type === 'image/png')
      && (file.name.endsWith('.jpg') || file.name.endsWith('.png') || file.name.endsWith('.jpeg'))) {
      const localImageUrl = URL.createObjectURL(file);
      setBackgroundUrl(localImageUrl);
      setBackgroundFileName(file.name);
      dispatch(setBackgroundFile({ fieldId: 'background_setting_image', file }));
      dispatch(setFormStyle({
        formStyle: {
          ...reduxState.style.formStyle,
        },
        buttonStyle: {
          ...reduxState.style.buttonStyle,
        },
        backgroundStyle: {
          ...reduxState.style.backgroundStyle,
          backgroundImageUrl: localImageUrl,
        },
        fieldStyle: {
          ...reduxState.style.fieldStyle,
        },
      }));
      // Prepare the formData to send to the server
      const formData = new FormData();
      formData.append('form_image', file);
      try {
        // Upload the image using the mutation hook
        await uploadBackgroundImage(formData);
      } catch (error) {
        showErrorMessage('Error occurred during image upload:', error);
      }
    } else {
      showErrorMessage('Only .jpg and .png files are allowed');
    }
  };

  useEffect(() => {
    if (uploadBackgroundResult.isSuccess && uploadBackgroundResult.data?.form_image) {
      setBackgroundUrl(uploadBackgroundResult.data.form_image);
      dispatch(setFormStyle({
        formStyle: {
          ...reduxState.style.formStyle,
        },
        buttonStyle: {
          ...reduxState.style.buttonStyle,
        },
        backgroundStyle: {
          ...reduxState.style.backgroundStyle,
          backgroundImageUrl: uploadBackgroundResult.data.form_image,
        },
        fieldStyle: {
          ...reduxState.style.fieldStyle,
        },
      }));
    } else if (uploadBackgroundResult.isError) {
      showErrorMessage('Error occurred during image upload:', uploadBackgroundResult.error);
    }
  }, [uploadBackgroundResult]);

  const handleFormFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file
      && (file.type === 'image/jpeg' || file.type === 'image/png')
      && (file.name.endsWith('.jpg') || file.name.endsWith('.png') || file.name.endsWith('.jpeg'))) {
      const localImageUrl = URL.createObjectURL(file);
      setFormUrl(localImageUrl);
      setFormFileName(file.name);
      dispatch(setFormImageFile({ fieldId: 'form_setting_image', file }));
      dispatch(setFormStyle({
        formStyle: {
          ...reduxState.style.formStyle,
          formImageUrl: localImageUrl,
        },
        buttonStyle: {
          ...reduxState.style.buttonStyle,
        },
        backgroundStyle: {
          ...reduxState.style.backgroundStyle,
        },
        fieldStyle: {
          ...reduxState.style.fieldStyle,
        },
      }));
      // Prepare the formData to send to the server
      const formData = new FormData();
      formData.append('form_image', file);
      try {
        // Upload the image using the mutation hook
        await uploadFormImage(formData);
      } catch (error) {
        showErrorMessage('Error occurred during image upload:', error);
      }
    } else {
      showErrorMessage('Only .jpg and .png files are allowed');
    }
  };

  const handleInvertFieldColorsChange = () => {
    const newValue = !invertFieldColors;
    dispatch(setFormStyle({
      formStyle: {
        ...reduxState.style.formStyle,
      },
      buttonStyle: {
        ...reduxState.style.buttonStyle,
      },
      backgroundStyle: {
        ...reduxState.style.backgroundStyle,
      },
      fieldStyle: {
        ...reduxState.style.fieldStyle,
        invertColours: newValue,
      },
    }));
    setInvertFieldColors(newValue);
  };

  useEffect(() => {
    if (uploadFormResult.isSuccess && uploadFormResult.data?.form_image) {
      setFormUrl(uploadFormResult.data.form_image);
      dispatch(setFormStyle({
        formStyle: {
          ...reduxState.style.formStyle,
          formImageUrl: uploadFormResult.data.form_image,
        },
        buttonStyle: {
          ...reduxState.style.buttonStyle,
        },
        backgroundStyle: {
          ...reduxState.style.backgroundStyle,
        },
        fieldStyle: {
          ...reduxState.style.fieldStyle,
        },
      }));
    } else if (uploadFormResult.isError) {
      console.error('Error occurred during image upload:', uploadFormResult.error);
    }
  }, [uploadFormResult]);

  const removeBackgroundImage = () => {
    setBackgroundUrl('');
    setBackgroundFileName('');
    dispatch(setFormStyle({
      formStyle: {
        ...reduxState.style.formStyle,
      },
      buttonStyle: {
        ...reduxState.style.buttonStyle,
      },
      backgroundStyle: {
        ...reduxState.style.backgroundStyle,
        backgroundImageUrl: '',
      },
      fieldStyle: {
        ...reduxState.style.fieldStyle,
      },
    }));
  };

  const removeFormImage = () => {
    setFormUrl('');
    setFormFileName('');
    dispatch(setFormStyle({
      formStyle: {
        ...reduxState.style.formStyle,
        formImageUrl: '',
      },
      buttonStyle: {
        ...reduxState.style.buttonStyle,
      },
      backgroundStyle: {
        ...reduxState.style.backgroundStyle,
      },
      fieldStyle: {
        ...reduxState.style.fieldStyle,
      },
    }));
  };

  useEffect(() => {
    if (activeSettings && formStyle) {
      handleColorChange(formStyle.formStyle.formColour, 'form');
      handleColorChange(formStyle.formStyle.textColour, 'text');
      handleColorChange(formStyle.buttonStyle.buttonColour, 'button');
      handleColorChange(formStyle.buttonStyle.textColour, 'buttonText');
      handleColorChange(formStyle.backgroundStyle.backgroundColour, 'background');
      setBackgroundUrl(formStyle.backgroundStyle.backgroundImageUrl);
      setFormUrl(formStyle.formStyle.formImageUrl);
    }
  }, [activeSettings]);

  return (
    <>
      <div className={settingsStyles.section_title}>
        Style
      </div>

      <ColorScheme handleConfigChange={handleColorClick} />

      <div className={settingsStyles.section_title}>
        Form style
      </div>

      <div className={settingsStyles.row}>
        <ColorSelector
          id="form"
          title="Form Color"
          color={formColor}
          colorChange={handleColorChange}
        />

        <PictureSelector
          title="Form Image"
          url={formUrl}
          fileName={fileFormName}
          uploadResult={uploadFormResult}
          handleFileUpload={handleFormFileUpload}
          removeImage={removeFormImage}
        />

        <ColorSelector
          id="text"
          title="Text Color"
          color={textColor}
          colorChange={handleColorChange}
        />
      </div>

      <div className={settingsStyles.section_title}>
        Button style
      </div>

      <div className={settingsStyles.row}>
        <ColorSelector
          id="button"
          title="Button Color"
          color={buttonColor}
          colorChange={handleColorChange}
        />

        <ColorSelector
          id="buttonText"
          title="Button Text Color"
          color={buttonTextColor}
          colorChange={handleColorChange}
        />
      </div>

      <div className={settingsStyles.section_title}>
        Background style
      </div>

      <div className={settingsStyles.row}>
        <ColorSelector
          id="background"
          title="Background Color"
          color={backgroundColor}
          colorChange={handleColorChange}
        />

        <PictureSelector
          title="Background Image"
          url={backgroundUrl}
          fileName={fileBackgroundName}
          uploadResult={uploadBackgroundResult}
          handleFileUpload={handleBackgroundFileUpload}
          removeImage={removeBackgroundImage}
        />
      </div>

      <div className={settingsStyles.section_title}>
        Field style
      </div>

      <Switch
        text="Invert field colors"
        checked={invertFieldColors}
        onChange={handleInvertFieldColorsChange}
      />
    </>
  );
};

export default StyleSettings;
