import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import Loader from '../../base/Loader';
import NoData from '../../base/NoData';
import Arrow from '../../../assets/images/long_arrow.svg';
import styles from './SharedInterests.module.scss';
import CardTitle from '../CardTitle';

const SharedInterests = ({
  symbol, data, isLoading, isFetching, info,
}) => {
  const [chartPoints, setChartPoint] = useState([]);
  const [points, setPoints] = useState([]);
  const [minY, setMinY] = useState(0);
  const [minX, setMinX] = useState(0);
  const [maxX, setMaxX] = useState(100);

  const adjustLabels = () => {
    const chartElement = document.querySelector('.apexcharts-canvas');
    if (chartElement) {
      // const chartWidth = chartElement.clientWidth;
      const updatedPoints = chartPoints.map((point) => {
        const updatedPoint = { ...point };
        const leftSpace = point.x;
        const rightSpace = maxX - point.x;
        updatedPoint.label.offsetX = leftSpace > rightSpace ? -10 : 10;
        updatedPoint.label.textAnchor = leftSpace > rightSpace ? 'end' : 'start';
        return updatedPoint;
      });
      setChartPoint(updatedPoints);
    }
  };

  const options = {
    chart: {
      type: 'scatter',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      events: {
        redraw: () => {
          adjustLabels();
        },
      },
    },
    grid: {
      show: true,
      borderColor: '#707683',
      position: 'back',
      yaxis: {
        lines: {
          show: false,
        },
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    annotations: {
      points: chartPoints,
    },
    xaxis: {
      type: 'numeric',
      tickAmount: 10,
      min: minX,
      max: maxX,
      axisBorder: {
        show: true,
        color: '#707683',
        offsetX: 0,
        offsetY: 0,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
    yaxis: {
      tickAmount: 10,
      min: minY - (minY / 5),
      axisBorder: {
        show: true,
        color: '#707683',
        offsetX: 0,
        offsetY: 0,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
  };

  useEffect(() => {
    if (data && data.length) {
      const topData = data.slice(0, 8);
      const adjustedPoints = topData.map((item) => ({ ...item })).sort((a, b) => a.total_number - b.total_number);
      adjustedPoints.forEach((element) => {
        if (adjustedPoints.indexOf(element) === 0) return;
        const previous = adjustedPoints[adjustedPoints.indexOf(element) - 1];
        const current = adjustedPoints[adjustedPoints.indexOf(element)];
        const threshold = (data[0].total_number / 360) * 17;
        if (current.total_number - previous.total_number <= threshold) {
          element.total_number = previous.total_number + threshold;
        }
      });
      const score = adjustedPoints.map((elem) => elem.avg_score);
      const xRange = [Math.min(...score), Math.max(...score)];
      const scaleFactor = (100 - 1) / (xRange[1] - xRange[0]);

      const normalizedPoints = adjustedPoints.map((elem) => ({
        ...elem,
        avg_score: 1 + (elem.avg_score - xRange[0]) * scaleFactor,
      }));

      setPoints(normalizedPoints);
      const pointsArr = normalizedPoints.map((elem) => ({
        x: +elem.avg_score,
        y: elem.total_number,
        marker: { size: 0 },
        label: {
          textAnchor: elem.avg_score <= 50 ? 'start' : 'end',
          borderWidth: 0,
          offsetY: 12,
          offsetX: elem.avg_score <= 50 ? 10 : -10,
          text: elem.name,
          style: {
            background: 'transparent',
            color: '#192A3E',
            fontSize: '12px',
            fontWeight: 500,
            fontFamily: 'Poppins',
          },
        },
      }));

      setChartPoint(pointsArr);
      setMaxX(100);
      setMinX(0);
      setMinY(pointsArr.reduce((prev, cur) => (cur.y < prev.y ? cur : prev), { y: Infinity }).y);
    }
  }, [data]);

  useEffect(() => {
    window.addEventListener('resize', adjustLabels);
    return () => window.removeEventListener('resize', adjustLabels);
  }, [chartPoints]);

  return (
    <div className={styles.wrapper}>
      <CardTitle title="Shared Interests" info={info} />
      { isLoading || isFetching
        ? (
          <div className="p-5">
            <Loader />
          </div>
        )
        : data?.length && data.some((item) => item.total_number >= 4) ? (
          <div className="position-relative">
            <div className="
            d-flex
            flex-column
            align-items-center
            gap-2
            justify-content-end
            position-absolute
            bottom-0 mb-5"
            >
              <Arrow />
              <div className={styles.strength_title}>Interest strength</div>
            </div>
            <Chart
              options={options}
              series={points.map((elem) => ({
                data: [[+elem.avg_score.toFixed(0), elem.total_number]],
                name: `${elem.name} xxx`,
              }))}
              type="scatter"
              height={390}
              width="100%"
            />
            <div className={`${styles.holders} d-flex gap-4 position-absolute align-items-center w-100`}>
              <div className={`d-flex text-nowrap gap-1 ${symbol?.length > 25 ? 'w-75' : ''}`}>
                {symbol ? (
                  <div className="text-truncate">
                    {symbol === 'AUDIENCE' || symbol === 'VISITORS' ? symbol : `$${symbol}`}
                  </div>
                ) : null}
                <span>
                  {symbol === 'AUDIENCE' ? 'members interested'
                    : symbol === 'VISITORS' ? 'interested' : 'holders interested'}
                </span>
              </div>
              <div>
                <Arrow />
              </div>
            </div>
          </div>
        ) : (
          <div>
            <NoData />
          </div>
        )}
    </div>
  );
};

export default React.memo(SharedInterests);
