import React, { useEffect, useState } from 'react';
import CircleChart from '../../CircleChart';
import { getWalletAge } from '../../../../utils/segments';
import { getGrowth } from '../../../../utils/values';
import { getAgeInMilliseconds } from '../../../../utils/date';
import AgeDate from '../../Table/AgeDate';

const WalletAge = ({
  activityData, previousHighlight, isLoading, className = '',
}) => {
  const [data, setData] = useState([]);
  const [avgShare, setAvgShare] = useState('');

  useEffect(() => {
    if (activityData && !isLoading) {
      const total = activityData.buckets.reduce((sum, { value }) => sum + value, 0);
      if (total === 0) {
        setData([]);
        return;
      }
      const newData = activityData.buckets.map((bucket) => {
        const percent = total ? ((bucket.value * 100) / total).toFixed(2) : 0;
        return {
          label: bucket.label,
          count: bucket.value,
          percent: +percent,
        };
      });
      const { median } = activityData;
      setAvgShare(<AgeDate date={median} defaultText="No Transactions" isAge />);
      setData(newData);
    }
  }, [activityData, isLoading]);

  return (
    <div className={className || ''}>
      <CircleChart
        title="Wallet Age"
        highlightText="Median Wallet Age"
        highlightValue={avgShare}
        data={data}
        isLoading={isLoading || (!data.length && isLoading)}
        growth={previousHighlight
          ? getGrowth(
            getAgeInMilliseconds(activityData?.median),
            getAgeInMilliseconds(previousHighlight?.median),
            getWalletAge(previousHighlight?.median),
          )
          : null}
      />
    </div>
  );
};

export default WalletAge;
