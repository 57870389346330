import React from 'react';
import SliderPercentage from '../../../../../../../components/ui/SliderPercentage';
import styles from './width.module.scss';
import settingsStyles from '../../../../Components/ModalSettings/ModalSettings.module.scss';

const Width = ({ width, setWidth, setChange }) => (
  <div className={styles.wrapper}>
    <div className={settingsStyles.title}>Width</div>
    <div className={styles.slider}>
      <SliderPercentage value={width} setValue={setWidth} setChange={setChange} />
    </div>
  </div>
);

export default Width;
