import React from 'react';
import ListFilterItem from './ListFilterItem';
import styles from './listFilter.module.scss';

const ListFilter = ({ filters, setFilter }) => (
  <div className={styles.wrapper}>
    {filters.map((filter) => (
      <ListFilterItem
        key={filter.id}
        id={filter.id}
        label={filter.label}
        icon={filter.icon}
        selected={filter.selected}
        setFilter={setFilter}
      />
    ))}

  </div>
);

export default ListFilter;
