import React from 'react';

import styles from './RejectedInvitationModal.module.scss';

const RejectedInvitationModal = ({ openModal }) => (
  <div
    className={`modal modal-dialog-centered ${styles.modal_wrapper}`}
    id="exampleModal"
    tabIndex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div
      role="presentation"
      className="modal-dialog modal-md modal-phone w-100"
    >
      <div className={`modal-content ${styles.body}`}>
        <div className="d-flex flex-column justify-content-center">
          <div className={styles.title}>Your invitation link has Expired</div>
          <div className={styles.description}>
            Please, reach your Customer Success Manager to get a
            new invitation or log In using your credentials.
          </div>
          <button className="regular-button w-100" type="button" onClick={() => { openModal(); }}>Log in</button>
        </div>
      </div>
    </div>
  </div>
);

export default RejectedInvitationModal;
