import { ethers } from 'ethers';

const chainMapping = {
  ethereum: 'Ethereum',
  'eth-sepolia': 'Sepolia',
  polygon: 'Polygon',
  'polygon-pos': 'Polygon',
  base: 'Base',
  'base-sepolia': 'Base Sepolia',
  linea: 'Linea',
  'linea-sepolia': 'Linea Sepolia',
  chiliz: 'Chiliz',
  'chiliz-spicy': 'Chiliz Spicy',
  near: 'Near',
  'near-protocol': 'Near',
  flow: 'Flow',
  ordinals: 'Ordinals',
  amoy: 'Amoy',
};

const isObject = (a) => (!!a) && (a.constructor === Object);

export const excludedBlockchainsForFlows = ['near', 'flow', 'ordinals'];

export const isWalletAddress = (name) => !!name && (((name.startsWith('0x') || name.startsWith('bc1'))
&& !name.includes('-')) || name.endsWith('.near') || name.endsWith('.tg') || name.length === 64);

export const isWalletAddressPath = (name) => isWalletAddress(name.replace(/\/profile\//, ''));

// Returns allowed chains in the order given in chainMapping
export const supportedBlockchains = (platforms) => {
  if (!platforms) {
    return [];
  }
  // Direct handling for object type platforms
  if (isObject(platforms)) {
    return Object.keys(chainMapping).filter(
      (chain) => Object.keys(platforms).filter((key) => !!platforms[key]).includes(chain),
    );
  }
  // Convert to array if not already so that we can properly filter using includes
  const platformArray = Array.isArray(platforms) ? platforms : [platforms];
  return Object.keys(chainMapping).filter((chain) => platformArray.includes(chain));
};
