import React, { useEffect, useState } from 'react';
import './LoadingList.scss';
import { useWindowSize } from '../../../../hooks/app';
import uniqueId from '../../../../../utils/uniqueId';
import styles from './LoadingList.module.scss';

export const LoadingAvatar = ({ size }) => (
  <svg height={size} width={size} className={styles.blinking}>
    <circle cx={size / 2} cy={size / 2} r={size / 2} fill="#ced4da" />
  </svg>
);

export const LoadingListOnly = () => (
  <div className="ph-container">
    <div className="ph-item" key={uniqueId('loading_list')}>
      <div className="wrapper-loading position-relative">
        <div className="ph-row">
          <div className="ph-col-4 radius" />
          <div className="ph-col-8 empty" />
          <div className="ph-col-2 radius" />
          <div className="ph-col-10 empty" />
        </div>
      </div>
    </div>
  </div>
);

export const ControlledList = () => (
  <div className={styles.container}>
    <div className={styles.post}>
      <div className={styles.avatar} />
      <div className={styles.lines}>
        <div className={styles.line} />
        <div className={styles.line} />
      </div>
    </div>
  </div>
);

export const LoadingLines = ({
  align = 'center',
  rows = 1,
  height = '80px',
  width = '90%',
  flexDirection = 'column',
}) => (
  <div className={styles.container} style={{ alignItems: align }}>
    {Array(rows).fill(true).map(() => (
      <div
        className={styles.post}
        key={uniqueId('loading_row')}
        style={{ height }}
      >
        <div className={styles.lines} style={{ flexDirection }}>
          <div className={styles.line} style={{ width }} />
          <div className={styles.line} />
        </div>
      </div>
    ))}
  </div>
);

const LoadingList = ({ rows = null }) => {
  const { height } = useWindowSize();

  const [arrayLength, setArrayLength] = useState(5);

  const contentLoading = () => (
    <>
      <div className="ph-col-2">
        <div className="ph-avatar" />
      </div>
      <div className="wrapper-loading position-relative">
        <div className="ph-row">
          <div className="ph-col-4 radius" />
          <div className="ph-col-8 empty" />
          <div className="ph-col-2 radius" />
          <div className="ph-col-10 empty" />
        </div>
      </div>
    </>
  );

  useEffect(() => {
    if (height > 700) {
      setArrayLength(5);
    }
    if (height <= 700) {
      setArrayLength(4);
    }
    if (height < 600) {
      setArrayLength(3);
    }
    if (height < 500) {
      setArrayLength(2);
    }
    if (height < 430) {
      setArrayLength(1);
    }
  }, [height]);

  return (
    <div className="ph-container">
      {Array(rows || arrayLength).fill(contentLoading()).map((elem) => (
        <div className="ph-item" key={uniqueId('loading_list')}>
          {elem}
        </div>
      ))}
    </div>
  );
};

export default LoadingList;
