import { convertNumber } from './NumberConverterTool';

export const counter = (data, page, limit) => {
  if (data) {
    return `Showing ${convertNumber((page - 1) * limit + 1)} - ${page * limit > data
      ? convertNumber(data)
      : convertNumber(page * limit)} of ${convertNumber(data)}`;
  }
  return 'Showing 0 of 0';
};
