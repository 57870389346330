import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SettingsIcon from '../../../../../assets/icons/forms/settings.svg?url';
import {
  setActiveSettings,
  setCurrPage,
  selectCurrPage,
} from '../../../../../store/reducers/forms';
import styles from './Navigation.module.scss';

const pages = [
  {
    title: 'Main form',
    name: 'main-form',
  },
  {
    title: 'Thank you',
    name: 'thank-you',
  },
];

const Navigation = ({ isStatic, left }) => {
  const dispatch = useDispatch();
  const currPage = useSelector(selectCurrPage);

  useEffect(() => {
    if (!currPage) {
      dispatch(setCurrPage(pages[0].name));
    }
  }, []);

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{
        position: 'fixed',
        bottom: '100px',
        left: `${left || 0}px`,
        transform: 'translateX(-50%)',
        zIndex: 10,
      }}
    >
      {!isStatic ? (
        <div className={styles.buttonPill}>
          {
            pages.map((page, index) => (
              <React.Fragment key={page.name}>
                <button
                  type="button"
                  className={currPage === page.name ? styles.selectedButton : styles.defaultButton}
                  onClick={() => dispatch(setCurrPage(page.name))}
                >
                  {page.title}
                </button>
                {index === 0 && <div className={styles.divider} />}
              </React.Fragment>
            ))
          }
        </div>
      ) : null}
      <button
        type="button"
        className={styles.iconButton}
        onClick={() => { dispatch(setActiveSettings({ type: 'overall' })); }}
      >
        <img src={SettingsIcon} alt="Global settings" />
      </button>
    </div>
  );
};

export default Navigation;
