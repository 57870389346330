import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Handle, Position } from 'react-flow-renderer';
import { useLocation } from 'react-router-dom';
import styles from './WebhookESNode.module.scss';
import Webhook from '../../../../../assets/icons/flows/webhook.svg';
import Edit from '../../../../../assets/icons/flows/edit.svg';
import Divider from '../../../../../assets/icons/divider.svg';
import Delete from '../../../../../assets/icons/flows/delete.svg';
import Copy from '../../../../../assets/icons/copy.svg';
import { handle, right } from '../../../../../utils/flows';
import {
  clearActiveSettings,
  deleteSettings,
  selectConfigsList,
  selectFlowStatus,
  setActiveSettings,
} from '../../../../../store/reducers/flows';
import { showInfoMessage } from '../../../../../components/base/Notifications';

const WebhookESNode = ({ ...props }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [data, setData] = useState();
  const [onNodeHover, setOnNodeHover] = useState(false);

  const link = import.meta.env.VITE_APP_WEBHOOK_ENTRY_SOURCE_LINK;
  const flowId = pathname.split('/')[2];

  const configList = useSelector(selectConfigsList);
  const status = useSelector(selectFlowStatus);

  useEffect(() => {
    if (configList.length) {
      const index = configList.map((object) => object.node_id).indexOf(props.id);
      if (index >= 0) {
        setData(configList[index]);
      }
    }
  }, [configList, props.id]);

  return (
    <>
      <div
        className={`
        ${styles.wrapper}
        ${status ? styles.size120 : ''}
        ${status === 'running' || status === 'scheduled' || status === 'stopped' ? 'cursor-pointer' : ''}
        d-flex
        flex-column
        justify-content-center
        `}
        onMouseEnter={() => setOnNodeHover(true)}
        onMouseLeave={() => setOnNodeHover(false)}
        role="presentation"
        onClick={() => {
          if (status === 'running' || status === 'scheduled' || status === 'stopped') {
            dispatch(setActiveSettings({ type: 'webhookESNode', node_id: props.id }));
          }
        }}
      >
        <div className="d-flex align-items-center justify-content-between">
          {status !== 'running' && status !== 'stopped' && status !== 'scheduled'
            ? (
              <div className={onNodeHover ? styles.delete : styles.delete_hide}>
                <Delete onClick={() => {
                  props.data.onRemove(props.id);
                  dispatch(deleteSettings(props.id));
                  dispatch(clearActiveSettings());
                }}
                />
              </div>
            )
            : null}
          <div className={`${styles.title} d-flex align-items-center gap-2`}>
            <Webhook />
            <div className={styles.description}>
              {(data && data.name) || 'New webhook source'}
            </div>
          </div>
          <div className="cursor-pointer ms-2">
            <Edit onClick={() => dispatch(setActiveSettings({ type: 'webhookESNode', node_id: props.id }))} />
          </div>
        </div>
        {status
          ? (
            <div>
              <div className={`${styles.divider} d-flex`}>
                <Divider />
              </div>
              <div className={`${styles.info} d-flex`}>
                <div className={`${styles.info_col} align-items-center gap-2 w-100`}>
                  <div>
                    Link:
                  </div>
                  <div className="d-flex justify-content-between w-100">
                    <div className={`${styles.link} text-truncate`}>
                      {flowId ? `${link}${flowId}/` : `${link}id`}
                    </div>
                    <Copy
                      className="cursor-pointer"
                      onClick={() => {
                        navigator.clipboard.writeText(flowId ? `${link}${flowId}/` : `${link}id`);
                        showInfoMessage('Link was copied to your clipboard');
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )
          : null}
      </div>
      <Handle
        type="source"
        id="webhookESOutput"
        position={Position.Right}
        style={{ ...handle, ...right }}
      />
    </>
  );
};

export default WebhookESNode;
