import React from 'react';
import TickCircle from '../../../../assets/icons/tick_circle.svg';
import XCircle from '../../../../assets/icons/x-circle.svg';
import styles from './DownloadModal.module.scss';
import Loader from '../../../base/Loader';
import useKeydown from '../../../hooks/app/useKeydown';
import CloseModalButton from '../components/CloseModalButton';

const DownloadModal = ({ setIsOpen, result }) => {
  useKeydown('Escape', () => {
    setIsOpen(false);
    result.reset();
  });
  return (
    <div
      className={`modal modal-dialog-centered ${styles.modal_wrapper}`}
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div
        role="presentation"
        className="modal-dialog modal-md modal-phone w-100 position-relative"
      >
        <div
          className={`
        modal-content p-3 d-flex flex-column align-items-center justify-content-center ${styles.content}
        `}
        >
          <div className={styles.loading}>
            {result.isLoading
              ? <Loader />
              : result.isSuccess
                ? <TickCircle className="" />
                : <XCircle className="" />}
          </div>
          <div className="text-h3-regular text-center mb-2 mt-1 fw-bold d-flex flex-column">
            {result.isLoading
              ? (
                <>
                  <span>Your export is being generated.</span>
                  <span>Please hold, this can take up to a minute.</span>
                </>
              )
              : result.isSuccess
                ? 'The file has been generated.'
                : (
                  <>
                    <span>File generation failed.</span>
                    <span>Please try again later.</span>
                  </>
                )}
          </div>
          {result.isSuccess
            && (
              <div className={`${styles.message} text-center`}>
                If the download does not start in a couple of seconds, please check your browser settings.
              </div>
            )}
          <CloseModalButton onCancel={() => {
            setIsOpen(false);
            result.reset();
          }}
          />
        </div>
      </div>
    </div>
  );
};

export default DownloadModal;
