import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Title from '../../../../components/base/Title';
import uniqueId from '../../../../utils/uniqueId';
import { webSDKApi } from '../../../../api/webSDK';
import Sessions from '../../../../assets/icons/trackedWebsite/Sessions.svg?url';
import Users from '../../../../assets/icons/trackedWebsite/Users.svg?url';
import WalletDetected from '../../../../assets/icons/trackedWebsite/walletDetected.svg?url';
import WalletConnected from '../../../../assets/icons/trackedWebsite/WalletConnected.svg?url';
import Web3 from '../../../../assets/icons/trackedWebsite/Web3.svg?url';
import TopBlocks from '../TopBlocks';
import DailyChart from '../DailyChart';
import styles from './MonthlyActivity.module.scss';
import WidgetCard from '../../../../components/ui/WidgetCard';
import { getCardValue, getPercent } from '../../../../utils/values';

const MonthlyActivity = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [widgetData, setWidgetData] = useState([]);

  const {
    data: topBlock,
    isLoading,
    isFetching,
  } = webSDKApi.useGetWebSDKDataForTopBlocksQuery(id);

  const {
    data: stats,
    isLoading: isStatsLoading,
    isFetching: isStatsFetching,
  } = webSDKApi.useGetWebSDKStatsQuery(id);

  useEffect(() => {
    if (stats) {
      setWidgetData([
        {
          title: 'Sessions',
          value: getCardValue(stats?.sessions),
          icon: Sessions,
          info: 'Total active sessions',
        },
        {
          title: 'Visitors',
          value: getCardValue(stats?.visitors),
          icon: Users,
          info: 'Number of unique visitors',
        },
        {
          title: 'Wallets detected',
          value: getCardValue(stats?.visitors_with_wallets),
          icon: WalletDetected,
          info: 'Unique wallets detected in the browser',
        },
        {
          title: 'Wallets connected',
          value: getCardValue(stats?.visitor_wallets_connected),
          icon: WalletConnected,
          info: 'Unique wallets connected to the webpage',
        },
        {
          title: 'Web3 Adoption',
          value: getPercent(stats.visitors_with_wallets, stats.can_have_wallet, 2, true),
          icon: Web3,
          info: 'Score indicating the weighted Web3 adoption among visitors',
        },
      ]);
    }
  }, [dispatch, stats]);

  return (
    <div className="asset-container">
      <div className="title-gap d-flex align-items-center gap-2">
        <Title>Monthly Activity</Title>
      </div>
      <div className="dropdown-divider w-100" />
      <div className={styles.wrapper}>
        <div className="d-flex justify-content-between gap-4 pb-4">
          {(widgetData.length ? widgetData : [{}, {}, {}, {}, {}]).map((item) => (
            <WidgetCard
              key={uniqueId('website-widget')}
              icon={item.icon}
              info={item.info}
              growth={item.growth}
              title={item.title}
              value={item.value}
              isLoading={isStatsLoading || isStatsFetching}
            />
          ))}
        </div>
        <div className="pb-4">
          <DailyChart />
        </div>
        <div className={`${styles.blockWrapper} d-flex gap-3 justify-content-between`}>
          <TopBlocks title="Top Environments" data={topBlock?.blockchain_environments} isLoading={isLoading || isFetching} />
          <TopBlocks title="Top Wallets" data={topBlock?.wallets} isLoading={isLoading || isFetching} />
          <TopBlocks title="Top Countries" data={topBlock?.countries} isLoading={isLoading || isFetching} />
          <TopBlocks title="Top Sources" data={topBlock?.sources} isLoading={isLoading || isFetching} />
        </div>
      </div>
    </div>
  );
};

export default MonthlyActivity;
