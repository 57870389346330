export const STATIC_FIELDS = [
  'headline',
  'paragraph',
  'image',
  'button',
];

export const INPUT_FIELDS = [
  'connectWallet',
  'email',
  'name',
  'twitter',
  'telegram',
  'instagram',
  'discord',
  'phone',
  'country',
  'language',
  'optIn',
  'questionText',
];
