import React from 'react';
import styles from '../ModalSettings.module.scss';

const Input = ({
  title,
  value,
  placeholder,
  prefix = '',
  onChange,
  maxLength = Number.MAX_SAFE_INTEGER,
  error = '',
  optional = false,
}) => (
  <div className={styles.settings_wrapper}>
    <div className={styles.title_wrapper}>
      <div className={styles.title}>{title}</div>
      {optional && <div className={styles.optional}>Optional</div>}
    </div>
    <div className={`${styles.text_input}`}>
      {prefix ? (
        <span className={styles.prefix}>
          {prefix}
        </span>
      ) : null}
      <input
        placeholder={placeholder}
        type="text"
        value={value}
        maxLength={maxLength}
        onChange={onChange}
        className={prefix ? styles.valueWithPrefix : ''}
      />
    </div>
    {error && (
      <div className={`${styles.error}`}>
        {error}
      </div>
    )}
  </div>
);

export default Input;
