import React from 'react';
import styles from './HeadlineSettings.module.scss';
import AlignRight from '../../../../../../assets/icons/forms/align_right_raw.svg';
import AlignCenter from '../../../../../../assets/icons/forms/align_center_raw.svg';
import AlignLeft from '../../../../../../assets/icons/forms/align_left_raw.svg';

export const tagOptions = [
  {
    value: 'h1',
    elem: <span className={styles.tag_button}>h1</span>,
  },
  {
    value: 'h2',
    elem: <span className={styles.tag_button}>h2</span>,
  },
  {
    value: 'h3',
    elem: <span className={styles.tag_button}>h3</span>,
  },
  {
    value: 'h4',
    elem: <span className={styles.tag_button}>h4</span>,
  },
];

export const styleOptions = [
  {
    value: 'left',
    elem: <AlignLeft />,
  },
  {
    value: 'center',
    elem: <AlignCenter />,
  },
  {
    value: 'right',
    elem: <AlignRight />,
  },
];
