import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import { userApi } from '../../../../api/user';
import Loader from '../../../../components/base/Loader';
import { convertNumber, convertToReadableFormat } from '../../../../tools/NumberConverterTool';
import styles from './Investments.module.scss';
import { counter } from '../../../../tools/Counter';
import AssetImg from '../../../../components/ui/NotableInvestments/AssetImg';
import tableStyles from '../../../../components/base/Table/Table.module.scss';
import { setSelectedAsset } from '../../../../store/reducers/app';
import { supportedBlockchains } from '../../../../utils/supportedBlockchains';
import Table from '../../../../components/base/Table';
import ItemPreview from '../../../../components/ui/ItemPreview';
import ChainList from '../../../../components/base/ChainLogo/ChainList';
import Checkbox from '../../../../components/ui/Checkbox';

const Investments = ({
  investments, portfolioValue, isLoading, page, setPage, setDesc, desc, setCheckedNft, setCheckedToken,
  checkedToken, checkedNft, order, setOrder, limit, setLimit,
}) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const [tableData, setTableData] = useState([]);
  const [myAssets, setMyAssets] = useState([]);

  const { data: assets } = userApi.useGetAssetsQuery();

  useEffect(() => {
    if (assets) {
      setMyAssets(assets.filter((elem) => elem.item_type === 'asset' && elem.name).map((elem) => elem.name));
    } else {
      setMyAssets([]);
    }
  }, [assets]);

  const header = [
    {
      field: '',
      title: '#',
      width: '32px',
    },
    {
      field: 'name',
      title: 'Name',
      width: '400px',
    },
    {
      field: 'token_qty_decimal',
      title: 'Token Qty',
    },
    {
      field: 'value',
      title: 'Value',
    },
    {
      field: 'value',
      title: 'Percent of Portfolio',
    },
  ];

  const convertBalance = (value, assetType) => {
    if (assetType === 'token') {
      return (convertToReadableFormat(value));
    }
    return convertNumber(value);
  };

  useEffect(() => {
    const getAddressAndPlatform = (elem) => {
      const address = elem.address || (elem.addresses && elem.addresses[0]);
      const platform = elem.platform || (elem.blockchains && elem.blockchains[0]);
      return { address, platform };
    };

    if (investments?.results) {
      const temp = investments.results.map((elem, index) => {
        const { address, platform } = getAddressAndPlatform(elem);
        return {
          pageNumber: (
            <span className={styles.number}>{(page - 1) * limit + index + 1}</span>
          ),
          name: (
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex gap-2 align-items-center">
                <div
                  data-for={
                    elem.asset_type === 'nft'
                      ? `preview_${address}_${platform}`
                      : elem.asset_type === 'token'
                        ? `preview_${elem.coin_id}`
                        : null
                  }
                  data-tip="show"
                >
                  <AssetImg logo={elem.logo} type={elem.asset_type} text={elem.asset_type} />
                </div>
                <Link
                  role="presentation"
                  className={`${styles.content_name} ${tableStyles.underline_on_hover} text-truncate`}
                  data-for={
                    elem.asset_type === 'nft'
                      ? `preview_${address}_${platform}`
                      : elem.asset_type === 'token'
                        ? `preview_${elem.coin_id}`
                        : null
                  }
                  data-tip="show"
                  onClick={() => {
                    dispatch(setSelectedAsset({
                      type: elem.asset_type,
                      name: elem.name,
                      logo: elem.logo,
                      symbol: elem.symbol,
                      network: elem.platform,
                    }));
                    setSearchParams({
                      ...Object.fromEntries([...searchParams]),
                      scroll: window.scrollY,
                    });
                  }}
                  to={`/${elem.asset_type === 'token' ? 'coins' : 'nfts'}/${elem.asset_type
                !== 'token' ? `${elem.blockchains[0]}/`
                    : ''}${elem.asset_type === 'token' ? elem.coin_id : elem.addresses[0]}`}
                >
                  {elem.opensea_slug_contract_count > 1 ? elem.contract_name || elem.name : elem.name}
                </Link>
                {elem.asset_type === 'nft'
                  ? (
                    <ItemPreview
                      id={`preview_${address}_${platform}`}
                      data={{
                        address,
                        type: elem.asset_type,
                        blockchain: platform,
                        img: elem.logo,
                        name: elem.contract_name || elem.name,
                      }}
                    />
                  )
                  : null}
                {elem.asset_type === 'token'
                  ? (
                    <ItemPreview
                      id={`preview_${elem.coin_id}`}
                      data={{
                        id: elem.coin_id,
                        type: 'coin',
                      }}
                    />
                  )
                  : null}
              </div>
              <div className={`d-flex align-items-center ${styles.chain}`}>
                <div
                  className={`
                ${styles.asset}
                ${elem.asset_type === 'token' ? styles.red : ''}
                px-3 d-flex justify-content-center align-items-center`}
                >
                  {elem.asset_type === 'token' ? 'Token' : 'NFT Collection'}
                </div>
                {elem.blockchains && elem.blockchains.length
                  ? (
                    <>
                      <div className={`${styles.divider} mx-2`} />
                      <div className={styles.blockchains_block}>
                        <ChainList chains={supportedBlockchains(elem.blockchains)} />
                      </div>
                    </>
                  )
                  : null}
              </div>
            </div>
          ),
          token_qty_decimal: (
            elem.token_qty_decimal !== null
              ? `${convertBalance(+(Math.floor(elem.token_qty_decimal))
                .toLocaleString('fullwide', { useGrouping: false }), elem.asset_type)}
              ${
            elem.asset_type === 'token'
              ? `Token${Math.floor(elem.token_qty_decimal) === 1 ? '' : 's'}`
              : `NFT${Math.floor(elem.token_qty_decimal) === 1 ? '' : 's'}`}`
              : '?'
          ),
          value: elem.value > 0
            ? `$${convertToReadableFormat(+(Math.floor(elem.value))
              .toLocaleString('fullwide', { useGrouping: false }))}`
            : '?',
          portfolio_value: portfolioValue && elem.value !== null
            ? `${((elem.value / portfolioValue) * 100) < 1
              ? '<1'
              : ((elem.value / portfolioValue) * 100).toFixed(2)}%`
            : '<1%',
          highlight: myAssets.includes(elem.name),
        };
      });
      setTableData(temp);
    }
  }, [investments]);

  return (
    <div className={`${styles.wrapper} d-flex w-100 flex-column align-items-center mt-4`}>
      <div className={styles.title}>Investments</div>
      {isLoading && !tableData.length
        ? (
          <div className="d-flex justify-content-center align-items-center p-5 h-75">
            <Loader />
          </div>
        )
        : (
          <>
            <div className="d-flex w-100 justify-content-between align-items-center mb-4">
              <div className={styles.content_info}>
                {counter(investments?.count, page, limit)}
              </div>
              <div className="d-flex gap-3">
                <Checkbox
                  label="Tokens"
                  checked={checkedToken}
                  onChange={() => {
                    setCheckedToken(!checkedToken);
                    setPage(1);
                    setSearchParams({
                      ...Object.fromEntries([...searchParams]),
                      page: 1,
                      sort: !checkedToken,
                    });
                  }}
                  disabled={!checkedNft && checkedToken}
                />
                <Checkbox
                  label="NFTs"
                  checked={checkedNft}
                  onChange={() => {
                    setCheckedNft(!checkedNft);
                    setPage(1);
                    setSearchParams({
                      ...Object.fromEntries([...searchParams]),
                      page: 1,
                      sort: !checkedNft,
                    });
                  }}
                  disabled={checkedNft && !checkedToken}
                />
              </div>
            </div>
            <div className="w-100">
              <Table
                total={investments?.count}
                data={tableData}
                desc={desc}
                setDesc={setDesc}
                ordering={order}
                setOrdering={setOrder}
                page={page}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
                headers={header}
                isLoading={isLoading}
                min={25}
              />
            </div>
          </>
        )}
    </div>
  );
};

export default Investments;
