import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  assetType: null,
};

export const airdropSlice = createSlice({
  name: 'airdrop',
  initialState,
  reducers: {
    setAssetType: (state, action) => {
      state.assetType = action.payload;
    },
    clearState: (state) => {
      state.assetType = null;
    },
  },
});

export const {
  setAssetType,
  clearState,
} = airdropSlice.actions;

export const getAssetType = (state) => state.airdropReducer.assetType;

export default airdropSlice.reducer;
