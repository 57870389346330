import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';
import Table from '../../../../../components/base/Table';
import { counter } from '../../../../../tools/Counter';
import styles from './Responses.module.scss';
import { formsApi } from '../../../../../api/forms';
import DownloadModal from '../../../../../components/ui/modals/DownloadModal';
import ResponseDetailModal from '../../../Modals/ResponseDetailsModal';
import { STATIC_FIELDS } from '../../../Constants';
import Export from '../../../../../components/ui/Buttons/Export';

const ResponsesTable = ({
  url, form, isFormLoading, responses,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [headers, setHeaders] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedResponse, setSelectedResponse] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const [page, setPage] = useState(Number(searchParams.get('page')) || 1);
  const [desc, setDesc] = useState(searchParams.get('order') !== 'false');
  const [order, setOrder] = useState(searchParams.get('orderBy') || 'inserted_at');
  const [limit, setLimit] = useState(searchParams.get('perPage') || 25);

  const {
    currentData: responseData,
    isLoading,
    isFetching,
  } = formsApi.useResponseListQuery(
    {
      url,
      limit,
      offset: (page - 1) * limit,
      order_by: `${order}`,
      order: `${desc ? 'desc' : 'asc'}`,
    },
    { skip: !url },
  );

  const [getResponseListCSV, result] = formsApi.useGetResponseListCSVMutation();

  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const handleRowClick = (response) => {
    const responseModalData = {};
    form.field_settings[0].fields.forEach((field) => {
      if (STATIC_FIELDS.includes(field.type)) {
        return;
      }
      let key = field.options.label;
      if (field.type === 'optIn') {
        const contentWithoutHTML = field.options.content.replace(/<[^>]*>/g, '');
        key = contentWithoutHTML;
      } else if (field.type === 'connectWallet') {
        key = 'Wallet Address';
      }
      if (field.id in response) {
        if (responseModalData[key]) {
          key += `&^%${Math.floor(Math.random() * 1000)}`;
        }
        if (field.type === 'name') {
          const nameValues = response[field.id].obj;
          const value = Object.keys(nameValues).length > 1
            ? `<strong>First Name:</strong> ${nameValues?.first_name ? nameValues.first_name : 'Not Provided'} 
            <br /> <strong>Last Name:</strong> ${nameValues?.last_name ? nameValues.last_name : 'Not Provided'}`
            : `<strong>First Name:</strong> ${nameValues.first_name}`;
          responseModalData[key] = value;
        } else {
          responseModalData[key] = response[field.id]?.resp;
        }
      } else {
        responseModalData[key] = null;
      }
    });
    responseModalData.inserted_at = format(new Date(response.inserted_at.resp), 'eeee, MMM dd, yyyy');
    setSelectedResponse(responseModalData);
    setIsModalVisible(true);
  };

  useEffect(() => {
    if (responseData?.length) {
      const fieldIdToName = {};
      const newFieldIdToLabel = {};

      form?.field_settings.forEach((pages) => {
        pages.fields.forEach((field) => {
          fieldIdToName[field.id] = field.name;
          if (field.type === 'questionText') {
            newFieldIdToLabel[field.id] = field.options.label;
          }
          if (field.type === 'optIn') {
            const contentWithoutHTML = field.options.content.replace(/<[^>]*>/g, '');
            newFieldIdToLabel[field.id] = contentWithoutHTML;
          }
        });
      });

      const processedResponses = responseData.map((response) => {
        const transformedResponse = {};
        Object.keys(response).forEach((key) => {
          if (key === 'inserted_at' || key === 'uuid') {
            transformedResponse[key] = { id: key, resp: response[key] };
          } else if (fieldIdToName[key] === 'name') {
            const newKey = newFieldIdToLabel[key] || fieldIdToName[key] || key;
            let respJson = JSON.parse(response[key]);
            if (respJson === null) {
              respJson = { first_name: '', last_name: '' };
              return;
            }
            const fullName = Object.keys(respJson).length > 1
              ? (`${respJson.first_name} ${respJson.last_name}`) : respJson.first_name;
            transformedResponse[key] = { id: newKey, resp: fullName, obj: respJson };
          } else {
            const newKey = newFieldIdToLabel[key] || fieldIdToName[key] || key;
            transformedResponse[key] = { id: newKey, resp: response[key] };
          }
        });
        return transformedResponse;
      });

      setData(processedResponses);
    }
  }, [responseData, isFormLoading, form]);

  useEffect(() => {
    if (data?.length && !isLoading && !isFetching) {
      const temp = data.map((elem, index) => {
        const row = {};

        row.pageNumber = (
          <span className={styles.number}>{(page - 1) * limit + index + 1}</span>
        );

        headers.forEach((header) => {
          if (header.field !== 'pageNumber' && header.field !== 'inserted_at') {
            row[header.field] = (
              <div
                className="d-flex justify-content-between align-items-center"
                onClick={() => handleRowClick(elem)}
                role="button"
                tabIndex={0}
                onKeyDown={(e) => e.key === 'Enter' && handleRowClick(elem)}
              >
                <div className="d-flex gap-2 align-items-center">
                  <span className={`${styles.content_name} text-truncate`}>
                    {elem[header?.field]?.resp?.length > 0 ? elem[header?.field]?.resp : 'Not Provided'}
                  </span>
                </div>
              </div>
            );
          }
        });

        row.posted_at = (
          <div
            className="d-flex justify-content-between align-items-center"
            onClick={() => handleRowClick(elem)}
            role="button"
            tabIndex={0}
            onKeyDown={(e) => e.key === 'Enter' && handleRowClick(elem)}
          >
            <div className="d-flex gap-2 align-items-center">
              <span className={`${styles.content_name} ${order === "inserted_at" ? styles.bold : ''} text-truncate`}>
                {format(new Date(elem.inserted_at.resp), 'eeee, MMM dd, yyyy')}
              </span>
            </div>
          </div>
        );

        return row;
      });
      setTableData(temp);
    }
  }, [data, limit, page, headers, isLoading, isFetching, order, desc]);

  useEffect(() => {
    if (result.isLoading) {
      setIsOpen(true);
    }
    if (result.isSuccess && result.data) {
      const handleOpen = () => {
        setTimeout(() => {
          window.location.replace(result.data[0].link);
        }, 1000);
      };
      handleOpen();
    }
    if (result.error?.status === 403) {
      setIsOpen(false);
    }
    return () => clearTimeout();
  }, [result]);

  const generateHeaders = () => {
    const priorityHeaders = ['Email', 'Wallet Address', 'Name', 'Twitter', 'Telegram'];
    const baseHeaders = [
      { field: 'pageNumber', title: '#', width: '50px' },
    ];
    const additionalHeaders = form.field_settings[0].fields
      .filter((object) => object.type !== 'headline'
      && object.type !== 'image' && object.type !== 'button' && object.type !== 'paragraph')
      .map((object) => {
        let title = object.options.label;
        if (object.options?.label?.length > 20) {
          title = `${object.options.label.substring(0, 20)}...`;
        } else if (object.type === 'optIn') {
          const contentWithoutHTML = object.options.content.replace(/<[^>]*>/g, '');
          title = `${contentWithoutHTML.substring(0, 20)}...`;
        } else if (object.type === 'connectWallet') {
          title = 'Wallet Address';
        }
        return { field: object.id, title };
      });

    if (Array.isArray(additionalHeaders)) {
      additionalHeaders.sort((a, b) => {
        let indexA = priorityHeaders.indexOf(a.title);
        let indexB = priorityHeaders.indexOf(b.title);
        indexA = indexA === -1 ? priorityHeaders?.length : indexA;
        indexB = indexB === -1 ? priorityHeaders?.length : indexB;
        return indexA - indexB;
      });
    }

    const additionalHeadersArray = Array.from(additionalHeaders);
    while (baseHeaders.length + additionalHeadersArray.length > 6) {
      additionalHeadersArray.pop();
    }
    baseHeaders.push(...additionalHeadersArray);
    baseHeaders.push({ field: 'inserted_at', title: 'Posted on' });
    setHeaders(baseHeaders);
  };

  useEffect(() => {
    if (data?.length && form) {
      generateHeaders();
    }
  }, [data, form, isFormLoading]);

  return (
    <div className={styles.wrapper}>
      {isModalVisible && (
        <ResponseDetailModal
          onCancel={() => setIsModalVisible(false)}
          responseData={selectedResponse}
          headers={headers}
        />
      )}
      <div className={styles.title}>Response List</div>
      <div className="d-flex w-100 justify-content-between align-items-center mb-2">
        <div className={styles.content_info}>
          {counter(responses, page, limit)}
        </div>
        <div className="d-flex gap-3">
          <Export
            onClick={() => getResponseListCSV({
              id: url,
              limit: 50000,
              orderBy: order,
              order: desc ? 'desc' : 'asc',
            })}
            tooltip={"Export responses to CSV"}
            tooltipWarnings={["No more than 50,000 responses will be downloaded"]}
          />
        </div>
      </div>
      <div className={`${styles.table} ${isLoading ? styles.loading_table : ''}`}>
        <Table
          total={responses || 0}
          data={tableData}
          desc={desc}
          setDesc={setDesc}
          ordering={order}
          setOrdering={setOrder}
          page={page}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          headers={headers}
          isLoading={isLoading && !tableData.length}
          min={25}
        />
      </div>
      {isOpen && result?.error?.status !== 403 ? <DownloadModal result={result} setIsOpen={setIsOpen} /> : null}
    </div>
  );
};

export default ResponsesTable;
