import React from 'react';
import styles from './listFilterItem.module.scss';

const ListFilterItem = ({
  id, icon, label, selected, setFilter,
}) => {
  const handleClick = () => {
    if (setFilter) {
      setFilter(id);
    }
  };
  return (
    <button
      className={`${styles.wrapper} ${selected ? styles.selected : ''}`}
      type="button"
      onClick={handleClick}
    >
      {icon}
      {label}
    </button>
  );
};

export default ListFilterItem;
