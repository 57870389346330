import React, { useEffect, useState } from 'react';
import styles from './UniqueModal.module.scss';
import Switch from '../../Forms/CreateForm/Components/ModalSettings/Switch';
import Info from '../../../assets/icons/info_small.svg';

const OtherToggle = ({
  fields, uniqueField, setUniqueField, defaultValue,
}) => {
  const [toggle, setToggle] = useState(false);

  const handleSwitch = () => {
    if (toggle) {
      setUniqueField(defaultValue);
    } else {
      setUniqueField(null);
    }
  };

  useEffect(() => {
    if (uniqueField && !fields.includes(uniqueField)) {
      setToggle(false);
    } else if (!uniqueField) {
      setToggle(true);
    }
  }, [uniqueField]);

  return (
    <div className={styles.toggle_wrapper}>
      <Switch
        text="Use another field as key instead"
        checked={toggle}
        onChange={() => handleSwitch()}
      />
      {toggle && (
        <>
          <div className={styles.input}>
            {fields.map((field) => (
              <div className="d-flex align-items-center gap-1 mb-2" key={field}>
                <input
                  type="radio"
                  id={field}
                  checked={field === uniqueField}
                  style={{ width: '20px', height: '20px' }}
                  onChange={() => {
                    if (uniqueField !== field) {
                      setUniqueField(field);
                    }
                  }}
                />
                <label htmlFor={field}>{field}</label>
              </div>
            ))}
          </div>
          <div className={`${styles.info} d-flex gap-2`}>
            <div>
              <Info />
            </div>
            <div>
              Mapping to the wrong field could create duplicate profiles.
              If you are unsure which field to use, please reach out to your Customer Success Manager.
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default OtherToggle;
