import React, {
  useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
  setCurrentFolder,
  getSelectedItems,
  setSelectedItems,
  getSearchParams,
  setSearchParams,
} from '../../store/reducers/folders';
import emitter from '../../utils/emitter';
import { flowsApi } from '../../api/flows';
import { foldersApi } from '../../api/folders';
import NoFlow from './NoFlow';
import Loader from '../../components/base/Loader';
import FlowsList from './FlowsList';
import ListWithFolders from '../../components/ui/ListWithFolders';
import useSetSearchParams from '../../utils/useSetSearchParams';

const Flows = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const selection = useSelector((state) => getSelectedItems(state, 'flows'));
  const storedSearchParams = useSelector((state) => getSearchParams(state, 'flows'));
  const setParams = useSetSearchParams();

  const [ordering, setOrdering] = useState(searchParams.get('orderBy') || 'created_at');
  const [page, setPage] = useState(searchParams.get('page') || 1);
  const [desc, setDesc] = useState(searchParams.get('desc') || true);
  const [limit, setLimit] = useState(searchParams.get('limit') || 10);
  const [status, setStatus] = useState(searchParams.get('status') || '');
  const [search, setSearch] = useState(searchParams.get('search') || '');
  const [folder, setFolder] = useState(searchParams.get('folder') || 'all');

  const {
    data: flows,
    isLoading,
    isFetching,
    refetch,
  } = flowsApi.useGetFlowsQuery(
    {
      limit,
      offset: (page - 1) * limit,
      ordering: `${desc ? '-' : ''}${ordering}`,
      search,
      status,
      my_items: folder === 'my',
      folders: (folder === 'all' || folder === 'my') ? null : folder,
    },
  );

  useEffect(() => {
    setParams(storedSearchParams);
  }, [storedSearchParams]);

  useEffect(() => {
    refetch();
  }, [limit, page, desc, ordering, search, status, folder, refetch]);

  useEffect(() => {
    const listener = () => {
      refetch();
    };

    emitter.on('refetch_flows', listener);

    return () => {
      emitter.off('refetch_flows', listener);
    };
  }, []);

  useEffect(() => {
    if (flows?.results?.length === 0 && page > 1) {
      setParams({ page: page - 1 });
    }
  }, [flows]);

  const {
    data: folders,
    isLoading: isLoadingFolders,
    refetch: refetchFolders,
  } = foldersApi.useGetFoldersQuery({
    type: 'flows',
  });

  useEffect(() => {
    const listener = () => {
      refetchFolders();
    };

    emitter.on('refetch_folders', listener);

    return () => {
      emitter.off('refetch_folders', listener);
    };
  }, []);

  useEffect(() => {
    if (isLoadingFolders) {
      return;
    }
    if (folder === 'all') {
      dispatch(setCurrentFolder({
        type: 'flows',
        value: {
          id: 'all',
          name: 'All Flows',
          sub_folders: [],
        },
      }));
    } else if (folder === 'my') {
      dispatch(setCurrentFolder({
        type: 'flows',
        value: {
          id: 'my',
          name: 'My Flows',
          sub_folders: [],
        },
      }));
    } else {
      let tempFolder = null;
      let found = false;

      folders.forEach((f) => {
        if (found) return;

        if (f.id === folder) {
          found = true;
          tempFolder = f;
        } else {
          f.sub_folders.forEach((sf) => {
            if (sf.id === folder) {
              found = true;
              tempFolder = sf;
            }
          });
        }
      });

      if (tempFolder) {
        dispatch(setCurrentFolder({
          type: 'flows',
          value: {
            id: tempFolder.id,
            name: tempFolder.name,
            sub_folders: tempFolder.sub_folders,
          },
        }));
      }
    }
  }, [folder, isLoadingFolders]);

  useEffect(() => {
    if (searchParams.get('sort')) {
      setDesc(searchParams.get('sort') === 'desc');
    } else {
      setDesc(true);
    }

    if (searchParams.get('search')) {
      setSearch(searchParams.get('search'));
    } else {
      setSearch('');
    }

    if (searchParams.get('status')) {
      setStatus(searchParams.get('status'));
    } else {
      setStatus('');
    }

    if (searchParams.get('orderBy')) {
      setOrdering(searchParams.get('orderBy'));
    } else {
      setOrdering('created_at');
    }

    if (searchParams.get('page')) {
      setPage(Number(searchParams.get('page')));
    } else {
      setPage(1);
    }

    if (searchParams.get('limit')) {
      setLimit(Number(searchParams.get('limit')));
    } else {
      setLimit(10);
    }

    if (searchParams.get('folder')) {
      if (searchParams.get('folder') === 'all' || searchParams.get('folder') === 'my') {
        setFolder(searchParams.get('folder'));
      } else {
        setFolder(Number(searchParams.get('folder')));
      }
    } else {
      setFolder('all');
    }

    dispatch(setSearchParams({ type: 'flows', value: Object.fromEntries([...searchParams]) }));
  }, [searchParams]);

  useEffect(() => {
    if (flows?.results) {
      const tempSelection = selection.filter((item) => flows.results.some((d) => d.id === item.id));
      dispatch(setSelectedItems({ type: 'flows', value: tempSelection }));
    }
  }, [flows?.results]);

  return useMemo(() => {
    if (isLoadingFolders) {
      return <Loader />;
    }

    return (
      <ListWithFolders
        folders={folders}
        allText="All Flows"
        myText="My Flows"
        type="flows"
      >
        <FlowsList
          data={flows?.results}
          count={flows?.count || 0}
          limit={limit}
          setLimit={setLimit}
          isLoading={isLoading || isFetching}
          refetch={refetch}
          desc={desc}
          ordering={ordering}
          page={page}
          search={search}
          status={status}
        />
      </ListWithFolders>
    );
  }, [
    isLoading,
    isFetching,
    isLoadingFolders,
    flows,
    flows?.results,
    flows?.total_count,
    flows?.count,
    limit,
    refetch,
    desc,
    ordering,
    page,
    folders,
    search,
  ]);
};

export default Flows;
