import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { formatDistanceToNow } from 'date-fns';
import styles from './ItemPreview.module.scss';
import ChainLogo from '../../base/ChainLogo';
import ChainList from '../../base/ChainLogo/ChainList';
import { LoadingAvatar, LoadingLines } from '../modals/SearchModal/LoadingList';
import { coinApi } from '../../../api/coin';
import { convertToReadableFormat, removeExcessZeros } from '../../../tools/NumberConverterTool';
import LogoDisplay from '../modals/SearchModal/LogoDisplay';
import Mail from '../../../assets/icons/email_white.svg';
import Twitter from '../../../assets/icons/twitter_white.svg';
import Discord from '../../../assets/icons/discord_white.svg';
import Phone from '../../../assets/icons/phone_white.svg';
import Airdrop from '../../../assets/icons/airdrop_white.svg';
import Telegram from '../../../assets/icons/telegram_white.svg';
import XMTP from '../../../assets/icons/xmtp_blue.svg';
import NoData from '../../base/NoData';
import { ethFormat } from '../../../utils/singleAssetPage/parseData';
import { supportedBlockchains } from '../../../utils/supportedBlockchains';
import { profilesApi } from '../../../api/profiles';
import AgeDate from '../Table/AgeDate';
import { getPrice } from '../../../utils/values';
import { getTokenUnit } from '../../../utils/chains';

const tagText = ({
  is_exchange_wallet: 'Exchange wallet',
  is_swing_trader: 'Swing trader',
  mints_nfts: 'NFT minter',
  uses_cex: 'CEX user',
  trades_nfts: 'NFT trader',
  trades_tokens: 'Token trader',
});

const ItemPreview = ({
  id, data, collection = '', offsetPrev = { left: -200 },
}) => {
  const [previewData, setPreviewData] = useState(null);
  const [assetInfo, setAssetInfo] = useState(null);
  const [address, setAddress] = useState('');
  const [nftSearchData, setNftSearchData] = useState();
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const handleAfterShow = () => {
    setTooltipVisible(true);
  };

  const handleAfterHide = () => {
    setTooltipVisible(false);
  };

  const getValidALID = () => (data?.alid?.includes('-') && data.alid)
  || (data?.nameId?.id?.includes('-') && data.nameId.id)
  || '';

  const {
    data: nftData,
    isLoading: isNftDataLoading,
    isFetching: isNftDataFetching,
  } = coinApi.useGetNFTLookupQuery(nftSearchData, {
    skip: !nftSearchData || collection || !tooltipVisible,
  });

  const {
    data: walletData,
    isLoading: isWalletDataLoading,
    isFetching: isWalletDataFetching,
  } = profilesApi.useGetWalletALIDLookupQuery({
    address,
    alid: getValidALID(),
  }, {
    skip: data.type !== 'wallet' || !address || !tooltipVisible,
  });

  const {
    currentData: coin,
    isLoading: isLoadingCoin,
    isFetching: isFetchingCoin,
  } = coinApi.useGetTokenLookupQuery(data.id, {
    skip: data.type !== 'coin' || !tooltipVisible,
  });

  const loading = isNftDataLoading
  || isNftDataFetching
    || isWalletDataLoading
    || isWalletDataFetching
    || isLoadingCoin
    || isFetchingCoin;

  const convertValue = (value) => {
    if (value === null || value < 0) return '?';
    return convertToReadableFormat(+value);
  };

  const getAssetName = (name) => (name?.length > 50 ? `${name?.slice(0, 50)}...` : name);

  useEffect(() => {
    if (coin) {
      setPreviewData({
        holders: {
          title: 'Holders',
          value: coin.holder_count ? ethFormat(coin.holder_count) : '0',
        },
        relevant_holders: {
          title: 'Relevant holders',
          value: coin.relevant_holder_count ? ethFormat(coin.relevant_holder_count) : '0',
        },
        price: {
          title: 'Price',
          value: coin.price ? getPrice(coin.price) : '0',
        },
        market_cap: {
          title: 'Market cap',
          value: coin.market_cap ? `$${ethFormat(coin.market_cap)}` : '0',
        },
        daily_volume: {
          title: 'Daily volume',
          value: coin.daily_volume ? `$${ethFormat(coin.daily_volume)}` : '0',
        },
      });
    }
  }, [coin]);

  useEffect(() => {
    if (walletData) {
      const tags = [];
      Object.keys(tagText).forEach((tag) => {
        if (walletData[tag]) {
          tags.push(tagText[tag]);
        }
      });
      setAssetInfo({
        ALID: walletData.ALID,
        alias: walletData.alias,
        address: walletData.address,
        name: walletData.profile_name,
        img: walletData.profile_image_url,
        active_on: walletData.active_on,
        tags,
      });
      setPreviewData({
        total_portfolio_value: {
          title: 'Portfolio Value',
          value: convertValue(walletData.total_portfolio_value) === '?'
            ? '?'
            : `$${convertValue(walletData.total_portfolio_value)}`,
        },
        buying_power: {
          title: 'Buying Power',
          value: convertValue(walletData.buying_power) === '?'
            ? '?'
            : `$${convertValue(walletData.buying_power)}`,
        },
        first_txn_timestamp: {
          title: 'Wallet Age',
          value: walletData.first_txn_timestamp
            ? <AgeDate date={walletData.first_txn_timestamp} defaultText="No Transactions" isAge />
            : 'No Transactions',
        },
        last_txn_timestamp: {
          title: 'Last Activity',
          value: walletData.last_txn_timestamp
            ? `${formatDistanceToNow(new Date(walletData.last_txn_timestamp), { addSuffix: true })}`
            : 'No Transactions',
        },
      });
    }
  }, [walletData]);

  useEffect(() => {
    if (nftData) {
      setPreviewData({
        holders: {
          title: 'Holders',
          value: convertValue(nftData.holder_count),
        },
        items: {
          title: '# Items',
          value: convertValue(nftData.count),
        },
        perHolder: {
          title: 'Per holder',
          value: convertValue((nftData.count / nftData.holder_count).toFixed(2)),
        },
        volume7d: {
          title: '7d volume',
          value: nftData.volume_7d === undefined || nftData.volume_7d === null
            ? `0 ${getTokenUnit(nftData.blockchain)}`
            : `${ethFormat(nftData.volume_7d)} ${getTokenUnit(nftData.blockchain)}`,
        },
        over30d: {
          title: '30d stickiness',
          value: nftData.pct_held_30d ? `${removeExcessZeros(nftData.pct_held_30d, 2)}%` : '-',
        },
      });
    }
  }, [nftData]);

  return (
    <ReactTooltip
      id={id}
      backgroundColor="white"
      arrowColor="transparent"
      multiline
      place="bottom"
      effect="solid"
      className={styles.wrapper}
      textColor="#192a3e"
      delayShow={1000}
      offset={offsetPrev}
      afterShow={() => {
        handleAfterShow();
        if (data.type === 'nft') {
          setNftSearchData({
            address: data.nameId?.id || data.address,
            blockchain: (data.platforms && data.platforms[0]) || data.blockchain,
          });
        } else {
          setAddress(data.id);
        }
      }}
      afterHide={handleAfterHide}
    >
      <div style={{ minWidth: '480px', minHeight: '125px' }}>
        {!loading && (walletData === null && !coin)
          ? <NoData />
          : (
            <>
              <div className="d-flex gap-3 align-items-center mb-3">
                {loading
                  ? (
                    <div style={{ minWidth: '64px' }}>
                      <LoadingAvatar size={64} />
                    </div>
                  )
                  : (
                    <LogoDisplay
                      logo={data.img || assetInfo?.img || coin?.image_url}
                      type={data.type}
                      asset={data.type}
                      size="64px"
                      position="static"
                    />
                  )}
                {loading
                  ? (
                    <div style={{ minWidth: '200px' }}>
                      <LoadingLines rows={1} height="unset" />
                    </div>
                  )
                  : (
                    <div
                      className={`
                        ${assetInfo && data.type === 'wallet' ? 'gap-1' : 'gap-2'}
                        d-flex flex-column
                      `}
                    >
                      {/* show name */}
                      <div className="d-flex align-items-center gap-2">
                        <div className={`${styles.name}`}>
                          {
                            assetInfo
                              ? getAssetName(
                                data.type === 'wallet'
                                  ? assetInfo?.alias || assetInfo?.name || assetInfo?.address
                                  : assetInfo?.name || data?.name,
                              )
                              : `${getAssetName(data?.name || coin?.name)}`
                          }
                        </div>
                        {assetInfo && data.type === 'wallet'
                          ? (
                            <ChainList chains={supportedBlockchains(assetInfo.active_on)} />
                          )
                          : null}
                        {data.type === 'coin' && coin?.symbol
                          ? (
                            <span className={styles.symbol}>{`$${coin.symbol}`}</span>
                          )
                          : null}
                      </div>
                      {/* show address */}
                      {assetInfo && data.type === 'wallet'
                        ? (
                          <div className={styles.wallet_additional_info}>
                            {`${assetInfo?.address?.slice(0, 7)}...`}
                          </div>
                        )
                        : null}
                      {data.type === 'nft' || data.type === 'coin'
                        ? (
                          <div className="d-flex gap-2 align-items-center">
                            <div
                              className={`
                                ${data.type === 'nft' ? styles.nft_pill : styles.token_pill}
                                px-3 d-flex align-items-center align-self-start
                              `}
                            >
                              {data.type === 'nft' ? 'NFT Collection' : 'Token'}
                            </div>
                            <div className={styles.divider} />
                            {data?.blockchain ? (
                              <div className="d-flex align-items-center">
                                <ChainLogo chain={data.blockchain} />
                              </div>
                            ) : null}
                            {coin ? (
                              <div className="d-flex gap-2 align-items-center">
                                {supportedBlockchains(coin.blockchains?.split(', '))
                                  .map((platform) => <ChainLogo chain={platform} />)}
                              </div>
                            ) : null}
                          </div>
                        )
                        : null}
                    </div>
                  )}
              </div>
              {assetInfo && data.type === 'wallet'
                ? (
                  <div className="d-flex gap-2 mb-3">
                    {assetInfo.tags.map((tag) => (
                      <div key={tag} className={styles.tag}>
                        {tag}
                      </div>
                    ))}
                  </div>
                )
                : null}
              {data.type === 'wallet'
                ? <div className={`${styles.h_divider} mb-3`} />
                : null}
              <div>
                {loading
                  ? (
                    <div style={{ minWidth: '460px' }}>
                      <LoadingLines rows={1} height="unset" />
                    </div>
                  )
                  : (
                    <div className={`${styles.stats} d-flex`}>
                      {collection
                        ? <div className={styles.collection}>Multiple collections, click on logo to expand</div>
                        : previewData && Object.keys(previewData).map((elem) => (
                          <div className="d-flex flex-column align-items-center" key={elem}>
                            <div className={styles.value}>{previewData[elem].value}</div>
                            <div className={styles.title}>{previewData[elem].title}</div>
                          </div>
                        ))}
                    </div>
                  )}
              </div>
              {data.type === 'wallet' && !loading
                ? <div className={`${styles.h_divider} my-3`} />
                : null}
              {data.type === 'wallet' && walletData && !loading
                ? (
                  <div className="d-flex align-items-center gap-3">
                    <div className={styles.reach}>Reachable via:</div>
                    <Airdrop style={{ color: '#2E3888' }} />
                    {walletData.email && <Mail style={{ color: '#2E3888' }} />}
                    {walletData.phone && <Phone style={{ color: '#2E3888' }} />}
                    {walletData.twitter_id && <Twitter style={{ color: '#2E3888' }} />}
                    {walletData.discord_id && <Discord style={{ color: '#2E3888' }} />}
                    {walletData.telegram_id && <Telegram style={{ color: '#2E3888' }} />}
                    {walletData.can_message_on_xmtp && <XMTP />}
                  </div>
                )
                : null}
            </>
          )}
      </div>
    </ReactTooltip>
  );
};

export default ItemPreview;
