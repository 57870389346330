import React from 'react';
import { useLocation } from 'react-router-dom';
import NotableInvestments from '../../../components/ui/NotableInvestments';
import SharedInterests from '../../../components/ui/SharedInterests';
import { audiencesApi } from '../../../api/audiences';
import styles from './InvestorProfile.module.scss';
import TopStats from '../../../components/ui/TopStats';
import LastActivity from '../../../components/ui/DonutCharts/LastActivity';
import MultiChainActivity from '../../../components/ui/DonutCharts/MultiChainActivity';
import PortfolioValue from '../../../components/ui/DonutCharts/PortfolioValue';
import BuyingPower from '../../../components/ui/DonutCharts/BuyingPower';
import WalletAge from '../../../components/ui/DonutCharts/WalletAge';
import TypicalPortfolioDistribution from '../../../components/ui/DonutCharts/TypicalPortfolioDistribution';

const InvestorProfile = ({ holders, audienceProfileData, audienceProfileIsLoading }) => {
  const { pathname } = useLocation();

  const {
    currentData: notableInvestments,
    isLoading: isNotableInvestmentsLoading,
    isFetching: isNotableInvestmentsFetching,
  } = audiencesApi.useGetSegmentNotableInvestmentsQuery(
    {
      id: pathname.split('/')[2],
    },
    {
      skip: !pathname.split('/')[2],
    },
  );

  const {
    currentData: sharedInterestsData,
    isLoading: sharedInterestsIsLoading,
    isFetching: sharedInterestsIsFetching,
  } = audiencesApi.useGetSegmentShareInterestQuery(
    {
      id: pathname.split('/')[2],
    },
    {
      skip: !pathname.split('/')[2],
    },
  );

  return (
    <div className={`${styles.wrapper}`}>
      <div className="row">
        <div className="col-12 pb-1">
          <TopStats data={audienceProfileData} isLoading={audienceProfileIsLoading} />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-sm-12 col-md-6 col-lg-3">
          <PortfolioValue
            activityData={audienceProfileData?.portfolio_value_distribution}
            isLoading={audienceProfileIsLoading}
            className="h-100"
          />
        </div>
        <div className="col-sm-12 col-md-6 col-lg-3 mt-4 mt-md-0">
          <BuyingPower
            activityData={audienceProfileData?.buying_power_distribution}
            isLoading={audienceProfileIsLoading}
            className="h-100"
          />
        </div>
        <div className="col-sm-12 col-md-6 col-lg-3 mt-4 mt-lg-0">
          <WalletAge
            activityData={audienceProfileData?.wallet_age_distribution}
            isLoading={audienceProfileIsLoading}
            className="h-100"
          />
        </div>
        <div className="col-sm-12 col-md-6 col-lg-3 mt-4 mt-lg-0">
          <TypicalPortfolioDistribution
            activityData={audienceProfileData?.typical_portfolio_distribution}
            isLoading={audienceProfileIsLoading}
            className="h-100"
          />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-sm-12 col-md-6 col-lg-4">
          <NotableInvestments
            text="tokens"
            symbol="audience"
            isLoading={isNotableInvestmentsLoading || isNotableInvestmentsFetching}
            data={notableInvestments?.tokens}
            holders={holders}
            info="Tokens most commonly held by holders of this audience"
          />
        </div>
        <div className="col-12 col-lg-6 col-xl-4 mt-4 mt-lg-0">
          <NotableInvestments
            text="NFTs"
            symbol="audience"
            isLoading={isNotableInvestmentsLoading || isNotableInvestmentsFetching}
            data={notableInvestments?.nfts}
            holders={holders}
            info="NFTs most commonly held by holders of this audience"
          />
        </div>
        <div className="col-12 col-lg-6 col-xl-4 mt-4 mt-xl-0">
          <SharedInterests
            symbol="AUDIENCE"
            data={sharedInterestsData}
            isLoading={sharedInterestsIsLoading}
            isFetching={sharedInterestsIsFetching}
            info="Topics of interest for audience"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-6 col-lg-6 mt-4">
          <LastActivity
            activityData={audienceProfileData?.last_activity_distribution}
            isLoading={audienceProfileIsLoading}
            className="h-100"
          />
        </div>
        <div className="col-sm-12 col-md-6 col-lg-6 mt-4">
          <MultiChainActivity
            activityData={audienceProfileData?.multi_chain_activity}
            isLoading={audienceProfileIsLoading}
            walletCount={audienceProfileData?.wallet_count}
            className="h-100"
          />
        </div>
      </div>
    </div>
  );
};

export default InvestorProfile;
