/* eslint-disable max-len */
import React from 'react';

export const Activity = ({ hover }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.3333 7.08464C18.3333 10.0763 15.9083 12.5013 12.9167 12.5013C12.775 12.5013 12.625 12.493 12.4833 12.4846C12.275 9.84298 10.1583 7.72629 7.51666 7.51796C7.50832 7.37629 7.5 7.2263 7.5 7.08464C7.5 4.09297 9.925 1.66797 12.9167 1.66797C15.9083 1.66797 18.3333 4.09297 18.3333 7.08464Z"
      stroke={hover ? 'white' : '#192A3E'}
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.4998 12.9167C12.4998 15.9083 10.0748 18.3333 7.08317 18.3333C4.0915 18.3333 1.6665 15.9083 1.6665 12.9167C1.6665 9.925 4.0915 7.5 7.08317 7.5C7.22484 7.5 7.37483 7.50832 7.51649 7.51666C10.1582 7.72499 12.2748 9.84168 12.4832 12.4833C12.4915 12.625 12.4998 12.775 12.4998 12.9167Z"
      stroke={hover ? 'white' : '#192A3E'}
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.35 12.182L7.08333 10.832L7.81667 12.182L9.16667 12.9154L7.81667 13.6487L7.08333 14.9987L6.35 13.6487L5 12.9154L6.35 12.182Z"
      stroke={hover ? 'white' : '#192A3E'}
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ProfileAttributes = ({ hover }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.05 8.70139L17.2333 12.1847C16.5333 15.1931 15.15 16.4097 12.55 16.1597C12.1333 16.1264 11.6833 16.0514 11.2 15.9347L9.79999 15.6014C6.32499 14.7764 5.24999 13.0597 6.06665 9.57639L6.88332 6.08472C7.04999 5.37639 7.24999 4.75972 7.49999 4.25139C8.47499 2.23472 10.1333 1.69306 12.9167 2.35139L14.3083 2.67639C17.8 3.49306 18.8667 5.21806 18.05 8.70139Z"
      stroke={hover ? 'white' : '#192A3E'}
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5498 16.1583C12.0331 16.5083 11.3831 16.8 10.5915 17.0583L9.2748 17.4917C5.96646 18.5583 4.2248 17.6667 3.1498 14.3583L2.08313 11.0667C1.01646 7.75833 1.8998 6.00833 5.20813 4.94167L6.5248 4.50833C6.86646 4.4 7.19146 4.30833 7.4998 4.25C7.2498 4.75833 7.0498 5.375 6.88313 6.08333L6.06646 9.575C5.2498 13.0583 6.3248 14.775 9.7998 15.6L11.1998 15.9333C11.6831 16.05 12.1331 16.125 12.5498 16.1583Z"
      stroke={hover ? 'white' : '#192A3E'}
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5332 7.10938L14.5749 8.13438"
      stroke={hover ? 'white' : '#192A3E'}
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.7168 10.332L12.1335 10.9487"
      stroke={hover ? 'white' : '#192A3E'}
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>

);

export const Investments = ({ hover }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.22656 11.9422C7.22656 13.0172 8.05156 13.8839 9.07656 13.8839H11.1682C12.0599 13.8839 12.7849 13.1255 12.7849 12.1922C12.7849 11.1755 12.3432 10.8172 11.6849 10.5839L8.32656 9.41719C7.66823 9.18385 7.22656 8.82552 7.22656 7.80885C7.22656 6.87552 7.95156 6.11719 8.84323 6.11719H10.9349C11.9599 6.11719 12.7849 6.98385 12.7849 8.05885"
      stroke={hover ? 'white' : '#192A3E'}
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 5V15"
      stroke={hover ? 'white' : '#192A3E'}
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.4998 18.3346H7.49984C3.33317 18.3346 1.6665 16.668 1.6665 12.5013V7.5013C1.6665 3.33464 3.33317 1.66797 7.49984 1.66797H12.4998C16.6665 1.66797 18.3332 3.33464 18.3332 7.5013V12.5013C18.3332 16.668 16.6665 18.3346 12.4998 18.3346Z"
      stroke={hover ? 'white' : '#192A3E'}
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>

);

export const Lists = ({ hover }) => (
  <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.799959 0H15.2C15.6419 0 16 0.454861 16 0.823529C16 1.27839 15.6419 1.64706 15.2 1.64706H0.799959C0.358116 1.64706 0 1.1922 0 0.823529C0 0.368668 0.358116 0 0.799959 0Z"
      fill={hover ? 'white' : '#192A3E'}
    />
    <path
      d="M0.799959 4.11719H15.2C15.6419 4.11719 16 4.57205 16 4.94072C16 5.39558 15.6419 5.76425 15.2 5.76425H0.799959C0.358116 5.76425 0 5.30938 0 4.94072C0 4.48586 0.358116 4.11719 0.799959 4.11719Z"
      fill={hover ? 'white' : '#192A3E'}
    />
    <path
      d="M0.799952 8.23438H7.20005C7.64189 8.23438 8 8.68924 8 9.0579C8 9.51277 7.64189 9.88143 7.20005 9.88143H0.799952C0.358113 9.88143 0 9.42657 0 9.0579C0 8.60304 0.358113 8.23438 0.799952 8.23438Z"
      fill={hover ? 'white' : '#192A3E'}
    />
    <path
      d="M12.8 7.41016C13.2419 7.41016 13.6 7.865 13.6 8.23365V13.1749C13.6 13.6297 13.2419 13.9984 12.8 13.9984C12.3581 13.9984 12 13.5436 12 13.1749V8.23365C12 7.77881 12.3581 7.41016 12.8 7.41016Z"
      fill={hover ? 'white' : '#192A3E'}
    />
    <path
      d="M10.4015 9.88281H15.2016C15.6434 9.88281 16.0016 10.3377 16.0016 10.7063C16.0016 11.1612 15.6434 11.5299 15.2016 11.5299H10.4015C9.95968 11.5299 9.60156 11.075 9.60156 10.7063C9.60156 10.2515 9.95968 9.88281 10.4015 9.88281Z"
      fill={hover ? 'white' : '#192A3E'}
    />
  </svg>
);

export const Portfolio = ({ hover }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.66685 18.3333H13.3335C16.6835 18.3333 17.2835 16.9917 17.4585 15.3583L18.0835 8.69167C18.3085 6.65833 17.7252 5 14.1668 5H5.83351C2.27518 5 1.69185 6.65833 1.91685 8.69167L2.54185 15.3583C2.71685 16.9917 3.31685 18.3333 6.66685 18.3333Z"
      stroke={hover ? 'white' : '#192A3E'}
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.6665 5.0013V4.33464C6.6665 2.85964 6.6665 1.66797 9.33317 1.66797H10.6665C13.3332 1.66797 13.3332 2.85964 13.3332 4.33464V5.0013"
      stroke={hover ? 'white' : '#192A3E'}
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6668 10.8333V11.6667C11.6668 11.675 11.6668 11.675 11.6668 11.6833C11.6668 12.5917 11.6585 13.3333 10.0002 13.3333C8.35016 13.3333 8.3335 12.6 8.3335 11.6917V10.8333C8.3335 10 8.3335 10 9.16683 10H10.8335C11.6668 10 11.6668 10 11.6668 10.8333Z"
      stroke={hover ? 'white' : '#192A3E'}
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.0415 9.16797C16.1165 10.568 13.9165 11.4013 11.6665 11.6846"
      stroke={hover ? 'white' : '#192A3E'}
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.18311 9.39062C4.05811 10.674 6.17477 11.449 8.33311 11.6906"
      stroke={hover ? 'white' : '#192A3E'}
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Segments = ({ hover }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.2667 10.0013C17.4333 10.0013 18.3333 9.16796 17.5333 6.43462C16.9917 4.59296 15.4083 3.00962 13.5667 2.46796C10.8333 1.66796 10 2.56796 10 4.73462V7.13462C10 9.16796 10.8333 10.0013 12.5 10.0013H15.2667Z"
      stroke={hover ? 'white' : '#192A3E'}
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.6667 12.2482C15.8917 16.1065 12.1917 18.9065 7.98336 18.2232C4.82502 17.7148 2.28336 15.1732 1.76669 12.0148C1.09169 7.82318 3.87502 4.12318 7.71669 3.33984"
      stroke={hover ? 'white' : '#192A3E'}
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>

);
