import React from 'react';
import { components } from 'react-select';

const VALUE_PREFIX = 'In ';

export const SingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>
    {VALUE_PREFIX + children}
  </components.SingleValue>
);

export const Placeholder = ({ children, ...props }) => (
  <components.Placeholder {...props}>
    {VALUE_PREFIX + children}
  </components.Placeholder>
);
